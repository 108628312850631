import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { AlertController } from '@ionic/angular';
import { LoaderService } from '../../../services/loader.service'
import { AdminUserDetailService } from '../../../services/admin-user-detail.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpClient, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { AppService } from 'src/app/services/app.service';
declare var require: any;
var Excel = require('exceljs');
import * as fs from 'file-saver';
@Component({
  selector: 'app-tables-prompt',
  templateUrl: './tables-prompt.component.html',
  styleUrls: ['./tables-prompt.component.scss'],
})
export class TablesPromptComponent implements OnInit {
  public currentTables: TablesPromptDataset;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public loadingSrv: LoaderService,
    public adminUserDetailSrv: AdminUserDetailService,
    public storaveVariableSrv: StorageVariablesService,
    public router: Router,
    public httpUtilitySrv: HttpUtilityService,
    private _http: HttpClient,
    private _appSrv: AppService,
    public modalCtrl: ModalController,
    public navParams: NavParams) {
    this.currentTables = new TablesPromptDataset(this.navParams.get('datasetJson'), this);
  }

  ngOnInit() {

  }

  //extract
  public extractTables() {
    if (this.currentTables) {
      let workbook = new Excel.Workbook();
      for (let table of this.currentTables.tables) {
        let worksheet = workbook.addWorksheet(table.title);
        let headers = table.headers;
        worksheet.addRow(headers);
        for (let row of table.rows) {
          let tempRow = [];
          for (let header of headers) {
            tempRow.push(row[header]);
          }
          worksheet.addRow(tempRow);
        }
      }
      let dt = new Date();
      let fname = this.translate.instant('labels.extraction') + "_" + (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()) + "-" + ((dt.getMonth() + 1) < 10 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + dt.getFullYear() + "_" + dt.getHours() + "-" + dt.getMinutes();
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
    }
  }
}

export class TablesPromptDataset {
  public tables: Array<{ headers: Array<string>, rows: Array<any>, title: string }>;
  constructor(dataSetJson: Array<any>, context: TablesPromptComponent) {
    this.tables = [];
    if (dataSetJson) {
      let jsonToExtract;
      if (Array.isArray(dataSetJson)) {
        let newJson = {};
        newJson[context.translate.instant('labels.extractedData')] = dataSetJson;
        jsonToExtract = newJson;
      } else {
        jsonToExtract = dataSetJson;
      }
      for (let table in jsonToExtract) {
        if (jsonToExtract[table] && Array.isArray(jsonToExtract[table])) {
          //calcolo headers ciclando tutte le righe in caso in cui abbiano proprietà diverse
          let headers: Array<string> = [];
          jsonToExtract[table].forEach((row) => {
            for (let property in row) {
              if (!headers.includes(property))
                headers.push(property)
            }
          })
          //aggiungo la tabella alla lista
          this.tables.push({ headers: headers, rows: jsonToExtract[table], title: table });
        }
      }
    }
  }
}