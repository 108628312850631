import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormat implements PipeTransform {

  constructor() { }
  transform(date, type: 'DATE' | 'DATETIME') {
    try {
      let dt = new Date(date);
      if (['en-US'].includes(navigator.languages[0])) {
        return ("0" + (dt.getMonth() + 1)).slice(-2) + "/" +
          ("0" + dt.getDate()).slice(-2) + "/" +
          dt.getFullYear() + (type == 'DATETIME' ? " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2) : '');
      }
      return ("0" + dt.getDate()).slice(-2) + "/" +
        ("0" + (dt.getMonth() + 1)).slice(-2) + "/" +
        dt.getFullYear() + (type == 'DATETIME' ? " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2) : '');
    }
    catch(err) {
      return date;
    }
  }
}