import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpUtilityService } from './http-utility.service';
import { AuthService } from './auth-service.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LegacyFrontendContainerService {
  //current legacy frontend
  public currentLegacyFrontendChangeByMenu = new BehaviorSubject<LegacyMenuItem>(null);

  constructor(public http: HttpClient, private _httpUtilitySrv: HttpUtilityService, private _authSrv: AuthService, public location: Location) { }

  //setCurrentLegacyFrontend
  public setCurrentLegacyFrontendByMenu(menuItem: LegacyMenuItem) {
    this.currentLegacyFrontendChangeByMenu.next(menuItem);
  }
  public async iframeUrlChanged(iframeUrl: string) {
    this.location.go('/legacy?legacyUrl=' + iframeUrl);
  }

  //menu
  public async checkForLegacyModules(items: Array<any>) {
    if (items?.length > 0) {
      for (let item of items) {
        if (['gem_legacy'].includes(item.id)) {
          await this._getTokenServizio(item);
        }
        if (item.figli?.length > 0)
          await this.checkForLegacyModules(item.figli);
      }
    }
    return true;
  }
  private async _getTokenServizio(menuItem: any) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(menuItem.id);
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        let sessionId = await this._getLegacySessionId(menuItem, tokenServizio.payload.token);
        if (sessionId != null && sessionId != undefined && sessionId != '') {
          let menu: LegacyMenuItem = await this._getLegacyMenuItems(menuItem);
          if (menu?.Items) {
            this._getLegacyMenuFormatted(menu.Items, menuItem.url, menuItem.id);
            menuItem.figli = menu.Items;
          }
        }
      }
      resolve(true);
    })
  }
  //get token servizio api request
  private _getTokenServizioAwait(moduloId) {
    return new Promise((resolve) => {
      let payload = {
        "idModulo": moduloId
      }
      this.http.post(`${this._httpUtilitySrv.links.server}getTokenServizio`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        resolve(null);
      })
    });
  }
  private async _getLegacySessionId(legacyItem: any, token: string) {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append('token', token);
      this.http.post(this.decodeUrl(legacyItem.url) + (endpoint[legacyItem.id] ? endpoint[legacyItem.id].login : ''), formData, { withCredentials: true, responseType: 'text' })
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private async _getLegacyMenuItems(legacyItem: any): Promise<LegacyMenuItem> {
    return new Promise((resolve) => {
      this.http.get(this.decodeUrl(legacyItem.url) + (endpoint[legacyItem.id] ? endpoint[legacyItem.id].menu : ''), { withCredentials: true })
        .subscribe((res: any) => {
          resolve(res as LegacyMenuItem);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private _getLegacyMenuFormatted(legacyMenu: Array<LegacyMenuItem>, baseurl: string, parentId: string) {
    if (legacyMenu?.length) {
      for (let legacyItem of legacyMenu) {
        legacyItem.url = legacyItem.NavigateUrl ? ("/legacy?legacyUrl=" + baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl))) : null;
        legacyItem.NavigateUrl = legacyItem.NavigateUrl ? baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl)) : null;
        legacyItem.id = parentId + '.' + legacyItem.Text;
        legacyItem.nome = legacyItem.Text;
        legacyItem.idtipoModulo = legacyItem.NavigateUrl ? 2 : 1;
        legacyItem.figli = legacyItem?.Items?.length ? JSON.parse(JSON.stringify(legacyItem.Items)) : null;
        legacyItem.tipoRoot = "MAIN";
        legacyItem.legacyFrontend = parentId.indexOf('.') != -1 ? parentId : parentId.substring(0, parentId.indexOf('.'));
        if (legacyItem.figli?.length)
          this._getLegacyMenuFormatted(legacyItem.figli, baseurl, legacyItem.id);
      }
    }
    return true;
  }
  public decodeUrl(url: string): string {
    try {
      return decodeURIComponent(url);
    } catch (e) {
      return null;
    }
  }


  //silentLoginLegacy
  public silentLoginLegacy(legacyMenuItem: LegacyMenuItem) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(legacyMenuItem ? legacyMenuItem.id.substring(legacyMenuItem.id.indexOf('.')) : new URLSearchParams(window.location.search).get('legacyUrl'));
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        await this._getLegacySessionId(legacyMenuItem, tokenServizio.payload.token);
        resolve(true);
      }
      resolve(true);
    })
  }
  //getCurrentLegacyFrontend
  public async getLegacyMenuItemByUrl(legacyUrl: string): Promise<LegacyMenuItem> {
    return new Promise(async (resolve) => {
      let legacyItem: LegacyMenuItem;
      let authObj: any = await this._authSrv.getAuthObject().catch(() => { });
      let moduleId: string = this.getModuleIdByUrl(legacyUrl);
      legacyItem = this._getLegacyMenuItemById(moduleId, authObj?.currentMenu);
      resolve(legacyItem);
    })
  }
  private _getLegacyMenuItemById(id: string, menuItem: LegacyMenuItem): LegacyMenuItem {
    if (menuItem.id == id)
      return menuItem;

    if (menuItem.figli && menuItem.figli.length > 0) {
      for (let i = 0; i < menuItem.figli.length; i++) {
        const result = this._getLegacyMenuItemById(id, menuItem.figli[i]);
        if (result) {
          return result; // Nodo trovato, restituisci il risultato
        }
      }
    }
    return null;
  }

  //getItemIdByUrl
  public getModuleIdByUrl(url: string): string {
    if (url.indexOf('/GEM/') != -1)
      return 'gem_legacy';
    else
      return null;
  }
}
export const endpoint = {
  gem_legacy: { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" }
}
export interface LegacyMenuItem {
  IconName: string,
  NavigateUrl: string,
  Target: string,
  Text: string,
  ToolTip: string,
  Value: string,
  Items: Array<LegacyMenuItem>
  url: string,
  id: string,
  idtipoModulo: number,
  nome: string,
  figli: Array<LegacyMenuItem>,
  tipoRoot: string,
  legacyFrontend: string,
  params: any,
}