import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccessConfigurationService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }


  //getListaTemplateServiziSafo api request
  public getListaTemplateServiziSafo(serviceId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getListaTemplateServiziSafo/` + serviceId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }


  //dettaglioTemplateServiziSafo api request
  public dettaglioTemplateServiziSafo(templateId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}dettaglioTemplateServiziSafo/` + templateId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
