<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.searchStateHistory' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content>
  <div class="search-state-history-list" *ngIf="searchStateHistory && searchStateHistory.length > 0">
    <div class="search-state-history-item" *ngFor="let item of searchStateHistory; let i = index;" [ngClass]="{'last-search': i == 0}">
      <input type="text" [(ngModel)]="item.title" *ngIf="i != 0" (blur)="itemChanged(item)"
        placeholder="{{'labels.insert' | translate}}">
      <span *ngIf="i == 0">{{'labels.lastSearch' | translate}}</span>
      <span>
        <ion-icon name="close" color="danger" title="{{'labels.delete' | translate}}" *ngIf="i != 0"
          (click)="deleteItem(item)"></ion-icon>
        <ion-icon name="arrow-forward" color="primary" title="{{'labels.use' | translate}}"
          (click)="useSearchStateObject(item)"></ion-icon>
      </span>
    </div>
  </div>
  <div class="no-history" *ngIf="!searchStateHistory || searchStateHistory.length== 0">
    {{'labels.noSearchStateHistory' | translate}}
  </div>
</ion-content>
<ion-footer>
  <div class="search-state-history-buttons">
    <input type="text" [(ngModel)]="currentSearchTitle" placeholder="{{'labels.title' | translate}}">
    <ion-button size="default" color="primary" (click)="addCurrentSearch()"
      [disabled]="!currentSearchTitle">{{'labels.saveCurrentSearch' |
      translate}}</ion-button>
  </div>
</ion-footer>