import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../app/services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { MainLayout } from '../../pages/postit-board-main/postit-board-main.page';
import { AppService } from 'src/app/services/app.service';
import { PostitBoardListManagementComponent, listManagementProperty } from '../../modals/postit-board-list-management/postit-board-list-management.component';
import { BoardMainService } from '../../services/board-main.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';

@Component({
  selector: 'app-postit-board-board',
  templateUrl: './postit-board-board.component.html',
  styleUrls: ['./postit-board-board.component.scss'],
})
export class PostitBoardBoardComponent implements OnInit {
  //mainLayout
  @Input() mainLayout: MainLayout;
  @Output() updateMainLayout = new EventEmitter<any>();
  @Output() enterResourceMode = new EventEmitter<any>();
  //dragStart
  @Output() dragStart = new EventEmitter<any>();
  //dragEnd
  @Output() dragEnd = new EventEmitter<any>();


  constructor(
    public translate: TranslateService,
    public languageSrv: LanguageService,
    private router: Router,
    public alertCtrl: AlertController,
    public route: ActivatedRoute,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    private appSrv: AppService,
    private postitBoardSrv: BoardMainService,
    private loaderSrv: LoaderService,
    private postitBoardUtilitySrv: PostitBoardUtilityService) {
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.mainLayout) {
      this.mainLayout = changes.mainLayout.currentValue;
    }
  }

  //--- DRAG AND DROP ---//
  public emitDragStart(target, event) {
    this.dragStart.emit({ target: target, event: event })
  }
  public emitDragEnd(event) {
    this.dragEnd.emit(event)
  }
  //--- END DRAG AND DROP ---//

  //--- BOARD ---//
  //handleSectionClick
  async sectionClick(section) {
    if (section.attivita?.codice && section.attivita?.idGruppo) {
      let properties: Array<listManagementProperty> = [
        {
          name: 'codice',
          type: 'LABEL',
          width: '15%'
        },
        {
          name: 'attivita',
          type: 'LABEL',
          width: '25%'
        },
        {
          name: 'dataInizio',
          type: 'LABEL_DATE',
          width: '15%'
        },
        {
          name: 'dataFine',
          type: 'LABEL_DATE',
          width: '15%'
        },
        {
          name: 'risorse',
          type: 'LABEL',
          width: '15%'
        },
        {
          name: 'percentuale',
          type: 'LABEL',
          width: '15%'
        }
      ];
      const modal = await this.modalCtrl.create({
        component: PostitBoardListManagementComponent,
        cssClass: 'postit-board-list-management',
        componentProps: {
          properties: properties,
          list: [] ,
          title: this.translate.instant("postitBoard.labels.groupDetail"),
          target: "SECTION_DETAIL",
          readonly: true,
          highlightRow: {
            propertyTarget: "codice",
            valueTarget: section.attivita.codice
          },
          refreshEnpointParams: "?idGruppo="+section.attivita.idGruppo
        }
      });
      return await modal.present();
    }
  }

  //enterResourceMode
  public emitEnterResourceMode(resource) {
    this.enterResourceMode.emit({ resource: resource, mainLayout: null })
  }
  //--- END BOARD ---//
}
