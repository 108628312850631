import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccessConfigurationTemplateService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //saveTemplateServiziSafo api request
  public saveTemplateServiziSafo(template) {
    if (template.idServizio)
      template.idServizio = parseInt(template.idServizio);
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}saveTemplateServiziSafo`, template).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getListaTemplateServiziSafo api request
  public getListaTemplateServiziSafo(serviceId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getListaTemplateServiziSafo/` + serviceId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //dettaglioTemplateServiziSafo api request
  public dettaglioTemplateServiziSafo(templateId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}dettaglioTemplateServiziSafo/` + templateId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //eliminaTemplateServiziSafo api request
  public eliminaTemplateServiziSafo(templateId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}eliminaTemplateServiziSafo/` + templateId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

}
