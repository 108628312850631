import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from "@angular/core";
import { GroupBy } from './group-by.pipe';

@NgModule({
    declarations: [
        GroupBy
    ],
    imports: [CommonModule],
    exports: [GroupBy],
    providers: [DatePipe]
})
export class GroupByPipeModule { }