import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { DcrudService } from 'src/app/services/dcrud.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SearchStateObject, StorageVariablesService } from 'src/app/services/storage-variables.service';
import { SearchStateHistoryPrompt } from 'src/app/shared-components/search-state-history-prompt/search-state-history-prompt.component';
import { columnTypes, tableExtractPageSize } from 'src/app/shared-components/table/models/table-models';
import { TableComponent } from 'src/app/shared-components/table/table.component';
import { InputTypes, DcrudInput, TriggerActions, TriggersListItem, EndpointQueryParams, DcrudModalParams } from '../models/dcrud.models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { dcrudSqlKeywords } from '../models/dcrudSqlKeywords';
import { DcrudFormModal } from '../dcrud-form-modal/dcrud-form-modal.component';
import { PssfeDropdownComponent } from 'src/app/shared-components/pssfe-dropdown/pssfe-dropdown.component';

@Component({
  selector: 'app-dcrud-input',
  templateUrl: './dcrud-input.component.html',
  styleUrls: ['./dcrud-input.component.scss'],
})

export class DcrudInputComponent implements OnInit, OnChanges, OnDestroy {
  //input
  @Input() input: DcrudInput;
  //inputId
  public inputId: string;
  //inputList
  @Input() inputList: Array<DcrudInput>;
  //input types
  public InputTypes = InputTypes;
  //table
  @ViewChild(TableComponent) table: TableComponent;
  //inputTargetTmp
  private _inputTargetTmp: DcrudInput;
  //editorMode
  @Input() editorMode: boolean = false;
  //columnsWithCallback
  private _columnsWithCallback = [columnTypes.INPUT_POPUP_STRINGA.toString(), columnTypes.INPUT_STRINGA.toString(), columnTypes.INPUT_NUMERO.toString(), columnTypes.INPUT_POPUP_NUMERO.toString(), columnTypes.INPUT_DATA.toString(),
  columnTypes.INPUT_POPUP_DATA.toString(), columnTypes.INPUT_DATAORA.toString(), columnTypes.INPUT_POPUP_DATAORA.toString(), columnTypes.INPUT_BOOLEAN.toString(), columnTypes.INPUT_POPUP_BOOLEAN.toString(), columnTypes.INPUT_COMBO.toString(),
  columnTypes.INPUT_POPUP_COMBO.toString(), columnTypes.INPUT_MULTICOMBO.toString(), columnTypes.INPUT_POPUP_MULTICOMBO.toString(), columnTypes.INPUT_RADIO.toString(), columnTypes.INPUT_POPUP_RADIO.toString()];
  //input with modelChange
  private _inputWithModelChange = [InputTypes.AREA_TESTO.toString(), InputTypes.DATA.toString(), InputTypes.DATA_ORA.toString(), InputTypes.DATA_RANGE.toString(),InputTypes.CHECKBOX.toString(),
  InputTypes.FILE.toString(), InputTypes.NUMERO.toString(), InputTypes.RADIO.toString(), InputTypes.RANGE.toString(), InputTypes.TESTO.toString(), InputTypes.VALUTA.toString(), InputTypes.SQL_EDITOR.toString()]
  //model change
  public onModelChangeSubject = new Subject<string>();
  //endpointPaginationFE
  public endpointPaginationFE: boolean = false;
  //isDcrudModal
  @Input() isDcrudModal: boolean = false;
  //modalList
  private _modalList: Array<string> = ["modalSmall", "modalMedium", "modalLarge"];
  //pssfeDropdownSuggestion
  @ViewChild(PssfeDropdownComponent) pssfeDropdownSuggestion: PssfeDropdownComponent;

  constructor(
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    private domSanitizer: DomSanitizer,
    private _httpUtilitySrv: HttpUtilityService,
    private alertSrv: AlertService,
    private _dcrudSrv: DcrudService,
    private _router: Router,
    private _location: Location,
    private _loaderSrv: LoaderService,
    private _storageSrv: StorageVariablesService,
    public modalCtrl: ModalController) {
  }
  ngOnDestroy(): void {
    if (this.onModelChangeSubject)
      this.onModelChangeSubject.unsubscribe();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.input) {
      this.input = changes.input.currentValue;
      this.inputId = this.input.id;
      //init table
      if (this.input.inputType == InputTypes.TABELLA && !this.editorMode) {
        this.input.tableDataSource = new MatTableDataSource([]);
        if (this.input.tableColumns && this.input.tableColumns.length > 0) {
          this.input.tableColumns.forEach((column) => {
            if (column.columnType == columnTypes.DETAIL) {
              column.columnCallback = (event) => {
                this.redirectToPage(event);
              }
            }
            if (this._columnsWithCallback.includes(column.columnType) || (column.columnType == columnTypes.ICONA.toString() && column.columnEndpoint)) {
              column.columnCallback = (callBackRes) => {
                if (callBackRes && callBackRes.element && column.columnEndpoint) {
                  this.tableColumnCallback(callBackRes, column);
                }
              }
            }
          });
        }
        this.input.inputReferenceFE = this;
        if (this.input.endpoint && this.input.endpoint.indexOf("endpointPaginationFE=true") != -1) {
          this.endpointPaginationFE = true;
        }
        if (this.input.endpoint && this.input.endpoint.indexOf("endpointAutoload=true") != -1) {
          setTimeout(() => {
            this.searchTable({ keyUp: null, isExtract: false });
          }, 1000);
        } else if (this.input.value && Array.isArray(this.input.value)) {
          setTimeout(() => {
            this.input.tableDataSource = new MatTableDataSource(this.input.value);
          }, 1000);
        }
      }
      //init render value for textArea and SqlEditor
      if ([this.InputTypes.AREA_TESTO.toString(), this.InputTypes.SQL_EDITOR.toString()].includes(this.input.inputType)) {
        this.input.renderValue = this.input.value;
        if (this.input.inputType.toString() == this.InputTypes.SQL_EDITOR.toString())
          this._setSqlInputReferenceFE(this.input);
      }
      setTimeout(() => {
        //model change
        if (this._inputWithModelChange.includes(this.input.inputType))
          this.onModelChangeSubject.pipe(debounceTime(500)).subscribe((event) => {
            this.inputChanged(event);
          });
        //chek for validation rules
        this.checkForTriggers();
      }, 1000);

    }
    if (changes.isDcrudModal) {
      this.isDcrudModal = changes.isDcrudModal.currentValue;
    }
  }

  //inputChanged
  public inputChanged(event) {
    if (this.input && this.input.inputType) {
      //format values
      switch (this.input.inputType) {
        case InputTypes.AREA_TESTO:
          this.input.value = event.target.textContent.replace(/<div>/g, "").replace(/<\/div>/g, "\n").replace(/<br>/g, "").replace(/<\/br>/g, "");
          //check for max
          if (this.input.value && this.input.max && this.input.value.length >= this.input.max) {
            this.input.value = this.input.value.substring(0, this.input.max);
          }
          break;
        case InputTypes.NUMERO:
          //check for and min
          if (this.input.value != null && this.input.value != undefined && this.input.max != null && this.input.value > this.input.max) {
            this.input.value = null;
          }
          if (this.input.value != null && this.input.value != undefined && this.input.min != null && this.input.value < this.input.min) {
            this.input.value = null;
          }
          break;
        case InputTypes.FILE:
          let context = this;
          const file = event.target.files[0];
          if (file) {
            context.input.fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              context.input.value = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
              context.input.value = context.input.value.changingThisBreaksApplicationSecurity ? context.input.value.changingThisBreaksApplicationSecurity : context.input.value.attachedPdfBase64String;
              context.input.value = context.input.value.replace("data:application/pdf;base64,", "");
            }
          }
          break;
        case InputTypes.SQL_EDITOR:

          break;
        default:
          break;
      }
    }
    //check for validation rules
    let jsonReq = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList), funcTmp, ruleTmp;
    for (let input of jsonReq) {
      this._dcrudSrv.formatValueForBE(input);
    }
    let skipValidationRules = this.input.value == null || this.input.value == undefined || this.input.value == '' || !this.input.validationRules || this.input.validationRules.length == 0;
    this.checkValidationRules(jsonReq, 0, funcTmp, ruleTmp, skipValidationRules);
    //update endpoints
    this._dcrudSrv.updateEndpoints(this.inputList);
  }

  //checkValidationRules: 1)check validation rules, 2)check required, 3)check for triggers
  private checkValidationRules(jsonReq, index, funcTmp, ruleTmp, skipValidationRules: boolean) {
    if (!skipValidationRules && jsonReq && index < this.input.validationRules.length) {
      ruleTmp = this.input.validationRules[index];
      //http request validation
      if (ruleTmp.rule.toString().startsWith("https://")) {
        this._httpUtilitySrv.genericGet(ruleTmp.rule).then((res: any) => {
          if (res.errorMessage) {
            this.input.errorMessage = ruleTmp.errorMessage;
            this.input.invalid = true;
            this.checkValidationRules(jsonReq, index + 1, funcTmp, ruleTmp, true);
          } else {
            this.input.errorMessage = null;
            this.input.invalid = false;
            this.checkValidationRules(jsonReq, index + 1, funcTmp, ruleTmp, false);
          }
        }).catch((err) => {
          if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
            this._httpUtilitySrv.getRequestError(err).then((err: any) => {
              this.alertSrv.errorAlert(err);
            })
            this.input.errorMessage = this.translate.instant("errors.1");
            this.input.invalid = true;
            this.checkValidationRules(jsonReq, index + 1, funcTmp, ruleTmp, true);
          }
        })
      }
      //js validation
      else {
        funcTmp = new Function("jsonReq", ruleTmp.rule);
        if (!funcTmp(jsonReq)) {
          this.input.errorMessage = ruleTmp.errorMessage;
          this.input.invalid = true;
          this.checkValidationRules(jsonReq, index + 1, funcTmp, ruleTmp, true);
        }
        else {
          this.input.errorMessage = null;
          this.input.invalid = false;
          this.checkValidationRules(jsonReq, index + 1, funcTmp, ruleTmp, false);
        }
      }
    } else {
      //check input validation: required
      //!this.input.invalid &&
      if (this.input.required) {
        if (this.input.value == null || this.input.value == undefined || this.input.value == "")
          this.input.invalid = true;
        else
          this.input.invalid = false;
      }
      //check for triggers
      this.checkForTriggers();
    }
  }

  //check for triggers: loop intpuList and check if input exists in inputListIem.triggersList. If exists execute action
  private checkForTriggers() {
    if (!this.editorMode) {
      let flatInputList = [];
      this._dcrudSrv.getFlatINputList1to1(this.inputList, flatInputList);
      if (flatInputList && flatInputList.length > 0) {
        for (let input of flatInputList) {
          //check if this.input exists in input.triggersList
          if (input.triggersList && input.triggersList.filter((trigger) => { return (trigger.condition && trigger.condition.indexOf(`[${this.input.id}]`) != -1) || (trigger.endpoint && trigger.endpoint.indexOf(`[${this.input.id}]`) != -1) })[0]) {
            this.executeTriggersAction(input, input.triggersList.filter((trigger: TriggersListItem) => { return (trigger.condition && trigger.condition.indexOf(`[${this.input.id}]`) != -1) || (trigger.endpoint && trigger.endpoint.indexOf(`[${this.input.id}]`) != -1) }));
            //check for updated values
            //if (!input.triggerDisabled && input.triggersList.filter((x) => { return (x.condition && x.condition.indexOf(`[${this.input.id}]`) != -1) || (x.endpoint && x.endpoint.indexOf(`[${this.input.id}]`) != -1) })[0])
            //  this.deleteValue(input);
          }
        }
      }
    }
  }

  //executeTriggersAction: execute actions in all triggers
  private executeTriggersAction(input: DcrudInput, triggers: Array<TriggersListItem>) {
    let flatInputListDeepCopy = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
    triggers.forEach((trigger: TriggersListItem) => {
      let conditionTmp;
      //check for condition ex: [input1]==1 && [input2] == 7
      if (trigger.condition) {
        conditionTmp = this._dcrudSrv.resolveConditionByFlatInputList(trigger.condition, this._dcrudSrv.getFlatINputListDeepCopy(this.inputList));
        //ENABLE
        if (trigger.action == TriggerActions.ENABLE)
          input.triggerDisabled = !eval(conditionTmp);
        //DISPLAY
        else if (trigger.action == TriggerActions.DISPLAY) {
          input.hidden = !eval(conditionTmp);
        }
        //REQUIRED
        else if (trigger.action == TriggerActions.REQUIRED) {
          input.required = eval(conditionTmp);
          if (!input.required) {
            input.invalid = false;
          }
        }
        //UPDATE_VALUE
        else if (trigger.action == TriggerActions.UPDATE_VALUE && trigger.updateValue && eval(conditionTmp)) {
          let valueTmp = this.translate.instant(trigger.updateValue);
          valueTmp = this._dcrudSrv.resolveEndpointByFlatInputList(valueTmp, flatInputListDeepCopy);
          input.value = valueTmp;
        }
      }
      //resolve endpoint if !input.triggerDisabled
      if (!input.triggerDisabled && trigger.endpoint) {
        input.loading = true;
        this._httpUtilitySrv.genericGet(this._dcrudSrv.resolveEndpointByFlatInputList(trigger.endpoint, flatInputListDeepCopy)).then((res: any) => {
          input.loading = false;
          //check response  action. Response action must be checked before trigger action for overrides
          if (res.action) {
            //POPUP
            if (res.action == TriggerActions.POPUP)
              this.alertSrv.warningAlert({ message: res.text });
            //DISPLAY
            else if (res.action == TriggerActions.DISPLAY) {
              if (res.text != null && res.text != undefined && res.text != '') {
                input.value = res.text;
                input.hidden = false;
                input.triggerDisabled = false;
              }
              else {
                input.value = null;
                input.hidden = true;
                input.triggerDisabled = true;
              }
            }
            //ENABLE
            if (res.action == TriggerActions.ENABLE) {
              if (res.text != null && res.text != undefined && res.text != '') {
                input.value = res.text;
                input.triggerDisabled = false;
              }
              else {
                input.value = null;
                input.triggerDisabled = true;
              }
            };
          } else {
            //POPUP
            if (trigger.action == TriggerActions.POPUP)
              this.alertSrv.warningAlert({ message: res.text });
            //DISPLAY
            else if (trigger.action == TriggerActions.DISPLAY) {
              if (res.text != null && res.text != undefined && res.text != '') {
                input.value = res.text;
                input.hidden = false;
                input.triggerDisabled = false;
              }
              else {
                input.value = null;
                input.hidden = true;
                input.triggerDisabled = true;
              }
            }
            //ENABLE
            if (trigger.action == TriggerActions.ENABLE) {
              if (res.text != null && res.text != undefined && res.text != '') {
                input.value = res.text;
                input.triggerDisabled = false;
              }
              else {
                input.value = null;
                input.triggerDisabled = true;
              }
            };
          }
        }).catch((err) => {
          input.loading = false;
          input.errorMessage = this.translate.instant("errors.1");
          input.invalid = true;
          if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
            this._httpUtilitySrv.getRequestError(err).then((err: any) => {
              this.alertSrv.errorAlert(err);
            })
          }
        });
      }
      //}
    });
  }


  //textAreaKeyUp
  public textAreaKeyUp(event) {
    //hide show placeholder
    if (event.target && event.target.textContent == null || event.target.textContent == undefined || event.target.textContent == '')
      event.target.classList.remove("dcrud-textarea-noplaceholder");
    else if (event.target)
      event.target.classList.add("dcrud-textarea-noplaceholder");
    this.onModelChangeSubject.next(event)
  }

  //textAreaKeyDown
  public textAreaKeyDown(event) {
    //check for max length
    if (event.target.textContent && this.input.max && event.target.textContent.length >= this.input.max) {
      event.preventDefault()
    }
  }

  //sqlEditorKeyUp
  public sqlEditorKeyUp(event) {
    this.input.value = event.target.textContent.replace(/<div>/g, "").replace(/<\/div>/g, "\n").replace(/<br>/g, "").replace(/<\/br>/g, "");
    //highlight sql keywords
    this._setSqlInputReferenceFE(this.input);
    //hide show placeholder
    if (event.target && event.target.innerHTML == null || event.target.innerHTML == undefined || event.target.innerHTML == '')
      event.target.classList.remove("dcrud-sqleditor-noplaceholder");
    else if (event.target)
      event.target.classList.add("dcrud-sqleditor-noplaceholder");
    this.onModelChangeSubject.next(event)
  }

  //sqlEditorKeyDown
  public sqlEditorKeyDown(event) {
    if (event.keyCode === 9) {
      event.preventDefault();
      // now insert four non-breaking spaces for the tab key
      var editor = document.getElementById("dcrud-input-" + this.inputId);
      var doc = editor.ownerDocument.defaultView;
      var sel = doc.getSelection();
      var range = sel.getRangeAt(0);
      var tabNode = document.createTextNode("\u00a0\u00a0\u00a0\u00a0");
      range.insertNode(tabNode);
      range.setStartAfter(tabNode);
      range.setEndAfter(tabNode);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  //getSqlInputReferenceFE
  private _setSqlInputReferenceFE(input: DcrudInput) {
    if (input.value != null && input.value != undefined && input.value != "") {
      let tmpRows: Array<string> = JSON.parse(JSON.stringify(input.value)).replace(/\\r/g, "").split(/\\n/), tmpRowArray: Array<string>, tmpValue: string;
      //check for max
      if (this.input.value && input.max && input.value.length >= input.max) {
        input.value = input.value.substring(0, input.max);
      }
      tmpRows.forEach((row, rowIndex) => {
        tmpRowArray = JSON.parse(JSON.stringify(row)).split(" ");
        tmpRowArray.forEach((word: string, wordIndex) => {
          if (dcrudSqlKeywords.includes(word.toUpperCase()))
            tmpRowArray[wordIndex] = `<span class="dcrud-sqleditor-keyword-blue">${word}</span>`;
        })
        tmpRows[rowIndex] = tmpRowArray.join(" ");
      })
      tmpValue = tmpRows.join("\n");
      tmpValue = tmpValue.replace(/'(.*?)'/gm, '<span class="dcrud-sqleditor-keyword-red">$&</span>');
      tmpValue = tmpValue.replace(/(--.*)|(((\/\*)+?[\w\W]+?(\*\/)+))/gm, '<span class="dcrud-sqleditor-keyword-green">$&</span>');
      input.inputReferenceFE = tmpValue;
    } else {
      input.inputReferenceFE = null;
    }
  }

  //valutaKeyDown
  public valutaKeyDown(event) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  //valutaTransform
  public valutaTransform(event, input) {
    let element = (document.getElementById("dcrud-input-" + this.inputId)) as HTMLInputElement, start;
    event = this.valutaFormat(event);
    start = event.charAt(element.selectionStart - 2) == "." || event.charAt(element.selectionStart - 2) == "," ? element.selectionStart + 1 : element.selectionStart;
    input["value"] = event;
    setTimeout(() => {
      element.setSelectionRange(start, start);
    })
  }

  //valutaFormat
  public valutaFormat(event) {
    if (event != '' && event != undefined && event != null) {
      if (/^[0-9,.]*$/.test(event)) {
        event = event.toString().replace(/\D/g, "");
        //add comma if required 
        if (event.length > 2) {
          event = event.substring(0, event.length - 2) + "," + event.substring(event.length - 2);
          event = event.toString().replace(/,\./g, ",").replace(/\.,/g, ",");
        }
        if (event.includes(",")) {
          let decimal = event.substring(0, event.indexOf(","));
          decimal = parseInt(decimal).toLocaleString('it-IT');
          event = decimal + event.substring(event.indexOf(","), event.length);
        }
      } else {
        event = event.slice(0, -1);
      }
    } else {
      event = '';
    }

    return event;
  }

  //fileTrigger
  public fileTrigger() {
    let domFile = document.getElementById('dcrud-input-' + this.inputId)
    if (domFile)
      domFile.click();
  }

  //deleteValue
  public deleteValue(input?: DcrudInput) {
    let inputToDelete = input ? input : this.input;
    if ((inputToDelete && inputToDelete.inputType && inputToDelete.value != null && inputToDelete.value != undefined) || (inputToDelete && inputToDelete.inputType == InputTypes.DATA_RANGE)) {
      switch (inputToDelete.inputType) {
        case InputTypes.FILE:
          inputToDelete.value = null;
          inputToDelete.fileName = null;
          break;
        case InputTypes.DATA_RANGE:
          if (inputToDelete.inputList && inputToDelete.inputList[0])
            inputToDelete.inputList[0].value = null;
          if (inputToDelete.inputList && inputToDelete.inputList[1])
            inputToDelete.inputList[1].value = null;
          break;
        default:
          inputToDelete.value = null;
          break;
      }
    }
    //set focus on input
    if (!input && inputToDelete.inputType != InputTypes.DATA_RANGE) {
      let domInput = document.getElementById("dcrud-input" + this.inputId);
      if (domInput)
        domInput.focus();
    }
  }

  //buttonClicked
  public buttonClicked() {
    //no endpoint: check for routerUrl | tableTargetId else back
    if (!this.input.endpoint) {
      if (this.input.routerUrl)
        this.isDcrudModal ? this._dcrudSrv.closeModalCallbackEmit({ navigateByUrl: this.input.routerUrl }) : this._router.navigateByUrl(this.input.routerUrl);
      else if (this.input.tableTargetId) {
        this._inputTargetTmp = null;
        this._getInputById(this.inputList, this.input.tableTargetId, false);
        if (this._inputTargetTmp && this._inputTargetTmp.inputReferenceFE) {
          let tableTargetComponent: DcrudInputComponent = this._inputTargetTmp.inputReferenceFE;
          tableTargetComponent.searchTable({ keyUp: null, isExtract: false });
        }
      }
      else
        this._location.back()
    }
    //endpoint: check modal, call endpoint, if routerUrl -> navigate, else update detail
    else if (this.input.endpoint) {
      let flatInputList = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
      let payload = this.input.endpoint.indexOf("endpointMethod=post") ? this._dcrudSrv.getAllFromValuesFromFlatInpuList(flatInputList) : null;
      let url = this.input.endpoint.startsWith("http") ? new URL(this._dcrudSrv.resolveEndpointByFlatInputList(this.input.endpoint, flatInputList)) : null;
      let searchParams = new URLSearchParams(this.input.endpoint.startsWith("http") ? (url as URL).searchParams : this.input.endpoint);
      if (url)
        for (let endpointParameter of EndpointQueryParams)
          url.searchParams.delete(endpointParameter);
      let endpointResponseType = this.input.endpoint.indexOf("endpointResponseType=blob") != -1 ? 'blob' : 'json'
      //dcrud modal endpoint
      if (searchParams && this._modalList.includes(searchParams.get("endpointMethod"))) {
        this.openDrcudModal(searchParams.toString(), this._getModalSizeClass(searchParams.get("endpointMethod")));
      }
      //standard endpoint
      else {
        this._loaderSrv.open();
        this._httpUtilitySrv.genericRequest(url.href, this.input.endpoint.indexOf("endpointMethod=post") != -1 ? 'post' : 'get', payload, endpointResponseType).then((res) => {
          this._loaderSrv.dismiss();
          if (endpointResponseType == 'blob') {
            this._downloadBlob(res, `file_${new Date().getTime()}`);
          } else {
            this.alertSrv.successAlert(this.translate.instant('errors.0'));
            //check for routerUrl
            if (this.input.routerUrl)
              this.isDcrudModal ? this._dcrudSrv.closeModalCallbackEmit({ navigateByUrl: this._dcrudSrv.resolveEndpointByTableRow(this.input.routerUrl, res) }) : this._router.navigateByUrl(this._dcrudSrv.resolveEndpointByTableRow(this.input.routerUrl, res));
            //default refresh detail
            else
              this._dcrudSrv.updateFormDetailFromChild();
          }
        }).catch((err) => {
          this._loaderSrv.dismiss();
          if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
            this._httpUtilitySrv.getRequestError(err).then((err: any) => {
              this.alertSrv.errorAlert(err);
            })
          }
        });
      }
    }
  }

  //get input by id
  public _getInputById(inputList: Array<DcrudInput>, inputId: string, indexOfControl: boolean) {
    if (inputList && inputList.length > 0 && inputId) {
      for (let i = 0; i < inputList.length; i++) {
        if (indexOfControl ? inputList[i].id.indexOf(inputId) != -1 : inputList[i].id == inputId) {
          this._inputTargetTmp = inputList[i];
        } else if (inputList[i].inputList && inputList[i].inputList.length > 0) {
          this._getInputById(inputList[i].inputList, inputId, indexOfControl);
        }
      }
    }
  }

  //goToPage
  public goToPage() {
    this.isDcrudModal ? this._dcrudSrv.closeModalCallbackEmit({ navigateByUrl: this.input.routerUrl }) : this._router.navigateByUrl(this.input.routerUrl)
  }

  //--- TABELLE ---//
  public searchTable(event) {
    let flatInputListDeepCopy = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
    this._loaderSrv.open()
    this.input.tableSearchDoneFE = true;
    //save inputList states
    this._saveInputListStates(flatInputListDeepCopy);
    let url = new URL(this._dcrudSrv.resolveEndpointByFlatInputList(this.input.endpoint, flatInputListDeepCopy));
    for (let endpointParameter of EndpointQueryParams)
      url.searchParams.delete(endpointParameter);
    if (this.table.tablePaginator)
      this.table.tablePaginator.firstPage();
    this._httpUtilitySrv.genericRequest(url.href, this.input.endpoint.indexOf("endpointMethod=post") != -1 ? 'post' : 'get', {
      query: this._dcrudSrv.getAllFromValuesFromFlatInpuList(JSON.parse(JSON.stringify(flatInputListDeepCopy))),
      pagination: {
        maxResult: event.isExtract ? tableExtractPageSize : this.input.noPagination ? 10000 : this.table.tablePaginator && this.table.tablePaginator.pageSize ? this.table.tablePaginator.pageSize : 10,
        orderField: this.table.tableSort.active,
        startPosition: 0,
        orderType: this.table.tableSort.direction
      }
    }).then((res: any) => {
      if (this.table) {
        let url = new URL(this.input.endpoint);
        let urlParams = url.searchParams;
        if (!event.isExtract) {
          this.input.tableDataSource = new MatTableDataSource(urlParams.get("searchResultTarget") && res[urlParams.get("searchResultTarget")] ? res[urlParams.get("searchResultTarget")] : res);
          this.table.tablePaginator.length = this.endpointPaginationFE ? this.input.tableDataSource.filteredData.length : res.pagination?.totalResults;
          //expand relative group
          this._inputTargetTmp = null;
          this._getInputById(this.inputList, "[[" + this.input.id + "]]", true);
          if (this._inputTargetTmp)
            this._inputTargetTmp.collapsed = false;
        } else {
          if (event.isExtract)
            this.table.extractTable(urlParams.get("searchResultTarget") && res[urlParams.get("searchResultTarget")] ? res[urlParams.get("searchResultTarget")] : res);
        }
      }
      this._loaderSrv.dismiss();
    }).catch((err) => {
      this._loaderSrv.dismiss();
      if (err && err.status && err.status != 401 && err.status != 403) {
        this._httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    });
  }
  public tableSortChange() {
    if (this.table && !this.endpointPaginationFE) {
      this.searchTable({ keyUp: null, isExtract: false });
    }
  };
  public tablePaginatorChange(pagination) {
    if (!this.endpointPaginationFE) {
      let flatInputListDeepCopy = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
      //pagination
      if (pagination == undefined || pagination == null) {
        this.table.tablePaginator.firstPage();
      }
      let pageIndex = pagination ? pagination.pageIndex : this.table.tablePaginator.pageIndex;
      let pageSize = pagination ? pagination.pageSize : this.table.tablePaginator.pageSize;
      this._loaderSrv.open();
      let url = new URL(this._dcrudSrv.resolveEndpointByFlatInputList(this.input.endpoint, flatInputListDeepCopy));
      for (let endpointParameter of EndpointQueryParams)
        url.searchParams.delete(endpointParameter);
      this._httpUtilitySrv.genericRequest(url.href, this.input.endpoint.indexOf("endpointMethod=post") != -1 ? 'post' : 'get', {
        query: this._dcrudSrv.getAllFromValuesFromFlatInpuList(flatInputListDeepCopy),
        pagination: {
          maxResult: pageSize,
          orderField: this.table.tableSort.active,
          startPosition: pageIndex,
          orderType: this.table.tableSort.direction
        }
      }).then((res: any) => {
        let url = new URL(this.input.endpoint);
        let urlParams = url.searchParams;
        this.input.tableDataSource = new MatTableDataSource(urlParams.get("searchResultTarget") ? res[urlParams.get("searchResultTarget")] : res);
        this.table.tablePaginator.length = res.pagination?.totalResults;
        this._loaderSrv.dismiss();
      }).catch((err) => {
        this._loaderSrv.dismiss();
        if (err && err.status && err.status != 401 && err.status != 403) {
          this._httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      });
    }
  };
  public extractTable(event) {
    if (this.input.endpoint)
      this.searchTable(event);
    else
      this.table.extractTable(this.table.tableDataSource.data);
  }
  public redirectToPage(event) {
    if (event.detailColumn && event.detailColumn.columnEndpoint) {
      this.isDcrudModal ? this._dcrudSrv.closeModalCallbackEmit({ navigateByUrl: this._dcrudSrv.resolveEndpointByTableRow(event.detailColumn.columnEndpoint, event) })
        : this._router.navigateByUrl(this._dcrudSrv.resolveEndpointByTableRow(event.detailColumn.columnEndpoint, event));
    }

  }
  private _saveInputListStates(flatInputList: Array<DcrudInput>) {
    let saveStateFlatInputList: Array<DcrudInput> = [];
    for (let input of flatInputList) {
      if (input.saveState) {
        saveStateFlatInputList.push(input);
      }
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this._storageSrv.setSearchState("DCRUD-" + urlParams.get("CodicePagina"), { filters: saveStateFlatInputList, sortAndPaginator: null }, true);
  }
  async openSearchStateHistory() {
    let searchStateObject: SearchStateObject = {
      filters: [],
      sortAndPaginator: {
        sortActive: null,
        sortDirection: null,
        pageIndex: null,
        pageSize: null
      }
    };
    let flatInputListDeepCopy = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
    searchStateObject.filters = flatInputListDeepCopy.filter((input) => { return input.saveState });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const modal = await this.modalCtrl.create({
      component: SearchStateHistoryPrompt,
      cssClass: 'search-state-history-prompt',
      showBackdrop: true,
      componentProps: {
        searchStateId: "DCRUD-" + urlParams.get("CodicePagina"),
        searchStateObject: searchStateObject,
        dynamic: true
      }
    });
    modal.onDidDismiss().then((res) => {
      if (res && res.data && res.data.useSearchStateObject) {
        let newSearchStateObject = res.data.useSearchStateObject.searchStateObject;
        if (newSearchStateObject && newSearchStateObject.filters && newSearchStateObject.filters.length > 0) {
          let inputTmp: DcrudInput;
          let flatInputList = [];
          this._dcrudSrv.getFlatINputList1to1(this.inputList, flatInputList);
          for (let input of flatInputList) {
            if (input.saveState) {
              inputTmp = newSearchStateObject.filters.filter((x) => { return x.id == input.id })[0];
              if (inputTmp)
                input.value = inputTmp.value;
            }
          }
        }
      }
    })
    modal.present();
  }
  public tableColumnCallback(callBackRes, column) {
    //redirect
    if (column.columnEndpoint.startsWith("/")) {
      let formattedUrl = this._resolveEndpointByTableRow(column.columnEndpoint, callBackRes.element);
      let targetURLSearchParams = new URLSearchParams(formattedUrl.substring(formattedUrl.indexOf("?"), formattedUrl.length));
      this._storageSrv.setDcrudFormObject(targetURLSearchParams.get("CodicePagina"), targetURLSearchParams.toString());
      setTimeout(() => {
        this.isDcrudModal ? this._dcrudSrv.closeModalCallbackEmit({ navigateByUrl: `${formattedUrl.split('?')[0]}?CodicePagina=${targetURLSearchParams.get("CodicePagina")}` }) : this._router.navigateByUrl(`${formattedUrl.split('?')[0]}?CodicePagina=${targetURLSearchParams.get("CodicePagina")}`);
      }, 200)
    }
    else {
      let queryParams = new URLSearchParams(column.columnEndpoint);
      //download
      if (queryParams && queryParams.get("endpointDownload") == 'true') {
        let endpointResponseType = queryParams.get("endpointResponseType") ? queryParams.get("endpointResponseType") : 'json';
        this._httpUtilitySrv.genericRequest(callBackRes.element[column.columnDef], 'get', null, endpointResponseType).then((res: any) => {
          if (res) {
            let filename = queryParams.get("endpointDownloadFileName") && callBackRes.element[queryParams.get("endpointDownloadFileName")] ? callBackRes.element[queryParams.get("endpointDownloadFileName")] : `file_${new Date().getTime()}`
            if (endpointResponseType == 'blob')
              this._downloadBlob(res, filename);
          }
        }).catch((err) => {
          this._loaderSrv.dismiss();
          if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
            this._httpUtilitySrv.getRequestError(err).then((err: any) => {
              this.alertSrv.errorAlert(err);
            })
          }
        });
      }
      //modal
      else if (queryParams && this._modalList.includes(queryParams.get("endpointMethod"))) {
        let formattedUrl = this._resolveEndpointByTableRow(column.columnEndpoint, callBackRes.element);
        let targetURLSearchParams = new URLSearchParams(formattedUrl.substring(formattedUrl.indexOf("?"), formattedUrl.length));
        this._storageSrv.setDcrudFormObject(targetURLSearchParams.get("CodicePagina"), targetURLSearchParams.toString());
        setTimeout(() => {
          this.openDrcudModal(formattedUrl, this._getModalSizeClass(queryParams.get("endpointMethod")));
        }, 200)
      }
      //action
      else {
        this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.proceedOperationMessage') }, () => {
          this._loaderSrv.open();
          let flatInputList = this._dcrudSrv.getFlatINputListDeepCopy(this.inputList);
          let url = this._dcrudSrv.resolveEndpointByFlatInputList(column.columnEndpoint, flatInputList);
          this._loaderSrv.open();
          this._httpUtilitySrv.genericRequest(url, column.columnEndpoint.indexOf('endpointMethod=post') != -1 ? 'post' : 'get', callBackRes.element).then((res) => {
            this._loaderSrv.dismiss();
            this.alertSrv.successAlert(this.translate.instant("errors.0"));
            this._dcrudSrv.updateFormDetailFromChild();
          }).catch((err) => {
            this._loaderSrv.dismiss();
            if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
              this._httpUtilitySrv.getRequestError(err).then((err: any) => {
                this.alertSrv.errorAlert(err);
              })
            }
          });
        })
      }
    }
  }
  //downlaod blob
  private _downloadBlob(res, filename) {
    let url = window.URL.createObjectURL(res);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  //--- END TABELLE ---//

  //--- EDITOR ---//
  public dragover(event) {
    event.preventDefault();
  }
  public inputDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.editorMode)
      this._dcrudSrv.inputDropFromChild(event);
  }
  public openInputSettingsPromt(event) {
    event.preventDefault();
    event.stopPropagation();
    this._dcrudSrv.inputSettingsPromptFromChild(this.input);
  }
  //--- END EDITOR ---//

  //resolve endpoint by table row
  private _resolveEndpointByTableRow(endpoint, tableRow) {
    tableRow = JSON.parse(JSON.stringify(tableRow));
    let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag;
    while (endpointTmp.indexOf("]") != -1) {
      currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
      //value exists in tableRow
      if (tableRow[currentTag] != null) {
        //format value
        endpointBe = endpointBe.toString().replace("[" + currentTag + "]", tableRow[currentTag]);
      }
      //value doesn't exist in tableRow
      else {
        endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "");
      }
      endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
    }
    return endpointBe;
  }

  //dcrud modal
  async openDrcudModal(urlParams: string, modalSizeClass: string) {
    const dcrudModal = await this.modalCtrl.create({
      component: DcrudFormModal,
      cssClass: modalSizeClass,
      backdropDismiss: false,
      componentProps: {
        urlParams: urlParams
      }
    });
    dcrudModal.onDidDismiss()
      .then((res) => {

      });
    return await dcrudModal.present();
  }

  //getModalSizeClass
  private _getModalSizeClass(endpointMethod: string) {
    switch (endpointMethod) {
      case 'modalSmall':
        return 'dcrud-form-modal-small';
      case 'modalMedium':
        return 'dcrud-form-modal-medium';
      case 'modalLarge':
        return 'dcrud-form-modal-large';
      default:
        return 'dcrud-form-modal';
    }
  }

  //--- suggestion ---//
  public openInputSuggestion() {
    if (this.pssfeDropdownSuggestion) {
      this.pssfeDropdownSuggestion.openPssfeDropdown();
    }
  }
  public suggestionChanged(event) {
    if (event) {
      if (this.input.optionValue) {
        this.input.value = event[this.input.optionValue];
      } else {
        this.input.value = event;
      }
    }
    if (this.pssfeDropdownSuggestion) {
      this.pssfeDropdownSuggestion.value = null;
    }
  }
  //--- end suggestion ---//

}