import { Component, OnInit } from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { columnTypes, tableColumn, storageTableColumn, tableSettingsEvent } from '../models/table-models';

@Component({
  selector: 'app-table-popup-input',
  templateUrl: './table-popup-input.component.html',
  styleUrls: ['./table-popup-input.component.scss'],
})
export class TablePopupInputComponent implements OnInit {
  //modal nav params
  public modalNavParams;
  //columnTypes
  public tableColumnTypes = columnTypes;
  //comparison
  public comparison: string;

  constructor(
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public navParams: NavParams,
    private alertSrv: AlertService) {
    //setup nav params
    this.modalNavParams =
    {
      columns: this.navParams.get("columns") ? this.navParams.get("columns") : null,
      element: this.navParams.get("element") ? this.navParams.get("element") : null,
      fromHeader: this.navParams.get("fromHeader") ? this.navParams.get("fromHeader") : null,
      showComparison: this.navParams.get("showComparison") ? this.navParams.get("showComparison") : null,
      message: this.navParams.get("message") ? this.navParams.get("message") : null,
    }
    //init comparison
    if (this.modalNavParams.showComparison && this.modalNavParams.columns[0].columnHeaderComparisonOptions) {
      this.comparison = this.modalNavParams.columns[0].columnHeaderComparisonOptions[0];
    }
  }

  ngOnInit() {

  }

  //save
  public save() {
    this.popoverCtrl.dismiss({ comparison: this.comparison, element: this.modalNavParams.element, columns: this.modalNavParams.columns });
  }

  //dateTimeChanged()
  /*public dateTimeChanged() {
    let dtTmp = new Date(this.modalNavParams.element[this.modalNavParams.column.columnDef]);
    this.modalNavParams.element[this.modalNavParams.column.columnDef] = dtTmp.getFullYear() + "-" + (dtTmp.getMonth() + 1 > 9 ? dtTmp.getMonth() + 1 : "0" + (dtTmp.getMonth() + 1)) + "-" +
      (dtTmp.getDay() > 9 ? dtTmp.getDay() : "0" + dtTmp.getDay()) + "T" + (dtTmp.getHours() > 9 ? dtTmp.getHours() : "0" + dtTmp.getHours()) + ":" + (dtTmp.getMinutes() > 9 ? dtTmp.getMinutes() : "0" + dtTmp.getMinutes());
  }*/
}
