import { Injectable } from '@angular/core';
import { ActionSheetButton, ActionSheetController, AlertButton, AlertController, AlertInput, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  //messages translated
  //public messagesTranslated;

  constructor(public alertCtrl: AlertController, public translate: TranslateService, private _platform: Platform, public actionSheetCtrl: ActionSheetController) {
    /*this.translate.get("menu").subscribe((res: string) => {
      if (res) {
        this.show(res)
      }
    });*/
  }

  //success alert
  async successAlert(message) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      this.mobileSimpleAlert({ message: message, type: 'success' });
    } else {
      message = '<div class="alert-service-title title-success">' +
        '<ion-icon name="checkbox-outline"></ion-icon>' +
        '&nbsp;' + '<span>' + this.translate.instant('labels.operation_success') + '</span>' +
        '</div>' +
        '<div class="alert-service-message">' +
        '<span>' + message + '</span>' +
        '</div>';
      const alert = await this.alertCtrl.create({
        cssClass: 'alert-service',
        message: message,
        mode: "md",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            cssClass: 'secondary focus-button'
          }
        ]
      });
      await alert.present()
      setTimeout(() => {
        if (Array.from(document.getElementsByClassName("focus-button"))[0])
          (Array.from(document.getElementsByClassName("focus-button"))[0] as HTMLElement).focus();
      }, 500)
    }
  }

  //success alert
  async successConfirmAlert(message, callback?, isSave?) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      this.mobileSimpleAlert({ message: message, type: 'success', callback: callback });
    } else {
      message = '<div class="alert-service-title title-success">' +
        '<ion-icon name="checkbox-outline"></ion-icon>' +
        '&nbsp;' + '<span>' + (isSave ? this.translate.instant('labels.saving') : this.translate.instant('labels.operation_success')) + '</span>' +
        '</div>' +
        '<div class="alert-service-message">' +
        '<span>' + message + '</span>' +
        '</div>';
      const alert = await this.alertCtrl.create({
        cssClass: 'alert-service',
        message: message,
        mode: "md",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel'),
            cssClass: 'danger',
            role: 'cancel'
          },
          {
            text: this.translate.instant('buttons.ok'),
            cssClass: 'secondary focus-button',
            handler: () => {
              if (callback) {
                callback();
              }
            }
          }
        ]
      });
      await alert.present()
      setTimeout(() => {
        if (Array.from(document.getElementsByClassName("focus-button"))[0])
          (Array.from(document.getElementsByClassName("focus-button"))[0] as HTMLElement).focus();
      }, 500)
    }
  }

  //warning alert
  async warningAlert(options, callback?) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      this.mobileSimpleAlert({ message: options.message, type: 'warning', callback: callback });
    } else {
      let message = '<div class="alert-service-title title-warning">' +
        '<ion-icon name="warning-outline"></ion-icon>' +
        '&nbsp;' + '<span>' + this.translate.instant('labels.operation_warning') + '</span>' +
        '</div>' +
        '<div class="alert-service-message">' +
        '<span>' + options.message + '</span>' +
        '</div>';
      let classes = options.cssClasses ? ["alert-service"].concat(options.cssClasses) : ["alert-service"];
      const alert = await this.alertCtrl.create({
        cssClass: classes,
        message: message,
        mode: "md",
        backdropDismiss: options && options.denyBackdropDismiss ? false : true,
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            cssClass: 'secondary focus-button',
            handler: () => {
              if (callback) {
                callback();
              }
            }
          }
        ]
      });
      await alert.present()
      setTimeout(() => {
        if (Array.from(document.getElementsByClassName("focus-button"))[0])
          (Array.from(document.getElementsByClassName("focus-button"))[0] as HTMLElement).focus();
      }, 500)
    }
  }

  //warning alert
  async warningConfirmAlert(options, successCallback?, cancelCallback?, customAlertId?) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      this.mobileSimpleAlert({ message: options.message, type: 'warningConfirm', callback: successCallback, cancelCallback: cancelCallback });
    } else {
      if (!customAlertId || (customAlertId && !Array.from(document.getElementsByClassName(customAlertId))[0])) {
        const confirmButtonClass = "focus-button-" + new Date().getTime();
        let title = options.title ? options.title : this.translate.instant('labels.operation_warning');
        let message = '<div class="alert-service-title title-warning">' +
          '<ion-icon name="warning-outline"></ion-icon>' +
          '&nbsp;' + '<span>' + title + '</span>' +
          '</div>' +
          '<div class="alert-service-message">' +
          '<span>' + options.message + '</span>' +
          '</div>';
        let classes = options.cssClasses ? ["alert-service"].concat(options.cssClasses) : ["alert-service"];
        if (customAlertId)
          classes.push(customAlertId.toString());
        const alert = await this.alertCtrl.create({
          cssClass: classes,
          message: message,
          backdropDismiss: false,
          mode: "md",
          buttons: [
            {
              text: this.translate.instant('buttons.cancel'),
              role: 'cancel',
              handler: () => {
                if (cancelCallback) {
                  cancelCallback();
                }
              }
            },
            {
              text: options.okButton ? options.okButton : this.translate.instant('buttons.ok'),
              cssClass: 'alert-button-warning ' + confirmButtonClass,
              handler: () => {
                if (successCallback) {
                  successCallback();
                }
              }
            }
          ]
        });
        await alert.present().then(() => {
          if (Array.from(document.getElementsByClassName(confirmButtonClass))[0])
            (Array.from(document.getElementsByClassName(confirmButtonClass))[0] as HTMLElement).focus();
        });
      }
    }
  }

  //error alert
  async errorAlert(message, callback?) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      this.mobileSimpleAlert({ message: message, type: 'error', callback: callback });
    } else {
      message = '<div class="alert-service-title title-danger">' +
        '<ion-icon name="warning-outline"></ion-icon>' +
        '&nbsp;' + '<span>' + this.translate.instant('labels.operation_error') + '</span>' +
        '</div>' +
        '<div class="alert-service-message">' +
        '<span>' + message + '</span>' +
        '</div>';
      const alert = await this.alertCtrl.create({
        cssClass: 'alert-service',
        backdropDismiss: false,
        message: message,
        mode: "md",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            cssClass: 'secondary focus-button',
            handler: () => {
              if (callback) {
                callback();
              }
            }
          }
        ]
      });
      await alert.present()
      setTimeout(() => {
        if (Array.from(document.getElementsByClassName("focus-button"))[0])
          (Array.from(document.getElementsByClassName("focus-button"))[0] as HTMLElement).focus();
      }, 500)
    }
  }

  //generic alert
  async genericAlert(options: GenericAlertOptions, successCallback?, cancelCallback?) {
    let color = options.titleColor ? ' title-' + options.titleColor : "";
    let message = '<div class="alert-service-title' + color + '">' +
      '<ion-icon name="' + (options.titleIcon ? options.titleIcon : 'information-circle-outline') + '"></ion-icon>' +
      '&nbsp;' + '<span>' + options.title + '</span>' +
      '</div>' +
      '<div class="alert-service-message">' +
      '<span>' + options.message + '</span>' +
      '</div>';
    let buttons = [
      {
        text: options.buttonText ? options.buttonText : this.translate.instant('buttons.ok'),
        cssClass: options.buttonColor ? 'alert-button-' + options.buttonColor : '' + ' focus-button',
        role: '',
        handler: () => {
          if (successCallback) {
            successCallback();
          }
        }
      }
    ]
    if (cancelCallback)
      buttons.unshift({
        text: this.translate.instant('buttons.cancel'),
        cssClass: '',
        role: 'cancel',
        handler: () => {
          if (cancelCallback) {
            cancelCallback();
          }
        }
      });
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      backdropDismiss: options.denyBackdropDismiss ? false : true,
      message: message,
      mode: "md",
      buttons: buttons
    });
    await alert.present()
    setTimeout(() => {
      if (Array.from(document.getElementsByClassName("focus-button"))[0])
        (Array.from(document.getElementsByClassName("focus-button"))[0] as HTMLElement).focus();
    }, 500)
  }

  //get alert
  public isAlertOpened() {
    return new Promise((resolve) => {
      this.alertCtrl.getTop().then((res) => {
        resolve(res);
      });
    })
  }
  public dismissAlert(id: string) {
    this.alertCtrl.dismiss(null, null, id)
  }

  //--- MOBILE ---//
  public async mobileSimpleAlert(options: MobileSimpleAlertOptions) {
    let buttons: AlertButton[] = [
      {
        text: this.translate.instant('buttons.ok'),
        handler: () => {
          if (options.callback) {
            options.callback();
          }
        }
      }
    ]
    if (options.type == 'warningConfirm')
      buttons.unshift({
        text: this.translate.instant('buttons.cancel'),
        role: 'cancel',
        handler: () => {
          if (options.cancelCallback) {
            options.cancelCallback();
          }
        }
      },);
    if (options.appSettingsCallback)
      buttons.push({
        text: this.translate.instant('labels.settings'),
        handler: () => {
          options.appSettingsCallback();
        }
      })
    const alert = await this.alertCtrl.create({
      cssClass: 'mobile-alert' + ' mobile-alert-' + options.type,
      header: options.title ? this.translate.instant(options.title) : this.translate.instant('labels.' + options.type),
      message: options.message,
      backdropDismiss: false,
      buttons: buttons,
      id: options.id ? options.id : null
    });
    await alert.present()
  }
  public async mobileActionSheet(options: MobileSimpleAlertOptions) {
    let buttons: ActionSheetButton[] = [
      {
        text: this.translate.instant('buttons.ok'),
        handler: () => {
          if (options.callback) {
            options.callback();
          }
        }
      }
    ]
    if (options.type == 'warningConfirm')
      buttons.unshift({
        text: this.translate.instant('buttons.cancel'),
        role: 'cancel',
        handler: () => {
          if (options.cancelCallback) {
            options.cancelCallback();
          }
        }
      },);
    if (options.appSettingsCallback)
      buttons.push({
        text: this.translate.instant('labels.settings'),
        handler: () => {
          options.appSettingsCallback();
        }
      })
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'mobile-alert' + ' mobileAlert-' + options.type,
      header: options.title ? this.translate.instant(options.title) : this.translate.instant('labels.' + options.type),
      subHeader: options.message,
      buttons: buttons,
      id: options.id ? options.id : null
    });
    await actionSheet.present()
  }



  public async mobileForgotPasswordAlert(callback: (username: string) => any, useCodeCallback: (username: string) => any) {
    const alertForgotPassword = await this.alertCtrl.create({
      header: this.translate.instant('labels.resetPassword'),
      message: this.translate.instant('labels.resetPasswordMessage'),
      inputs: [
        {
          name: 'username',
          type: 'email',
          placeholder: this.translate.instant('labels.username'),
          id: 'emailForgotPassword'
        }
      ],
      buttons: [
        {
          text: this.translate.instant('labels.requestReset'),
          handler: (data) => {
            if (data.username && data.username.trim())
              callback(data.username.trim());
            else {
              return false;
            }
          },
          id: 'resetForgotPassword',
          cssClass: 'alert-disabled-button'
        },
        {
          text: this.translate.instant('labels.useResetCode'),
          handler: (data) => {
            if (data.username && data.username.trim())
              useCodeCallback(data?.username?.trim());
            else {
              return false;
            }
          },
          id: 'useCodeForgotPassword',
          cssClass: 'alert-disabled-button'
        },
        {
          text: this.translate.instant('labels.cancel'),
          role: 'cancel',
        }
      ]
    });
    await alertForgotPassword.present().then(() => {
      const domEmail = document.getElementById('emailForgotPassword');
      const domReset = document.getElementById('resetForgotPassword');
      const domUse = document.getElementById('useCodeForgotPassword');
      if (domEmail) {
        domEmail.addEventListener('input', (event: any) => {
          if (domReset && domUse) {
            if (event.target.value && event.target.value != '') {
              domReset.classList.remove("alert-disabled-button");
              domUse.classList.remove("alert-disabled-button");
            } else {
              domReset.classList.add("alert-disabled-button");
              domUse.classList.add("alert-disabled-button");
            }
          }
        });
      }
    });
  }

  public async mobileTutorialAlert(title: string, message: string, callback: any) {
    const tutorialActionSheet = await this.actionSheetCtrl.create({
      header: title,
      subHeader: message,
      buttons: [
        {
          text: this.translate.instant('labels.doNotShowAgain'),
          handler: () => {
            callback();
          }
        },
        {
          text: this.translate.instant('labels.close'),
          role: 'cancel'
        },
      ]
    });
    await tutorialActionSheet.present();
  }

  public async mobilePin(title: string, message: string, callback: any, pinType: 'password' | 'text' | 'tel', max?: string, okButtonText?: string) {
    const pinAlert = await this.alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: false,
      inputs: [
        {
          type: pinType,
          name: 'pin',
          id: 'pinInput'
        }
      ],
      buttons: [
        {
          text: okButtonText ? okButtonText : this.translate.instant('buttons.ok'),
          handler: (res) => {
            if (res && res.pin != null && res.pin != undefined && res.pin != '') {
              callback(res, pinAlert);
              return false;
            }
            else
              return false;
          },
          id: 'confirmPinButtom',
          cssClass:'alert-disabled-button'
        },
        {
          text: this.translate.instant('labels.close'),
          role: 'cancel'
        }
      ]
    });
    await pinAlert.present().then(() => {
      const domPin = document.getElementById('pinInput');
      const domPinConfirm = document.getElementById('confirmPinButtom');
      if (domPin) {
        domPin.addEventListener('input', (event: any) => {
          if (domPinConfirm) {
            if (event.target.value && event.target.value != '') {
              domPinConfirm.classList.remove("alert-disabled-button");
            } else {
              domPinConfirm.classList.add("alert-disabled-button");
            }
          }
        });
      }
      if (max) {
        document.getElementById('pinInput').setAttribute('maxlength', max);
      }
    });
  }


  public async mobileNoVehicleAlert(callback: any) {
    const tutorialAlert = await this.alertCtrl.create({
      header: this.translate.instant('labels.warning'),
      message: this.translate.instant('labels.noVehiclesMessage'),
      buttons: [
        {
          text: this.translate.instant('buttons.ok'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('labels.add'),
          handler: () => {
            if (callback)
              callback();
          }
        },
      ]
    });
    await tutorialAlert.present();
  }


  public async confirmSignAlert(confirmCallback: any, undoSignature: any) {
    const tutorialAlert = await this.alertCtrl.create({
      header: this.translate.instant('labels.warning'),
      message: this.translate.instant('labels.confirmAccidentMessage'),
      buttons: [
        {
          text: this.translate.instant('labels.confirm'),
          handler: () => {
            if (confirmCallback)
              confirmCallback();
          }
        },
        {
          text: this.translate.instant('labels.modifySignature'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('buttons.cancel'),
          handler: () => {
            if (undoSignature)
              undoSignature();
          }
        }
      ]
    });
    await tutorialAlert.present();
  }
  //--- END  MOBILE ---//

}

/*export interface genericAlertOptions {
  title: string,
  message: string,
  titleColor?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary',
  denyBackdropDismiss?: boolean,
  buttonText?: string,
  buttonColor?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary'
}*/

export class GenericAlertOptions {
  public title?: string;
  public message?: string;
  public titleColor?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary';
  public titleIcon?: string;
  public denyBackdropDismiss?: boolean;
  public buttonText?: string;
  public buttonColor?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary';
  constructor(titleParam?: string, messageParam?: string, titleColorParam?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary', titleIconParam?: string,
    denyBackdropDismissParam?: boolean, buttonTextParam?: string, buttonColorParam?: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary') {
    this.title = titleParam;
    this.message = messageParam;
    this.titleColor = titleColorParam;
    this.titleIcon = titleIconParam;
    this.denyBackdropDismiss = denyBackdropDismissParam;
    this.buttonText = buttonTextParam;
    this.buttonColor = buttonColorParam;
  }
}

export interface MobileSimpleAlertOptions {
  message: string,
  type: 'success' | 'warning' | 'warningConfirm' | 'error' | 'info',
  callback?: any,
  cancelCallback?: any,
  appSettingsCallback?: any,
  id?: string,
  title?: string
}