import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { AlertController } from '@ionic/angular';
import { LoaderService } from '../../services/loader.service'
import { AdminUserDetailService } from '../../services/admin-user-detail.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  //impersonated user guid
  public impersGuid = "";
  //show messages
  @Input() showMessages = false;
  //show footer
  public showFooter = false;
  //is mobile
  @Input() isMobile;

  constructor(private platform: Platform,
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public loadingSrv: LoaderService,
    public adminUserDetailSrv: AdminUserDetailService,
    public storaveVariableSrv: StorageVariablesService,
    public router: Router,
    public httpUtilitySrv: HttpUtilityService) {
    //route strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf("/login") == -1) {
          this.storaveVariableSrv.getAdminImpersGuidObject().then((res: any) => {
            if (res && res.guid && res.guid != "" && res.guid.includes('-')) {
              this.impersGuid = res.guid;
            } else
              this.impersGuid = null;
          })
          this.showFooter = !(["localImmaExt", "testImmaExt", "preProductionImmaExt", "productionImmaExt"].includes(this.httpUtilitySrv.getEnvironment()));
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isMobile)
      this.isMobile = changes.isMobile.currentValue;
  }

  ngOnInit() {

  }

  //stop impersonate user alert
  async stopImpersonateUserAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.stop_impersonate_user'),
      message: this.translate.instant('messages.stop_impersonate_user_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.stopImpersonaUtente();
          }
        }
      ]
    });

    await alert.present()
  }

  //stop impersona utente 
  private stopImpersonaUtente() {
    this.loadingSrv.open();
    this.adminUserDetailSrv.stopImpersonaUtente(this.impersGuid).then((res: any) => {
      if (res.body.code == 200) {
        this.storaveVariableSrv.setAdminImpersGuidObject(null);
        if (res.body.payload && res.body.payload.idUtente) {
          res.body.payload["token"] = res.headers.get('token');
          res.body.payload["refreshToken"] = res.headers.get('tokenrefresh');
          this.storaveVariableSrv.setAdminImpersUserObject(res.body.payload);
          this.router.navigateByUrl("login", { replaceUrl: true });
        }
        this.loadingSrv.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.body.code))
      }
    }).catch((err) => {
      //
      this.loadingSrv.dismiss();

    })
  }

}
