<div class="pssfe-dropdown" [ngClass]="{'pssfe-dropdown-disabled': disabled, 'pssfe-dropdown-readonly': readonly}">
  <div class="pssfe-dropdown-button" (click)="openPssfeDropdown()" id="pss-dropdown-button">
    <div *ngIf="value == null || value == undefined || (value && value.length == 0)">
      {{placeholder | translate}}
    </div>
    <div class="pssfe-value-label" *ngIf="!multiple && value">
      <ion-icon [name]="value.icon" [color]="value.iconColor ? value.iconColor : 'primary'"
        *ngIf="value.icon"></ion-icon>{{optionText ? (value[optionText] | translate) : (value | translate) }}
    </div>
    <div *ngIf="multiple && value && value.length > 0">
      <div class="pssfe-value-label" *ngFor="let item of value;let itemI=index;"><span
          *ngIf="itemI != 0">,</span><ion-icon [name]="item.icon" [color]="item.iconColor ? item.iconColor : 'primary'"
          *ngIf="item.icon"></ion-icon>{{optionText ?
        (item[optionText] | translate)
        : (item | translate) }}</div>
    </div>
  </div>
</div>