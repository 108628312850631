import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminRoleDetailService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //get ruoli api request
  public getRuoli() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getRuoli`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get clienti api request
  public getClienti() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getClienti`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }


  //get dettaglio ruolo api request
  public getDettaglioRuolo(roleId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}dettaglioRuolo/` + roleId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //inserisci ruolo api request
  public inserisciRuolo(name, enabled, isSupervisor, idModuli, idClienti, newServices, assignableOnlyFromHigherTerritory) {
    return new Promise((resolve, reject) => {
      let payload = {
        "ruolo": {
          "nome": name,
          "attivo": enabled,
          "isSupervisor": isSupervisor,
          "assignableOnlyFromHigherTerritory": assignableOnlyFromHigherTerritory
        },
        "newIdCliente": idClienti,
        "newIdModuli": idModuli,
        "newServizi": newServices,
      }
      this.http.post(`${this.httpUtilitySrv.links.server}inserisciRuolo`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //modifica ruolo api request
  public modificaRuolo(roleId, name, enabled, isSupervisor,  customer, idModuli, newServices, assignableOnlyFromHigherTerritory) {
    return new Promise((resolve, reject) => {
      let payload = {
        "ruolo": {
          "id": roleId,
          "nome": name,
          "attivo": enabled,
          "cliente": customer,
          "isSupervisor": isSupervisor,
          "assignableOnlyFromHigherTerritory": assignableOnlyFromHigherTerritory
        },
        "newIdModuli": idModuli,
        "newServizi": newServices
      }
      this.http.post(`${this.httpUtilitySrv.links.server}modificaRuolo`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get moduli menu api request
  public getModuli(idTerritorio) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getModuli/` + idTerritorio).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //elimina ruolo api request
  public eliminaRuolo(roleId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}eliminaRuolo/` + roleId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //copia ruolo api request
  public copiaRuolo(roleId, newIdClienti) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}copiaRuolo`, {
        newIdClienti: newIdClienti,
        idRuolo: roleId
      }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getListaChiaviValoreRuoloServiziSafo api request
  public getListaChiaviValoreRuoloServiziSafo(roleId, modulesIdList) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        let payload = {
          "idRuolo": roleId,
          "idModuliSelezionati": modulesIdList
        }
        this.http.post(`${this.httpUtilitySrv.links.server}getListaChiaviValoreRuoloServiziSafo`, payload).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }
}
