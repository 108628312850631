import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-service.service';
import { Router, NavigationEnd } from '@angular/router';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { InputTypes, DcrudInput, TriggerActions, DcrudModalParams } from 'src/app/pages/dcrud/models/dcrud.models';
import { AlertService } from 'src/app/services/alert.service';
import { DcrudService } from 'src/app/services/dcrud.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Location } from '@angular/common';
import { SearchStateObject, StorageVariablesService } from 'src/app/services/storage-variables.service';
import { columnTypes } from 'src/app/shared-components/table/models/table-models';
import { SectionService } from 'src/app/services/section.service';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-dcrud-form',
  templateUrl: './dcrud-form.component.html',
  styleUrls: ['./dcrud-form.component.scss'],
})
export class DcrudForm implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  //router sub
  public routerSub;
  //updateFormDetailSub
  public updateFormDetailSub;
  //
  //InputTypes
  public InputTypes = InputTypes;
  //inputList
  public inputList: Array<DcrudInput>;
  //header
  public header;
  //modalParams: detail from api before modal open {apiResponse, urlParams}
  @Input() modalParams: DcrudModalParams = null;

  constructor(public translate: TranslateService,
    public authSrv: AuthService,
    private router: Router,
    private _httpUtilitySrv: HttpUtilityService,
    private alertSrv: AlertService,
    private _dcrudSrv: DcrudService,
    private loaderSrv: LoaderService,
    private _location: Location,
    private _storageSrv: StorageVariablesService,
    private _sectionSrv: SectionService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void { }


  ngOnDestroy(): void {
    if (this.routerSub)
      this.routerSub.unsubscribe();
    if (this.updateFormDetailSub)
      this.updateFormDetailSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    //setup
    if (!this.modalParams) {
      this.routerSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url && (event.url.indexOf("/form?") != -1 || event.url.indexOf("/int-form?") != -1)) {
            this.getFormDetail();
          }
        }
      })
    } else if (this.modalParams && this.modalParams.apiResponse && this.modalParams.urlParams) {
      let urlParams = new URLSearchParams(this.modalParams.urlParams);
      this._storageSrv.getDcrudFormObject(urlParams.get("CodicePagina")).then((dcrudFormObject: any) => {
        this._initForm(this.modalParams.apiResponse, urlParams, dcrudFormObject);
      });
    }
    //update sub
    this.updateFormDetailSub = this._dcrudSrv.updateFormDetail.subscribe((res) => {
      if (res) {
        this.getFormDetail();
      }
    })
  }
  ngOnInit() {

  }

  //getFormDetail
  public getFormDetail() {
    let urlParams = new URLSearchParams(window.location.search);
    this._storageSrv.getDcrudFormObject(urlParams.get("CodicePagina")).then((dcrudFormObject: any) => {
      this.loaderSrv.open();
      this.inputList = [];
      this._dcrudSrv.getFormDetail(dcrudFormObject ? `?${dcrudFormObject.params}` : window.location.search, window.location.href.indexOf("/int-form?") != -1 ? true : false).then((res: any) => {
        if (res && res.body && res.headers) {
          this.loaderSrv.dismiss();
          try {
            //get modules from token
            let tokenModules = jwt_decode(res.headers.get("token"))['modules'];
            //handle input with permissions non in token (trigger action PERMISSION...)
            this._dcrudSrv.handleAnauthorizedInput(tokenModules, res.body)
            //check for permissionModule
            if (res.body.permissionModule != null && res.body.permissionModule != undefined) {
              if (tokenModules && tokenModules.indexOf('#' + res.body.permissionModule + '#') != -1)
                this._initForm(res.body, urlParams, dcrudFormObject)
              else
                this.alertSrv.errorAlert(this.translate.instant('errors.7'))
            } else {
              this._initForm(res.body, urlParams, dcrudFormObject)
            }
          } catch (Error) {
            this.alertSrv.errorAlert(this.translate.instant('errors.7'))
          }
        }
      }).catch((err) => {
        this.loaderSrv.dismiss();
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this._httpUtilitySrv.getRequestError(err).then((message) => {
            this.alertSrv.errorAlert(message);
          })
        }
      });
    })
  }

  //init form
  private _initForm(getDatailResponse, urlParams, dcrudFormObject) {
    if (getDatailResponse && getDatailResponse.inputList) {
      //check for saved states
      this._storageSrv.getSearchState("DCRUD-" + urlParams.get("CodicePagina"), true).then((searchStateObject: SearchStateObject) => {
        if (searchStateObject && searchStateObject.filters && searchStateObject.filters.length > 0) {
          let flatinputList: Array<DcrudInput> = [], inputTmp: DcrudInput;
          this._dcrudSrv.getFlatINputList1to1(getDatailResponse.inputList, flatinputList);
          for (let input of flatinputList) {
            if (input.saveState && !input.disabled && !input.hidden && !input.readonly) {
              inputTmp = searchStateObject.filters.filter((x) => { return x.id == input.id })[0];
              if (inputTmp)
                input.value = inputTmp.value;
            }
          }
        }
        //header
        this.header = getDatailResponse.header;
        //merge values
        if (getDatailResponse.valuesEndpoint) {
          if (getDatailResponse.valuesEndpointPayload)
            this._getPayloadByPayloadAndUrlParams(getDatailResponse.valuesEndpointPayload, urlParams);
          //replace tags of valuesEndpointPayload with storage params saved. Add current url params if exists
          let url = this._dcrudSrv.formatUrlByUrlAndURLSearchParams(getDatailResponse.valuesEndpoint, urlParams.toString(), dcrudFormObject?.params);
          this._httpUtilitySrv.genericRequest(url, getDatailResponse.valuesEndpointPayload ? 'post' : 'get', getDatailResponse.valuesEndpointPayload).then((genericReuqestRes) => {
            if (genericReuqestRes) {
              this._mergeTemplateWithValues(getDatailResponse.inputList, genericReuqestRes);
            }
            this.inputList = getDatailResponse.inputList;
            this._dcrudSrv.formatInputListValuesForFE(this.inputList);
            this._dcrudSrv.updateEndpoints(this.inputList);
            this._initOptionsList();
          }).catch((err) => {
            this.loaderSrv.dismiss();
            if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
              this._httpUtilitySrv.getRequestError(err).then((message) => {
                this.alertSrv.errorAlert(message);
              })
            }
          });
        } else {
          //format values
          this.inputList = getDatailResponse.inputList;
          //this._dcrudSrv.formatInputListValuesForFE(this.inputList);
          this._dcrudSrv.updateEndpoints(this.inputList);
          this._initOptionsList();
        }
      })
    }
  }


  //mergeTemplateWithValues
  private _mergeTemplateWithValues(inputList, genericRequestResponse) {
    let flatinputList: Array<DcrudInput> = [];
    this._dcrudSrv.getFlatINputList1to1(inputList, flatinputList);
    flatinputList.forEach((innerInput) => {
      //merge value
      if (innerInput.mergeValueId) {
        innerInput.value = this._getValueFromObject(innerInput.mergeValueId, genericRequestResponse);
        this._formatInputValueForFE(innerInput);
      }
      //populate table columnParams for lists
      if (innerInput.tableColumns && Array.isArray(innerInput.tableColumns)) {
        innerInput.tableColumns.forEach((column) => {
          if ([columnTypes.INPUT_COMBO.toString(), columnTypes.INPUT_POPUP_COMBO.toString(), columnTypes.INPUT_MULTICOMBO.toString(), columnTypes.INPUT_POPUP_MULTICOMBO.toString(),
          columnTypes.INPUT_RADIO.toString(), columnTypes.INPUT_POPUP_RADIO.toString()].includes(column.columnType)
            && genericRequestResponse[column.columnDef] && Array.isArray(genericRequestResponse[column.columnDef])) {
            column.columnParam = genericRequestResponse[column.columnDef];
          }
        })
      }
    });
  }

  //getValueFromObject
  private _getValueFromObject(property: string, object: any) {
    //ex: myObj.myInnerObj.MyProperty
    let propertyPath: Array<string> = property.split("."), currentObject = JSON.parse(JSON.stringify(object));
    for (let i = 0; i < propertyPath.length; i++) {
      if (currentObject[propertyPath[i]] != null && currentObject[propertyPath[i]] != undefined) {
        currentObject = currentObject[propertyPath[i]];
      } else {
        currentObject = null;
        break;
      }
    }
    return currentObject;
  }

  //formatInputValueForFE
  private _formatInputValueForFE(input: DcrudInput) {
    if (input.value != null && input.value != undefined) {
      //SELEZIONE SELEZIONE_AVANZATA RADIO check for not object values with optionValue ex: optionValue: "id", value = 1 istead of value = {id: 1, desc: "desc_1"}
      if ((input.inputType == InputTypes.SELEZIONE || input.inputType == InputTypes.SELEZIONE_AVANZATA || input.inputType == InputTypes.RADIO)
        && input.optionsList && input.optionsList.length > 0 && input.optionValue
        && ((Array.isArray(input.value) && input.value.length > 0 && typeof input.value[0] != 'object') || typeof input.value != 'object')) {
        let mergeValueList = input.optionsList.filter((item) => { return input.multiple ? input.value.includes(item[input.optionValue]) : item[input.optionValue] == input.value });
        input.value = input.multiple ? mergeValueList : mergeValueList[0] ? mergeValueList[0] : null;
      }
      //DATA yyyy-MM-dd
      if (input.inputType == InputTypes.DATA)
        input.value = input.value.substring(0, 10);
      //DATA_ORA yyyy-MM-ddTHH:mm
      if (input.inputType == InputTypes.DATA_ORA)
        input.value = input.value.substring(0, 19);
    }
  }

  //getPayloadByPayloadAndInputList
  private _getPayloadByPayloadAndUrlParams(payload, urlParams) {
    if (payload && urlParams)
      for (let property in payload) {
        payload[property] = urlParams.get(property);
      }
  }

  //initOptionsLists
  private _initOptionsList() {
    let flatinputList: Array<DcrudInput> = [];
    this._dcrudSrv.getFlatINputList1to1(this.inputList, flatinputList);
    flatinputList.forEach((input) => {
      if ([this.InputTypes.RADIO.toString(), this.InputTypes.SELEZIONE.toString(), this.InputTypes.SELEZIONE_AVANZATA.toString(), this.InputTypes.TESTO.toString(), this.InputTypes.NUMERO.toString(), this.InputTypes.AREA_TESTO.toString()].includes(input.inputType) && input.optionListEndpoint) {
        this._httpUtilitySrv.genericRequest(this._dcrudSrv.resolveEndpointByFlatInputList(input.optionListEndpoint, this._dcrudSrv.getFlatINputListDeepCopy(this.inputList)), input.optionListEndpoint.indexOf("endpointMethod=post") != -1 ? 'post' : 'get', this._dcrudSrv.getAllFromValuesFromFlatInpuList(this._dcrudSrv.getFlatINputListDeepCopy(this.inputList))).then((res) => {
          let url = new URL(input.optionListEndpoint);
          let urlParams = url.searchParams;
          if (urlParams.get("searchResultTarget") && res[urlParams.get("searchResultTarget")] && Array.isArray(res[urlParams.get("searchResultTarget")]))
            input.optionsList = res[urlParams.get("searchResultTarget")];
          else if (res && Array.isArray(res))
            input.optionsList = res;
          //init selection and radio value if value exists and optionList exists
          if (input.inputType == InputTypes.SELEZIONE || input.inputType == InputTypes.SELEZIONE_AVANZATA || input.inputType == InputTypes.RADIO) {
            this._formatInputValueForFE(input);
          }
        })
          .catch(() => { })
      }
    });
  }

}
