import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-service.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { DcrudModalParams } from 'src/app/pages/dcrud/models/dcrud.models';
import { AlertService } from 'src/app/services/alert.service';
import { DcrudService } from 'src/app/services/dcrud.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SectionService } from 'src/app/services/section.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-dcrud-form-modal',
  templateUrl: './dcrud-form-modal.component.html',
  styleUrls: ['./dcrud-form-modal.component.scss'],
})
export class DcrudFormModal implements OnInit, AfterViewInit, OnDestroy {
  //modalParams
  public modalParams: DcrudModalParams;
  //closeModalCallbackSub
  private _closeModalCallbackSub;

  constructor(public translate: TranslateService,
    public authSrv: AuthService,
    private _httpUtilitySrv: HttpUtilityService,
    private alertSrv: AlertService,
    private _dcrudSrv: DcrudService,
    private loaderSrv: LoaderService,
    private _sectionSrv: SectionService,
    private _navParams: NavParams,
    public modalCtrl: ModalController,
    private _router: Router
  ) {
  }


  ngOnChanges(changes: SimpleChanges): void { }

  ngAfterViewInit(): void {
    if (this._navParams.get("urlParams")) {
      this._getFormDetail(this._navParams.get("urlParams"));
      this._closeModalCallbackSub = this._dcrudSrv.closeModalCallback.subscribe((params) => {
        if (params && params.navigateByUrl) {
          this._router.navigateByUrl(params.navigateByUrl);
          this.modalCtrl.dismiss();
        }
      })
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this._closeModalCallbackSub)
      this._closeModalCallbackSub.unsubscribe();
  }

  //getFormDetail
  private _getFormDetail(urlParams) {
    urlParams = new URLSearchParams(urlParams);
    this.loaderSrv.open();
    this._dcrudSrv.getFormDetail(`?${urlParams}`, false).then((res: any) => {
      this.loaderSrv.dismiss();
      //check for permissions
      try {
        //get modules from token
        let tokenModules = jwt_decode(res.headers.get("token"))['modules'];
        //delete input with permissions non in token (trigger action PERMISSION)
        this._dcrudSrv.handleAnauthorizedInput(tokenModules, res.body)
        //check for permissionModule
        if (res.body.permissionModule != null && res.body.permissionModule != undefined) {
          if (tokenModules && tokenModules.indexOf('#' + res.body.permissionModule + '#') != -1)
            this.modalParams = {
              apiResponse: res.body,
              urlParams: urlParams.toString()
            }
          else
            this.alertSrv.errorAlert(this.translate.instant('errors.7'))
        } else {
          this.modalParams = {
            apiResponse: res.body,
            urlParams: urlParams.toString()
          }
        }
      } catch (Error) {
        this.alertSrv.errorAlert(this.translate.instant('errors.7'))
      }
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this._httpUtilitySrv.getRequestError(err).then((message) => {
          this.alertSrv.errorAlert(message);
        })
      }
    });

  }

  //get sezioni
  private _getSezioni(permission: string, dcrudDetail, urlParams) {
    this._sectionSrv.getSezioni(permission).then((res: any) => {
      this.loaderSrv.dismiss();
      if (res.code == 200 && res.payload && Array.isArray(res.payload) && res.payload.includes(permission)) {
        this.modalParams = {
          apiResponse: dcrudDetail,
          urlParams: urlParams.toString()
        }
      } else {
        this.alertSrv.errorAlert(this.translate.instant("errors.7"));
      }
    }).catch((err) => {
      
    })
  }

}
