<div class="login-container">
  <div class="language" *ngIf="!isEnvironmentImma">
    <div class="language">
      <div class="language-select">
        <ion-select [(ngModel)]="language" (ngModelChange)="selectLanguage($event)" interface="popover"
          [style.background-image]="'url(./assets/icon/flags/' + language + '.png)'">
          <ion-select-option value="it">Italiano</ion-select-option>
          <ion-select-option value="en">English</ion-select-option>
          <ion-select-option value="en-US">American</ion-select-option>
          <ion-select-option value="fr">Français</ion-select-option>
          <ion-select-option value="de">Deutsche</ion-select-option>
          <ion-select-option value="at">Austrian</ion-select-option>
        </ion-select>
      </div>
    </div>
  </div>
  <div class="login-center">
    <table>
      <tr>
        <td class="login-left">
          <div class="login-left-wrapper">
            <div class="login-logo">
              <img src="assets/images/logo_safo.png">
            </div>
            <div class="login-message">
              {{'messages.loginMessage' | translate}}
            </div>
          </div>
        </td>
        <td>
          <div class="login-right">
            <!-- test environment message -->
            <div class="test-environment-message" *ngIf="isEnvironmentTest && !isEnvironmentImma">
              {{'labels.testEnvironment' | translate}}
            </div>
            <!-- form -->
            <form (keyup)="loginWithCredentials(loginFormGroup.valid, $event)" [formGroup]="loginFormGroup"
              (ngSubmit)="loginWithCredentials(loginFormGroup.valid)" [hidden]="!customerListCollapsed">
              <ion-item class="input-field" lines="none" lines="none">
                <ion-label position="stacked"> {{'labels.username' | translate}}</ion-label>
                <ion-input name="username" autocomplete="on" type="text" formControlName="email"
                  placeholder="{{'labels.username' | translate}}"></ion-input>
              </ion-item>
              <ion-item class="input-field input-field-icon" lines="none">
                <ion-label position="stacked"> {{'labels.password' | translate}}</ion-label>
                <ion-input [type]="showPassword ? 'text' : 'password'" formControlName="password" name="password"
                  placeholder="{{'labels.password' | translate}}" autocomplete="on">
                </ion-input>
                <ion-icon *ngIf="!showPassword" class="clickable" color="secondary" name="eye"
                  (click)="showPassword = !showPassword">
                </ion-icon>
                <ion-icon *ngIf="showPassword" class="clickable" color="secondary" name="eye-off"
                  (click)="showPassword = !showPassword"></ion-icon>
              </ion-item>

              <div class="login-buttons">
                <ion-button color="primary" type="submit" [disabled]="!loginFormGroup.valid">
                  {{'buttons.login' | translate}}
                </ion-button>
              </div>

              <div class="flex-center-center" *ngIf="registerKey != null && !isEnvironmentImma">
                <div class="forgot-password" (click)="goAgencyRegistration()">
                  <span>
                    {{'labels.register_agency_login' | translate}}
                  </span>
                </div>
              </div>
              <div class="forgot-password" *ngIf="!isEnvironmentImma">
                <span (click)="forgotPassword()">
                  {{'labels.forgot_password' | translate}}
                </span>
              </div>
            </form>
            <!-- customer list after login  -->
            <div class="customer-list" [hidden]="customerListCollapsed">
              <div class="customer-list-message">
                {{'messages.welcome_safoapp_message' | translate}}
              </div>
              <app-infinite-tree *ngFor="let item of searchList" [item]="item" [titleKey]="'denominazione'"
                [childrenKey]="'figli'" [hideCheckboxParent]="true" [hideCheckboxRole]="true" [allOpened]="true"
                (itemSelect)="selectRole($event)" [hideTerritoryCount]="true">
              </app-infinite-tree>
              <div class="flex-center-center">
                <div class="forgot-password" (click)="logout()">
                  <span>
                    {{'labels.logout' | translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </td>

      </tr>
    </table>
  </div>
</div>