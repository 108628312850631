import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsDetailService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //get image body api request
  public getAttachmentNews(newsId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}ottieniAllegatoNotizia/` + newsId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
