<div class="dcrud-inner-wrapper">
  <!-- header  -->
  <div class="page-title-card" *ngIf="header && !modalParams">
    <ion-icon class="header-icon" [name]="header.icon" *ngIf="header.icon"></ion-icon>
    <div class="title-data">
      <div>
        {{header.title | translate}}
      </div>
      <span>
        {{header.subtitle | translate}}<ion-icon name="information-circle-outline"
          [matTooltip]="header.description  | translate" *ngIf="header.description" matTooltipClass="table-tooltip"
          [ngClass]="{'ml-10px': header.subtitle}"></ion-icon>
      </span>
    </div>
  </div>
  <!-- input -->
  <ion-row>
    <ion-col *ngFor="let input of inputList"
      [ngClass]="{'dcrud-spazio-vuoto' : input.inputType == InputTypes.SPAZIO_VUOTO, 'dcrud-hidden-input': input.hidden, 'unknown-input': input.inputType == InputTypes.UNKNOWN}"
      [attr.size-lg]="input.hidden ? '0' : input.colSize && input.colSize.lg ? input.colSize.lg : '3'"
      [attr.size-md]="input.hidden ? '0' : input.colSize && input.colSize.md ? input.colSize.md : '3'"
      [attr.size-sm]="input.hidden ? '0' : input.colSize && input.colSize.sm ? input.colSize.sm : '4'"
      [attr.size-xs]="input.hidden ? '0' : input.colSize && input.colSize.xs ? input.colSize.xs : '12'">
      <app-dcrud-input [inputList]="inputList" [input]="input"
        [isDcrudModal]="modalParams ? true : false"></app-dcrud-input>
    </ion-col>
  </ion-row>

  <!-- 
  <ion-row>
    <ion-col *ngFor="let input of inputList;"
      [ngClass]="{'dcrud-spazio-vuoto' : input.inputType == InputTypes.SPAZIO_VUOTO, 'dcrud-hidden-input': input.hidden}"
      [attr.size-lg]="input.hidden ? '0' : input.colSize && input.colSize.lg ? input.colSize.lg : '3'"
      [attr.size-md]="input.hidden ? '0' : input.colSize && input.colSize.md ? input.colSize.md : '3'"
      [attr.size-sm]="input.hidden ? '0' : input.colSize && input.colSize.sm ? input.colSize.sm : '4'"
      [attr.size-xs]="input.hidden ? '0' : input.colSize && input.colSize.xs ? input.colSize.xs : '12'">
      <app-dcrud-input [inputList]="inputList" [input]="input" (buttonClicked)="buttonClicked($event)"></app-dcrud-input>
    </ion-col>
  </ion-row>-->
</div>