import { NgModule } from '@angular/core';
import { IonicModule } from"@ionic/angular";
import { TableDecimalePipe } from './table-decimale.pipe';
@NgModule({
    declarations: [
        TableDecimalePipe
    ],
    imports: [
        IonicModule
    ],
    exports: [
        TableDecimalePipe
    ]
})
export class TableDecimalePipeModule {}