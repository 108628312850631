import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderContainer: HTMLDivElement;
  constructor() {
    this.loaderContainer = document.createElement('div');
    this.loaderContainer.classList.add('global-loader-container');
  }

  //loading
  public open(options?: { message?: string }) {
    if (Array.from(document.getElementsByClassName('global-loader-container')).length == 0) {
      if (document.activeElement instanceof HTMLElement)
        (document.activeElement as HTMLElement).blur();
      this.loaderContainer.innerHTML = "<div class='global-loader-center'>" +
        "<div class='global-loader-center-inner'>" +
        /*"<ion-spinner color='primary' name='crescent'></ion-spinner>" +
        "<span>" + (options && options.message ? options.message : "") + "</span>" +
        "</div>" +*/
        "<div class='loader-folding-cube'>" +
        "<div class='loader-cube1 loader-cube'></div>" +
        "<div class='loader-cube2 loader-cube'></div>" +
        "<div class='loader-cube4 loader-cube'></div>" +
        "<div class='loader-cube3 loader-cube'></div>" +
        "</div>" + (options && options.message ?
          "<span>" + options.message : "") + "</span>" +
        "</div>"
      document.body.append(this.loaderContainer);
    }
  }
  public dismiss() {
    let loaderList = Array.from(document.getElementsByClassName('global-loader-container'));
    loaderList.forEach((element: Element) => {
      element.remove();
    });
  }
}
