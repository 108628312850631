import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth-service.service'
import { AlertController, PopoverController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { MessageListService } from 'src/app/services/message-list.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { AppService } from 'src/app/services/app.service';
import { PinPopoverComponent } from 'src/app/modals_popsover/pin-popover/pin-popover.component';
import { PinPopoverTypes } from 'src/app/modals_popsover/pin-popover/models/pin-popover.models';
import { MicroFrontEndContainerService } from 'src/app/services/micro-frontend-container.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LegacyFrontendContainerService } from 'src/app/services/legacy-frontend-container.service';
@Component({
  selector: 'app-login-services',
  templateUrl: './login-services.component.html',
  styleUrls: ['./login-services.component.scss'],
})
export class LoginServicesComponent implements OnInit {
  //language
  public language = "it";
  //login variables
  public email: '';
  public password: '';
  //login validator
  public loginFormGroup: FormGroup;
  //customer list collapsed
  public customerListCollapsed = true;
  //customer list search key
  public customerListKey = '';
  //customer list
  public currentUser = {
    "list": []
  }
  //search list
  public searchList = null;
  //show agency register
  public registerKey = null;
  //showPassword
  public showPassword = false;
  //isEnvironmentImmaExt
  public isEnvironmentImma;
  //isEnvironmentTest
  public isEnvironmentTest: boolean = false;

  constructor(
    private httpUtilitySrv: HttpUtilityService,
    public translate: TranslateService,
    public languageSrv: LanguageService,
    private router: Router,
    private authSrv: AuthService,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private loginSrv: LoginService,
    public storaveVariableSrv: StorageVariablesService,
    public route: ActivatedRoute,
    public alertSrv: AlertService,
    public signalRSrv: SignalRService,
    public messageListSrv: MessageListService,
    private appSrv: AppService,
    public popoverCtrl: PopoverController,
    private _microFESrv: MicroFrontEndContainerService,
    private _loaderSrv: LoaderService,
    private _legacyFrontendSrv: LegacyFrontendContainerService) {
    //setup validator
    this.loginFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.languageSrv.getLanguage().then((lan: string) => {
      this.language = lan;
    });
    //setup environment
    this.isEnvironmentImma = this.httpUtilitySrv.isEnvironmentImmaExt();
    this.isEnvironmentTest = this.httpUtilitySrv.isEnvironmentTest();
    this.translate.onLangChange.subscribe((language) => {
      this.language = language.lang;
    })
  }

  ngOnInit() {
    //setup current key
    this.route.queryParams.subscribe((params: any) => {
      if (params && params.token && params.id && params.id.toString().endsWith("_LINK")) {
        this.registerAgency(params.token, params.id)
        this.registerKey = {
          tokenUrl: params.token,
          tokenName: params.id,
          tokenValue: null
        };
      }
      else if (params && params.token && params.id && params.id.toString() == "SILENT_LOGIN") {
        this.silentlogin(params.token, params.id)
      }
      //silent login pin TPA TPN TPV
      else if (params && params.token && params.token.startsWith('TPN-')) {
        this.openPinPopover(PinPopoverTypes.NUMERIC, "messages.insertPin", null, params.token);
      }
      else if (params && params.token && params.token.startsWith('TPA-')) {
        this.openPinPopover(PinPopoverTypes.ALPHANUMERIC, "messages.insertPin", null, params.token);
      }
      else if (params && params.token && params.token.startsWith('TPV-')) {
        this.silentlogin(params.token, "CODICE_INGAGGIO", null)
      }
      else {
        this.storaveVariableSrv.getAdminImpersUserObject().then((res: any) => {
          if (res && res.user && res.user.idUtente && res.user.idUtente != 0) {
            this.storaveVariableSrv.setAdminImpersUserObject(null);
            if (res.user.guidImpersonificazione && res.user.guidImpersonificazione != "") {
              this.storaveVariableSrv.setAdminImpersGuidObject(res.user.guidImpersonificazione);
            }
            this.loginWithImpersonate(res.user);
          }
        })
      }
      //override services
      if (this.httpUtilitySrv.getEnvironment() == 'test')
        this._saveDevelopServicesOverrideQueryParams();
    })
  }

  //login with credentials
  public loginWithCredentials(valid, evt?) {
    if (valid && (!evt || (evt && evt.keyCode === 13))) {
      this.loginSrv.login(this.loginFormGroup.get("email").value, this.loginFormGroup.get("password").value, this.language).then((res: any) => {
        if (res.body.code == 200) {
          this.storaveVariableSrv.setAdminImpersGuidObject(null);
          this.currentUser.list = res.body.payload.clienti;
          this.searchList = res.body.payload.clienti;
          //autologin
          if (this.currentUser.list && this.currentUser.list.length == 1 && this.currentUser.list[0].ruoli && this.currentUser.list[0].ruoli.length == 1 && (!this.currentUser.list[0].figli || (this.currentUser.list[0].figli && this.currentUser.list[0].figli.length == 0))) {
            res.body.payload["token"] = res.headers.get('token');
            res.body.payload["refreshToken"] = res.headers.get('tokenrefresh');
            this.authSrv.setAuthObject({
              userData: res.body.payload,                            //user data
              role: this.currentUser.list[0].ruoli[0],          //role
              customer: this.currentUser.list[0].denominazione,  //customer name
              customerId: this.currentUser.list[0].idCliente,     //customer id
            });
            this.getMenu(this.currentUser.list[0].ruoli[0], this.currentUser.list[0].denominazione, this.currentUser.list[0].idCliente);
          }
          //manual login
          else {
            if (this.currentUser.list && this.currentUser.list.length > 0) {
              res.body.payload["token"] = res.headers.get('token');
              res.body.payload["refreshToken"] = res.headers.get('tokenrefresh');
              this.authSrv.setAuthObject({
                userData: res.body.payload
              });
              this.customerListCollapsed = false;
            }
          }
        } else if (res.body.code == 599) {
          this.storaveVariableSrv.setResetPasswordObject(this.loginFormGroup.get('email').value, this.loginFormGroup.get('password').value);
          setTimeout(() => {
            this.router.navigateByUrl('/reset-password');
          }, 500)
        }
        else {
          this.alertSrv.errorAlert(this.translate.instant('errors.' + res.body.code));

        }
      }).catch((err) => {
      })
    }
  }

  //login with impersonate
  private loginWithImpersonate(user) {
    if (user) {
      this.currentUser.list = user.clienti;
      this.searchList = user.clienti;
      //autologin
      if (this.currentUser.list && this.currentUser.list.length == 1 && this.currentUser.list[0].ruoli && this.currentUser.list[0].ruoli.length == 1 && (!this.currentUser.list[0].figli || (this.currentUser.list[0].figli && this.currentUser.list[0].figli.length == 0))) {
        this.authSrv.setAuthObject({
          userData: user,                            //user data
          role: this.currentUser.list[0].ruoli[0],          //role
          customer: this.currentUser.list[0].denominazione,  //customer name
          customerId: this.currentUser.list[0].idCliente,     //customer id
        });
        this.getMenu(this.currentUser.list[0].ruoli[0], this.currentUser.list[0].denominazione, this.currentUser.list[0].idCliente);
      }
      //manual login
      else {
        if (this.currentUser.list && this.currentUser.list.length > 0) {
          this.authSrv.setAuthObject({
            userData: user
          });
          this.customerListCollapsed = false;
        }
      }
    }
  }

  //select role
  public selectRole(object) {
    if (object.group && object.role) {
      this.getMenu(object.role, object.group.denominazione, object.group.idCliente);
    }
  }

  //get user info
  public async getMenu(role, customer, customerId) {
    this._loaderSrv.open();
    this.loginSrv.getModuli(role.id).then((result: any) => {

      if (result.body.code == 200) {
        this.authSrv.getAuthObject().then(async (authObject: any) => {
          authObject.userData.token = result.headers.get('token');
          authObject.userData['refreshToken'] = result.headers.get('tokenrefresh');
          this.authSrv.setAuthObject({
            userData: authObject.userData,
            role: role,                           //role
            customer: customer,                   //customer name
            customerId: customerId,               //customer id
            currentMenu: result.body.payload           //current menu
          });
          //check for legacy modules
          await this._legacyFrontendSrv.checkForLegacyModules(result.body.payload.figli);
          this.authSrv.setAuthObject({
            userData: authObject.userData,
            role: role,
            customer: customer,
            customerId: customerId,
            currentMenu: result.body.payload
          });
          //signalr
          this.signalRSrv.manageSignalrConnection();
          this.messageListSrv.getCountMessages();
          //this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
          //get translations
          let loadMenuItem = result.body.payload.figli[0].url ? result.body.payload.figli[0] : result.body.payload.figli[0].figli[0]
          if (loadMenuItem.url.indexOf('/route/') == -1)
            this.appSrv.navigate(loadMenuItem.url, true);
          else {
            this._microFESrv.lastRoute = null;
            this._microFESrv.microFrontendClicked(loadMenuItem);
          }
          this._loaderSrv.dismiss();
        })
      } else {
        this._loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + result.body.code))
      }
    }).catch((err) => {
      this._loaderSrv.dismiss();
    })
  }
  //forgot password alert
  async forgotPassword() {
    const alertForgotPassword = await this.alertCtrl.create({
      header: this.translate.instant('labels.password_recover'),
      message: this.translate.instant('messages.reset_password_message'),
      inputs: [
        {
          name: 'username',
          type: 'text',
          placeholder: this.translate.instant('labels.username_email')
        }
      ],
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant('buttons.send'),
          handler: (data) => {
            if (data.username && data.username.trim())
              this.resetPassword(data.username.trim());
            else {
              //this.alertCtrl.setMessagemsg = msg + "\n" + this.translate.instant('messages.field_required');
              return false;
            }
          }
        }
      ]
    });
    await alertForgotPassword.present()
  }

  //filter customer list
  public filterCustomerList() {
    this.searchList = this.currentUser.list.filter((item) => { return item.cliente.Denominazione.toLowerCase().includes(this.customerListKey.toLowerCase()) })
  }

  //logout 
  async logout() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.logout_message') }, () => {
      this.authSrv.setAuthObject(null);
      this.storaveVariableSrv.setAdminImpersGuidObject(null);
      this.customerListCollapsed = true;
    });
  }

  //reset password
  public resetPassword(username) {
    this.loginSrv.resetPassword(username, this.language).then((res: any) => {
      if (res.code == 200)
        this.alertSrv.successAlert(this.translate.instant('messages.password_email_message'))
      else
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
    }).catch(() => {

    })
  }

  //select language
  public selectLanguage(language) {
    this.languageSrv.changeLanguage(language);
  }

  //register agency
  public registerAgency(token, id) {
    this.loginSrv.registerAgency(token, id).then((result: any) => {
      if (result.code == 200) {
        this.registerKey = this.registerKey = {
          tokenName: result.payload.tokenName,
          tokenValue: result.payload.tokenValue,
          tokenUrl: this.registerKey.tokenUrl
        };
      } else {
        this.registerKey = null;
      }
    }).catch((err) => {
    })
  }

  //go agency registration
  public goAgencyRegistration() {
    if (this.registerKey.tokenValue && this.registerKey.tokenName)
      this.router.navigateByUrl("/agency-registration?tokenValue=" + this.registerKey.tokenValue + "&tokenName=" + this.registerKey.tokenName + "&tokenUrl=" + this.registerKey.tokenUrl)
  }


  //silent login
  public silentlogin(token, id, tokenPin?) {
    setTimeout(() => {
      this.loginSrv.silentLogin(token, id, tokenPin).then((result: any) => {
        if (result.body.code == 200) {
          result.body.payload.userData.token = result.headers.get('token');
          result.body.payload.userData["refreshToken"] = result.headers.get('tokenrefresh');
          this.authSrv.setAuthObject(result.body.payload);
          setTimeout(() => {
            //signalr
            this.signalRSrv.manageSignalrConnection();
            this.messageListSrv.getCountMessages();
            let redirectUrl = result.body.payload.currentMenu.figli[0].url ? result.body.payload.currentMenu.figli[0].url : result.body.payload.currentMenu.figli[0].figli[0].url;
            this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
          }, 500)
        } else {
          this.alertSrv.errorAlert(this.translate.instant('errors.' + result.body.code), () => {
            if (tokenPin) {
              if (token.startsWith('TPN-')) {
                this.openPinPopover(PinPopoverTypes.NUMERIC, "messages.insertPin", null, token);
              }
              if (token.startsWith('TPA-')) {
                this.openPinPopover(PinPopoverTypes.ALPHANUMERIC, "messages.insertPin", null, token);
              }
            }
          })
        }
      }).catch((err) => {
      })
    }, 1000)
  }

  //unflatJson
  private unflatJson(json) {
    if (Object(json) !== json || Array.isArray(json)) return json;
    var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
      resultholder = {};
    for (var p in json) {
      var cur = resultholder,
        prop = "",
        m;
      while (m = regex.exec(p)) {
        cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
        prop = m[2] || m[1];
      }
      cur[prop] = json[p];
    }
    return resultholder[""] || resultholder;
  }

  //open pin popover
  async openPinPopover(pinType: string, pinMessage: string, pinMaxLength: number, token: string) {
    const popover = await this.popoverCtrl.create({
      component: PinPopoverComponent,
      cssClass: pinType == PinPopoverTypes.NUMERIC ? 'pin-popover-numeric' : 'pin-popover-alphanumeric',
      componentProps: { pinType: pinType, pinMessage: pinMessage, pinMaxLength: pinMaxLength },
      backdropDismiss: false
    });
    popover.onDidDismiss().then((res) => {
      if (res && res.data && res.data.pin) {
        this.silentlogin(token, "CODICE_INGAGGIO", res.data.pin);
      }
    })
    return await popover.present();
  }


  private _saveDevelopServicesOverrideQueryParams() {
    if (this.httpUtilitySrv.getEnvironment() == "test") {
      let developmentServiceOverrideListQueryParam = new URLSearchParams(window.location.search).get("developmentServiceOverrideList");
      if (developmentServiceOverrideListQueryParam && developmentServiceOverrideListQueryParam != '') {
        window.sessionStorage.setItem("developmentServiceOverrideListQueryParam", developmentServiceOverrideListQueryParam);
      }
    }
  }

}
