<div class="postit-board-board" *ngIf="mainLayout" [style.height]="mainLayout.boardHeight + 'px'"
    [style.min-height]="mainLayout.boardHeight + 'px'" [style.max-height]="mainLayout.boardHeight + 'px'">
    <div class="postit-board-board-inner">
        <div class="board" [style.height]="(mainLayout.boardHeight - 8) + 'px'"
            [style.min-height]="(mainLayout.boardHeight - 8) + 'px'"
            [style.max-height]="(mainLayout.boardHeight - 8) + 'px'">
            <div class="board-inner" id="board-inner" *ngIf="mainLayout.board && mainLayout.board.risorse"
                [ngClass]="{'w100': mainLayout.resourceMode.resource}">
                <!-- standard mode -->
                <div class="board-inner-standard-mode" *ngIf="!mainLayout.resourceMode.resource">
                    <div class="board-header">
                        <div class="board-header-item" *ngFor="let item of mainLayout.daysHeader">
                            <span>{{item}}</span>
                        </div>
                    </div>
                    <div class="board-row" *ngFor="let row of mainLayout.board.risorse">
                        <div class="board-row-name" (click)="emitEnterResourceMode(row)">
                            {{row.nome}}
                        </div>
                        <div class="board-row-day" *ngFor="let day of row.compactDays">
                            <div class="board-row-day-section" *ngFor="let section of day">
                                <div class="board-row-day-section-tag"
                                    [ngClass]="{'cursor-pointer': section.attivita?.codice && section.attivita?.idGruppo}"
                                    (click)="sectionClick(section)"
                                    [style.background]="section.color ? section.color : '#fff'">
                                    {{section.attivita?.codice}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- resource mode -->
                <div class="board-inner-resource-mode" *ngIf="mainLayout.resourceMode.resource">
                    <div class="resource-name">
                        {{mainLayout.resourceMode.resource.nome}}
                    </div>
                    <div class="resource-days-list">
                        <div class="resource-day"
                            *ngFor="let day of mainLayout.resourceMode.resource.compactDaysResourceMode">
                            <div class="resource-day-date">
                                {{day.date}}
                            </div>
                            <div class="resource-day-section" *ngFor="let section of day.sections">
                                <div class="resource-day-section-tag"
                                    [ngClass]="{'cursor-pointer': section.attivita?.codice && section.attivita?.idGruppo}"
                                    (click)="sectionClick(section)"
                                    [style.background]="section.color ? section.color : '#fff'">
                                    {{section.attivita?.codice}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div draggable="true" class="board-resizer" (dragstart)="emitDragStart('boardResizer', $event)"
            (dragend)="emitDragEnd($event)"></div>
    </div>
</div>