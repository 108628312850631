import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from "@angular/core";
import { OrderBy } from './order-by.pipe';

@NgModule({
    declarations: [
        OrderBy
    ],
    imports: [CommonModule],
    exports: [OrderBy],
    providers: [DatePipe]
})
export class OrderByPipeModule { }