import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TableComponent } from '../table/table.component';
import { tableIds } from '../table/models/table-ids';
import { columnTypes, tableColumn, tableSearchEvent, tableExtractPageSize } from '../table/models/table-models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { crpFE, query, updateColumn, FEReportType } from './models/custom-reports-models';
import { TablePopupInputComponent } from '../table/table-popup-input/table-popup-input.component';
import { CustomReportsService } from 'src/app/services/custom-reports.service';
import { SearchStateObject, StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { AppService } from 'src/app/services/app.service';
import { SearchStateHistoryPrompt } from '../search-state-history-prompt/search-state-history-prompt.component';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss'],
})
export class CustomReportsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  //tables
  @ViewChildren(TableComponent) tables: QueryList<TableComponent>;
  public tableId = tableIds.CUSTOM_REPORTS_TABLE;
  //crp list
  public crp: any;
  public crpFE: crpFE;
  //router sub
  public routerSub;
  //execute polling
  public executePolling: boolean;
  public pollingFinished: boolean = true;
  //columnNoParamsFE
  readonly columnNoParamsFE: string = "columnNoParamsFE";
  //url params
  private urlParams;
  //disableExtract
  public disableExtract: boolean = true;
  //showBack
  public showBack: boolean = false;
  //FEReportType
  public FEReportType = FEReportType;
  //chartOptions
  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true
    //legend: { position: 'left' }
  }
  // selectable combo
  @ViewChild('selectableField') selectableField: IonicSelectableComponent;


  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public activatedRoute: ActivatedRoute,
    private loadingSrv: LoaderService,
    private formBuilder: FormBuilder,
    private appSrv: AppService,
    private customReportsSrv: CustomReportsService,
    private storageSrv: StorageVariablesService,
    private httpUtilitySrv: HttpUtilityService) {
  }

  ngOnDestroy(): void {
    if (this.routerSub)
      this.routerSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url && (event.url.indexOf("/custom-report-detail") != -1 || event.url.indexOf("/report") != -1)) {
          const queryString = window.location.search;
          this.urlParams = new URLSearchParams(queryString);
          if (event.url.indexOf("/custom-report-detail") != -1) {
            this.showBack = true;
          } else {
            this.showBack = false;
          }
          if (this.urlParams.get("crpId")) {
            this.getReportById(this.urlParams.get("crpId"))
          }
          //chartOptions for mobile
          if (this.appSrv.isMobile())
            this.chartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              //legend: { position: 'top' },
            }
        }
      }
    })

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void { }

  //getReportById
  private getReportById(crpId) {
    this.loadingSrv.open();
    this.customReportsSrv.getReportById(crpId).then((res: any) => {
      if (res && res.report) {
        this.buildCrpFE(res);
      }
      this.loadingSrv.dismiss();
    }).catch((err) => {
      this.loadingSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //buildCrpFE
  private buildCrpFE(res) {
    this.storageSrv.getSearchState(this.tableId + "-" + this.urlParams.get("crpId")).then((searchStateObject: SearchStateObject) => {
      let formGroupTmp = {};
      //reset variables
      this.crpFE = { 
        crpTitle: this.urlParams.get("crpTitle") ? this.urlParams.get("crpTitle") : res.report.crpTitle,
        formGroup: null,
        formGroupConfig: res.formGroupConfig,
        crpId: res.report.crpId,
        crpRequestToken: res.report.crpRequestToken,
        crpSubTitle: this.urlParams.get("crpSubTitle") ? this.urlParams.get("crpSubTitle") : res.report.crpSubTitle,
        tipo: res.report.tipo,
        queries: [],
        loading: false,
        crpIcon: this.urlParams.get("crpIcon") ? "assets/icon/" + this.urlParams.get("crpIcon") : res.report.crpIcon ? "assets/icon/" + res.report.crpIcon : "assets/icon/reports.png",
        descriptions: res.report.descriptions
      };
      //build form 
      if (this.crpFE.formGroupConfig && this.crpFE.formGroupConfig.length > 0) {
        let valueTmp, stateSearchitemTmp;
        this.crpFE.formGroupConfig.forEach((formField) => { 
          //format value for frontend
          switch (formField.type) {
            case 'DATA':
              valueTmp = this.customReportsSrv.formatDateForFe(formField.value);
              break;
            case 'DATAORA':
              valueTmp = this.customReportsSrv.formatDateTimeForFe(formField.value);
              break;
            case 'COMBO':
            if((formField.options == null || formField.options.length == 0) && formField.endpointpreloaded) {
                valueTmp = formField.value;
                this.customReportsSrv.getListByEndpoint(formField.endpointurl).then((res: any) => {
                  formField.options = res.result.map((item: any) => {
                     return {
                      id: String(item.value) ?? this.getFirstTwoProperties(item)[0],
                      desc: item.text ?? this.getFirstTwoProperties(item)[1]
                     }
                   });
                 }).catch((err) => {
                   if (err && err.status && err.status != 401 && err.status != 403) {
                     this.httpUtilitySrv.getRequestError(err).then((err: any) => {
                       this.alertSrv.errorAlert(err);
                     })
                   }
                 })
               }
              else if((formField.options == null || formField.options.length == 0) && !formField.endpointpreloaded) {
                let splitValue = formField.value 
                ? JSON.parse(formField.value?.replace(/\\/g, '')).text ?? this.getFirstTwoProperties(JSON.parse(formField.value?.replace(/\\/g, '')))[1]
                : formField.value;
                valueTmp = formField.value ? JSON.parse(formField.value) : formField.value;
                this.customReportsSrv.getListByEndpoint(formField.endpointurl+splitValue).then((res: any) => {
                  formField.options = res.result;
                 }).catch((err) => {
                   if (err && err.status && err.status != 401 && err.status != 403) {
                     this.httpUtilitySrv.getRequestError(err).then((err: any) => {
                       this.alertSrv.errorAlert(err);
                     })
                   }
                 })
               }
               else {valueTmp = formField.value;}
              break;
            default:
              valueTmp = formField.value;  
              break;
          }
          //searchState
          if (searchStateObject && searchStateObject.filters && searchStateObject.filters.length > 0 && !formField.disabled && !formField.readOnly && !formField.hidden) {
            stateSearchitemTmp = searchStateObject.filters.filter((x) => { return x.name == formField.property })[0];
            if (stateSearchitemTmp)
              valueTmp = stateSearchitemTmp.value;
          }
          formGroupTmp[formField.property] = [valueTmp, formField.required && !formField.hidden && !formField.readOnly ? Validators.required : null];
        });
        this.crpFE.formGroup = this.formBuilder.group(formGroupTmp); 
      }
      //execute for no form
      if (this.urlParams.get("autoload")) {
        this.execute({ isExtract: false, keyUp: null });
      }
      //if (!this.crpFE.formGroupConfig)
      //  
    })
  }

  //execute 
  public execute(event: tableSearchEvent) {
    if (this.crpFE && ((!this.crpFE.formGroup || this.crpFE.formGroup && this.crpFE.formGroup.valid)) && (!event.keyUp || (event.keyUp && event.keyUp.keyCode === 13))) {
      this.loadingSrv.open();
      //build search obj
      let payload = {
        crpId: this.crpFE.crpId,
        crpRequestToken: this.crpFE.crpRequestToken,
        formGroup: []
      }, valueTmp;
      if (this.crpFE.formGroupConfig) {
        let searchStateObject: SearchStateObject = {
          filters: [],
          sortAndPaginator: {
            sortActive: null,
            sortDirection: null,
            pageIndex: null,
            pageSize: null
          }
        };
        this.crpFE.formGroupConfig.forEach((formField) => {
          //format value for backend
          if (this.crpFE.formGroup.get(formField.property).value == null || this.crpFE.formGroup.get(formField.property).value == undefined || this.crpFE.formGroup.get(formField.property).value == "")
            valueTmp = null;
          else
            switch (formField.type) {
              case 'DATA':
                valueTmp = this.customReportsSrv.formatDateForBe(this.crpFE.formGroup.get(formField.property).value);
                break;
              case 'DATAORA':
                valueTmp = this.customReportsSrv.formatDateTimeForBe(this.crpFE.formGroup.get(formField.property).value);
                break;
              default:
                valueTmp = this.crpFE.formGroup.get(formField.property).value?.value ?? this.crpFE.formGroup.get(formField.property).value ; 
            }
          payload.formGroup.push({
            "Name": formField.property,
            "Value": valueTmp
          });
          searchStateObject.filters.push({
            name: formField.property, value: formField.type == 'DATA' ? this.customReportsSrv.formatDateForFe(JSON.parse(JSON.stringify(valueTmp))) :
              formField.type == 'DATAORA' ? this.customReportsSrv.formatDateTimeForFe(JSON.parse(JSON.stringify(valueTmp))) : this.crpFE.formGroup.get(formField.property).value
          });
        })
        this.storageSrv.setSearchState(this.tableId + "-" + this.urlParams.get("crpId"), searchStateObject);
      }
      this.customReportsSrv.execute(payload).then((res: any) => {
        if (res) {
          this.executePolling = true;
          this.pollingGetExecutionStatus(true);
        }
        this.loadingSrv.dismiss();
      }).catch((err) => {
        this.loadingSrv.dismiss();
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    }
  }

  private pollingGetExecutionStatus(setUp: boolean, refreshIdxQry?: string) {
    if (this.executePolling) {
      this.pollingFinished = false;
      if (setUp)
        this.crpFE.loading = true;
      this.customReportsSrv.getExecutionStatus(this.crpFE.crpId, this.crpFE.crpRequestToken).then((res: any) => {
        if (this.executePolling) {
          //first call setup
          if (setUp) {
            this.crpFE.loading = false;
            if (res && Array.isArray(res) && res.length > 0) {
              this.crpFE.queries = [];
              for (let i = 0; i < res.length; i++) {
                this.crpFE.queries.push({
                  result: null,
                  tableId: this.tableId + "-" + i + "-" + this.crpFE.crpId,
                  tableConfig: null,
                  extractEndpoint: null,
                  filter: null,
                  errorsOccured: res[i].errorsOccured,
                  idxQry: res[i].idxQry,
                  threadFinished: res[i].threadFinished,
                  qryTitle: res[i].qryTitle,
                  pollingStopped: false,
                  idxFlag: null,
                  updateButton: false,
                  expanded: res.length == 1 ? true : false
                });
                if (this.crpFE.queries[i].threadFinished && !this.crpFE.queries[i].errorsOccured)
                  this.getSingleResult(this.crpFE.queries[i]);
              }
            }
          }
          //update queries 
          else {
            if (res && Array.isArray(res) && res.length > 0) {
              let tmpQuery: query, tmpTable: TableComponent;
              for (let i = 0; i < res.length; i++) {
                tmpQuery = this.crpFE.queries.filter((query) => {
                  return query.idxQry == res[i].idxQry;
                })[0];
                tmpTable = this.getTableByTableId(tmpQuery.tableId);
                //set refreshIdxQry query threadFinished false
                if (refreshIdxQry != null && refreshIdxQry != undefined && refreshIdxQry != "" && tmpQuery.idxQry == refreshIdxQry)
                  tmpQuery.threadFinished = false;
                //reset master checkbox
                if (tmpTable)
                  tmpTable.masterCheckbox = false;
                //update query
                if (tmpQuery && !tmpQuery.threadFinished && res[i].threadFinished) {
                  tmpQuery.errorsOccured = res[i].errorsOccured;
                  tmpQuery.threadFinished = res[i].threadFinished;
                  if (!tmpQuery.errorsOccured)
                    this.getSingleResult(tmpQuery);
                }
              }
            }
          }
          //check all treadfinished
          if (this.crpFE.queries && this.crpFE.queries.filter((query) => { return !query.threadFinished }).length > 0) {
            setTimeout(() => {
              this.pollingGetExecutionStatus(false);
            }, 3000)
          } else {
            this.pollingFinished = true;
          }
        } else {
          this.crpFE.loading = false;
        }
      }).catch((err) => {
        if (setUp)
          this.crpFE.loading = false;
        this.loadingSrv.dismiss();
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
            this.stopPolling();
          })
        }
      })
    } else {
      this.crpFE.loading = false;
    }
  }

  //stopPolling
  public stopPolling() {
    this.executePolling = false;
    this.pollingFinished = true;
    if (this.crpFE.queries && this.crpFE.queries.length > 0) {
      this.crpFE.queries.forEach((query) => {
        if (!query.threadFinished)
          query.pollingStopped = true;
      });
    }
    this.customReportsSrv.stop(this.crpFE.crpId, this.crpFE.crpRequestToken).then(() => { }).catch((err) => {
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //getSingleResult
  private getSingleResult(query: query) {
    if (this.executePolling) {
      this.customReportsSrv.getSingleResult(this.crpFE.crpId, this.crpFE.crpRequestToken, query.idxQry).then((res: {}) => {
        if (this.executePolling) {
          this.setSingleResult(query, res);
          //check extract
          if (this.crpFE.queries && this.crpFE.queries.filter((query) => { return !query.result }).length > 0) {
            this.disableExtract = true;
          } else {
            this.disableExtract = false;
          }
        } else {
          this.crpFE.loading = false;
        }
      }).catch((err) => {
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    } else {
      this.crpFE.loading = false;
    }
  }

  //setSungleResult
  private setSingleResult(query: query, res) {
    if (res && this.executePolling) {
      //table if properies lbl, val, clr?, ct doesn't exists (label, value, color, chart type)
      if ((!res || !res.list || res.list.length == 0) || (res && res.list && res.list.length > 0 && !res.list[0].lbl && !res.list[0].val && !res.list[0].ct)) {
        query["FEReportType"] = this.FEReportType.TABLE;
        let currentTable = this.getTableByTableId(query.tableId);
        if (currentTable) {
          //check for updateColumns with no parameters (if columnDef in updateColumns is empty string)
          if (res["tableConfig"] && res["tableConfig"]["updateColumns"] && res["tableConfig"]["updateColumns"].length > 0) {
            let columnNoParamsFETmp = res["tableConfig"]["updateColumns"].filter((column) => {
              return column.columnDef == "" || column.columnDef == null || column.columnDef == undefined;
            })[0];
            if (columnNoParamsFETmp) {
              res["tableConfig"]["updateColumns"] = [{ columnDef: this.columnNoParamsFE, idxFlag: columnNoParamsFETmp.idxFlag, updateMessage: columnNoParamsFETmp.updateMessage }];
              if (res["tableConfig"]["tableColumns"] && !res["tableConfig"]["tableColumns"].filter((column) => { return column.columnType == columnTypes.SELECT })[0]) {
                res["tableConfig"]["tableColumns"].push({
                  columnDef: "update",
                  columnType: columnTypes.DETAIL,
                  columnParam: "assets/icon/update.png",
                  columnIndex: 0,
                  columnWidth: 50,
                  columnHidden: false,
                  columnSortable: false,
                  columnHeader: null,
                  columnDynamicDetailList: null,
                  columnMessage: columnNoParamsFETmp.updateMessage,
                  columnCallback:
                    (callBackRes) => {
                      this.openUpdateSingleRowPopover(callBackRes.tableId, callBackRes)
                    },
                })
              }
            }
          }
          //check updateColumnTarget
          if (res["tableConfig"] && res["tableConfig"]["tableColumns"] && res["tableConfig"]["columnUpdateTarget"]) {
            for (let column of res["tableConfig"]["tableColumns"]) {
              if (column.columnDef.toLowerCase() == res["tableConfig"]["columnUpdateTarget"].toLowerCase()) {
                res["tableConfig"]["columnUpdateTarget"] = column.columnDef;
                break;
              }
            }
          }
          if (res["tableConfig"] && res["tableConfig"]["tableColumns"]) {
            let columnsWithCallback = [columnTypes.INPUT_POPUP_STRINGA.toString(), columnTypes.INPUT_STRINGA.toString(), columnTypes.INPUT_NUMERO.toString(), columnTypes.INPUT_POPUP_NUMERO.toString(), columnTypes.INPUT_DATA.toString(),
            columnTypes.INPUT_POPUP_DATA.toString(), columnTypes.INPUT_DATAORA.toString(), columnTypes.INPUT_POPUP_DATAORA.toString(), columnTypes.INPUT_BOOLEAN.toString(), columnTypes.INPUT_POPUP_BOOLEAN.toString(), columnTypes.INPUT_COMBO.toString(),
            columnTypes.INPUT_POPUP_COMBO.toString(), columnTypes.INPUT_MULTICOMBO.toString(), columnTypes.INPUT_POPUP_MULTICOMBO.toString(), columnTypes.INPUT_RADIO.toString(), columnTypes.INPUT_POPUP_RADIO.toString()],
              queryTmp: query;
            res["tableConfig"]["tableColumns"].forEach((column: tableColumn) => {
              //column callback
              if (columnsWithCallback.includes(column.columnType))
                column.columnCallback = (callBackRes) => {
                  queryTmp = this.getQueryByTableId(callBackRes.tableId)
                  if (callBackRes && callBackRes.element && queryTmp.tableConfig.columnUpdateTarget) {
                    this.update(queryTmp, callBackRes, null);
                  }
                }
              //columnHeaderCallback
              if (column.columnHeaderComparisonOptions)
                column.columnHeaderCallback = (callBackRes) => {
                  if (callBackRes.tableId && callBackRes.comparison && callBackRes.columns && callBackRes.element)
                    this.applyFilter(callBackRes.tableId, callBackRes.comparison, callBackRes.columns, callBackRes.element);
                }
              //column checkbox (for selection)
              if (column.columnType == columnTypes.SELECT) {
                query.updateButton = true;
              }
            });
            query.tableConfig = res["tableConfig"];
            //format result values
            if (res.list && res.list.length > 0 && query.tableConfig && query.tableConfig.tableColumns) {
              //get all date and datetime columndef
              let dateColumnDefList: Array<string> = [];
              let dateTimeColumnDefList: Array<string> = [];
              for (let column of query.tableConfig.tableColumns) {
                if ([columnTypes.DATE.toString(), columnTypes.INPUT_DATA.toString(), columnTypes.INPUT_POPUP_DATA.toString()].includes(column.columnType))
                  dateColumnDefList.push(column.columnDef);
                if ([columnTypes.DATE_TIME.toString(), columnTypes.INPUT_DATAORA.toString(), columnTypes.INPUT_POPUP_DATAORA.toString()].includes(column.columnType))
                  dateTimeColumnDefList.push(column.columnDef);
              }
              if (dateColumnDefList.length > 0 || dateTimeColumnDefList.length > 0) {
                for (let row of res.list) {
                  for (let property in row) {
                    //format date
                    if (dateColumnDefList.includes(property) && row[property])
                      row[property] = this.customReportsSrv.formatDateForFe(row[property]);
                    //format datetime
                    if (dateTimeColumnDefList.includes(property) && row[property])
                      row[property] = this.customReportsSrv.formatDateTimeForFe(row[property]);
                  }
                }
              }
            }
            query.result = new MatTableDataSource(res["list"]);
            currentTable.tablePaginator.length = query.result.filteredData.length;
            query.result.sort = currentTable.tableSort;
            query.result.paginator = currentTable.tablePaginator;
            this.storageSrv.getCustomReportPaginationSize(query.tableId).then((pageSize: number) => {
              if (pageSize)
                query.result.paginator.pageSize = pageSize;
            })
            this.setFilterPredicate(query);
          }
        }
      } else if (res && res.list && res.list.length > 0 && res.list[0].lbl && res.list[0].val && res.list[0].ct) {
        switch (res.list[0].ct) {
          case this.FEReportType.CHART_BAR:
            query["FEReportType"] = 'bar';
            break;
          case this.FEReportType.CHART_DOUGHNUT:
            query["FEReportType"] = 'doughnut';
            break;
          case this.FEReportType.CHART_PIE:
            query["FEReportType"] = 'pie';
            break;
          case this.FEReportType.CHART_LINE:
            query["FEReportType"] = 'line';
            break;
          case this.FEReportType.CHART_POLARAREA:
            query["FEReportType"] = 'polarArea';
            break;
        }
        this.formatResultChartBar(query, res);
      }
    } else {
      this.crpFE.loading = false;
    }
  }

  //formatResultChartBar
  private formatResultChartBar(query, res) {
    let labels: Array<string> = [];
    let data: Array<number> = [];
    let backgroundColors: Array<string> = [];
    let borderColors: Array<string> = [];
    let filters: Array<any> = [];

    //rows
    res.list.forEach((row) => {
      //label
      labels.push(row[row.lbl]);
      //data
      data.push(row[row.val]);
      //color
      //randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
      backgroundColors.push(row.clr ? "rgba(" + row.clr + ", 0.4)" : "rgba(51, 51, 102, 0.4)");
      borderColors.push(query["FEReportType"] == (this.FEReportType.CHART_DOUGHNUT || this.FEReportType.CHART_PIE || this.FEReportType.CHART_POLARAREA) ? 'rgb(255,255,255)' :
        row.clr ? "rgba(" + row.clr + ", 0.8)" : "rgba(51, 51, 102, 0.8)");
      //filters
      if (filters.length == 0 && res.tableConfig.tableColumns && res.tableConfig.tableColumns.length > 0) {
        filters.push(res.tableConfig.tableColumns.filter((column) => { return column.columnDef == row.lbl })[0]);
        filters.push(res.tableConfig.tableColumns.filter((column) => { return column.columnDef == row.val })[0]);
      }

    });
    let resultChart: ResultChart = {
      originalResult: res.list,
      filters: filters,
      type: query.FEReportType as ChartType,
      data: {
        datasets: [{
          data: data,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
          label: "test"
        }],
        labels: labels,
      }
    }
    query["resultChart"] = resultChart;
  }

  //clean filters
  public cleanFilters() {
    if (this.crpFE && this.crpFE.formGroup) {
      this.crpFE.formGroupConfig.forEach((formField) => {
        this.crpFE.formGroup.get(formField.property).setValue(null);
      });
    }
  }

  //extractApi
  public extractApi(event: tableSearchEvent) {
    let currentTable = this.getTableByTableId(event.tableId);
    let currentQuery = this.getQueryByTableId(event.tableId);
    if (currentTable && currentQuery) {
      let extractDataSource = new MatTableDataSource(currentTable.tableDataSource.filteredData);
      extractDataSource.sortData(extractDataSource.filteredData, currentTable.tableDataSource.sort);
      currentTable.extractTable(extractDataSource.filteredData);
    }
  }

  //getTableByTableId
  public getTableByTableId(tableId) {
    return this.tables ? this.tables.filter((table) => {
      return table.tableId == tableId;
    })[0] : null;
  }

  //getQueryByTableId
  public getQueryByTableId(tableId) {
    return this.crpFE && this.crpFE.queries ? this.crpFE.queries.filter((query) => {
      return query.tableId == tableId;
    })[0] : null;
  }


  //setFilterPredicate
  private setFilterPredicate(query: query) {
    if (query) {
      //format data
      query.result.filterPredicate = (row, filter) => {
        if (query.filter) {
          let value1 = row[query.filter.column.columnType] == (columnTypes.DATE || columnTypes.DATE_TIME || columnTypes.INPUT_DATA || columnTypes.INPUT_POPUP_DATA
            || columnTypes.INPUT_DATAORA || columnTypes.INPUT_POPUP_DATAORA) ? new Date(row[query.filter.column.columnDef]).getTime() : row[query.filter.column.columnDef],
            value2 = row[query.filter.column.columnType] == (columnTypes.DATE || columnTypes.DATE_TIME || columnTypes.INPUT_DATA || columnTypes.INPUT_POPUP_DATA
              || columnTypes.INPUT_DATAORA || columnTypes.INPUT_POPUP_DATAORA) ? new Date(query.filter.element[query.filter.column.columnDef]).getTime() : query.filter.element[query.filter.column.columnDef];
          switch (query.filter.comparison) {
            case '>':
              return value1 > value2;
            case '>=':
              return value1 >= value2;
            case '<':
              return value1 < value2;
            case '<=':
              return value1 <= value2;
            case '=':
              return value1 == value2;
            case '!=':
              return value1 != value2;
            case '%':
              return value1 && value2 ? value1.toString().toUpperCase().indexOf(value2.toString().toUpperCase()) != -1 : true;
            default:
              return true;
          }
        } else {
          return true;
        }
      }
    }
  }

  //filted data
  private applyFilter(tableId: string, comparison: string, columns: Array<tableColumn>, element: any) {
    let currentQuery = this.getQueryByTableId(tableId);
    if (currentQuery && tableId && comparison && columns[0] && element) {
      currentQuery.filter = {
        tableId: tableId,
        comparison: comparison,
        column: columns[0],
        element: element
      }
      //format dateTime value
      if (currentQuery.filter.element[currentQuery.filter.column.columnDef] && (currentQuery.filter.column.columnType == columnTypes.DATE_TIME || currentQuery.filter.column.columnType == columnTypes.INPUT_DATAORA || currentQuery.filter.column.columnType == columnTypes.INPUT_POPUP_DATAORA))
        currentQuery.filter.element[currentQuery.filter.column.columnDef] = this.formatMatDatePickerDate(currentQuery.filter.element[currentQuery.filter.column.columnDef]);
      currentQuery.result.filter = "filter" //string not used
    }
  }

  //delete filter
  public deleteFilter(tableId) {
    let currentQuery = this.getQueryByTableId(tableId);
    if (currentQuery) {
      currentQuery.filter = null;
      currentQuery.result.filter = "filter";
    }
  }

  //format matDatePickerDate
  public formatMatDatePickerDate(date) {
    let dt = date ? new Date(date) : null;
    return dt ? dt.getFullYear() + "-" + ((dt.getMonth() + 1 < 10) ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())
      + "T" + (dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()) + ":" + (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes())) : null;
  }

  //updateRowsPopover
  async openUpdateRowsPopover(tableId) {
    let currentQuery = this.getQueryByTableId(tableId);
    if (currentQuery && currentQuery.tableConfig.updateColumns && currentQuery.tableConfig.updateColumns.length > 0) {
      //build columns for popup
      let columnsTmp: Array<tableColumn> = [], columnTmp: tableColumn;
      currentQuery.tableConfig.updateColumns.forEach((updateColumn: updateColumn) => {
        //check for columnNoParamsFE
        if (updateColumn.columnDef == this.columnNoParamsFE) {
          let tmpColumn: tableColumn = {
            columnDef: this.columnNoParamsFE,
            columnCallback: null,
            columnType: columnTypes.INPUT_POPUP_STRINGA,
            columnParam: null,
            columnIndex: 0,
            columnWidth: 200,
            columnHidden: false,
            columnSortable: false,
            columnHeader: "",
            columnDynamicDetailList: null,
            columnMessage: currentQuery.tableConfig.updateColumns[0].updateMessage
          }
          columnsTmp.push(tmpColumn)
        } else {
          columnTmp = currentQuery.tableConfig.tableColumns.filter((column) => {
            return column.columnDef.toLowerCase() == updateColumn.columnDef.toLowerCase();
          })[0];
          if (columnTmp)
            columnsTmp.push(columnTmp);
        }
      });
      const modal = await this.popoverCtrl.create({
        component: TablePopupInputComponent,
        cssClass: 'table-popup-input',
        componentProps: {
          columns: columnsTmp,
          element: {},
          message: currentQuery.tableConfig.updateMessage
        },
        backdropDismiss: false
      });
      modal.onDidDismiss()
        .then((res: any) => {
          if (res && res.data && res.data.columns && res.data.element && currentQuery.tableConfig.updateColumns) {
            let currentTable = this.getTableByTableId(tableId);
            if (currentTable)
              this.update(currentQuery, res.data, currentTable.selectedList);
          }
        });
      return await modal.present();
    }
  }

  //openUpdateSingleRowPopover
  async openUpdateSingleRowPopover(tableId, row) {
    let currentQuery = this.getQueryByTableId(tableId);
    if (currentQuery && currentQuery.tableConfig.updateColumns && currentQuery.tableConfig.updateColumns.length > 0) {
      //build columns for popup
      let columnsTmp: Array<tableColumn> = [], columnTmp: tableColumn;
      currentQuery.tableConfig.updateColumns.forEach((updateColumn: updateColumn) => {
        //check for columnNoParamsFE
        if (updateColumn.columnDef == this.columnNoParamsFE) {
          let tmpColumn: tableColumn = {
            columnDef: this.columnNoParamsFE,
            columnCallback: null,
            columnType: columnTypes.INPUT_POPUP_STRINGA,
            columnParam: null,
            columnIndex: 0,
            columnWidth: 200,
            columnHidden: false,
            columnSortable: false,
            columnHeader: "",
            columnDynamicDetailList: null,
            columnMessage: currentQuery.tableConfig.updateColumns[0].updateMessage
          }
          columnsTmp.push(tmpColumn)
        } else {
          columnTmp = currentQuery.tableConfig.tableColumns.filter((column) => {
            return column.columnDef.toLowerCase() == updateColumn.columnDef.toLowerCase();
          })[0];
          if (columnTmp)
            columnsTmp.push(columnTmp);
        }
      });
      const modal = await this.popoverCtrl.create({
        component: TablePopupInputComponent,
        cssClass: 'table-popup-input',
        componentProps: {
          columns: columnsTmp,
          element: JSON.parse(JSON.stringify(row)),
          message: currentQuery.tableConfig.updateMessage
        },
        backdropDismiss: false
      });
      modal.onDidDismiss()
        .then((res: any) => {
          if (res && res.data && res.data.element && res.data.columns && currentQuery.tableConfig.updateColumns && currentQuery.tableConfig.columnUpdateTarget) {
            this.update(currentQuery, res.data, null);
          }
        });
      return await modal.present();
    }
  }

  //update
  public update(query: query, callBackRes, selectedList: Array<any>) {
    this.loadingSrv.open();
    let updateColumnDef = callBackRes.columns[0]?.columnDef;
    let updateColumnType = callBackRes.columns[0]?.columnType;
    let value = callBackRes.element && callBackRes.element[this.columnNoParamsFE] ? callBackRes.element[this.columnNoParamsFE] : callBackRes.element[updateColumnDef];
    //format value for backend
    if (value == null || value == undefined || value == "")
      value = null;
    else {
      if (updateColumnType && [columnTypes.DATE, columnTypes.INPUT_DATA, columnTypes.INPUT_POPUP_DATA].includes(updateColumnType)) {
        value = this.customReportsSrv.formatDateForBe(value);
      }
      else if (updateColumnType && [columnTypes.DATE_TIME, columnTypes.INPUT_DATAORA, columnTypes.INPUT_POPUP_DATAORA].includes(updateColumnType)) {
        value = this.customReportsSrv.formatDateTimeForBe(value);
      }
    }
    let idxFlag = query.tableConfig.updateColumns.filter((updateColumn: updateColumn) => { return updateColumn.columnDef.toLowerCase() == updateColumnDef.toLowerCase() })[0]?.idxFlag;
    this.customReportsSrv.update(!selectedList ? false : true, this.crpFE.crpId, this.crpFE.crpRequestToken, value, query.idxQry, idxFlag, !selectedList ? callBackRes.element[query.tableConfig.columnUpdateTarget] : selectedList, updateColumnDef, updateColumnType).then((res: any) => {
      if (res && res.isRefresh) {
        //polling
        if (res.goPolling) {
          this.pollingGetExecutionStatus(false, query.idxQry);
        }
        //resultQry
        else if (res.resultQry && Array.isArray(res.resultQry) && res.resultQry.length > 0) {
          let updateRow = query.result.data.filter((row) => { return row.Id == callBackRes.element.Id })[0];
          res.resultQry.forEach((updateColumn) => {
            for (let property in updateRow) {
              if (property.toLowerCase() == updateColumn.columnName.toLowerCase())
                updateRow[property] = updateColumn.value;
            }
          });
        }
      }
      this.loadingSrv.dismiss();
    }).catch((err) => {
      this.loadingSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //extract
  public extract(exportType) {
    this.loadingSrv.open();
    this.customReportsSrv.export(this.crpFE.crpId, this.crpFE.crpRequestToken, exportType).then((res: any) => {
      if (res && res.documento && res.nomeFile && res.estensione) {
        let docObj = {
          type: res.estensione == ".pdf" ? "data:application/pdf;base64," : res.estensione == '.zip' ? "data:application/zip;base64," : "data:image/png;base64,",
          name: res.nomeFile + res.estensione
        }
        const linkSource = `${docObj.type}${res.documento}`;
        const downloadLink = document.createElement("a");
        const fileName = docObj.name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      this.loadingSrv.dismiss();
    }).catch((err) => {
      this.loadingSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //back
  public back() {
    this.router.navigateByUrl("/custom-report-search", { replaceUrl: true });
  }

  //tablePaginatorChange        
  public tablePaginatorChange(event) {
    this.storageSrv.setCustomReportPaginationSize(event.tableId, event.pageSize);
  }

  //--- CHART FILTER ---//
  //comparison popover
  public openChartComparisonPopover(query, filter: tableColumn, filterId) {
    let domFilter = document.getElementById(filterId);
    let ev;
    if (domFilter) {
      ev = {
        target: domFilter
      };
    }
    this.chartComparisonPopover(query, filter, ev);
  }
  //openComparisonPopover
  async chartComparisonPopover(query, filter: tableColumn, event) {
    const modal = await this.popoverCtrl.create({
      component: TablePopupInputComponent,
      cssClass: 'table-popup-input-popover',
      event: event,
      componentProps: {
        columns: [JSON.parse(JSON.stringify(filter))],
        element: {},
        fromHeader: true,
        showComparison: true
      },
      backdropDismiss: false, //for date time picker
      showBackdrop: true
    });
    modal.onDidDismiss()
      .then((res: any) => {
        if (res && res.data && res.data.columns && res.data.element && res.data.comparison) {
          if (res.data.comparison && res.data.element && res.data.columns) {
            let filteredData = query.resultChart.originalResult.filter((item) => {
              return this.dynamicComparison(res.data.comparison, item[res.data.columns[0].columnDef], res.data.element[res.data.columns[0].columnDef]);
            })
            this.setFilteredChartData(query, filteredData, res.data.columns[0].columnHeader + " " + res.data.comparison + " " + res.data.element[res.data.columns[0].columnDef])
          }
        }
      });
    return await modal.present();
  }
  //dynamic comparison
  private dynamicComparison(comparison, value1, value2) {
    switch (comparison) {
      case '>':
        return value1 > value2;
      case '>=':
        return value1 >= value2;
      case '<':
        return value1 < value2;
      case '<=':
        return value1 <= value2;
      case '=':
        return value1 == value2;
      case '!=':
        return value1 != value2;
      case '%':
        return value1 && value2 ? value1.toString().indexOf(value2.toString()) != -1 : true;
      default:
        return true;
    }
  }
  //setFilteredChartData
  private setFilteredChartData(query, filteredData, activeFilter: string) {
    let groupedData: ChartDataset[] = [{ data: [], label: filteredData[0]?.val }];
    let groupedLables = [];
    let groupedColors: Array<string> = [];
    let groupedBorderColors: Array<string> = [];
    //labels
    if (filteredData && filteredData.length > 0) {
      filteredData.forEach((row) => {
        //label
        groupedLables.push(row[row.lbl]);
        //data
        groupedData[0].data.push(row[row.val]);
        //color
        //randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
        groupedColors.push(row.clr ? "rgba(" + row.clr + ", 0.4)" : "rgba(51, 51, 102, 0.4)");
        groupedBorderColors.push((query["FEReportType"] == this.FEReportType.CHART_DOUGHNUT || this.FEReportType.CHART_PIE || this.FEReportType.CHART_POLARAREA) ? 'rgb(255,255,255)' :
          row.clr ? "rgba(" + row.clr + ", 0.8)" : "rgba(51, 51, 102, 0.8)");
      });
    }
    //line chart
    let lineChartColors = [
      {
        borderColor: groupedBorderColors,
        backgroundColor: groupedColors,
        borderWidth: 1
      }
    ];
    query.resultChart.groupedData = groupedData;
    query.resultChart.groupedLables = groupedLables;
    query.resultChart.lineChartColors = lineChartColors;
    query.resultChart["activeFilter"] = activeFilter;
  }

  //--- END CHART FILTER ---//

  //openSearchStateHistory
  async openSearchStateHistory() {
    let searchStateObject: SearchStateObject = {
      filters: [],
      sortAndPaginator: {
        sortActive: null,
        sortDirection: null,
        pageIndex: null,
        pageSize: null
      }
    };
    this.crpFE.formGroupConfig.forEach((formField) => {
      searchStateObject.filters.push({ name: formField.property, value: this.crpFE.formGroup.get(formField.property).value });
    })
    const modal = await this.modalCtrl.create({
      component: SearchStateHistoryPrompt,
      cssClass: 'search-state-history-prompt',
      showBackdrop: true,
      componentProps: {
        searchStateId: this.tableId + "-" + this.urlParams.get("crpId"),
        searchStateObject: searchStateObject
      }
    });
    modal.onDidDismiss().then((res) => {
      if (res && res.data && res.data.useSearchStateObject) {
        let newSearchStateObject = res.data.useSearchStateObject.searchStateObject;
        //filters
        if (newSearchStateObject && newSearchStateObject.filters) {
          let stateSearchitemTmp;
          this.crpFE.formGroupConfig.forEach((formField) => {
            if (!formField.disabled && !formField.readOnly && !formField.hidden) {
              stateSearchitemTmp = newSearchStateObject.filters.filter((x) => { return x.name == formField.property })[0];
              if (stateSearchitemTmp)
                this.crpFE.formGroup.get(formField.property).setValue(stateSearchitemTmp.value);
            }
          });
        }
      }
    })
    modal.present();
  }

  //COMBO Selectable methods
 public getListByEndpoint (event, endPointUrl) {
  if(event.text != null && event.text != undefined && event.text != "")
  {
    endPointUrl = endPointUrl + event.text;
    event.component.startSearch();
    event.component.showLoading();
    this.customReportsSrv.getListByEndpoint(endPointUrl).then((res: any) => {
      event.component.items = res.result;
      event.component.hideLoading();
      event.component.endSearch();
    }).catch((err) => {
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
          event.component.hideLoading();
          event.component.endSearch();
        })
      }
    })
    }else {
      event.component.items = null;
    }
  }

  public isSelectableItemsEmpty(id) {
    let selectable;
    if (id == 'selectableField') {
      selectable = this.selectableField;
    }
    return selectable ? selectable.items : null;
  }

  
  private getFirstTwoProperties(response) {
    const firstTwoProperties = [];

    let count = 0;
    for (const key in response) {
        if (response.hasOwnProperty(key) && count < 2) {
            firstTwoProperties.push(response[key]);
            count++;
        }
    }

    return firstTwoProperties;
}
}


interface ResultChart {
  originalResult: Array<any>,
  filters: Array<any>,
  type: ChartType,
  data: ChartData
}