import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../app/services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { MainLayout } from '../../pages/postit-board-main/postit-board-main.page';
import { ChartDataset, ChartOptions } from 'chart.js';
import { MatTableDataSource } from '@angular/material/table';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';

@Component({
  selector: 'app-postit-board-stats',
  templateUrl: './postit-board-stats.component.html',
  styleUrls: ['./postit-board-stats.component.scss'],
})
export class PostitBoardStatsComponent implements OnInit {
  //mainLAyout
  @Input() mainLayout: MainLayout;
  //dragStart
  @Output() dragStart = new EventEmitter<any>();
  //dragEnd
  @Output() dragEnd = new EventEmitter<any>();
  //stats
  public statsCharts: Array<StatsChart> = [];
  public statsDisplayedColumns: Array<string> = [
    "resource",
    "type",
    "group",
    "description",
    "days",
    "status"
  ];
  public statsDataSource;
  //todayLAbel
  public todayLabel;

  constructor(
    public translate: TranslateService,
    public languageSrv: LanguageService,
    private router: Router,
    public alertCtrl: AlertController,
    public route: ActivatedRoute,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public postitBoardUtilitySrv: PostitBoardUtilityService) {
    this.todayLabel = new Date();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.mainLayout) {
      this.mainLayout = changes.mainLayout.currentValue;
      if (this.mainLayout.board && this.mainLayout.activitiesTypesList)
        this.extractStatsDatasource();
    }
  }

  //--- STATS ---//
  //extractStats
  private extractStatsDatasource() {
    if (this.mainLayout.board && this.mainLayout.board.risorse && this.mainLayout.board.risorse.length > 0) {
      //TODO: sostituisci la data hardcode con today
      let newDatasource: Array<any> = [], tmpCompactDay, today = this.postitBoardUtilitySrv.getDate(new Date()), tmpCharts: Array<any> = [];
      this.mainLayout.board.risorse.forEach((resource) => {
        if (!this.mainLayout.resourceMode.resource || (this.mainLayout.resourceMode.resource && this.mainLayout.resourceMode.resource.idx == resource.idx)) {
          newDatasource.push({ resource: resource.nome });
          tmpCharts.push({
            options: {
              responsive: true,
              maintainAspectRatio: false, title: {
                text: resource.nome,
                display: true,
                fontColor: "#333",
                lineHeight: 12,
                fontSize: 12
              }
            },
            labels: [],
            values: [{
              data: []
            }],
            colors: [{
              backgroundColor: []
            }],
            type: "doughnut"
          });
          //datasource
          if (resource.compactDays && resource.compactDays.length > 0) {
            //standard
            if (!this.mainLayout.resourceMode.resource) {
              tmpCompactDay = resource.compactDays.filter((x) => { return x[0] && x[0].data && x[0].data.indexOf(today) != -1 })[0];
              if (tmpCompactDay) {
                tmpCompactDay.forEach((section) => {
                  if (section.attivita && section.attivita.idAttivita) {
                    let count = this.getResourceDaysAndStatus(resource, section.attivita.idAttivita)
                    section["days"] = count.days;
                    section["status"] = count.status;
                  }
                })
                newDatasource[newDatasource.length - 1]["sections"] = tmpCompactDay;
              }
            }
            //resource mode 
            else if (this.mainLayout.resourceMode.resource && this.mainLayout.resourceMode.resource.idx == resource.idx) {
              newDatasource[newDatasource.length - 1]["sections"] = [];
              resource.compactDays.forEach((compactDay) => {
                compactDay.forEach((section) => {
                  if (section.attivita && section.attivita.idAttivita) {
                    let count = this.getResourceDaysAndStatus(resource, section.attivita.idAttivita)
                    section["days"] = count.days;
                    section["status"] = count.status;
                  }
                })
                newDatasource[newDatasource.length - 1]["sections"] = newDatasource[newDatasource.length - 1]["sections"].concat(compactDay);
              });
            }
          }
          //charts
          if (resource.giorni && resource.giorni.length > 0) {
            resource.giorni.forEach((day) => {
              //attivita exists
              if (tmpCharts[tmpCharts.length - 1].labels.includes(day.tipoAttivita)) {
                tmpCharts[tmpCharts.length - 1].values[0].data[tmpCharts[tmpCharts.length - 1].labels.indexOf(day.tipoAttivita)] =
                  parseInt(tmpCharts[tmpCharts.length - 1].values[0].data[tmpCharts[tmpCharts.length - 1].labels.indexOf(day.tipoAttivita)] + "") + 1;
              }
              //attivita not exists
              else {
                tmpCharts[tmpCharts.length - 1].labels.push(day.tipoAttivita);
                tmpCharts[tmpCharts.length - 1].values[0].data.push(1);
                tmpCharts[tmpCharts.length - 1].colors[0].backgroundColor = tmpCharts[tmpCharts.length - 1].colors[0].backgroundColor.concat(this.getColor([day.idTipoAttivita]));
              }
            });
          }
        }
      });
      this.statsCharts = tmpCharts;
      this.statsDataSource = new MatTableDataSource(newDatasource);
    }
  }
  //--- END STATS ---//

  //--- DRAG AND DROP ---//
  public emitDragStart(target, event) {
    this.dragStart.emit({ target: target, event: event })
  }
  public emitDragEnd(event) {
    this.dragEnd.emit(event)
  }
  //--- END DRAG AND DROP ---//

  //--- GENERAL ---//
  public getColor(idTipoAttivita) {
    let color = this.mainLayout.activitiesTypesList.filter((x) => { return x.idTipoAttivita == idTipoAttivita })[0];
    return color ? color.colore : "#efefef";
  }
  private getResourceDaysAndStatus(resource, activityId) {
    let daysCount: number = 0, doneCount: number = 0;
    if (resource.giorni && resource.giorni.length > 0) {
      resource.giorni.forEach((day) => {
        if (day.attivita && day.attivita.idAttivita == activityId) {
          daysCount = daysCount + 1;
          if (day.data && ((new Date(new Date(day.data).setHours(0, 0, 0, 0))).getTime() < (new Date(new Date().setHours(0, 0, 0, 0)).getTime())))
            doneCount = doneCount + 1;
        }
      });
    }
    return { days: daysCount, status: Math.round(doneCount * 100 / daysCount) };
  }
  //--- END GENERAL ---//
}

export interface StatsChart {
  type: string,
  labels: Array<string>,
  values: [],
  colors: Array<any>,
  options: ChartOptions
}
