import { NgModule } from '@angular/core';
import { IonicModule } from "@ionic/angular";
import { DateFormatPlaceholder } from './date-format-placeholder.pipe';
@NgModule({
    declarations: [
        DateFormatPlaceholder
    ],
    imports: [
        IonicModule
    ],
    exports: [
        DateFormatPlaceholder
    ]
})
export class DateFormatPlaceholderPipeModule { }