import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-territory-tree',
  templateUrl: './territory-tree.component.html',
  styleUrls: ['./territory-tree.component.scss'],
})
export class TerritoryTreeComponent implements OnInit {
  //item
  @Input() item: any;
  //movable
  @Input() movable: boolean = false;
  //event for movable 
  @Output() onMovableClick = new EventEmitter<any>();
  @Output() onMovableSelect = new EventEmitter<any>();
  //event for detail
  @Output() onDetailClick = new EventEmitter<any>();

  constructor(private platform: Platform,
    public translate: TranslateService,
    private languageSrv: LanguageService,
    private alertCtrl: AlertController) {
    //setup input
    this.item = this.item ? this.item : null;
  }

  ngOnInit() {

  }

  //handleMovable
  public handleMovable(value, item) {
    this.onMovableClick.emit({
      value: value,
      item: item
    });
  }

  //handleMovableChild
  public handleMovableChild(event) {
    this.onMovableClick.emit(event);
  }

  //handleMovableSelect
  async handleMovableSelect(idTerritorioPadre) {
    const alert = await this.alertCtrl.create({
            cssClass: 'alert-service',
      header: this.translate.instant('labels.move_territory'),
      message: this.translate.instant('messages.move_territory_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.onMovableSelect.emit(idTerritorioPadre);
            this.handleMovable(false, idTerritorioPadre);
          }
        }
      ]
    });
    await alert.present()
  }

  //handleMovableSelectChild
  public handleMovableSelectChild(event) {
    this.onMovableSelect.emit(event);
  }

  //handleDetailClick
  public handleDetailClick(event, type) {
    if (type == 'detail')
      this.onDetailClick.emit({
        territory: event,
        idTerritorioPadre: null
      })
    else {
      this.onDetailClick.emit({
        territory: null,
        idTerritorioPadre: event
      })
    }
  }

  //handleDetailClickChild
  public handleDetailClickChild(event) {
    this.onDetailClick.emit(event)
  }
}
