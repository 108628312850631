import { NgModule } from '@angular/core';
import { AdminUserMassiveCreateSettingsComponent } from './admin-user-massive-create-settings.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
;
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceAccessConfigurationComponentModule } from '../../components/service-access-configuration/service-access-configuration.component.module'
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [
        AdminUserMassiveCreateSettingsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
    
        MatExpansionModule,
        ServiceAccessConfigurationComponentModule,
        MatTabsModule
    ],
    exports: [
        AdminUserMassiveCreateSettingsComponent
    ]
})
export class AdminUserMassiveCreateSettingsComponentModule {}