import { DcrudForm } from './dcrud-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DcrudInputComponentModule } from 'src/app/pages/dcrud/dcrud-input/dcrud-input.component.module';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [
    DcrudForm
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    DcrudInputComponentModule,
    MatTooltipModule
  ],
  exports: [
    DcrudForm
  ]
})
export class DcrudFormModule { }

