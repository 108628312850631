import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModalController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { columnTypes, tableColumn, storageTableColumn, tableSettingsEvent, tableExtractPageSize } from './models/table-models';
import { TableExtractionComponent } from '../table-extraction/table-extraction.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { TablePopupInputComponent } from './table-popup-input/table-popup-input.component';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  //Table input
  @Input() tableId: string = "tableComponent"; //table identifier ex: 'mySearchTable', defualt 'tableComponent'
  @Input() tablePaginatorRange: Array<number> = [10, 50, 100]; //table pagination range ex: '[50, 500, 1000]', default '[10, 50, 100]'
  @Input() tablePaginatorPageSize: number = 10;
  @Input() tableColumns: Array<tableColumn> = []; //table columns
  @Input() tableDataSource: MatTableDataSource<any>; //table datasource
  @Input() tableSortActive: string;
  @Input() tableSortDirection: "asc" | "desc" = 'asc';
  @Input() hideExtraction: boolean;
  @Input() paginationFrontend: boolean;
  @Input() selectedListParam: string = 'id';
  @Input() skipExtrationMessage: boolean = false;
  @Input() fileName: string;
  @Input() goDetailPassColumn: boolean = false;
  @Input() skipLayoutSave: boolean = false;
  //Table output
  @Output() tableSortChange = new EventEmitter<any>(); //sorting function
  @Output() tablePaginatorChange = new EventEmitter<any>(); //paginator function
  @Output() extractApi = new EventEmitter<any>();
  //Table children
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild(MatSort) tableSort: MatSort;
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;
  @ViewChild('tableExtraction') tableExtraction: TableExtractionComponent;
  //Variables
  public masterCheckbox: boolean;
  public selectedList: Array<any> = [];
  public displayedColumns: Array<string> = [];
  public previousIndex: number;
  public tableColumnTypes = columnTypes;
  public resizingObject = {
    isResizing: false,
    resizingIndex: null,
    pageX: null,
    target: null
  };
  public tableColumnsDefault: Array<tableColumn>;
  private onWindowResizeSub;
  public isMobile: boolean;
  public mobileColumns: mobileColumns = {
    headerColumns: [{ columnDef: "element" }],
    displayedColumns: ["element"],
    toolbarColumns: [],
    fieldColumns: [],
    sortColumns: []
  };
  public searchLoading: boolean;
  public maxPageSize = tableExtractPageSize;
  public currentMobileSortId;
  private _tableColumnMerged: boolean = false;
  //domEventSub
  private _domEventSub;

  constructor(
    public translate: TranslateService,
    private storage: Storage,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private appSrv: AppService,
    private _router: Router,
    private _alertSrv: AlertService
  ) {
    this.isMobile = this.appSrv.isMobile();
    if (!this.onWindowResizeSub) {
      this.onWindowResizeSub = fromEvent(window, 'resize')
        .pipe(debounceTime(1000))
        .subscribe((event) => {
          setTimeout(() => {
            this.isMobile = this.appSrv.isMobile();
            this.checkForEmptySpace();
            this.setTableStorage();
          }, 200)
        });
    }
    this._domEventSub = this.appSrv.domEvent.subscribe((event) => {
      if (event) {
        this._handleDomMouseEvents(event);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.onWindowResizeSub) {
      this.onWindowResizeSub.unsubscribe();
    }
    if (this._domEventSub) {
      this._domEventSub.unsubscribe();
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideExtraction) {
      this.hideExtraction = changes.hideExtraction.currentValue;
    }
    if (changes.skipExtrationMessage) {
      this.skipExtrationMessage = changes.skipExtrationMessage.currentValue;
    }
    if (changes.tableId) {
      this.tableId = changes.tableId.currentValue;
    }
    if (changes.tablePaginatorRange) {
      this.tablePaginatorRange = changes.tablePaginatorRange.currentValue;
    }
    if (changes.selectedListParam) {
      this.selectedListParam = changes.selectedListParam.currentValue;
    }
    if (changes.fileName) {
      this.fileName = changes.fileName.currentValue;
    }
    if (changes.goDetailPassColumn) {
      this.goDetailPassColumn = changes.goDetailPassColumn.currentValue;
    }
    if (changes.tableColumns) {
      if (changes.tableColumns.currentValue) {
        this.tableColumns = changes.tableColumns.currentValue;
        this.tableColumnsDefault = JSON.parse(JSON.stringify(changes.tableColumns.currentValue));
        //format columns
        if (this.tableColumns && this.tableColumns.length > 0) {
          this.mobileColumns = {
            headerColumns: [{ columnDef: "element" }],
            displayedColumns: ["element"],
            toolbarColumns: [],
            fieldColumns: [],
            sortColumns: []
          }
          let sortColumns: Array<tableColumn> = [{
            columnDef: "sortDefaultFE",
            columnType: columnTypes.TEXT,
            columnIndex: 999,
            columnParam: null,
            columnWidth: 0,
            columnHidden: true,
            columnSortable: true,
            columnHeader: 'labels.default',
            columnCallback: null,
            columnDynamicDetailList: null
          }], sortColumn: tableColumn;
          this.tableColumns.forEach((column: tableColumn) => {
            //toolbar
            if (column.columnType == this.tableColumnTypes.DETAIL || column.columnType == this.tableColumnTypes.SELECT || (column.columnType == this.tableColumnTypes.ICONA && column.columnEndpoint))
              this.mobileColumns.toolbarColumns.push(column);
            //body
            else
              this.mobileColumns.fieldColumns.push(column);
            //sort
            if (column.columnSortable) {
              sortColumn = JSON.parse(JSON.stringify(column));
              sortColumn.mobileSortDirection = 'asc';
              sortColumns.push(sortColumn);
              sortColumn = JSON.parse(JSON.stringify(column));
              sortColumn.mobileSortDirection = 'desc';
              sortColumns.push(sortColumn);
            }
            //column props
            /*column.columnStyleProp = {
              position: "relative",
              offsetLeft: "0",
              zIndex: "1",
              background: "#fff"
            }*/
          })
          this.mobileColumns.sortColumns = sortColumns;
          this.mobileColumns.sortColumns.forEach((column: tableColumn, index) => {
            column.mobileSortId = index;
          })
        }
        this.displayedColumns = [];
        //set displayed columns
        this.tableColumns.forEach((x) => {
          this.displayedColumns.push(x.columnDef);
        })
      }
    }
    if (changes.tableDataSource) {
      this.searchLoading = false;
      this.selectedList = [];
      this.tableDataSource = changes.tableDataSource.currentValue;
      if (this.isMobile) {
        if (this.tablePaginator && this.tablePaginator.pageSize >= 20 && !this.paginationFrontend) {
          setTimeout(() => {
            let elm = document.getElementById("table-row-" + (this.tablePaginator.pageSize - 20));
            if (elm)
              elm.scrollIntoView({ behavior: 'smooth' });
          }, 200)
        } /*else if (!this.tablePaginator || (this.tablePaginator) && this.tablePaginator.pageSize < 20) {
          let tableAnchor = document.getElementById("table-anchor");
          if (tableAnchor) {
            setTimeout(() => {
              tableAnchor.scrollIntoView({ behavior: 'smooth', block: 'start'});
            });
         
        } }*/

      }
      setTimeout(() => {
        if (this.paginationFrontend && this.tableDataSource) {
          this.tableDataSource.paginator = this.tablePaginator;
          this.tableDataSource.sort = this.tableSort;
        }
      }, 1000)
      /*if (this._tableColumnMerged)
        this._setColumnStickyLeftOffsetDom();*/
    }
    if ((changes.tableDataSource || changes.tableColumns) && this.tableDataSource?._filterData?.length > 0) {
      if (!this._tableColumnMerged) {
        //check for storage
        this.getTableStorage().then((tableStorage: any) => {
          //merge default columns
          if (tableStorage && tableStorage.storageTableColumn) {
            this.mergeDefaultColumns(tableStorage.storageTableColumn);
          }
          this._tableColumnMerged = true;
          let attempt = 1, tableContainerElement, tableElement;
          this._updateDimensions(attempt, tableContainerElement, tableElement);
        });
      }
    }
    if (changes.extractApi) {
      this.extractApi = changes.extractApi.currentValue;
    }
  }

  private _updateDimensions(attempt: number, tableContainerElement: HTMLDivElement, tableElement: HTMLTableElement) {
    if (attempt < 5 && (!tableContainerElement || tableContainerElement.getBoundingClientRect().width < 20) && (!tableElement || tableElement.getBoundingClientRect().width < 20)) {
      tableContainerElement = document.getElementById(`table-container-${this.tableId}`) as HTMLDivElement;
      tableElement = document.getElementById(`table-${this.tableId}`) as HTMLTableElement;
      attempt = attempt + 1;
      setTimeout(() => {
        this._updateDimensions(attempt, tableContainerElement, tableElement);
      }, 500)
    } else {
      this.checkForEmptySpace();
      this.setTableStorage();
    }
  }

  //--- EMMITTERS ---//
  //emit table sort change
  public emitTableSortChange() {
    this.searchLoading = true;
    this.tableSortChange.emit();
  }
  //emit table paginator change
  public emitTablePaginatorChange(event) {
    if (!this.paginationFrontend)
      this.searchLoading = true;
    else {
      event["tableId"] = this.tableId;
      //this._setColumnStickyLeftOffsetDom();
    }
    this.tablePaginatorChange.emit(event);
  }
  //emitLoadMore
  public emitLoadMore() {
    this.searchLoading = true;
    this.tablePaginator.pageIndex = 0;
    this.tablePaginator.pageSize = this.tablePaginator.pageSize + 20;
    this.tablePaginatorChange.emit(this.tablePaginator);
  }
  //--- END EMITTERS ---//

  //--- MASTER CHECKBOX ---//
  //handleMasterCheckbox
  public handleMasterCheckbox() {
    for (let item of this.tableDataSource.data) {
      if (this.masterCheckbox) {
        item['selected'] = true;
      } else {
        item['selected'] = false;
      }
    }
    this.getAllSelected();
  }
  //get all selected
  public getAllSelected() {
    this.selectedList = [];
    for (let item of this.tableDataSource.data)
      if (!!item['selected'])
        this.selectedList.push(item[this.selectedListParam]);
    if (this.tableDataSource.data.length == this.selectedList.length)
      this.masterCheckbox = true;
    else
      this.masterCheckbox = false;
  }
  //--- END MASTER CHECKBOX ---//

  //goDetail
  public goDetail(event: { column, element }) {
    if (event && event.column && event.column.columnCallback && event.element) {
      event.element["tableId"] = this.tableId;
      if (this.goDetailPassColumn)
        event.element["detailColumn"] = event.column;
      event.column.columnCallback(event.element);
    }
  }

  //goDynamicDetail
  public goDynamicDetail(event: { item, element }) {
    if (event && event.item && event.item.callback && event.element) {
      event.item.callback(event.element);
    }
  }

  //openlink
  public openLink(event: { column, element, condition }) {
    if (event && event.column && event.column.columnCallback && event.element && event.condition && !event.condition.disabled) {
      event.column.columnCallback(event);
    }
    else if (event && event.column && event.column.columnEndpoint && event.element && (!event.condition || !event.condition.disabled)) {
      this._router.navigateByUrl(this.resolveEndpointByTableRow(event.column.columnEndpoint, event.element));
    }
  }

  //--- ORDER COLUMNS ---//
  //dropListDropped
  public dropListDropped(event: any) {
    if (event) {
      moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
      this.moveColumns();
    }
  }
  //--- END ORDER COLUMNS ---//

  //--- RESIZE COLUMNS ---//
  public resizerMouseDown(event: any, index: number, column: tableColumn) {
    if (column.columnType != this.tableColumnTypes.DETAIL && column.columnType != this.tableColumnTypes.SELECT) {
      event.preventDefault();
      this.resizingObject = {
        resizingIndex: index,
        isResizing: true,
        pageX: event.pageX,
        target: document.getElementById(this.tableId + "-" + column.columnDef)
      };
    }
  }
  private _handleDomMouseEvents(event) {
    let context = this, width, currentColumn: tableColumn; //domTableContent = document.getElementById("tableContent-" + this.tableId);
    if (event) {
      if (event.eventType == 'mousemove' && context.resizingObject.isResizing) {
        event.event.preventDefault();
        event.event.stopPropagation();
        currentColumn = context.tableColumns[context.resizingObject.resizingIndex];
        width = Math.floor(event.event.pageX - context.resizingObject.target.getBoundingClientRect().left);
        currentColumn.columnWidth = width > 50 ? width : 50;
        context.resizingObject.pageX = event.event.pageX;
      }
      if (event.eventType == 'mouseup' && context.resizingObject.isResizing) {
        event.event.preventDefault();
        event.event.stopPropagation();
        if (context.resizingObject.isResizing) {
          context.tableLayoutChanged(true);
          context.resetResizingObj();
        }
      }
    }
  }
  private resetResizingObj() {
    this.resizingObject = {
      isResizing: false,
      resizingIndex: null,
      pageX: null,
      target: null
    }
  }
  public adaptToScreen() {
    this.checkForEmptySpace(false, true);
    this.setTableStorage();
  }
  //--- END RESIZE COLUMNS ---//

  //--- COLUMNS SETTINGS ---//
  async openColumnsSettings() {
    const popover = await this.modalCtrl.create({
      component: TableSettingsComponent,
      cssClass: 'table-settings-components',
      componentProps: {
        tableColumns: this.tableColumns ? JSON.parse(JSON.stringify(this.tableColumns)) : null
      }
    });
    popover.onDidDismiss()
      .then((event) => {
        let eventData: tableSettingsEvent = event.data ? event.data : null;
        if (eventData) {
          let sameColumn: tableColumn, newColumns: Array<tableColumn>;
          //update columns
          if (eventData.tableColumns)
            newColumns = JSON.parse(JSON.stringify(eventData.tableColumns));
          //reset columns
          else
            newColumns = JSON.parse(JSON.stringify(this.tableColumnsDefault));
          this.tableColumns.map((column: tableColumn) => {
            sameColumn = newColumns.filter((x: tableColumn) => { return x.columnDef == column.columnDef })[0];
            if (sameColumn) {
              column.columnHidden = sameColumn.columnHidden;
              column.columnIndex = sameColumn.columnIndex;
              column.columnWidth = sameColumn.columnWidth;
              column.columnStickyLeft = sameColumn.columnStickyLeft;
            }
          })
          this.tableColumns.sort((a: tableColumn, b: tableColumn) => {
            if (a.columnIndex > b.columnIndex)
              return 1;
            else if (a.columnIndex < b.columnIndex)
              return -1;
            else
              return 0;
          })
          this.moveColumns();
        }
      });
    return await popover.present();
  }

  //move moveColumn
  private moveColumns() {
    this.tableColumns.forEach((colunm, index) => {
      colunm.columnIndex = index;
      this.displayedColumns[index] = colunm.columnDef;
    });
    setTimeout(() => {
      this.tableLayoutChanged();
    }, 500);
  }
  //--- END COLUMNS SETTINGS ---//

  //--- STORAGE ---//
  //tableLayoutChanged
  private tableLayoutChanged(isColumnResize?) {
    this.checkForEmptySpace(isColumnResize ? isColumnResize : null);
    //this._setColumnStickyLeftOffset();
    this.setTableStorage();
  }
  //set table storage
  private setTableStorage() {
    if (!this.skipLayoutSave) {
      let storageTableColumn: Array<storageTableColumn> = this.tableColumnsToStorageTableColumns(this.tableColumns);
      this.storage.set(this.tableId, {
        storageTableColumn: storageTableColumn ? storageTableColumn : null
      })
    }
  }
  //check for empty space
  private checkForEmptySpace(isColumnResize?, skipWidthControl?) {
    let tableContainerElement = document.getElementById(`table-container-${this.tableId}`) as HTMLDivElement;
    let tableElement = document.getElementById(`table-${this.tableId}`) as HTMLTableElement;
    if (tableContainerElement && tableElement) {
      let tableContainerElementWidth = tableContainerElement.getBoundingClientRect().width;
      if (skipWidthControl || (!skipWidthControl && tableElement.getBoundingClientRect().width < tableContainerElementWidth)) {
        if (!isColumnResize) {
          let tableFixedWidth = 0, tableHiddenPercentage = { hiddenPercentage: 0, columnsPercentageNumber: 0, totalPercentage: 0 };
          this.tableColumns.forEach((column) => {
            if (!column.columnHidden && !column.columnWidthPercentage)
              tableFixedWidth = tableFixedWidth + column.columnWidth;
            else if (column.columnHidden && column.columnWidthPercentage)
              tableHiddenPercentage.hiddenPercentage = tableHiddenPercentage.hiddenPercentage + column.columnWidthPercentage;
            if (!column.columnHidden && column.columnWidthPercentage)
              tableHiddenPercentage.columnsPercentageNumber = tableHiddenPercentage.columnsPercentageNumber + 1;
          })
          tableContainerElementWidth = tableContainerElementWidth - tableFixedWidth;
          tableHiddenPercentage.hiddenPercentage = tableHiddenPercentage.hiddenPercentage > 0 && tableHiddenPercentage.columnsPercentageNumber > 0 ? tableHiddenPercentage.hiddenPercentage / tableHiddenPercentage.columnsPercentageNumber : 0;
          let currentColumn: tableColumn;
          for (let i = 0; i < this.tableColumns.length; i++) {
            currentColumn = this.tableColumns[i];
            if (!currentColumn.columnHidden && currentColumn.columnWidthPercentage) {
              //not last column
              if (i != this.tableColumns.length - 1)
                currentColumn.columnWidth = ((currentColumn.columnWidthPercentage + tableHiddenPercentage.hiddenPercentage) * tableContainerElementWidth) / 100;
              //last column
              else
                currentColumn.columnWidth = ((100 - tableHiddenPercentage.totalPercentage) * tableContainerElementWidth) / 100;
              tableHiddenPercentage.totalPercentage = tableHiddenPercentage.totalPercentage + (currentColumn.columnWidthPercentage + tableHiddenPercentage.hiddenPercentage);
            }
          }
        } else {
          for (let i = this.tableColumns.length - 1; i > 0; i--) {
            if (!this.tableColumns[i].columnHidden) {
              this.tableColumns[i].columnWidth = this.tableColumns[i].columnWidth + (tableContainerElement.getBoundingClientRect().width - tableElement.getBoundingClientRect().width);
              break;
            }
          }
        }
      }
    }
  }

  //setColumnStickyLeftOffset
  private _setColumnStickyLeftOffset() {
    if (!this.isMobile) {
      let tmpOffsetLeft = 0;
      for (let i = 0; i < this.tableColumns.length; i++) {
        this.tableColumns[i].columnStyleProp = {
          position: "relative",
          offsetLeft: "0",
          zIndex: "1",
          background: "#fff"
        };
        if (this.tableColumns[i].columnStickyLeft) {
          tmpOffsetLeft = 0;
          if (i > 0) {
            for (let j = 0; j < i; j++) {
              tmpOffsetLeft = tmpOffsetLeft + this.tableColumns[j].columnWidth;
            }
          }
          this.tableColumns[i].columnStyleProp = {
            position: "sticky",
            offsetLeft: tmpOffsetLeft + "px",
            zIndex: "2",
            background: "#f7f7f7"
          };
        }
      }
      this._setColumnStickyLeftOffsetDom();
    }
  }
  private _setColumnStickyLeftOffsetDom() {
    if (!this.isMobile) {
      setTimeout(() => {
        this.tableColumns.forEach((column) => {
          if (column.columnStickyLeft)
            for (let element of Array.from(document.getElementsByClassName("cdk-column-" + column.columnDef) as HTMLCollectionOf<HTMLElement>)) {
              element.style.left = column.columnStyleProp.offsetLeft;
              element.style.zIndex = column.columnStyleProp.zIndex;
              element.style.background = column.columnStyleProp.background;
              element.style.position = column.columnStyleProp.position;
            }
        })
      }, 200)
    }
  }
  //get ald areas search table object
  private getTableStorage() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get(this.tableId).then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  //merge default columns
  private mergeDefaultColumns(storageTableColumns: Array<tableColumn>) {
    if (storageTableColumns && this.tableColumns) {
      //check columns number (length diff reset after deploy)
      if (this.tableColumns.length == storageTableColumns.length) {
        let newDisplayedColumns = JSON.parse(JSON.stringify(this.displayedColumns));
        this.tableColumns.forEach((x) => {
          for (let storageColumn of storageTableColumns) {
            if (storageColumn.columnDef == x.columnDef) {
              x.columnHidden = storageColumn.columnHidden;
              x.columnIndex = storageColumn.columnIndex;
              x.columnStickyLeft = storageColumn.columnStickyLeft;
              x.columnWidth = storageColumn.columnWidth;
              newDisplayedColumns[x.columnIndex] = x.columnDef;
              break;
            }
          }
        })
        this.displayedColumns = newDisplayedColumns;
        this.tableColumns.sort((a, b) => {
          if (a.columnIndex < b.columnIndex) {
            return -1;
          }
          if (a.columnIndex > b.columnIndex) {
            return 1;
          }
          return 0;
        })
      }
    }
  }
  //tableColumnToStorageTableColumn
  private tableColumnsToStorageTableColumns(tableColumns: Array<tableColumn>) {
    let storageTableColumns: Array<storageTableColumn> = [];
    if (tableColumns) {
      for (let column of tableColumns) {
        storageTableColumns.push({
          columnDef: column.columnDef,
          columnHidden: column.columnHidden,
          columnIndex: column.columnIndex,
          columnWidth: column.columnWidth,
          columnStickyLeft: column.columnStickyLeft
        })
      }
    }
    return storageTableColumns;
  }
  //--- END STORAGE ---//

  //--- EXTRACT ---//
  public extractTable(apiResponse) {
    this.tableExtraction.extractTable(apiResponse);
  }
  public extractApiEmitter(event) {
    if (this.extractApi)
      this.extractApi.emit(event);
  }
  //--- END EXTACT ---//

  //--- POPUP ---//
  public editCell(event: { column: tableColumn, element, tdIndex }) {
    if (event && event.column.columnType == this.tableColumnTypes.INPUT_POPUP_STRINGA ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_NUMERO ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_DATA ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_DATAORA ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_COMBO ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_MULTICOMBO ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_RADIO ||
      event.column.columnType == this.tableColumnTypes.INPUT_POPUP_BOOLEAN) {
      this.openTablePopupInput(event.column, event.element, event.tdIndex);
    } /*else if (event && event.column.columnType == this.tableColumnTypes.INPUT_STRINGA ||
      event.column.columnType == this.tableColumnTypes.INPUT_NUMERO ||
      event.column.columnType == this.tableColumnTypes.INPUT_DATA ||
      event.column.columnType == this.tableColumnTypes.INPUT_DATAORA ||
      event.column.columnType == this.tableColumnTypes.INPUT_COMBO ||
      event.column.columnType == this.tableColumnTypes.INPUT_MULTICOMBO ||
      event.column.columnType == this.tableColumnTypes.INPUT_RADIO ||
      event.column.columnType == this.tableColumnTypes.INPUT_BOOLEAN) {
      event.element[event.column.columnDef + "EditMode"] = true;
    }*/
  }

  //saveCell
  public saveCell(event: { column: tableColumn, element }) {
    if (event && event.column && event.element) {
      this._alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.proceedOperationMessage') }, () => {
        event.column.columnCallback({ tableId: this.tableId, column: event.column, element: event.element });
      })
    }
  }



  private openTablePopupInput(column: tableColumn, element, tdIndex) {
    let domColumn = document.getElementById("column-" + tdIndex + "-" + this.tableId + "-" + column.columnDef);
    let ev;
    if (domColumn) {
      ev = {
        target: domColumn
      };
    }
    this.tablePopupInput(column, element, ev);
  }

  async tablePopupInput(column: tableColumn, element, event) {
    const modal = await this.popoverCtrl.create({
      component: TablePopupInputComponent,
      cssClass: 'table-popup-input-popover',
      event: event,
      componentProps: {
        columns: [JSON.parse(JSON.stringify(column))],
        element: JSON.parse(JSON.stringify(element))
      },
      backdropDismiss: false,
      showBackdrop: true
    });
    modal.onDidDismiss()
      .then((res: any) => {
        if (res && res.data && res.data.columns && res.data.element && column.columnCallback) {
          column.columnCallback({ tableId: this.tableId, columns: res.data.columns, element: res.data.element });
        }
      });
    return await modal.present();
  }

  //{{tableId}}-{{column.columnDef}}
  public openComparisonPopover(column: tableColumn) {
    let domColumnHeader = document.getElementById(this.tableId + "-" + column.columnDef);
    let ev;
    if (domColumnHeader) {
      ev = {
        target: domColumnHeader
      };
    }
    this.comparisonPopover(column, ev);
  }

  //openComparisonPopover
  async comparisonPopover(column: tableColumn, event) {
    const modal = await this.popoverCtrl.create({
      component: TablePopupInputComponent,
      cssClass: 'table-popup-input-popover',
      event: event,
      componentProps: {
        columns: [JSON.parse(JSON.stringify(column))],
        element: {},
        fromHeader: true,
        showComparison: true
      },
      backdropDismiss: false, //for date time picker
      showBackdrop: true
    });
    modal.onDidDismiss()
      .then((res: any) => {
        if (res && res.data && res.data.columns && res.data.element && column.columnHeaderCallback && res.data.comparison) {
          column.columnHeaderCallback({ tableId: this.tableId, comparison: res.data.comparison, columns: res.data.columns, element: res.data.element });
        }
      });
    return await modal.present();
  }
  //--- END POPUP ---//

  //--- SORT ---//
  public updateSort() {
    let matSortables = this.tableSort.sortables;
    const activeSortHeader = <MatSortHeader>(
      matSortables.get(this.tableSort.active)
    );
    if (activeSortHeader)
      activeSortHeader._setAnimationTransitionState({
        fromState: activeSortHeader._arrowDirection,
        toState: 'active',
      });
    this.tableSort.sortChange.emit();
  }
  public mobileSortChange() {
    let currentSort: tableColumn = this.mobileColumns.sortColumns.filter((column: tableColumn) => {
      return column.mobileSortId == this.currentMobileSortId;
    })[0];
    if (currentSort) {
      this.tableSort.active = currentSort.columnDef == "sortDefaultFE" ? null : currentSort.columnDef;
      this.tableSort.direction = currentSort.columnDef == "sortDefaultFE" ? null : currentSort.mobileSortDirection;
    }
    if (!this.paginationFrontend) {
      this.searchLoading = true;
      this.emitTableSortChange()
    }

  }
  //--- MOBILE SORT ---//

  //resolve endpoint by table row
  public resolveEndpointByTableRow(endpoint, tableRow) {
    tableRow = JSON.parse(JSON.stringify(tableRow));
    let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag;
    while (endpointTmp.indexOf("]") != -1) {
      currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
      //value exists in tableRow
      if (tableRow[currentTag] != null) {
        //format value
        endpointBe = endpointBe.toString().replace("[" + currentTag + "]", tableRow[currentTag]);
      }
      //value doesn't exist in tableRow
      else {
        endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "");
      }
      endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
    }
    return endpointBe;
  }
}


interface mobileColumns {
  headerColumns: Array<any>, //single item array [{ columnDef: "element" }]
  displayedColumns: Array<string>, //single item array ["element"]
  toolbarColumns: Array<any>, //array for toolbar (SELECT and DETAIL)
  fieldColumns: Array<any>, //array of columns for element (data fields)
  sortColumns: Array<any> //array of column for sorting list
}
