<ion-content >
  <!-- message -->
  <div class="pin-message" *ngIf="pinMessage">
    {{pinMessage | translate}}
  </div>

  <!-- pin -->
  <div class="pin" [ngClass]="{'pin-only-key': pinType == pinPopoverTypes.NUMERIC}">
    <form [formGroup]="pinForm">
      <input type="password" placeholder="PIN" formControlName="pin" autocomplete="pin" autofill="pin" name="pin" id="pinInput" (keyup)="pinKeyup($event)">
      <ion-button size="small" (click)="pinCharacterClick('confirm')" color="success"
        [hidden]="pinType != pinPopoverTypes.ALPHANUMERIC"><ion-icon name="checkmark"></ion-icon></ion-button>
    </form>
  </div>

  <!-- numeric keys -->
  <div class="pin-numeric" *ngIf="pinType == pinPopoverTypes.NUMERIC">
    <div class="keys-pin-numeric">
      <div class="keys-pin-numeric-button"
        *ngFor="let character of ['7', '8', '9', '4', '5', '6', '1', '2', '3', 'delete', '0', 'confirm']">
        <div class="keys-pin-numeric-button-number" [ngClass]="{'button-function': [ 'confirm'].includes(character)}"
          *ngIf="character != ''" (click)="pinCharacterClick(character)">
          <span *ngIf="character != 'delete' && character != '' && character != 'confirm'">{{character}}</span>
          <ion-icon name="checkmark" *ngIf="character == 'confirm'"></ion-icon>
          <ion-icon name="arrow-back" *ngIf="character == 'delete'"></ion-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- alphanumeric keys 
  <div class="pin-alphanumeric" *ngIf="pinType == pinPopoverTypes.ALPHANUMERIC">
    <div class="pin-alphanumeric-numbers">
      <div class="pin-alphanumeric-numbers-button"
        *ngFor="let number of ['1','2','3','4','5','6','7','8','9','0','delete']" (click)="pinCharacterClick(number)">
        <div class="pin-alphanumeric-numbers-button-number"
          [style.background]="number == 'delete' ? 'var(--ion-color-danger)' : 'var(--ion-color-secondary)'">
          <span *ngIf="number != 'delete'">{{number}}</span>
          <ion-icon name="arrow-back" *ngIf="number == 'delete'"></ion-icon>
        </div>
      </div>
    </div>
    <div class="pin-alphanumeric-letters">
      <div class="pin-alphanumeric-letters-button"
        *ngFor="let letter of ['q','w','e','r','t','y','u','i','o','p','--','a','s','d','f','g','h','j','k','l','--','shift','z','x','c','v','b','n','m', 'confirm']"
        (click)="pinCharacterClick(letter)" [ngClass]="{'pin-alphanumeric-letters-button-newline': letter == '--'}">
        <div class="pin-alphanumeric-letters-button-letter" *ngIf="letter != '--'"
          [style.background]="letter == 'confirm' ? 'var(--ion-color-success)' : letter == 'shift' ?'var(--ion-color-tertiary)' : 'var(--ion-color-primary)'">
          <span *ngIf="letter != 'shift' && letter != 'confirm'"
            [style.textTransform]="shiftActive ? 'uppercase' : 'none'">{{letter}}</span>
          <ion-icon name="arrow-up" *ngIf="letter == 'shift'"></ion-icon>
          <ion-icon name="checkmark" *ngIf="letter == 'confirm'"></ion-icon>
        </div>
      </div>
    </div>
  </div>-->
</ion-content>