import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service'
import { LoaderService } from './loader.service';
import { HttpClient } from '@angular/common/http';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';
import { ActivitiesService } from './activities.service';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public loaderSrv: LoaderService,
    public http: HttpClient,
    private _activitiesSrv: ActivitiesService,
    private _alertSrv: AlertService,
    public translate: TranslateService) { }

  //login api request
  public login(username: string, password: string, language: string, idApplicativo?: number) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then(() => {
        this.loaderSrv.open();
        let payload = {
          "Username": username,
          "Password": sha256(password).toString(Hex),
          "link": !idApplicativo ? `${this.httpUtilitySrv.getBaseUri()}reset-password` : null,
          "language": language,
          "idApplicativo": idApplicativo
        }
        this.http.post(`${this.httpUtilitySrv.links.server}login`, payload, { observe: 'response' }).subscribe((res) => {
          this.loaderSrv.dismiss();
          resolve(res);
        }, (err) => {
          this.loaderSrv.dismiss();
          reject(err);
        })
      }).catch((err) => {
        this.loaderSrv.dismiss();
        reject(err)
      });
    });
  }

  //reset password api request
  public resetPassword(username: string, language: string, idApplicativo?: number) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        this.loaderSrv.open();
        let payload = {
          "emailusername": username,
          "language": language,
          "link": !idApplicativo ? `${this.httpUtilitySrv.getBaseUri()}reset-password` : null,
          "idApplicativo": idApplicativo
        }
        this.http.post(`${this.httpUtilitySrv.links.server}external/resetPassword`, payload).subscribe((res) => {
          this.loaderSrv.dismiss();
          resolve(res);
        }, (err) => {
          this.loaderSrv.dismiss();
          reject(err);
        })
      }).catch((err) => {
        this.loaderSrv.dismiss();
        reject(err)
      });
    });
  }

  //get moduli api request
  public getModuli(idRuolo) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}getMenu`, { id: idRuolo + "" }, { observe: 'response' }).subscribe(async (res: any) => {
        //check for hide_homepage module: hide that module and homepage.
        //deleteme
        //res.body.payload = deleteme.payload;
        if (res && res.body && res.body.payload && res.body.payload.figli) {
          if (res.body.payload.figli.length > 0 && res.body.payload.figli[0].id == 'welcome_page' && res.body.payload.figli[0].figli && res.body.payload.figli[0].figli.filter((x) => { return x.id == 'welcome_page.hide' })[0]) {
            res.body.payload.figli.splice(0, 1);
          }
        }
        resolve(res);
      }, (err) => {
        reject(err);
      })
    })
  }

  //get user info api request
  public getUserInfo() {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}getUserInfo`, {})
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          reject(err);
        })
    });
  }

  //changePassword api request
  public changePassword(username: string, password: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      this.loaderSrv.open();
      let payload = {
        "username": username,
        "password": sha256(password).toString(Hex),
        "newPassword": newPassword
      }
      this.http.post(`${this.httpUtilitySrv.links.server}changePassword`, payload).subscribe((res) => {
        this.loaderSrv.dismiss();
        resolve(res);
      }, (err) => {
        this.loaderSrv.dismiss();
        reject(err);
      })
    })
  }

  //changePasswordExt api request
  public changePasswordExt(username: string, password: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      this.loaderSrv.open();
      let payload = {
        "username": username,
        "password": password,
        "newPassword": newPassword
      }
      this.http.post(`${this.httpUtilitySrv.links.server}external/changePasswordExt`, payload).subscribe((res) => {
        this.loaderSrv.dismiss();
        resolve(res);
      }, (err) => {
        this.loaderSrv.dismiss();
        reject(err);
      })
    })
  }

  //register agency request
  public registerAgency(token: string, id: string) {
    return new Promise((resolve, reject) => {
      this.loaderSrv.open();
      let payload = {
        "TokenName": id,
        "TokenValue": token,
      }
      this.http.post(`${this.httpUtilitySrv.links.server}external/registerAgency`, payload).subscribe((res) => {
        this.loaderSrv.dismiss();
        resolve(res);
      }, (err) => {
        this.loaderSrv.dismiss();
        reject(err);
      })
    })
  }

  //silentLogin api request
  public silentLogin(token: string, id: string, tokenPin?: string) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        this.loaderSrv.open();
        let payload = {
          "TokenName": id,
          "TokenValue": token,
          "TokenPin": tokenPin
        }
        this.http.post(`${this.httpUtilitySrv.links.server}silentLogin`, payload, { observe: 'response' }).subscribe((res: any) => {
          this.loaderSrv.dismiss();
          //check for hide_homepage module: hide that module and homepage.
          if (res && res.body && res.body.payload && res.body.payload.currentMenu && res.body.payload.currentMenu.figli) {
            if (res.body.payload.currentMenu.figli.length > 0 && res.body.payload.currentMenu.figli[0].id == 'welcome_page' && res.body.payload.currentMenu.figli[0].figli && res.body.payload.currentMenu.figli[0].figli.filter((x) => { return x.id == 'welcome_page.hide' })[0]) {
              res.body.payload.currentMenu.figli.splice(0, 1);
            }
          }
          resolve(res);
        }, (err) => {
          this.loaderSrv.dismiss();
          reject(err);
        })
      }).catch((err) => {
        this.loaderSrv.dismiss();
        reject(err)
      });
    });
  }

}

