import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PssfeSelectableService {

    private localCache = {};

    constructor(private http: HttpClient) {

    }

    //addItem
    public addLocalCacheItem(inputId, item) {
        //endpoint doesn't exist
        if (!this.localCache[inputId]) {
            this.localCache[inputId] = [item]
        } else {
            if (this.localCache[inputId].filter((x) => { return x.value == item.value }).length == 0)
                this.localCache[inputId].push(item);
        }
    }
    public deleteLocalCahceItem(inputId, item) {
        if (this.localCache[inputId])
            this.localCache[inputId] = this.localCache[inputId].filter((x) => { return x.value != item.value });
    }
    //getEndpointCache
    public getFieldTranslationCodeLocalCache(inputId: string) {
        return this.localCache[inputId] && this.localCache[inputId].length > 0 ? this.localCache[inputId] : [];
    }

    //get list by endPoint
    public getListByEndpoint(endPoint, payload) {
        return new Promise((resolve, reject) => {
            this.http.post(endPoint, payload).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //resolve endpoint by inputList
    public resolveEndpointByInputList(endpoint, inputList) {
        let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag;
        while (endpointTmp.indexOf("]") != -1) {
            currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
            for (let input of inputList) {
                //value exists in inputList
                if (input.id == currentTag && input.value != null) {
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", typeof input.value === 'object' ? input.value[0].value : input.value + "");
                }
                //value doesn't exist in inputList
                else if (input.id == currentTag && input.value == null) {
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "");
                }
            }
            endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
        }
        return endpointBe;
    }
}