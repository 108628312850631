import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { NavParams, Platform } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth-service.service';
import { TableExtractionService } from 'src/app/services/table-extraction.service';


@Component({
  selector: 'app-pending-request-popover',
  templateUrl: './pending-request-popover.component.html',
  styleUrls: ['./pending-request-popover.component.scss'],
})
export class PendingRequestPopoverComponent implements OnInit {
  //pendingRequests
  public pendingRequests: Array<any> = [];

  constructor(
    private authSrv: AuthService,
    public translate: TranslateService,
    private languageSrv: LanguageService,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    private tableExtractionSrv: TableExtractionService) {
    this.tableExtractionSrv.currentLoadingExtractionsIdObservable.subscribe((res) => {
      if (res) {
        this.pendingRequests = res;
      }
    })
  }

  ngOnInit() {

  }
}
