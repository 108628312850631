import { NgModule } from '@angular/core';
import { IonicModule } from "@ionic/angular";
import { NumberSeparatorPipe } from './number-separator.pipe';
@NgModule({
    declarations: [
        NumberSeparatorPipe
    ],
    imports: [
        IonicModule
    ],
    exports: [
        NumberSeparatorPipe
    ]
})
export class NumberSeparatorPipePipeModule { }