<ion-content >
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.user_massive_create_settings' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <form [formGroup]="userDetailForm">
        <ion-grid>
          <ion-row>
            <!-- roles -->
            <ion-col size-md="4" size-sm="4" size-xs="12">
              <ion-item class="input-field" lines="none" (click)="selectableTreePopover('role')">
                <ion-label position="stacked">{{'labels.roles' | translate}} * ({{roleIdList.length}})</ion-label>
                <button class="select-roles" >{{'buttons.select' |
                  translate}}</button>
              </ion-item>
            </ion-col>
            <!-- language -->
            <ion-col size-md="4" size-sm="4" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">Lingua *</ion-label>
                <ion-select formControlName="language" placeholder="{{'labels.select' | translate}}">
                  <ion-select-option value="it" selected>Italiano
                  </ion-select-option>
                  <ion-select-option value="en">English
                  </ion-select-option>
                  <ion-select-option value="en-US">American
                  </ion-select-option>
                  <ion-select-option value="fr">Français
                  </ion-select-option>
                  <ion-select-option value="de">Deutsche
                  </ion-select-option>
                  <ion-select-option value="at">Austrian
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <!-- enabled -->
            <ion-col size-md="4" size-sm="4" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.enabled' | translate}}</ion-label>
                <ion-checkbox mode="md" type="checkbox" formControlName="enabled">
                </ion-checkbox>
              </ion-item>
            </ion-col>
            <!-- owner (read/label) -->
            <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.denominazioneProprietario != null">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.owner' | translate}}</ion-label>
                <ion-input type="text" [readonly]="true">{{modalNavParams.denominazioneProprietario != null ?
                  modalNavParams.denominazioneProprietario : '-'}}</ion-input>
              </ion-item>
            </ion-col>
            <!-- owner (change/checkbox/simplified) - module permission required -->
            <ion-col size-md="4" size-sm="4" size-xs="12"
              *ngIf="!hideSection('amministrazione.gestione_utenti.set_owner_simplified')">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.ownerSelect' | translate}}</ion-label>
                <ion-select formControlName="currentTerritoryIsOwner" placeholder="{{'labels.select' | translate}}"
                  [disabled]="modalNavParams.proprietarioModificabile != null && !modalNavParams.proprietarioModificabile">
                  <ion-select-option [value]=0 selected>{{'labels.ownerSelectOpt1' | translate}}
                  </ion-select-option>
                  <ion-select-option [value]=1>{{'labels.ownerSelectOpt2' | translate}}
                  </ion-select-option>
                  <ion-select-option [value]=2>{{'labels.ownerSelectOpt3' | translate}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <!-- owner (change/treeview/advanced) - module permission required -->
            <ion-col size-md="4" size-sm="4" size-xs="12"
              *ngIf="!hideSection('amministrazione.gestione_utenti.set_owner')">
              <ion-item class="input-field" lines="none"  (click)="selectableTreePopover('customer')">
                <ion-label position="stacked">{{'labels.ownerSelect' | translate}} ({{modalNavParams.idProprietario
                  !=
                  null ? 1 : 0}})</ion-label>
                <button class="select-customers">{{'buttons.select' |
                  translate}}</button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <ion-row>
        <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box"> 
            <div class="button-box">
              <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
                <ion-label>{{'buttons.cancel' | translate}}</ion-label>
              </ion-button>
              <ion-button color="success" size="default" (click)="saveSettings()">
                <ion-label>{{'buttons.save' | translate}}</ion-label>
              </ion-button>
            </div>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>