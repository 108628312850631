<ion-content >
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.user_detail' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <mat-tab-group [(selectedIndex)]="firstIndex"
        [ngClass]="{'tab-show': true}">
        <mat-tab label="{{'labels.user' | translate}}" isActive>
          <form [formGroup]="userDetailForm">
            <ion-grid>
              <ion-row>
                <!-- name -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="isEnvironmentImma">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.name' | translate}} *</ion-label>
                    <ion-input type="text" formControlName="name" placeholder="{{'labels.insert' | translate}}">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- surname -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="isEnvironmentImma">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.surname' | translate}} *</ion-label>
                    <ion-input type="text" formControlName="surname" placeholder="{{'labels.insert' | translate}}">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- username -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{isEnvironmentImma ? ('labels.username' | translate) :
                      ('labels.email_username' | translate)}} *</ion-label>
                    <ion-input type="text" formControlName="username" placeholder="{{'labels.insert' | translate}}">
                    </ion-input>
                  </ion-item>
                  <div class="validation-message"
                    *ngIf="userDetailForm.get('username').hasError('pattern') && (userDetailForm.get('username').dirty || userDetailForm.get('username').touched)">
                    {{'messages.invalid_email_message' | translate}}</div>
                </ion-col>
                <!-- email 
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!showEmail">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.email' | translate}} *</ion-label>
                    <ion-input type="text" formControlName="email" placeholder="{{'labels.insert' | translate}}">
                    </ion-input>
                  </ion-item>
                  <div class="validation-message"
                    *ngIf="userDetailForm.get('email').hasError('pattern') && (userDetailForm.get('email').dirty || userDetailForm.get('email').touched)">
                    {{'messages.invalid_email_message' | translate}}</div>
                </ion-col>-->
                <!-- phone -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="isEnvironmentImma">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.phone' | translate}}</ion-label>
                    <ion-input type="text" formControlName="phone" placeholder="{{'labels.insert' | translate}}">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- roles -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none" (click)="selectableTreePopover('role')">
                    <ion-label position="stacked">{{'labels.roles' | translate}} * ({{roleIdList.length}})</ion-label>
                    <button class="select-roles">{{'buttons.select' |
                      translate}}</button>
                  </ion-item>
                </ion-col>
                <!-- language -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="isEnvironmentImma">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">Lingua *</ion-label>
                    <ion-select formControlName="language" placeholder="{{'labels.select' | translate}}">
                      <ion-select-option value="it" selected>Italiano
                      </ion-select-option>
                      <ion-select-option value="en">English
                      </ion-select-option>
                      <ion-select-option value="en-US">American
                      </ion-select-option>
                      <ion-select-option value="fr">Français
                      </ion-select-option>
                      <ion-select-option value="de">Deutsche
                      </ion-select-option>
                      <ion-select-option value="at">Austrian
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>
                <!-- enabled -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.enabled' | translate}}</ion-label>
                    <ion-checkbox mode="md" type="checkbox" formControlName="enabled">
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
                <!-- owner (read/label) -->
                <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.denominazioneProprietario != null">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.owner' | translate}}</ion-label>
                    <ion-input type="text" [readonly]="true">{{modalNavParams.denominazioneProprietario != null ?
                      modalNavParams.denominazioneProprietario : '-'}}</ion-input>
                  </ion-item>
                </ion-col>
                <!-- owner (change/checkbox/simplified) - module permission required -->
                <ion-col size-md="4" size-sm="4" size-xs="12"
                  *ngIf="!hideSection('amministrazione.gestione_utenti.set_owner_simplified')">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.ownerSelect' | translate}}</ion-label>
                    <ion-select formControlName="currentTerritoryIsOwner" placeholder="{{'labels.select' | translate}}"
                      [disabled]="modalNavParams.proprietarioModificabile != null && !modalNavParams.proprietarioModificabile">
                      <ion-select-option value="0" selected>{{'labels.ownerSelectOpt1' | translate}}
                      </ion-select-option>
                      <ion-select-option value="1">{{'labels.ownerSelectOpt2' | translate}}
                      </ion-select-option>
                      <ion-select-option value="2">{{'labels.ownerSelectOpt3' | translate}}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>
                <!-- owner (change/treeview/advanced) - module permission required -->
                <ion-col size-md="4" size-sm="4" size-xs="12"
                  *ngIf="!hideSection('amministrazione.gestione_utenti.set_owner')">
                  <ion-item class="input-field" lines="none" (click)="selectableTreePopover('customer')">
                    <ion-label position="stacked">{{'labels.ownerSelect' | translate}} ({{modalNavParams.idProprietario
                      !=
                      null ? 1 : 0}})</ion-label>
                    <button class="select-customers" >{{'buttons.select' |
                      translate}}</button>
                  </ion-item>
                </ion-col>
                <!-- id -->
                <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams && modalNavParams.userId">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.id' | translate}}</ion-label>
                    <ion-input type="text" formControlName="id" placeholder="{{'labels.insert' | translate}}"
                      [readonly]="true">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- temp pass -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!isEnvironmentImma">
                  <ion-item class="input-field flex-end">
                    <ion-label position="stacked"><span *ngIf="!showCopy">{{'labels.temp_password' | translate}}<span
                          *ngIf="!modalNavParams.userId">*</span></span><span
                        *ngIf="showCopy">{{'labels.copied_to_clipboard' | translate}}</span>
                    </ion-label>
                    <ion-input class="cursor-pointer" type="text" formControlName="tempPass" placeholder=""
                      [readonly]="true">
                    </ion-input>
                    <ion-icon slot="end" name="close" class="cursor-pointer copy-icon"
                      (click)="userDetailForm.get('tempPass').setValue(null)"
                      *ngIf="modalNavParams.userId && userDetailForm.get('tempPass').value"></ion-icon>
                    <ion-icon slot="end" name="refresh" class="cursor-pointer copy-icon"
                      (click)="refreshTempPassAlert()" title="{{'labels.refresh_temp_password' | translate}}"
                      *ngIf="modalNavParams.userId"></ion-icon>
                    <ion-icon slot="end" name="clipboard" class="cursor-pointer copy-icon"
                      [cdkCopyToClipboard]="userDetailForm.get('tempPass').value" (click)="copyTmpPass()"
                      title="{{'labels.copy_to_clipboard' | translate}}" *ngIf="userDetailForm.get('tempPass').value">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </mat-tab>
        <mat-tab label="{{'labels.configuration' | translate}}"
          [disabled]="userCredentials && userCredentials.length == 0">
          <app-service-access-configuration [configurationType]="'user'" [servicesList]="userCredentials">
          </app-service-access-configuration>
        </mat-tab>
      </mat-tab-group>
      <ion-row>
        <ion-col size-md="3" size-sm="3" size-xs="12">
          <ion-item class="box-required" lines="none">
            <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' | translate}}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size-md="9" size-sm="9" size-xs="12" class="col-button-box">
            <div class="button-box">
              <ion-button color="warning" size="default" (click)="impersonateUserAlert()"
                [hidden]="!modalNavParams.userId" *ngIf="!hideSection('amministrazione.gestione_utenti.impersonate')"
                [disabled]="!canBeImpersonated()">
                <ion-label>{{'buttons.impersonate_user' | translate}}</ion-label>
              </ion-button>
              <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
                <ion-label>{{'buttons.cancel' | translate}}</ion-label>
              </ion-button>
              <ion-button color="danger" size="default" (click)="deleteUserAlert();" [hidden]="!modalNavParams.userId">
                <ion-label>{{'buttons.delete_user' | translate}}</ion-label>
              </ion-button>
              <ion-button color="success" size="default" (click)="saveUser(userDetailForm.valid)"
                [disabled]="!userDetailForm.valid || (rolesList?.length == 0)">
                <ion-label>{{'buttons.save' | translate}}</ion-label>
              </ion-button>
            </div>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>