<ion-header>
  <ion-toolbar>
    <div class="modal-header">
      <div class="modal-title">
        {{modalNavParams.title}}
      </div>
      <div class="modal-icon">
        <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content  (mouseleave)="onMouseLeave()">
  <div class="message" *ngIf="modalNavParams && modalNavParams.message">
    {{modalNavParams.message}}
  </div>
  <div class="tree-view">
    <app-infinite-tree *ngFor="let item of modalNavParams.tree" [item]="item" [titleKey]="modalNavParams.titleKey"
      [childrenKey]="modalNavParams.childrenKey" [descriptionKey]="modalNavParams.descriptionKey" [hideSelector]="true"
      [hideCheckboxRole]="modalNavParams.hideCheckboxRole" [hideCheckboxParent]="modalNavParams.hideCheckboxParent"
      [showSeletAllRoles]="!modalNavParams.hideCheckboxRole && !modalNavParams.showSeletAllRoles" [showRoleDetail]="modalNavParams.showRoleDetail"
      [showRolesIndicator]="modalNavParams.showRolesIndicator" [allOpened]="modalNavParams.allOpened"
      [disableTerritoryCheckbox]="modalNavParams.disableTerritoryCheckbox"
      [disableRoleCheckbox]="modalNavParams.disableRoleCheckbox" [singleRole]="modalNavParams.singleRole"
      [hideTerritoryCount]="modalNavParams.hideTerritoryCount" (itemSelect)="onItemSelect($event)">
    </app-infinite-tree>
  </div>
</ion-content>
<ion-footer>
  <div class="button-box">
    <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
      <ion-label>{{'buttons.cancel' | translate}}</ion-label>
    </ion-button>
    <!--
    <ion-button color="danger" size="default" *ngIf="!canSelectAll_flag" (click)="deselectAll(modalNavParams.tree)">
      <ion-label>{{'buttons.deselect_all' | translate}}</ion-label>
    </ion-button>
    <ion-button color="primary" size="default" *ngIf="canSelectAll_flag" (click)="selectAll(modalNavParams.tree)">
      <ion-label>{{'buttons.select_all' | translate}}</ion-label>
    </ion-button>
  -->
    <ion-button color="primary" size="default" (click)="select()" [disabled]="modalNavParams.readOnly">
      <ion-label>{{'buttons.select' | translate}}</ion-label>
    </ion-button>
  </div>
</ion-footer>