<!-- item -->
<div class="modules-item">
  <!-- item data -->
  <div class="modules-item-data" [ngClass]="{'opened' : !item['opened']}">
    <!-- item title -->
    <div class="modules-item-title">
      <div class="modules-item-checkbox">
        <input type="checkbox" [(ngModel)]="item.abilitato" [disabled]="!!item.attivazioneForzata || !!checkboxDisabled"
          (click)="$event.stopPropagation();handleCheckboxParent(!item.abilitato, item.id, item.idPadre);">
      </div>
      <div class="modules-item-name" (click)="item['opened'] = !item['opened']">
        {{item.nome}}&nbsp;<span>({{'modules.'+item.idtipoModulo | translate}})</span>
      </div>
      <ion-icon class="modules-item-indicator" name="chevron-forward-outline" color="danger"
        *ngIf="item.figli && item.figli.length > 0" (click)="item['opened'] = !item['opened']"></ion-icon>
    </div>
  </div>
  <!-- item to repeat -->
  <div class="child" *ngIf="item.figli" [hidden]="item['opened']">
    <div *ngIf="item.figli.length > 0">
      <div *ngFor="let child of item.figli">
        <app-modules-tree [item]="child" (handleCheckbox)="handleCheckboxChild($event)"
          [checkboxDisabled]="checkboxDisabled" class="left-pipe">
        </app-modules-tree>
      </div>
    </div>
  </div>
</div>