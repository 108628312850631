<ion-header>
  <div class="pssfe-dropdown-prompt-search">
    <div><ion-spinner name="lines-small" [hidden]="!searchLoading"></ion-spinner>
      <input #searchInput type="text" placeholder="{{searchPlaceholder}}" [(ngModel)]="searchValue">
    </div>
    <ion-icon name="search" class="cursor-pointer" (click)="search()"></ion-icon>
  </div>
</ion-header>
<ion-content>
  <div class="pssfe-dropdown-prompt-list">
    <div class="pssfe-dropdown-prompt-list-item" *ngFor="let option of filteredOptionsList"
      [ngClass]="{'pssfe-dropdown-prompt-list-item-selected': isOptionSelected(option)}">
      <div class="pssfe-dropdown-prompt-list-item-selector" (click)="optionSelected(option)"
        [ngClass]="{'square': multiple}"></div>
      <ion-icon [name]="option.icon" *ngIf="option.icon"
        [color]="option.iconColor ? option.iconColor : 'primary'"></ion-icon>
      <span>{{optionText
        ? (option[optionText] | translate) : (option | translate) }}</span>
    </div>
    <div class="pssfe-dropdown-prompt-list-item-noresult"
      [hidden]="filteredOptionsList.length != 0 || searchLoading || !searchDone">
      {{noResultText}}
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="pssfe-dropdown-prompt-buttons">
    <ion-button size="small" color="tertiary" (click)="close()"><ion-icon name="close"></ion-icon></ion-button>
    <ion-button size="small" color="warning" [hidden]="!canClean" (click)="clean()"><ion-icon
        name="refresh"></ion-icon></ion-button>
    <ion-button size="small" color="primary" (click)="saveMultiple()"><ion-icon
        name="checkmark"></ion-icon></ion-button>
  </div>
</ion-footer>