<ion-header [hidden]="showRegistrationSuccess">
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'viewAndFine.labels.entityRegistration' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="vaf-entity-registration">
  <ion-card class="modal-page-card" [hidden]="showRegistrationSuccess">
    <ion-card-content>
      <div class="registration-message">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam, dui quis finibus pulvinar, sem dui
        imperdiet tellus, id semper sapien urna quis leo. Phasellus porta turpis dignissim nulla euismod blandit.
      </div>
      <div class="check-fiscal-code" [hidden]="fiscalCodeCheck">
        <ion-row>
          <ion-col size-md="6" size-sm="6" size-xs="12" class="col-button-box">
            <ion-item class="input-field" lines="none">
              <ion-label position="stacked">{{'viewAndFine.labels.fiscalCode' | translate}}</ion-label>
              <ion-input type="text" [(ngModel)]="fiscalCode" placeholder="{{'viewAndFine.labels.insert' | translate}}">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-sm="6" size-xs="12" class="col-button-box">
            <div class="button-box">
              <ion-button color="primary" size="default" (click)="checkAuthorityExistence()" [disabled]="!fiscalCode">
                <ion-label>{{'viewAndFine.buttons.verify' | translate}}</ion-label>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <div class="registration-form-wrapper" [hidden]="!fiscalCodeCheck">
        <app-view-and-fine-registration-form [fromExternalRegistration]="true" [countriesOptions]="countriesOptions"
          #entityRegistrationForm></app-view-and-fine-registration-form>
      </div>
    </ion-card-content>
  </ion-card>
  <div class="success-registration-box" [hidden]="!showRegistrationSuccess">
    <div class="success-registration-box-top">
      <div class="success-registration-box-top-inner">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <span>{{'viewAndFine.labels.registrationSuccess' | translate}}</span>
      </div>
    </div>
    <div class="success-registration-box-bottom">
      <div class="success-registration-box-bottom-inner">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam, dui quis finibus pulvinar, sem dui
          imperdiet tellus, id semper sapien urna quis leo.
        </span>
        <ion-button color="success" size="large" (click)="modalCtrl.dismiss({action: 'goToLogin'})">
          <ion-label>{{'viewAndFine.buttons.goLogin' | translate}}</ion-label>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer [hidden]="showRegistrationSuccess">
  <div class="button-box">
    <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
      <ion-label>{{'viewAndFine.buttons.back' | translate}}</ion-label>
    </ion-button>
    <ion-button color="primary" size="default" (click)="createAuthority()" [hidden]="!fiscalCodeCheck"
      [disabled]="!entityRegistrationForm.authorityForm.valid || !entityRegistrationForm.referentForm.valid || !entityRegistrationForm.isIssuingAuthoritiesValid()">
      <ion-label>{{'viewAndFine.buttons.register' | translate}}</ion-label>
    </ion-button>
  </div>
</ion-footer>