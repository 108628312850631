import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { MessageListService } from 'src/app/services/message-list.service';

@Component({
  selector: 'app-view-and-fine-registration-form',
  templateUrl: './view-and-fine-registration-form.component.html',
  styleUrls: ['./view-and-fine-registration-form.component.scss'],
})
export class ViewAndFineRegistrationFormComponent implements OnInit, OnChanges {
  //register form
  public authorityForm: FormGroup;
  public contractForm: FormGroup;
  public referentForm: FormGroup;
  public issuingAuthorities: Array<any> = [
    {
      description: null,
      code: null,
      errore: null,
      serviceTypeId: null,
      enteManagerMulteId: null
    }
  ];
  //registration object
  @Input() registrationObject;
  //layout
  @Input() readonly = false;
  @Input() hideHeaderCheckbox = false;
  @Input() fromExternalRegistration = false;
  @Input() fromAuthorityDetail = false;
  @Input() fromRegistrationDetail = false;
  @Input() editPostITApprove = false;
  //lists
  @Input() countriesOptions: Array<any> = [];
  @Input() enabledChannelsOptions: Array<any> = [];
  @Input() issuingAuthoritiesServiceTypesOptions: Array<any> = [];
  @Input() contractStatusIdOptions: Array<any> = [];
  //issuingAuthorities first check
  public issuingAuthoritiesFirstCheck: boolean = true;
  //sameAuthorityData
  public sameAuthorityData: boolean = false;
  //contractSuspended
  public contractSuspended: boolean = false;

  constructor(
    public translate: TranslateService,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public alertSrv: AlertService,
    public signalRSrv: SignalRService,
    public messageListSrv: MessageListService) {
    //setup validator
    this.authorityForm = this.formBuilder.group({
      name: [null],
      fiscalCode: [null],
      country: [null],
      dateOfInsert: [null],
      address: [null],
      place: [null],
      city: [null],
      province: [null],
      zipCode: [null],
      code: [null],
      enabledChannels: [null]
    });
    this.contractForm = this.formBuilder.group({
      completionDate: [null],
      completionDateBO: [null],
      contractDate: [null],
      contractId: [null],
      contractNumber: [null],
      contractStart: [null],
      contractEnd: [null],
      contractStatusDescription: [null],
      contractStatusId: [null],
      dateOfInsert: [null],
      inChargeDateBO: [null],
      notes: [null],
      contractSigned: [null],
      reasonRejection: [null],
      userBO: [null],
      userIT: [null]
    });
    this.referentForm = this.formBuilder.group({
      referentName: [null],
      referentSurname: [null],
      referentEmail: [null],
      referentPhone: [null]
    });
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly && changes.readonly.currentValue) {
      this.readonly = changes.readonly.currentValue;
    }
    if (changes.hideHeaderCheckbox && changes.hideHeaderCheckbox.currentValue) {
      this.hideHeaderCheckbox = changes.hideHeaderCheckbox.currentValue;
    }
    if (changes.fromExternalRegistration && changes.fromExternalRegistration.currentValue) {
      this.fromExternalRegistration = changes.fromExternalRegistration.currentValue;
    }
    if (changes.countriesOptions && changes.countriesOptions.currentValue) {
      this.countriesOptions = changes.countriesOptions.currentValue;
    }
    if (changes.registrationObject && changes.registrationObject.currentValue) {
      this.registrationObject = changes.registrationObject.currentValue;
    }
    if (changes.enabledChannelsOptions && changes.enabledChannelsOptions.currentValue) {
      this.enabledChannelsOptions = changes.enabledChannelsOptions.currentValue;
    }
    if (changes.fromAuthorityDetail && changes.fromAuthorityDetail.currentValue) {
      this.fromAuthorityDetail = changes.fromAuthorityDetail.currentValue;
    }
    if (changes.fromRegistrationDetail && changes.fromRegistrationDetail.currentValue) {
      this.fromRegistrationDetail = changes.fromRegistrationDetail.currentValue;
    }
    if (changes.issuingAuthoritiesServiceTypesOptions && changes.issuingAuthoritiesServiceTypesOptions.currentValue) {
      this.issuingAuthoritiesServiceTypesOptions = changes.issuingAuthoritiesServiceTypesOptions.currentValue;
    }
    if (changes.contractStatusIdOptions && changes.contractStatusIdOptions.currentValue) {
      this.contractStatusIdOptions = changes.contractStatusIdOptions.currentValue;
    }
    if (changes.editPostITApprove) {
      this.editPostITApprove = changes.editPostITApprove.currentValue;
    }
    //update form format 
    if ((this.fromExternalRegistration || this.registrationObject) && this.countriesOptions && this.countriesOptions.length > 0 && ((!this.fromAuthorityDetail && !this.fromRegistrationDetail) || (this.enabledChannelsOptions && this.enabledChannelsOptions.length > 0 && this.issuingAuthoritiesServiceTypesOptions && this.issuingAuthoritiesServiceTypesOptions.length > 0
      && this.contractStatusIdOptions && this.contractStatusIdOptions.length > 0))) {
      if (this.registrationObject) {
        let registrationObjectClone = JSON.parse(JSON.stringify(this.registrationObject)), idList: Array<number> = [];
        if (registrationObjectClone.country)
          registrationObjectClone.country = registrationObjectClone.country.id;
        if (registrationObjectClone.enabledChannels) {
          idList = [];
          registrationObjectClone.enabledChannels.forEach((item) => {
            idList.push(item.id)
          })
          registrationObjectClone.enabledChannels = JSON.parse(JSON.stringify(idList));
        }
        if (registrationObjectClone.dateOfInsert)
          registrationObjectClone.dateOfInsert = registrationObjectClone.dateOfInsert.substring(0, 10);
        if (registrationObjectClone.issuingAuthorities)
          this.issuingAuthorities = registrationObjectClone.issuingAuthorities;
        if (registrationObjectClone.contracts && registrationObjectClone.contracts[0]) {
          registrationObjectClone.contracts[0]["contractSigned"] = registrationObjectClone.contracts[0].contractSigned == null || registrationObjectClone.contracts[0].contractSigned == undefined ? null :
            registrationObjectClone.contracts[0].contractSigned === true ? 2 : 3;
          registrationObjectClone.contracts[0]["userBO"] = registrationObjectClone.contracts[0].userBO && registrationObjectClone.contracts[0].userBO.nominative ? registrationObjectClone.contracts[0].userBO.nominative : null;
          registrationObjectClone.contracts[0]["userIT"] = registrationObjectClone.contracts[0].userIT && registrationObjectClone.contracts[0].userIT.nominative ? registrationObjectClone.contracts[0].userIT.nominative : null;

        }
        this.updateForm(registrationObjectClone, this.readonly);
      } else {
        this.updateForm(null, this.readonly);
      }
    }
  }

  //update form
  private updateForm(registrationObject, readonly) {
    let authorityRequiredProperties: Array<string> = [], referentRequiredProperties: Array<string> = [], contractRequiredProperties: Array<string> = [];
    if (this.fromExternalRegistration) {
      authorityRequiredProperties = ["name", "fiscalCode", "country"];
      referentRequiredProperties = ["referentName", "referentSurname", "referentEmail"];
    }
    if (this.fromRegistrationDetail) {
      authorityRequiredProperties = ["name", "fiscalCode", "country", "code"];
      referentRequiredProperties = ["referentName", "referentSurname", "referentEmail"];
      contractRequiredProperties = ["contractSigned"]
    }
    for (let property in this.authorityForm.controls) {
      if (registrationObject)
        this.authorityForm.get(property).setValue(registrationObject[property]);
      if (!readonly && authorityRequiredProperties.includes(property))
        this.authorityForm.controls[property].setValidators(Validators.compose([Validators.required]));
      this.authorityForm.get(property).updateValueAndValidity();
    }
    for (let property in this.referentForm.controls) {
      if (registrationObject)
        this.referentForm.get(property).setValue(registrationObject[property]);
      if (!readonly && referentRequiredProperties.includes(property))
        this.referentForm.controls[property].setValidators(Validators.compose([Validators.required]));
      this.referentForm.get(property).updateValueAndValidity();
    }
    if (registrationObject && registrationObject.contracts && registrationObject.contracts[0]) {
      for (let property in this.contractForm.controls) {
        this.contractForm.get(property).setValue(registrationObject.contracts[0][property]);
        if (!readonly && contractRequiredProperties.includes(property)) {
          this.contractForm.controls[property].setValidators(Validators.compose([Validators.required]));
        }
        this.contractForm.get(property).updateValueAndValidity();
      }
      this.contractSuspended = registrationObject.contracts[0].contractSuspended ? true : false;
    }
    if (!readonly && this.fromRegistrationDetail) {
      this.authorityForm.markAllAsTouched();
      this.referentForm.markAllAsTouched();
      this.contractForm.markAllAsTouched();
      this.checkIssuingAuthoritiesValid();
    }
  }

  //addIssuingAuthority
  public addIssuingAuthority() {
    this.issuingAuthorities.push({
      description: null,
      code: null,
      errore: null
    })
  }

  //is authorities valid
  public isIssuingAuthoritiesValid() {
    let valid: boolean = true;
    if (this.issuingAuthorities && this.issuingAuthorities.length > 0) {
      this.issuingAuthorities.forEach((ente) => {
        if (!this.isValidString(ente.description) || !this.isValidString(ente.code)) {
          valid = false;
        }
        if (this.fromRegistrationDetail && !this.isValidString(ente.enteManagerMulteId)) {
          valid = false;
        }
      })
    } else
      valid = false;
    return valid
  }

  //check issuing authorities valid
  public checkIssuingAuthoritiesValid() {
    if (this.issuingAuthorities && this.issuingAuthorities.length > 0) {
      this.issuingAuthorities.forEach((ente) => {
        if (!this.isValidString(ente.description) || !this.isValidString(ente.code)) {
          ente.errore = true;
        }
        else if (this.fromRegistrationDetail && !this.isValidString(ente.enteManagerMulteId)) {
          ente.errore = true;
        }
        else {
          ente.errore = false;
        }
      })
    }
  }

  //deleteEntity
  public deleteEntity(entityIndex) {
    this.issuingAuthorities.splice(entityIndex, 1);
  }

  //is field required (for DOM)
  public isFieldRequired(form, field) {
    const form_field = form.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  //is valid string
  private isValidString(string) {
    return string != null && string != undefined && string != "";
  }

  //sameAuthorityDataChanged
  public sameAuthorityDataChanged() {
    if (this.sameAuthorityData && this.isValidString(this.authorityForm.get("name").value) && this.isValidString(this.authorityForm.get("fiscalCode").value)) {
      this.issuingAuthorities = [{
        description: this.authorityForm.get("name").value,
        code: this.authorityForm.get("fiscalCode").value,
        errore: null
      }]
      this.checkIssuingAuthoritiesValid();
    } else {
      this.issuingAuthorities = [{
        description: null,
        code: null,
        errore: null
      }]
    }
  }
}
