import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], condition: string): any {
        if (!items || !condition || condition == "" || !Array.isArray(items)) {
            return items;
        }

        condition = condition.replace(/#/g, "item.")
        return items.filter((item) => {
            return eval(condition);
        });
    }
}

