import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { PostitBoardLinkService } from './postit-board-link.service';

@Injectable({
  providedIn: 'root'
})
export class BoardMainService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient, public translate: TranslateService, public linkSrv: PostitBoardLinkService) { }

  //attivita
  public attivita() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}attivita/list`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //gruppi
  public gruppi() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}gruppo/list`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //board
  public board(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}board/getBoard?DataInizio=${dateFrom}&DataFine=${dateTo}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //listaTipoAttivita
  public listaTipoAttivita() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}board/ListaTipoAttivita`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //generic get
  public genericGet(endpoint) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}${endpoint}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //uploadPhoto
  public uploadPhoto(form) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.linkSrv.links.server}board/UploadPhoto`, form).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }


  //confirm
  public confirm(codiceAcquisizione, dataMin, dataMax) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}board/Confirm?codiceAcquisizione=${codiceAcquisizione}&dataMin=${dataMin}&dataMax=${dataMax}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getDettaglioGruppo
  public getDettaglioGruppo(idGruppo) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}gruppo/GetDettaglioGruppo?idGruppo=${idGruppo}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
