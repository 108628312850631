import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoginViewAndFineComponent } from './login-view-and-fine.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteTreeComponentModule } from '../../../../app/components/infinite-tree/infinite-tree.component.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { ViewAndFineRegistrationFormComponentModule } from '../view-and-fine-registration-form/view-and-fine-registration-form.component.module';
import { MatTabsModule } from '@angular/material/tabs';
//import { NgParticlesModule } from "ng-particles";
@NgModule({
  declarations: [LoginViewAndFineComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    InfiniteTreeComponentModule,
    MatExpansionModule,
    ViewAndFineRegistrationFormComponentModule,
    MatTabsModule//,
   //NgParticlesModule
  ],
  exports: [
    LoginViewAndFineComponent
  ]
})
export class LoginViewAndFineComponentModule { }
