<!-- messages -->
<div class="message-container" [hidden]="!showMessages">
  <!-- header -->
  <div class="modal-header">
    <div class="modal-title" *ngIf="currentView == 'messages'">
      <div class="slider-buttons"
        [hidden]="messageLoading || attachmentLoading || !messageList || messageList.length == 0">
        <div class="slide-index">
          {{'labels.notification' | translate}}&nbsp;{{sliderIndex}}&nbsp;{{'labels.of' |
          translate}}&nbsp;{{listTotalResult}}
        </div>
        <div class="arrow-left" (click)="prev()" *ngIf="messageList && listTotalResult > 1">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <div class="arrow-right" (click)="next()" *ngIf="messageList && listTotalResult > 1">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    </div>
    <div class="modal-title" *ngIf="currentView == 'list'">
      <div class="slider-buttons">
        <div class="slide-index">
          {{'labels.notifications' | translate}}<span
            *ngIf="messageList && messageList.length > 0 && listTotalResult">&nbsp;{{messageList.length}}&nbsp;{{'labels.of'
            |
            translate}}&nbsp;{{listTotalResult}}</span>
        </div>
      </div>
    </div>
    <div class="inline-flex-center-center">
      <div class="only-not-read">
        <span class="left-span">{{'labels.all_f' | translate}}</span>
        <label class="only-not-read-toggle">
          <input type="checkbox" [(ngModel)]="onlyNotRead" (ngModelChange)="messagesList(false)">
          <span class="slider round"></span>
        </label>
        <span class="right-span">{{'labels.not_read_f' | translate}}</span>
      </div>

      <ion-icon name="list" (click)="switchView('list', sliderIndex-1)" class="close-messages"
        *ngIf="currentView == 'messages'">
      </ion-icon>
      <ion-icon name="notifications" (click)="switchView('messages', sliderIndex-1)" class="not-icon"
        *ngIf="currentView == 'list'">
      </ion-icon>
      <ion-icon name="close-outline" (click)="closeMessages()" class="close-messages"></ion-icon>
    </div>
  </div>
  <!-- no messages -->
  <div class="p-5px" *ngIf="messageList && messageList.length == 0 && currentView == 'messages'">
    {{'labels.no_messages' | translate}}
  </div>
  <!-- messages -->
  <div class="message-box" [hidden]="currentView != 'messages'">
    <div class="paginator-loading" [hidden]="!messageLoading">
      <ion-spinner name="lines-small" *ngIf="messageLoading"></ion-spinner>
    </div>
    <swiper-container pager="false" #slides class="slides-container" *ngIf="messageList && messageList.length > 0"
      [hidden]="messageLoading" (ionSlideDidChange)="slideChanged()" [modules]="swiperModules">
      <swiper-slide *ngFor="let item of messageList">
        <div class="slide-container">
          <!-- toast -->
          <div class="new-messages-toast" [ngClass]="{'new-messages-toast-opened': showNewMessagesToast}">
            <span>
              <ion-icon name="mail-unread-outline" class="mr-5px"></ion-icon>{{'labels.new_messages_count' |
              translate}}
            </span><button class="update-button" (click)="messagesList(false)">{{'buttons.update' | translate}}</button>
          </div>
          <div class="message-data" [ngClass]="{'message-data-toast-opened': showNewMessagesToast}">
            <div class="object" [ngClass]="{'item-unread' : !item.read}">
              <span>{{item.oggetto}}</span>
              <ion-icon class="object-icon" name="eye" *ngIf="!item.read && item.status && item.status != 3"></ion-icon>
              <ion-icon class="object-icon" name="eye" *ngIf="item.read && item.status && item.status != 3"></ion-icon>
              <ion-icon class="object-icon" name="close" *ngIf="item.status && item.status == 3"
                class="deleted-message-icon"></ion-icon>
            </div>
            <div class="nameDate">
              <span class="name">{{'labels.by' | translate}}:&nbsp;{{item.mittente}}</span>
              <span class="date">{{item.dataInvio | date:'dd/MM/yyyy HH:mm'}}</span>
            </div>
            <div class="attachment" *ngIf="!attachmentLoading && currentAttachment && currentAttachment.attachmentBody">
              <a [href]="currentAttachment.attachmentBody" download="{{currentAttachment.nomeFile}}">
                <div class="attachment-icon">
                  <span>{{'labels.attached_file' | translate}}:&nbsp;</span>
                  <img src="assets/icon/file.png"
                    *ngIf="currentAttachment.estensione != 'png' && currentAttachment.estensione != 'jpg' && currentAttachment.estensione != 'jpeg' && currentAttachment.estensione != 'pdf'">
                  <img src="assets/icon/image.png"
                    *ngIf="currentAttachment.estensione == 'png' || currentAttachment.estensione == 'jpg' || currentAttachment.estensione == 'jpeg'">
                  <img src="assets/icon/pdf.png" *ngIf="currentAttachment.estensione == 'pdf'">
                </div>
                <div class="attachment-name">
                  {{currentAttachment.nomeFile}}
                </div>
              </a>
            </div>
            <div class="paginator-loading" [hidden]="!attachmentLoading">
              <ion-spinner name="lines-small"></ion-spinner>
            </div>
            <hr>
            <div class="message" [innerHtml]="item.body" *ngIf="item.status && item.status != 3">
            </div>
            <div class="deleted-message" *ngIf="item.status && item.status == 3">
              <ion-icon name="warning" class="mr-5px"></ion-icon>
              <div class="inline-flex-center-center">{{'messages.deleted_message' | translate}}</div>
            </div>
          </div>
          <div class="footer-buttons" [ngClass]="{'hidden': attachmentLoading}">
            <div class="flex-center-spacebetween">
              <div class="read-all" (click)="readAll()" *ngIf="!item.read && item.status && item.status != 3">
                {{'labels.read_all' | translate}}</div>
              <ion-button color="success" size="default" (click)="presaVisioneMessaggio(item.id)"
                *ngIf="!item.read && item.status && item.status != 3">
                <ion-label>{{'buttons.take_vision' | translate}}</ion-label>
              </ion-button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
  <!-- list -->
  <div class="list" [hidden]="currentView != 'list'">
    <!-- toast -->
    <div class="new-messages-toast-list" [ngClass]="{'new-messages-toast-list-opened': showNewMessagesToast}">
      <span>
        <ion-icon name="mail-unread-outline" class="mr-5px"></ion-icon>{{'labels.new_messages_count' |
        translate}}
      </span><button class="update-button" (click)="messagesList(false)">{{'buttons.update' | translate}}</button>
    </div>
    <!-- loader -->
    <div class="paginator-loading" [hidden]="!messageLoading">
      <ion-spinner name="lines-small"></ion-spinner>
    </div>
    <!-- list container-->
    <div class="list-container" *ngIf="messageList && messageList.length > 0" [hidden]="messageLoading"
      [ngClass]="{'list-toast-opened': showNewMessagesToast}">
      <div class="list-item" *ngFor="let item of messageList; let i = index" [ngClass]="{'item-unread' : !item.read}"
        (click)="switchView('messages', i)" id="item-{{i}}">
        <div class="flex-center-start">
          <div class="list-item-object">
            <span>{{item.oggetto}}</span>
          </div>
          <div class="list-item-status">
            <ion-icon name="eye" *ngIf="!item.read && item.status && item.status != 3"></ion-icon>
            <ion-icon name="eye" *ngIf="item.read && item.status && item.status != 3"></ion-icon>
            <ion-icon name="close" *ngIf="item.status && item.status == 3" class="deleted-message-icon"></ion-icon>
          </div>
        </div>
        <div class="flex-center-start">
          <div class="list-item-sender">
            <span>
              {{'labels.by' | translate}}:&nbsp;{{item.mittente}}
            </span>
          </div>
          <div class="list-item-date">
            <span>
              {{item.dataInvio | date:'dd/MM/yyyy HH:mm'}}
            </span>
          </div>
        </div>
      </div>
      <div class="load-more">
        <ion-spinner name="lines-small" *ngIf="loadMoreLoading"></ion-spinner>
        <ion-button color="primary" size="default" *ngIf="!loadMoreLoading" (click)="loadMore()"
          [disabled]="!messageList || !listTotalResult || !(messageList.length < listTotalResult)">
          <ion-label>{{'buttons.load_more' | translate}}</ion-label>
        </ion-button>
      </div>
    </div>
    <!-- no messages -->
    <div class="p-5px" *ngIf="messageList && messageList.length == 0" [hidden]="messageLoading">
      {{'labels.no_messages' | translate}}
    </div>
  </div>
</div>