import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/http-interceptor.service'
import { ResponseInterceptor } from './interceptors/http-interceptor-response.service'
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './services/matPaginatorIntl';
import { ToastrModule } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { MatNativeDateModule } from '@angular/material/core';

//shared-component
import { TablePopupInputComponentModule } from './shared-components/table/table-popup-input/table-popup-input.component.module';
import { TableTdComponentModule } from './shared-components/table/table-td/table-td.component.module';
import { TableComponentModule } from './shared-components/table/table.component.module';
import { TableExtractionComponentModule } from './shared-components/table-extraction/table-extraction.component.module';
import { TableSettingsComponentModule } from './shared-components/table-settings/table-settings.component.module';
import { TableDecimalePipeModule } from './shared-components/table/pipes/table-decimale/table-decimale.pipe.module';
import { TableNumeroPipeModule } from './shared-components/table/pipes/table-numero/table-numero.pipe.module';
import { CustomReportsComponentModule } from './shared-components/custom-reports/custom-reports.component.module';
import { CustomReportComboEditComponentModule } from './shared-components/custom-reports/custom-report-combo-edit/custom-report-combo-edit.component.module';
import { CustomReportConfigurationComponentModule } from './shared-components/custom-reports/custom-report-configuration/custom-report-configuration.component.module';
import { GaugeComponentModule } from './shared-components/gauge/gauge.component.module';
import { PssfeDropdownComponentModule } from './shared-components/pssfe-dropdown/pssfe-dropdown.component.module';
import { PssfeDropdownPromptComponentModule } from './shared-components/pssfe-dropdown/pssfe-dropdown-prompt/pssfe-dropdown-prompt.component.module';
import { PssfeSelectableComponentModule } from './shared-components/pssfe-selectable/pssfe-selectable.component.module';
import { PssfeSelectablePromptComponentModule } from './shared-components/pssfe-selectable/pssffe-selectable-prompt/pssfe-selectable-prompt.component.module';
import { SearchStateHistoryPromptModule } from './shared-components/search-state-history-prompt/search-state-history-prompt.component.module';

//pipes
import { DateFormatPipeModule } from './pipes/date-format/date-format.pipe.module';
import { DateFormatPlaceholderPipeModule } from './pipes/date-format-placeholder-pipe/date-format-placeholder.pipe.module';
import { TranslateMenuPipeModule } from './pipes/translate-menu-pipe/translate-menu.pipe.module';
import { CustomDatePipeModule } from './pipes/custom-date-pipe/custom-date.pipe.module';
import { FilterPipeModule } from './pipes/filter-pipe/filter.pipe.module';
import { GroupByPipeModule } from './pipes/group-by-pipe/group-by.pipe.module';
import { OrderByPipeModule } from './pipes/order-by-pipe/order-by.pipe.module';
import { SafePipeModule } from './pipes/safe-pipe/safe.pipe.module';

//components
import { SelectableTreePopoverComponentModule } from './modals_popsover/selectable-tree-popover/selectable-tree-popover.component.module';
import { SelectableModulesModule } from './modals_popsover/selectable-modules/selectable-modules.component.module';
import { ServiceAccessConfigurationTemplateComponentModule } from './modals_popsover/service-access-configuration-template/service-access-configuration-template.component.module';
import { AdminRoleDetailPopoverComponentModule } from './modals_popsover/admin-role-detail-popover/admin-role-detail-popover.component.module';
import { PinPopoverComponentModule } from './modals_popsover/pin-popover/pin-popover.component.module';
import { NewsDetailPopoverComponentModule } from './modals_popsover/news-detail-popover/news-detail-popover.component.module';
import { HelpComponentModule } from './components/help/help.component.module';
import { InfiniteTreeComponentModule } from './components/infinite-tree/infinite-tree.component.module';
import { LoginServicesComponentModule } from './components/landing-pages/login-services-component/login-services.component.module';
import { ModulesTreeComponentModule } from './components/modules-tree/modules-tree.component.modules';
import { ServiceAccessConfigurationComponentModule } from './components/service-access-configuration/service-access-configuration.component.module';
import { RightMenuPopoverComponentModule } from './modals_popsover/right-menu-popover/right-menu-popover.component.module';
import { MessagesBoxComponentModule } from './components/messages-box/messages-box.component.module';
import { FooterComponentModule } from './components/footer/footer.component.module';
import { MenuTreeComponentModule } from './components/menu-tree/menu-tree.component.modules';
import { PendingRequestButtonComponentModule } from './components/pending-request-button/pending-request-button.component.module';
import { TerritoryTreeComponentModule } from './components/territory-tree/territory-tree.component.module';
import { AdminTerritoryDetailComponentModule } from './modals_popsover/admin-territory-detail/admin-territory-detail.component.module';
import { AdminUserDetailModalComponentModule } from './modals_popsover/admin-user-detail-modal/admin-user-detail-modal.component.module';
import { AdminUserMassiveCreateSettingsComponentModule } from './modals_popsover/admin-user-massive-create-settings/admin-user-massive-create-settings.component.module';
import { ImageCropComponentModule } from './modals_popsover/image-crop/image-crop.component.module';
import { LoadingLogPopoverComponentModule } from './modals_popsover/loading-log-popover/loading-log-popover.component.module';
import { RecipientsListPopoverComponentModule } from './modals_popsover/recipients-list-popover/recipients-list-popover.component.module';
import { WebviewModalComponentModule } from './modals_popsover/webview-modal/webview-modal.component.module';
import { PendingRequestPopoverComponentModule } from './modals_popsover/pending-request-popover/pending-request-popover.component.module';
import { MicroFrontendConatinerPageModule } from './pages/micro-frontend-container/micro-frontend-container.module';
import { DcrudFormModalModule } from './pages/dcrud/dcrud-form-modal/dcrud-form-modal.component.module';
import { DcrudFormModule } from './pages/dcrud/dcrud-form-component/dcrud-form.component.module';
import { DcrudInputComponentModule } from './pages/dcrud/dcrud-input/dcrud-input.component.module';
import { PostitBoardModule } from 'src/projects/postit-board/postit-board.module';
import { NumberSeparatorPipePipeModule } from './pipes/number-separator-pipe/number-separator.pipe.module';
import { LoginViewAndFineComponentModule } from 'src/projects/view-and-fine/components/login-view-and-fine-component/login-view-and-fine.component.module';
import { VafEntityRegistrationComponentModule } from 'src/projects/view-and-fine/modals/vaf-entity-registration/vaf-entity-registration.component.module';
import { ChatBotComponentModule } from './components/chat-bot/chat-bot.component.module';
import { TablesPromptComponentModule } from './components/chat-bot/tables-prompt/tables-prompt.component.module';

//import { PostitBoardBoardComponentModule } from 'src/projects/postit-board/components/postit-board-board/postit-board-board.component.module';
//import { PostitBoardFileUploadComponentModule } from 'src/projects/postit-board/modals/postit-board-file-upload/postit-board-file-upload.component.module';
//import { PostitBoardListManagementComponentModule } from 'src/projects/postit-board/modals/postit-board-list-management/postit-board-list-management.component.module';
//import { PostitBoardStatsComponentModule } from 'src/projects/postit-board/components/postit-board-stats/postit-board-stats.component.module';



@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    FormsModule,
    //VafEntityRegistrationComponentModule,
    SelectableTreePopoverComponentModule,
    SelectableModulesModule,
    ServiceAccessConfigurationTemplateComponentModule,
    AdminRoleDetailPopoverComponentModule,
    PinPopoverComponentModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    NewsDetailPopoverComponentModule,
    HelpComponentModule,
    InfiniteTreeComponentModule,
    LoginServicesComponentModule,
    ModulesTreeComponentModule,
    ServiceAccessConfigurationComponentModule,
    RightMenuPopoverComponentModule,
    MessagesBoxComponentModule,
    FooterComponentModule,
    TablePopupInputComponentModule,
    TableTdComponentModule,
    TableComponentModule,
    TableExtractionComponentModule,
    TableSettingsComponentModule,
    TableDecimalePipeModule,
    TableNumeroPipeModule,
    DateFormatPipeModule,
    DateFormatPlaceholderPipeModule,
    MenuTreeComponentModule,
    TranslateMenuPipeModule,
    PendingRequestButtonComponentModule,
    TerritoryTreeComponentModule,
    AdminTerritoryDetailComponentModule,
    AdminUserDetailModalComponentModule,
    AdminUserMassiveCreateSettingsComponentModule,
    ImageCropComponentModule,
    LoadingLogPopoverComponentModule,
    RecipientsListPopoverComponentModule,
    WebviewModalComponentModule,
    CustomDatePipeModule,
    FilterPipeModule,
    GroupByPipeModule,
    OrderByPipeModule,
    SafePipeModule,
    CustomReportsComponentModule,
    CustomReportComboEditComponentModule,
    CustomReportConfigurationComponentModule,
    GaugeComponentModule,
    PssfeDropdownComponentModule,
    PssfeDropdownPromptComponentModule,
    PssfeSelectableComponentModule,
    PssfeSelectablePromptComponentModule,
    SearchStateHistoryPromptModule,
    PendingRequestPopoverComponentModule,
    MicroFrontendConatinerPageModule,
    DcrudFormModalModule,
    DcrudFormModule,
    DcrudInputComponentModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    PostitBoardModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NumberSeparatorPipePipeModule,
    LoginViewAndFineComponentModule,
    VafEntityRegistrationComponentModule,
    ChatBotComponentModule,
    TablesPromptComponentModule
    //PostitBoardBoardComponentModule,
    //PostitBoardFileUploadComponentModule,
    //PostitBoardListManagementComponentModule,
    //PostitBoardStatsComponentModule
  ],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
  },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


// required for AOT compilation 
export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: "./assets/i18n/evud/", suffix: ".json" },
    { prefix: "./assets/i18n/imma/", suffix: ".json" },
    { prefix: "./assets/i18n/odeva/", suffix: ".json" },
    { prefix: "./assets/i18n/viewAndFine/", suffix: ".json" },
    { prefix: "./assets/i18n/ald/", suffix: ".json" },
    { prefix: "./assets/i18n/postit-board/", suffix: ".json" },
  ]);
}