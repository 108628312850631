<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.tableSettings' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="table-settings-component">
  <div class="table-settings-container" cdkScrollable>
    <ion-card class="modal-page-card">
      <ion-card-content>
        <ion-row>
          <!-- columns list -->
          <ion-col size-md="12" size-sm="12" size-xs="12" class="no-padding">
            <div class="columns-list" [hidden]="!modalNavParams || !modalNavParams.tableColumns" cdkDropListLockAxis="y"
              cdkDropListOrientation="vertical" cdkDropList (cdkDropListDropped)="dropListDropped($event)">
              <div class="columns-list-item table-settings-component-drag-preview"
                *ngFor="let column of modalNavParams.tableColumns; let i = index" cdkDrag
                cdkDragBoundary=".table-settings-container" [cdkDragData]="{name: column.columnDef, columIndex: i}"
                [ngClass]="{'vertical-column-hidden': column.columnType == columnTypes.DETAIL || column.columnType == columnTypes.SELECT}">
                <span>{{column.columnHeader ? (column.columnHeader | translate) : ''}}</span>
                <div class="cursor-pointer" slot="end" title="{{'labels.visible' | translate}}"
                  class="columns-list-item-buttons">
                  <ion-icon name="eye" [color]="column.columnHidden ? 'tertiary' : 'primary'"
                    (click)="column.columnHidden  = !column.columnHidden"></ion-icon>
                  <ion-icon name="reorder-two" color="secondary"></ion-icon>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <!--
        <ion-list>
          <ion-reorder-group (ionItemReorder)="handleColumnsReorder($any($event))" [disabled]="false">
            <ion-item *ngFor="let column of modalNavParams.tableColumns; let i = index" lines="none">
              <ion-label>
                {{column.columnHeader ? (column.columnHeader | translate) : ''}}
              </ion-label>
             
              <div (click)="column.columnHidden  = !column.columnHidden" class="cursor-pointer" slot="end"
                title="{{'labels.visible' | translate}}" class="inline-flex-center-center">
                <ion-icon name="eye" [color]="column.columnHidden ? 'tertiary' : 'primary'"
                  style="pointer-events: none;"></ion-icon>
              </div>
              <ion-reorder slot="end">
                <ion-icon name="reorder-two"></ion-icon>
              </ion-reorder>
            </ion-item>
          </ion-reorder-group>
        </ion-list>
      -->
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
        <div class="button-box">
          <ion-button color="primary" size="default" (click)="resetColumns()"
            title="{{'labels.resetColumns' | translate}}">
            <ion-label>{{'buttons.reset' | translate}}</ion-label>
          </ion-button>
          <ion-button color="success" size="default" (click)="save()">
            <ion-label>{{'buttons.save' | translate}}</ion-label>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<!-- <div (click)="handleColumnStickyLeft(i)" class="cursor-pointer" slot="end"
                title="{{'labels.fixed' | translate}}">
                <ion-icon name="lock-closed" [color]="column.columnStickyLeft ? 'primary' : 'tertiary'"
                  style="pointer-events: none;"></ion-icon>
              </div>-->