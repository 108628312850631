<div class="app-table-container" id="tableContent-{{tableId}}">
    <div id="table-anchor"></div>
    <!-- table desktop/tablet -->
    <div class="table-container table-container-{{tableId}}" cdkScrollable id="table-container-{{tableId}}"
        *ngIf="!isMobile">
        <table mat-table [dataSource]="tableDataSource" matSort [matSortActive]="tableSortActive"
            [matSortDirection]="tableSortDirection" (matSortChange)="emitTableSortChange()" cdkDropListLockAxis="x"
            cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="dropListDropped($event)"
            id="table-{{tableId}}">
            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of tableColumns;let i = index">
                <!-- column header -->
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    [disabled]="!column.columnSortable || resizingObject.isResizing"
                    [style.width]="column.columnWidth + 'px'" [style.minWidth]="column.columnWidth + 'px'"
                    [style.maxWidth]="column.columnWidth + 'px'" cdkDrag cdkDragBoundary=".table-container-{{tableId}}"
                    [cdkDragData]="{name: column.columnDef, columIndex: i}" id="{{tableId}}-{{column.columnDef}}"
                    [ngClass]="{'column-hidden': column.columnHidden, 'equal-padding': column.columnType == tableColumnTypes.DETAIL ||  
                    column.columnType == tableColumnTypes.SELECT}">
                    <div class="header-cell-inner" [ngClass]="{'flex-center-center': column.columnType == tableColumnTypes.DETAIL ||  
                    column.columnType == tableColumnTypes.SELECT || column.columnType == tableColumnTypes.TOOLTIP}">
                        <!-- SELECT -->
                        <ion-checkbox *ngIf="column.columnType == tableColumnTypes.SELECT" [(ngModel)]="masterCheckbox"
                            (ngModelChange)="handleMasterCheckbox()"></ion-checkbox>
                        <!-- TEXT, DATE, DATE_TIME, BOOLEAN, TOOLTIP, TRANSLATE -->
                        <span title="{{column.columnHeader ? (column.columnHeader | translate) : ''}}"
                            *ngIf="column.columnType != tableColumnTypes.SELECT && column.columnType != tableColumnTypes.DETAIL">{{column.columnHeader
                            ? (column.columnHeader | translate) : ''}}</span>
                        <!-- COMPARISON -->
                        <div class="comparison-box" (click)="$event.stopPropagation();openComparisonPopover(column)"
                            *ngIf="column.columnHeaderComparisonOptions && column.columnHeaderComparisonOptions.length > 0">
                            <ion-icon name="funnel-outline"></ion-icon>
                        </div>
                    </div>
                    <!-- resizer -->

                    <div class="column-resizer"
                        [ngClass]="{'unresazibleColumn': column.columnType == tableColumnTypes.DETAIL  ||  column.columnType == tableColumnTypes.SELECT}"
                        (mousedown)="$event.preventDefault();$event.stopPropagation();resizerMouseDown($event, i, column)">
                    </div>
                </th>
                <!-- column body [style.position]="column.columnStyleProp.position"-->
                <td mat-cell *matCellDef="let element; let tdIndex = index" [style.width]="column.columnWidth + 'px'"
                    [style.minWidth]="column.columnWidth + 'px'" [style.maxWidth]="column.columnWidth + 'px'" [ngClass]="{'column-hidden': column.columnHidden, 'equal-padding': column.columnType == tableColumnTypes.DETAIL ||  
                    column.columnType == tableColumnTypes.SELECT}"
                    id="column-{{tdIndex}}-{{tableId}}-{{column.columnDef}}">
                    <span
                        [ngClass]="{'flex-center-center': column.columnType == tableColumnTypes.DETAIL ||  
                column.columnType == tableColumnTypes.SELECT || column.columnType == tableColumnTypes.TOOLTIP || column.columnType == tableColumnTypes.BOOLEAN_CHECKBOX}">
                        <app-table-td [column]="column" [element]="element" [tdIndex]="tdIndex"
                            (getAllSelected)="getAllSelected()" (goDetail)="goDetail($event)"
                            (goDynamicDetail)="goDynamicDetail($event)" (editCell)="editCell($event)"
                            (saveCell)="saveCell($event)" (openLink)="openLink($event)">
                        </app-table-td>
                    </span>
                </td>
            </ng-container>
        </table>
    </div>
    <!-- table mobile -->
    <div class="table-container table-container-{{tableId}} table-mobile" id="table-container-{{tableId}}"
        *ngIf="isMobile">
        <table mat-table [dataSource]="tableDataSource" matSort [matSortActive]="tableSortActive"
            [matSortDirection]="tableSortDirection" (matSortChange)="emitTableSortChange()" cdkDropListLockAxis="x"
            cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="dropListDropped($event)"
            id="table-{{tableId}}">
            <tr mat-header-row *matHeaderRowDef="mobileColumns.displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: mobileColumns.displayedColumns"></tr>
            <ng-container matColumnDef="element">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="table-mobile-sort">
                        <div class="table-mobile-sort-list" id="sort-list">
                            <div class="table-mobile-sort-list-item">
                                <span>{{'labels.orderBy' | translate}}:&nbsp;</span>
                                <ion-select [(ngModel)]="currentMobileSortId" (ngModelChange)="mobileSortChange()"
                                    [disabled]="searchLoading" multiple="false" interface="popover"
                                    placeholder="{{'labels.default' | translate }}">
                                    <ion-select-option [value]="option.mobileSortId"
                                        *ngFor="let option of mobileColumns.sortColumns">
                                        {{option.columnHeader
                                        ? (option.columnHeader | translate) : ''}}&nbsp;<span
                                            *ngIf="option.columnDef != 'sortDefaultFE'">{{('labels.'+option.mobileSortDirection)
                                            | translate}}</span>
                                    </ion-select-option>
                                </ion-select>
                            </div>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element; let tdIndex = index" id="table-row-{{tdIndex}}">
                    <ion-row class="table-mobile-item">
                        <!-- toolbarColumns -->
                        <ion-col size-md="12" size-sm="12" size-xs="12">
                            <div class="toolbar-columns">
                                <div class="toolbar-columns-item" *ngIf="mobileColumns.toolbarColumns.length == 1">
                                </div>
                                <div class="toolbar-columns-item"
                                    *ngFor="let column of mobileColumns.toolbarColumns;let tdIndex = index">
                                    <app-table-td [column]="column" [element]="element" [tdIndex]="tdIndex"
                                        (getAllSelected)="getAllSelected()" (goDetail)="goDetail($event)"
                                        (goDynamicDetail)="goDynamicDetail($event)" (editCell)="editCell($event)"
                                        (saveCell)="saveCell($event)" (openLink)="openLink($event)">
                                    </app-table-td>
                                </div>
                            </div>
                        </ion-col>
                        <!-- columns -->
                        <ion-row class="table-mobile-item-inner"
                            *ngFor="let column of mobileColumns.fieldColumns;let tdIndex = index">
                            <ion-col size-md="4" size-sm="4" size-xs="4">
                                <div class="table-mobile-item-inner-label">{{column.columnHeader
                                    ? (column.columnHeader | translate) : ''}}:</div>
                            </ion-col>
                            <ion-col size-md="8" size-sm="8" size-xs="8">
                                <app-table-td [column]="column" [element]="element" [tdIndex]="tdIndex"
                                    (getAllSelected)="getAllSelected()" (goDetail)="goDetail($event)"
                                    (goDynamicDetail)="goDynamicDetail($event)" (editCell)="editCell($event)"
                                    (saveCell)="saveCell($event)" (openLink)="openLink($event)">
                                </app-table-td>
                            </ion-col>
                        </ion-row>
                    </ion-row>
                </td>
            </ng-container>
        </table>
        <ion-row *ngIf="!paginationFrontend && tableDataSource && tableDataSource.data && tablePaginator"
            class="row-pagination-mobile">
            <ion-col size-md="12" size-sm="12" size-xs="12">
                <div class="load-more">
                    <ion-button color="tertiary" size="small" class="load-more-paginator">
                        {{tableDataSource.data.length+"/"+ tablePaginator.length}}
                    </ion-button>
                    <ion-button (click)="emitLoadMore()" color="primary" size="small"
                        [disabled]="searchLoading || tableDataSource.data.length >= tablePaginator.length">
                        <ion-spinner name="lines-small" [hidden]="!searchLoading"></ion-spinner>
                        <ion-label>{{'buttons.load_more' |
                            translate}}</ion-label>
                    </ion-button>
                </div>
            </ion-col>
        </ion-row>
    </div>
    <div class="table-footer" [ngClass]="{'table-footer-mobile': isMobile}">
        <div class="table-footer-buttons">
            <ion-button color="primary" size="small" title="{{'labels.columnsSettings' | translate}}"
                (click)="openColumnsSettings()" [hidden]="isMobile">
                <ion-icon name="cog"></ion-icon>
            </ion-button>
            <ion-button color="primary" size="small" (click)="adaptToScreen()"
                title="{{'labels.adaptToScreen' | translate}}" [hidden]="isMobile">
                <ion-icon src="assets/icon/width.svg" class="filter-white"></ion-icon>
            </ion-button>
            <app-table-extraction [hidden]="hideExtraction" (extractApi)="extractApiEmitter($event)"
                [fileName]="fileName" [tableColumns]="tableColumns" [extractionId]="tableId" [tableId]="tableId"
                [skipExtrationMessage]="skipExtrationMessage" #tableExtraction>
            </app-table-extraction>
        </div>
        <div class="table-footer-paginator" [hidden]="isMobile">
            <mat-paginator [length]="tablePaginator ? tablePaginator.length : 0" [pageSize]="tablePaginatorPageSize"
                [pageSizeOptions]="tablePaginatorRange" (page)="emitTablePaginatorChange($event)"
                *ngIf="!paginationFrontend" [ngClass]="{'paginator-disabled': searchLoading}">
            </mat-paginator>
            <mat-paginator [length]="tablePaginator ? tablePaginator.length : 0"
                [pageSize]="isMobile ? maxPageSize : tablePaginatorPageSize" (page)="emitTablePaginatorChange($event)"
                [pageSizeOptions]="isMobile ? [maxPageSize] : tablePaginatorRange" *ngIf="paginationFrontend">
            </mat-paginator>
        </div>
    </div>
</div>