import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TableExtractionService {
  //currentExtractionsIds
  private currentLoadingExtractionsIds = new BehaviorSubject<Array<{ extractionId: string }>>([]);
  public currentLoadingExtractionsIdObservable = this.currentLoadingExtractionsIds.asObservable();

  constructor() { }

  //add current extraction id
  public addLoadingExtractionId(extractionId: string) {
    let array = this.currentLoadingExtractionsIds.getValue();
    if (array.filter((x) => { return x.extractionId == extractionId }).length == 0)
      array.push({ extractionId: extractionId })
    if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0])
      (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).contentWindow.postMessage({ pendingRequest: { pendingRequestsList: array } }, "*");
    this.currentLoadingExtractionsIds.next(array);
  }

  //delete current extraction id
  public deleteLoadingExtractionId(extractionId: string) {
    let array = this.currentLoadingExtractionsIds.getValue();
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].extractionId == extractionId) {
          array.splice(i, 1);
        }
      }
    }
    if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0])
      (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).contentWindow.postMessage({ pendingRequest: { pendingRequestsList: array } }, "*");
    this.currentLoadingExtractionsIds.next(array);
  }

  //is extractionId Loading
  public isExtractionIdLoading(extractionId: string) {
    return extractionId ? this.currentLoadingExtractionsIds.getValue().filter((x) => { return x.extractionId == extractionId }).length > 0 : false;
  }

  //
}
