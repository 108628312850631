<div>
  <mat-tab-group class="service-access-configuration" (selectedTabChange)="getListaTemplateServiziSafo($event)">
    <mat-tab label="{{'menu.'+service.idModulo | translate}}" *ngFor="let service of servicesList">
      <div class="configuration-detail">
        <!-- configs role-->
        <ion-row>
          <ion-col size-md="12" size-sm="12" size-xs="12" *ngIf="configurationType == 'user'">
            <div class="config-message">
              {{'messages.configuration_message' | translate}}
            </div>
          </ion-col>
          <!-- username -->
          <ion-col size-md="6" size-sm="6" size-xs="12" *ngIf="configurationType == 'user'">
            <ion-item class="input-field username">
              <ion-label position="stacked">{{'labels.username' | translate}}</ion-label>
              <ion-input type="text" placeholder="{{'labels.insert' | translate}}"
                [(ngModel)]="service['serviceUserName']">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <!-- template management -->
            <div class="flex-center-right mt-10px" *ngIf="configurationType == 'role'">
              <div class="manage-template-button"
                (click)="openAccessConfigurationTemplate(service.id, service.idModulo)">
                {{'buttons.manage_template' | translate}}
              </div>
            </div>
            <!-- list -->
            <div *ngIf="service[currentKeyValueListProperty] && service[currentKeyValueListProperty].length > 0"
              [ngClass]="{'key-value-list-user': configurationType == 'user'}" class="key-value-list">
              <div class="config-key mt-10px">
                <div class="config-input">
                  <label>{{'labels.key' | translate}} *</label>
                </div>
              </div>
              <div class="config-value mt-10px">
                <div class="config-input">
                  <label>{{'labels.value' | translate}} {{'messages.multiple_value_message' | translate}}
                    *</label>
                </div>
              </div>
              <div class="config-overwrite" *ngIf="configurationType == 'role'">
                <div class="config-input flex-center-center">
                  <label>{{'labels.overwriteByUser' | translate}}</label>
                </div>
              </div>
              <div class="config-role" *ngIf="configurationType == 'user'">
                <div class="config-input">
                  <label>{{'labels.role' | translate}}</label>
                </div>
              </div>
              <div class="config-item" *ngFor="let config of service[currentKeyValueListProperty];let i = index">
                <div class="config-key">
                  <div class="config-input" *ngIf="configurationType == 'role'">
                    <input type="text" [(ngModel)]="config['chiave']" placeholder="{{'labels.insert' | translate}}"
                      maxlength="50">
                  </div>
                  <div class="readonly-param" *ngIf="configurationType == 'user'" title="{{config['chiave']}}">
                    {{config['chiave']}}
                  </div>
                </div>
                <div class="config-value">
                  <div class="config-input">
                    <input type="text" [(ngModel)]="config['valore']" placeholder="{{'labels.insert' | translate}}"
                      maxlength="1000">
                  </div>
                </div>
                <div class="config-role" *ngIf="configurationType == 'user'">
                  <div class="readonly-param" *ngIf="configurationType == 'user'"
                    title="{{config['nomeTerritorioRuolo']}}">
                    {{config['nomeTerritorioRuolo']}}
                  </div>
                </div>
                <div class="config-overwrite" *ngIf="configurationType == 'role'">
                  <input type="checkbox" [(ngModel)]="config['sovrascrivibileDaUtente']">
                </div>
                <div class="config-delete" *ngIf="configurationType == 'role'" (click)="removeConfig(service, i)">
                  <ion-icon name="close-outline"></ion-icon>
                </div>
              </div>
            </div>
            <!-- no results -->
            <div class="p-10px"
              *ngIf="(!service[currentKeyValueListProperty] || service[currentKeyValueListProperty].length == 0) && configurationType == 'role'">
              {{'messages.no_configs_message' | translate}}
            </div>
            <!-- add -->
            <div class="flex-center-right mt-10px">
              <!-- selection-->
              <div class="modify-template-button" *ngIf="configurationType == 'role'">
                <ion-select [disabled]="!templateOption || templateListLoading" [(ngModel)]="currentTemplateOption"
                  multiple="false" cancelText="{{'buttons.cancel' | translate}}"
                  okText="{{'buttons.select' | translate}}"
                  (ngModelChange)="dettaglioTemplateServiziSafo(service, currentTemplateOption)"
                  placeholder="{{'labels.load_template' | translate}} ({{templateOption && !templateListLoading ? templateOption.length : 0}})"
                  interface="popover">
                  <ion-select-option *ngFor="let option of templateOption" value="{{option.id}}">
                    {{option.nomeTemplate}}
                  </ion-select-option>
                </ion-select>
              </div>
              <div class="add-button" (click)="addConfig(service)" *ngIf="configurationType == 'role'">
                <ion-icon name="add-outline"></ion-icon>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>