<!-- item -->
<div class="territory-item">
  <!-- item data -->
  <div class="territory-item-data" [ngClass]="{'opened' : !item['opened']}">
    <!-- item title -->
    <div class="territory-item-title" (click)="item['opened'] = !item['opened']">
      <div class="territory-item-name" id="{{item.denominazione}}">
        {{item.denominazione}}
      </div>
      <ion-icon name="create-outline" (click)="handleDetailClick(item, 'detail');$event.stopPropagation();" *ngIf="!movable" title="{{'labels.detail_modify' | translate}}"></ion-icon>
      <ion-icon name="add-outline" (click)="handleDetailClick(item.idCliente, 'new');$event.stopPropagation();" *ngIf="!movable && !item['isClosed']" title="{{'labels.add' | translate}}"></ion-icon>
      <ion-icon name="move-outline" *ngIf="!movable && !item.isTheRoot" (click)="handleMovable(true, item);$event.stopPropagation();" title="{{'labels.move' | translate}}"></ion-icon>
      <div class="territory-item-move" *ngIf="movable && item['notMovable'] && item['enableCancel']" (click)="handleMovable(false, item.idCliente);$event.stopPropagation();">
        <button class="button-cancel">Annulla</button>
      </div>
      <div class="territory-item-move" *ngIf="movable && !item['notMovable'] && !item.isClosed" (click)="handleMovableSelect(item.idCliente);$event.stopPropagation();">
        <button class="button-select">Seleziona</button>
      </div>

      <ion-icon class="territory-item-indicator" name="chevron-forward-outline" color="danger" *ngIf="item.figli && item.figli.length > 0"></ion-icon>
    </div>
  </div>
  <!-- item to repeat -->
  <div class="child" *ngIf="item.figli" [hidden]="item['opened']">
    <div *ngIf="item.figli.length > 0">
      <div *ngFor="let child of item.figli">
        <app-territory-tree [item]="child" [movable]="movable" (onMovableClick)="handleMovableChild($event)" (onMovableSelect)="handleMovableSelectChild($event)"
        (handleMovable)="handleMovableChild($event)" (onDetailClick)="handleDetailClickChild($event)" class="left-pipe">
        </app-territory-tree>
      </div>
    </div>
  </div>
</div>