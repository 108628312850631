import { NgModule } from '@angular/core';
import { AdminTerritoryDetailComponent } from './admin-territory-detail.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { IonicSelectableComponent } from 'ionic-selectable';
import { HelpComponentModule } from '../../components/help/help.component.module'
import { ModulesTreeComponentModule } from "../../components/modules-tree/modules-tree.component.modules"
@NgModule({
    declarations: [
        AdminTerritoryDetailComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        IonicSelectableComponent,
        HelpComponentModule,
        ModulesTreeComponentModule
    ],
    exports: [
        AdminTerritoryDetailComponent
    ]
})
export class AdminTerritoryDetailComponentModule {}