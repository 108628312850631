import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { AppService } from './app.service';
import { AuthService } from './auth-service.service';
import { LanguageService } from './language.service';
import { LoaderService } from './loader.service';
import { TableExtractionService } from './table-extraction.service';
import { StorageVariablesService } from './storage-variables.service';
import { Location } from '@angular/common';
import { AlertService, GenericAlertOptions } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class MicroFrontEndContainerService {
    //show messsage
    public showHttpErrorMessage = true;
    //last route
    public lastRoute: string;

    constructor(private languageSrv: LanguageService, private authSrv: AuthService, private loaderSrv: LoaderService,
        private storageVariableSrv: StorageVariablesService, private popoverCtrl: PopoverController,
        private alertCtrl: AlertController, private appSrv: AppService, private router: Router, private tabelExtractionSrv: TableExtractionService,
        public location: Location, private alertSrv: AlertService, private translate: TranslateService, private _http: HttpClient) {

    }

    public onMicroFrontEndMessage(message: microFrontendMessage) {
        if (message) {
            //token
            if (message.token) {
                let newToken = message.token;
                this.authSrv.getAuthObject().then((authObject: any) => {
                    if (authObject && authObject.userData && authObject.userData.token) {
                        authObject.userData.token = newToken;
                        this.authSrv.setAuthObject(authObject);
                    }
                })
            }
            //loader
            if (message.loader) {
                if (message.loader.value)
                    this.loaderSrv.open(message.loader.message ? { message: message.loader.message } : null);
                else
                    this.loaderSrv.dismiss();
            }
            //httpError
            if (message.httpError) {
                if (message.httpError.status == 401 || message.httpError.status == 403) {
                    this.authSrv.setAuthObject(null);
                    this.storageVariableSrv.setAdminImpersGuidObject(null);
                    if (this.showHttpErrorMessage) {
                        this.languageSrv.transaltePathsPromise(['messages.session_expired_message', 'labels.operation_error']).then((translationList) => {
                            this.errorAlert(translationList['messages.session_expired_message'], translationList['labels.operation_error']);
                        })
                        this.showHttpErrorMessage = false;
                    }
                    this.popoverCtrl.dismiss();
                    if (message.httpError.status == 401)
                        this.appSrv.navigate('/login');
                    else
                        this.router.navigateByUrl('/login');
                }
            }
            //pageNotFound
            if (message.pageNotFound) {
                this.router.navigateByUrl('/page-not-found', { replaceUrl: true });
            }
            //pendingRequest
            if (message.pendingRequest && message.pendingRequest.pendingId) {
                if (message.pendingRequest.pending) {
                    this.tabelExtractionSrv.addLoadingExtractionId(message.pendingRequest.pendingId);
                } else {
                    this.tabelExtractionSrv.deleteLoadingExtractionId(message.pendingRequest.pendingId);
                }
            }
            //navigateByUrl
            if (message.navigateByUrl) {
                this.navigateByUrl(message);
            }
            //fullscreen
            if (message.fullScreen) {
                if (message.fullScreen.isFullscreen)
                    this.appSrv.enterFullscreen();
                else
                    this.appSrv.exitFullScreen();
            }
            //async messages
            if (message.id && message.id.indexOf("inviato") != -1) {
                this.handleAsyncMessage(message as { id, message });
            }
            //signalRMessages TODO: attualmente nessun micro frontend gestisce i messaggi
        }
    }

    public sendMicroFrontEndMessage(message: microFrontendMessage) {
        if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
            (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).contentWindow.postMessage(message, "*");
            /*Array.from(document.getElementsByClassName("micro-frontend-iframe")).forEach((microFrontend: HTMLIFrameElement) => {
                microFrontend.contentWindow.postMessage(message, "*");
            })*/
        }
    }

    public sendMicroFrontEndRoutingUrl(message: microFrontendMessage) {
        if (message && message.routingUrl) {
            message.routingUrl = message.routingUrl.replace("/route/", "");
            message.routingUrl = message.routingUrl.substring(message.routingUrl.indexOf('/'), message.routingUrl.length);
            //format microfrontendUrl
            if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
                (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).contentWindow.postMessage(message, "*");
            }
        }
    }

    public async loadMicroFrontEndBaseUrl(microFEBase: string, authObject: any, roleId: string) {
        this.lastRoute = microFEBase;
        this.loaderSrv.open();
        if (microFEBase) {
            //localhost
            if (microFEBase.indexOf("localhost") != -1) {
                microFEBase = microFEBase.replace("http://localhost:8100/route/", "");
                //odeva
                if (microFEBase.indexOf("odeva/") != -1)
                    microFEBase = "http://localhost:8101" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //crp
                if (microFEBase.indexOf("crp/") != -1)
                    microFEBase = "http://localhost:8102" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //empty mfe
                if (microFEBase.indexOf("mfe/") != -1)
                    microFEBase = "http://localhost:8103" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //custom ocr
                if (microFEBase.indexOf("customocr/") != -1)
                    microFEBase = "http://localhost:8104" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //monitoraggio
                if (microFEBase.indexOf("monitoraggio/") != -1)
                    microFEBase = "http://localhost:8105" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //evud
                if (microFEBase.indexOf("evud/") != -1)
                    microFEBase = "http://localhost:8106" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //gemanomalie
                if (microFEBase.indexOf("gemanomalie/") != -1)
                    microFEBase = "http://localhost:8107" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
                //toll
                if (microFEBase.indexOf("toll/") != -1)
                    microFEBase = "http://localhost:8108" + microFEBase.substring(microFEBase.indexOf('/'), microFEBase.length);
            }
            //server
            else {
                //develop: ss-silvanus/
                if (microFEBase.indexOf('/ss-silvanus/') != -1) {
                    microFEBase = microFEBase.replace("/route/", "/fe-");
                }
                //peprod: test.[mfe].safogroup.eu prod:  [mfe].safogroup.eu
                else {
                    microFEBase = this._getMfeUrl(microFEBase);
                }
            }
            //format microfrontendUrl + detect same url
            let subBaseRules = await this.appSrv.getSubBaseRules();
            let additionalStyle = !subBaseRules.folderPath ? "" : ("&additionalStyleFolder=" + subBaseRules.folderPath);
            let newSrc: string = microFEBase + (microFEBase.indexOf("?") == -1 ? "?token=" : "&token=") + authObject?.userData?.token + "&language=" + this.translate.getDefaultLang();
            + "&roleId=" + roleId + additionalStyle;
            if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0] && newSrc != this.getLoadedMicroFESrc()) {
                setTimeout(() => {
                    (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).src = newSrc;
                    this.loaderSrv.dismiss();
                }, 500)
            } else {
                this.loaderSrv.dismiss();
            }
        } else {
            this.loaderSrv.dismiss();
            if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
                (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).src = '';
            }
        }
    }

    //navigateByUrl
    private navigateByUrl(microFrontendMessage: microFrontendMessage) {
        if (microFrontendMessage && microFrontendMessage.navigateByUrl && microFrontendMessage.navigateByUrl.url) {
            let mfeRoute = window.location.href.substring(window.location.href.indexOf("/route/") + 7, window.location.href.length);
            mfeRoute = mfeRoute.substring(0, mfeRoute.indexOf("/"));
            let newUrl = "/route/" + mfeRoute + microFrontendMessage.navigateByUrl.url;
            this.location.go(newUrl);
        }
    }

    //get loaded micro fe src
    public getLoadedMicroFESrc() {
        let src: string;
        if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
            src = (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement).src;
        }
        return src
    }

    //error alert
    async errorAlert(message, title) {
        message = '<div class="alert-service-title title-danger">' +
            '<ion-icon name="warning-outline"></ion-icon>' +
            '&nbsp;' + '<span>' + title + '</span>' +
            '</div>' +
            '<div class="alert-service-message">' +
            '<span>' + message + '</span>' +
            '</div>';
        const alert = await this.alertCtrl.create({
            cssClass: 'alert-service',
            message: message,
            buttons: [
                {
                    text: this.languageSrv.instant('buttons.ok'),
                    cssClass: 'secondary'
                }
            ]
        });

        alert.onDidDismiss().then(() => {
            this.showHttpErrorMessage = true;
        })
        await alert.present()
    }

    //handleAsyncMessage
    private async handleAsyncMessage(recivedMessage: { id, message }) {
        if (recivedMessage.message) {
            //alert: restituisce l'azione sull'alert ('confirm' | 'cancel') se recivedMessage.message.successCallback == true o recivedMessage.message.cancelCallback == true. Esempio {id: 1234567, message{alert: true, {}}}
            if (recivedMessage.message.alert && recivedMessage.message.options) {
                let options: GenericAlertOptions = recivedMessage.message.options;
                //standard layout
                if (!options.title && options.titleColor) {
                    if (options.titleColor == 'danger') {
                        options.titleIcon = 'warning-outline';
                        options.title = this.translate.instant('labels.operation_error');
                    }
                    else if (options.titleColor == 'warning') {
                        options.titleIcon = 'warning-outline';
                        options.title = this.translate.instant('labels.operation_warning')
                    }
                    else if (options.titleColor == 'success') {
                        options.titleIcon = 'checkbox-outline';
                        options.title = this.translate.instant('labels.operation_success')
                    }
                    else {
                        options.titleIcon = 'information-circle-outline';
                        options.title = this.translate.instant('labels.operation_warning')
                    }
                }
                if (recivedMessage.message.successCallback || recivedMessage.message.cancelCallback)
                    this.alertSrv.genericAlert(options, () => {
                        this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: { successCallback: true } })
                    }, () => {
                        this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: { cancelCallback: true } })
                    });
                else {
                    this.alertSrv.genericAlert(options);
                    this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: {} })
                }
            }
            //httpRequest: restituisce la risposta http
            else if (recivedMessage?.message?.httpRequest) {
                let message = recivedMessage.message as MicrofrontendHttpRequest;
                let resolveRequest = await this._resolveHttpRequest(message);
                if (resolveRequest.httpError)
                    message.response = { httpError: resolveRequest.httpError, result: null };
                else
                    message.response = { httpError: null, result: resolveRequest.result };
                this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: JSON.parse(JSON.stringify(message)) });
            }
            //state: restituisce lo stato dell'utente (authObj)
            else if (recivedMessage.message.sessionState) {
                this.authSrv.getAuthObject().then((authObject: any) => {
                    if (authObject) {
                        this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: authObject })
                    }
                })
            }
            //getBase uri
            else if (recivedMessage.message.baseUri) {
                this.sendMicroFrontEndMessage({ id: recivedMessage.id, message: { baseUri: document.baseURI } })
            }
        }
    }

    //resolveHttpRequest
    private _resolveHttpRequest(request: MicrofrontendHttpRequest): Promise<{ result: any, httpError: HttpErrorResponse }> {
        return new Promise((resolve) => {
            this._http.request(request.method, request.url, request.options).subscribe({
                next: (res) => {
                    resolve({ result: res, httpError: null });
                },
                error: (err) => {
                    resolve({ result: null, httpError: err as HttpErrorResponse });
                }
            })
        })
    }

    //_getMfeUrl
    private _getMfeUrl(url: string): string {
        //depp clone current href
        let mfeUrl: string = JSON.parse(JSON.stringify(url));
        //get mfe target
        let mfeTarget = mfeUrl.substring(mfeUrl.indexOf('/route/') + 7);
        mfeTarget = mfeTarget.substring(0, mfeTarget.indexOf('/'));
        //based on enviromnent (TEST, STAGE, PROD) override first part of domain with mfe target
        //safomf-
        //ex: https://safomf-core-stage.azurewebsite.net/route/mfetarget -> https://safomf-mfetarget-stage.azurewebsite.net/mfetarget
        if (mfeUrl.startsWith('https://safomf-core')) {
            let newBaseUrl: string = mfeUrl.replace('core', mfeTarget);
            mfeUrl = newBaseUrl.replace('/route/' + mfeTarget, '');
        }
        //ex: https://test-services.gemportalservices.com/route/mfetarget -> .gemportalservices.com/route/mfetarget -> https://test-mfetarget.gemoportalservices.com/
        //environment: test
        else if (mfeUrl.startsWith('https://test-') || mfeUrl.startsWith('https://test.')) {
            let newBaseUrl: string = "https://test-" + mfeTarget + mfeUrl.substring(mfeUrl.indexOf('.'));
            mfeUrl = newBaseUrl.replace('/route/' + mfeTarget, '');
            mfeUrl = mfeUrl.replace('/services', '');
        }
        //environment: stage
        else if (mfeUrl.startsWith('https://stage-') || mfeUrl.startsWith('https://stage.')) {
            let newBaseUrl: string = "https://stage-" + mfeTarget + mfeUrl.substring(mfeUrl.indexOf('.'));
            mfeUrl = newBaseUrl.replace('/route/' + mfeTarget, '');
        }
        //environment: prod
        else {
            let newBaseUrl: string = "https://" + mfeTarget + mfeUrl.substring(mfeUrl.indexOf('.'));
            mfeUrl = newBaseUrl.replace('/route/' + mfeTarget, '');
        }
        return mfeUrl;
    }

    //menu handler
    public microFrontendClicked(event, url?: string) {
        if (url)
            event = { url: url }
        if (event && event.url && event.url.indexOf("/route/") != -1) {
            let currentUrl = window.location.href;
            if (currentUrl.indexOf("/route/") != -1) {
                let currentMicroFEBase: string = window.location.href.toString();
                currentMicroFEBase = currentMicroFEBase.substring(currentMicroFEBase.indexOf("/route/") + 7, currentMicroFEBase.length);
                currentMicroFEBase = currentMicroFEBase.substring(0, currentMicroFEBase.indexOf("/"));
                let newMicroFEBase: string = event.url.toString();
                newMicroFEBase = newMicroFEBase.substring(newMicroFEBase.indexOf("/route/") + 7, newMicroFEBase.length);
                newMicroFEBase = newMicroFEBase.substring(0, newMicroFEBase.indexOf("/"));
                //same microFE
                if (currentMicroFEBase == newMicroFEBase) {
                    this.location.go(event.url.toString());
                    this.sendMicroFrontEndRoutingUrl({ routingUrl: event.url });
                } else {
                    this.router.navigateByUrl(event.url, { replaceUrl: true })
                }
            } else {
                this.router.navigateByUrl(event.url, { replaceUrl: true })
            }
        }

    }

}

export interface microFrontendMessage {
    token?: any,
    routingUrl?: string,
    navigateByUrl?: { url: string, replaceUrl: boolean }
    signalRMessages?: Array<any>,
    loader?: any,
    httpError?: any,
    pageNotFound?: boolean,
    pendingRequest?: { pending?, pendingId?, pendingRequestsList?},
    fullScreen?: { isFullscreen },
    id?: string,
    message?: any,
    baseUri?: boolean
}

export interface MicrofrontendHttpRequest {
    httpRequest?: boolean,
    method: 'get' | 'post' | 'put' | 'delete' | 'abort',
    url: string,
    options: MicroFrontendHttpOptions,
    response: { httpError: HttpErrorResponse, result: any }
}

export interface MicroFrontendHttpOptions {
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
}

export enum ProdMfeUrls {
    SAFOSERVICES = "https://safomf-core-prod.azurewebsites.net",
    GEMPORTALSERVICES = "https://safomf-core-us-prod.azurewebsites.net"
}