import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AlertService } from 'src/app/services/alert.service';
import { StorageVariablesService, SearchStateHistoryItem, SearchStateHistoryObject } from 'src/app/services/storage-variables.service';

@Component({
  selector: 'app-search-state-history-prompt-component',
  templateUrl: './search-state-history-prompt.component.html',
  styleUrls: ['./search-state-history-prompt.component.scss'],
})
export class SearchStateHistoryPrompt implements OnInit {
  //modal nav params
  public modalNavParams;
  //searchStateHistory
  public searchStateHistory: Array<SearchStateHistoryItem>
  //currentSearchTitle
  public currentSearchTitle;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    private alertSrv: AlertService,
    private _storageVariableSrv: StorageVariablesService
  ) {
    //setup nav params
    this.modalNavParams =
    {
      searchStateId: this.navParams.get("searchStateId") ? this.navParams.get("searchStateId") : null,
      searchStateObject: this.navParams.get("searchStateObject") ? this.navParams.get("searchStateObject") : null,
      dynamic: this.navParams.get("dynamic") ? this.navParams.get("dynamic") : false
    }
  }

  ngOnInit() {
    if (this.modalNavParams.searchStateId)
      this._getSearchStateHistory();
  }

  //get search state
  private _getSearchStateHistory() {
    this._storageVariableSrv.getSearchStateHistory().then((searchStateHistory: Array<SearchStateHistoryObject>) => {
      let searchStateIdTmp = this.modalNavParams.dynamic ? "DYNAMIC_SEARCH_STATE_" + this.modalNavParams.searchStateId : this.modalNavParams.searchStateId;
      let currentSearchHistoryObject = searchStateHistory ? searchStateHistory.filter((x) => { return x.searchStateId == searchStateIdTmp })[0] : null;
      if (currentSearchHistoryObject)
        this.searchStateHistory = currentSearchHistoryObject.searchStateHistoryList;
      else
        this.searchStateHistory = [];
    });
  }

  //itemChanged
  public itemChanged(item) {
    let exitsts = this.searchStateHistory.filter((x) => { return x.title == item.title }).length > 1;
    if (exitsts) {
      this.alertSrv.errorAlert(this.translate.instant("messages.titleAlreadyExists"));
      item.title = null;
    } else {
      this._storageVariableSrv.setSearchStateHistoryList(this.modalNavParams.searchStateId, this.searchStateHistory, this.modalNavParams.dynamic);
    }
  }

  //delete item
  public deleteItem(item) {
    this._storageVariableSrv.deleteSearchStateHistoryItem(this.modalNavParams.searchStateId, item.title, this.modalNavParams.dynamic);
    setTimeout(() => {
      this._getSearchStateHistory();
    }, 500)
  }

  //addCurrentSearch
  public addCurrentSearch() {
    let exitsts = this.searchStateHistory.filter((x) => { return x.title == this.currentSearchTitle })[0];
    if (exitsts) {
      this.alertSrv.errorAlert(this.translate.instant("messages.titleAlreadyExists"));
    } else {
      this._storageVariableSrv.setSearchStateHistoryItem(this.modalNavParams.searchStateId, this.modalNavParams.searchStateObject, this.modalNavParams.dynamic, this.currentSearchTitle);
      this.currentSearchTitle = null;
      setTimeout(() => {
        this._getSearchStateHistory();
      }, 500)
    }
  }

  //useSearchStateObject
  public useSearchStateObject(item) {
    this.modalCtrl.dismiss({ useSearchStateObject: item });
  }
}
