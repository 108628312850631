import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { IonContent, IonRange, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare const Buffer;
@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
})
export class ImageCropComponent implements OnInit {
  //cropper
  @ViewChild('angularCropper') public angularCropper: ImageCroppedEvent;
  @ViewChild("content") content: IonContent;
  cropperOptions: any;
  croppedImage = null;
  scaleValX = 1;
  scaleValY = 1;
  //scan loading
  public scanLoading = false;
  //image selected
  public selectedImage: string = null;
  //aspect ratio
  public aspectRatio = 8.5 / 5.5;
  //can change aspect ratio
  public canChangeAspectRatio = false;
  //file name
  public fileName = null;
  //transform
  transform: ImageTransform = {};
  //canvas rotation
  public canvasRotation = 0;
  //signature
  public transparentBackground: boolean = false;
  public signatureBlueValue: number = 125;
  private originalSignature: string;
  private signatureColorQuality = 190;
  private bgColorQuality = 20;
  public isSignatureEdit: boolean;

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public translate: TranslateService,
    private languageSrv: LanguageService, public loaderSrv: LoaderService) {
    //setup aspect ratio
    this.aspectRatio = this.navParams && this.navParams.get("aspectRatio") ? this.navParams.get("aspectRatio") : 1;
    this.canChangeAspectRatio = this.navParams && this.navParams.get("canChangeAspectRatio") ? this.navParams.get("canChangeAspectRatio") : false;
    this.transparentBackground = this.navParams && this.navParams.get("transparentBackground") ? true : false;
  }

  ngOnInit() {
  }

  public fileChangeEvent(event: any): void {
    //this.imageChangedEvent = event;
    //read file
    const file = event?.target?.files[0];
    if (file) {
      this.loaderSrv.open();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileName = file.name;
        this.signatureBlueValue = 126;
        this.isSignatureEdit = false;
        if (this.transparentBackground) {
          this.loaderSrv.dismiss();
          this.originalSignature = reader.result.toString();
          this.cleanBackground(true);
        } else {
          this.selectedImage = reader.result.toString();
          this.loaderSrv.dismiss();
        }
      }
      reader.onerror = () => {
        this.loaderSrv.dismiss();
      }
    }
  }


  //colorsAreClose 
  private colorsAreClose(a, z, threshold) {
    let r = a.R - z.R,
      g = a.G - z.G,
      b = a.B - z.B;
    return (r * r + g * g + b * b) <= threshold * threshold;
  }

  //crop image
  public imageCropped(event: ImageCroppedEvent) {
    //downscale
    let context = this;
    let canvas = document.createElement('canvas');
    let canvasContext = canvas.getContext('2d');
    let image = new Image();
    image.onload = function () {
      canvasContext.drawImage(image, 0, 0);
      let imgW = image.width;
      let imgH = image.height;
      //resize image max width 800px
      if (imgW == imgH) {
        if (imgW > 800) {
          imgW = 800;
          imgH = 800;
        }
      }
      if (imgW > imgH) {
        if (imgW > 800) {
          imgH = (800 * imgH) / imgW;
          imgW = 800;
        }
      } else {
        if (imgH > 800) {
          imgW = (800 * imgW) / imgH;
          imgH = 800;
        }
      }
      canvas.width = imgW;
      canvas.height = imgH;
      canvasContext.drawImage(image, 0, 0, imgW, imgH);
      //this.croppedImage = event.base64;
      context.croppedImage = canvas.toDataURL();
    };
    image.src = event.base64;
  }
  public imageLoaded() {
    // show cropper
  }
  public cropperReady() {
    // cropper ready
  }
  public loadImageFailed() {
    // show message
  }

  //image transform
  public imageTransform(action) {
    switch (action) {
      case "rotate":
        this.canvasRotation++;
        break;
      default:
        break;
    }
  }

  //save
  public save() {
    this.modalCtrl.dismiss({
      file: {
        base64: this.croppedImage,
        name: this.fileName
      }
    })
  }

  //signatureBlueValueChanged
  public cleanBackground(onlyBg: boolean) {
    let context = this;
    const image = new Image();
    image.onload = () => {
      const w = Math.round(image.width);
      const h = Math.round(image.height);
      const canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;
      const canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        w,
        h
      );
      const canvasImageData = canvasContext.getImageData(0, 0, w, h);
      var bgColor = { R: 255, G: 255, B: 255 }, colors = [];
      //calcolo colore sfondo
      for (
        let index = 0, dataLength = canvasImageData.data.length;
        index < dataLength;
        index += 4
      ) {
        const r = canvasImageData.data[index];
        const g = canvasImageData.data[index + 1];
        const b = canvasImageData.data[index + 2];
        var currentColor = colors.filter((color) => { return context.colorsAreClose(color.color, { R: r, G: g, B: b }, context.bgColorQuality) })[0];
        if (!currentColor)
          colors.push({ color: { R: r, G: g, B: b }, count: 0 });
        else {
          currentColor.count = currentColor.count + 1;
        }
      }
      bgColor = colors.sort((a, b) => { if (a.count > b.count) return -1; else return 1; })[0].color;
      //pulizia sfondo da bg
      for (
        let index = 0, dataLength = canvasImageData.data.length;
        index < dataLength;
        index += 4
      ) {
        const r = canvasImageData.data[index];
        const g = canvasImageData.data[index + 1];
        const b = canvasImageData.data[index + 2];
        if (context.colorsAreClose({ R: r, G: g, B: b }, bgColor, context.bgColorQuality))
          canvasImageData.data[index + 3] = 0;
        else
          canvasImageData.data[index + 3] = 255;
      }
      //pulizia sfondo da firma
      if (!onlyBg) {
        for (
          let index = 0, dataLength = canvasImageData.data.length;
          index < dataLength;
          index += 4
        ) {
          const r = canvasImageData.data[index];
          const g = canvasImageData.data[index + 1];
          const b = canvasImageData.data[index + 2];
          if (context.colorsAreClose({ R: r, G: g, B: b }, { R: 0, G: 0, B: this.signatureBlueValue }, context.signatureColorQuality)) {
            canvasImageData.data[index + 3] = 255;
          }
          else
            canvasImageData.data[index + 3] = 0;
        }
      }
      image.width = w;
      image.height = h;
      canvasContext.putImageData(canvasImageData, 0, 0);
      this.selectedImage = canvas.toDataURL();
    };
    image.crossOrigin = "";
    image.src = JSON.parse(JSON.stringify(this.originalSignature));
  }

  //loadImage
  public loadImage() {
    let inputFile = document.getElementById("inputFile");
    if (inputFile)
      inputFile.click();
  }

  //isSignatureEditChange
  public isSignatureEditChange() {
    if (!this.isSignatureEdit)
      this.cleanBackground(true);
    else
      this.cleanBackground(false);
  }

}
