import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import ViewAndFineLinksJSON from '../assets/view-and-fine-links.json';

@Injectable({
    providedIn: 'root'
})
export class ViewAndFineLinkService {
    private linksProd: ViewAndFineModel = ViewAndFineLinksJSON.prod;
    private linksPreProd: ViewAndFineModel = ViewAndFineLinksJSON.preProd;
    private linksTest: ViewAndFineModel = ViewAndFineLinksJSON.test;
    private linksLocal: ViewAndFineModel = ViewAndFineLinksJSON.local;
    private linksStage: ViewAndFineModel = ViewAndFineLinksJSON.stage

    public links: ViewAndFineModel;

    constructor() {
        if (environment["production"])
            this.links = this.linksProd;
        else if (environment["preProduction"])
            this.links = this.linksPreProd;
        else if (environment["test"])
            this.links = this.linksTest;
        else if (environment["stage"]) {
            this.links = this.linksStage;
        }
        else if (environment["local"])
            this.links = this.linksLocal;
    }
}

export interface ViewAndFineModel {
    server: string
}