<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{modalNavParams && modalNavParams.title ? modalNavParams.title : 'Gestione lista'}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="postit-board-list-management-component">
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-content>
      <div class="list-container" *ngIf="modalNavParams.list && modalNavParams.properties">
        <div class="list-headers" [ngClass]="{'list-headers-readonly': modalNavParams.readonly}">
          <div class="header-name" *ngFor="let header of modalNavParams.properties" [style.width]="header.width"
            [style.min-width]="header.width" [style.max-width]="header.width">
            <span><b>{{header.name}}</b></span>
          </div>
        </div>
        <div class="list-row list-row-add-row" *ngIf="rowAddRow && !modalNavParams.readonly">
          <div class="row-properties">
            <div class="row-property" *ngFor="let property of modalNavParams.properties" [style.width]="property.width"
              [style.min-width]="property.width" [style.max-width]="property.width">
              <input type="text" [(ngModel)]="rowAddRow[property.name]"
                placeholder="{{'postitBoard.labels.insert' | translate}}" *ngIf="property.type == 'TEXT'">
              <input type="number" [(ngModel)]="rowAddRow[property.name]"
                placeholder="{{'postitBoard.labels.insert' | translate}}" *ngIf="property.type == 'NUMBER'">
              <div class="selectable-wrapper"
                *ngIf="property.type == 'SELECTABLE' && property.selectableOptions && property.itemTextField && property.itemValueField">
                <ionic-selectable [(ngModel)]="rowAddRow[property.name]" [canSearch]="true"
                  [isMultiple]="property.isMultiple" closeButtonText="{{'postitBoard.buttons.cancel' | translate}}"
                  [items]="property.selectableOptions" placeholder="{{'postitBoard.labels.select' | translate}}"
                  itemTextField="{{property.itemTextField}}" itemValueField="{{property.itemValueField}}"
                  modalCssClass="postit-board-list-management-selectable" headerColor="primary"
                  searchPlaceholder="{{'postitBoard.labels.search' | translate}}" closeButtonSlot="end">
                </ionic-selectable>
              </div>
            </div>
          </div>
          <div class="row-buttons">
            <div class="row-button">
              <ion-icon name="add" (click)="addRow()"></ion-icon>
            </div>
          </div>
        </div>
        <div class="list-row" *ngFor="let row of modalNavParams.list;let rowIndex = index"
          id="{{modalNavParams.highlightRow ? 'row'+ row[modalNavParams.highlightRow.propertyTarget] : 'row'+rowIndex}}">
          <div class="row-properties" [ngClass]="{'row-properties-readonly': modalNavParams.readonly}">
            <div class="row-property" *ngFor="let property of modalNavParams.properties" [style.width]="property.width"
              [style.min-width]="property.width" [style.max-width]="property.width">
              <!-- label fields-->
              <div class="label" *ngIf="property.type == 'LABEL'">
                {{row[property.name]}}
              </div>
              <div class="label" *ngIf="property.type == 'LABEL_DATE'">
                {{row[property.name] | date:'dd/MM/yyyy'}}
              </div>
              <!-- input fields -->
              <input type="text" [(ngModel)]="row[property.name]" (focus)="setCurrentBeforeFocusValue(row, property)"
                (ngModelChange)="setHasChanges(row)" placeholder="{{'postitBoard.labels.insert' | translate}}"
                *ngIf="property.type == 'TEXT'">
              <input type="number" [(ngModel)]="row[property.name]" (focus)="setCurrentBeforeFocusValue(row, property)"
                (ngModelChange)="setHasChanges(row)" placeholder="{{'postitBoard.labels.insert' | translate}}"
                *ngIf="property.type == 'NUMBER'">
              <div class="selectable-wrapper"
                *ngIf="property.type == 'SELECTABLE' && property.selectableOptions && property.itemTextField && property.itemValueField">
                <ionic-selectable [(ngModel)]="row[property.name]" [canSearch]="true" [isMultiple]="property.isMultiple"
                  closeButtonText="{{'postitBoard.buttons.cancel' | translate}}" [items]="property.selectableOptions"
                  placeholder="{{'postitBoard.labels.select' | translate}}" itemTextField="{{property.itemTextField}}"
                  itemValueField="{{property.itemValueField}}" modalCssClass="postit-board-list-management-selectable"
                  headerColor="primary" searchPlaceholder="{{'postitBoard.labels.search' | translate}}"
                  closeButtonSlot="end" [canClear]="true" [clearButtonText]="'labels.clear' | translate"
                  (click)="setCurrentBeforeFocusValue(row, property)" (ngModelChange)="setHasChanges(row);"
                  (onClose)="selectableSelect(row, property);">
                </ionic-selectable>
              </div>
            </div>
          </div>
          <div class="row-buttons" *ngIf="!modalNavParams.readonly">
            <div class="row-button" [hidden]="!row['hasChanges']">
              <ion-icon name="create" (click)="editRow(row)" class="icon-success"></ion-icon>
            </div>
            <div class="row-button">
              <ion-icon name="close" (click)="deleteRow(row)" class="icon-danger"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <!-- buttons-->
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>Chiudi</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>