import { NgModule } from '@angular/core';
import { AdminUserDetailModalComponent } from './admin-user-detail-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
;
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceAccessConfigurationComponentModule } from '../../components/service-access-configuration/service-access-configuration.component.module'
import { MatTabsModule } from '@angular/material/tabs';
import { ClipboardModule } from '@angular/cdk/clipboard'
@NgModule({
    declarations: [
        AdminUserDetailModalComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
    
        MatExpansionModule,
        ServiceAccessConfigurationComponentModule,
        MatTabsModule,
        ClipboardModule
    ],
    exports: [
        AdminUserDetailModalComponent
    ]
})
export class AdminUserDetailModalComponentModule { }