import { NgModule } from '@angular/core';
import { IonicModule } from "@ionic/angular";
import { DateFormat } from './date-format.pipe';
@NgModule({
    declarations: [
        DateFormat
    ],
    imports: [
        IonicModule
    ],
    exports: [
        DateFormat
    ]
})
export class DateFormatPipeModule { }