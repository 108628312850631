<div class="postit-board-toolbar" *ngIf="mainLayout" [style.height]="mainLayout.toolbarHeight + 'px'"
  [style.min-height]="mainLayout.toolbarHeight + 'px'" [style.max-height]="mainLayout.toolbarHeight + 'px'">
  <div class="postit-board-toolbar-group">
    <div class="postit-board-toolbar-item">
      <div class="postit-board-toolbar-button">
        <ion-icon name="arrow-undo" (click)="emitUndoResourceMode()"></ion-icon>
      </div>
    </div>
  </div>
  <div class="postit-board-toolbar-group">
    <div class="postit-board-toolbar-item">
      <div class="postit-board-toolbar-item-date">
        <input type="date" [(ngModel)]="dateFrom">
        -
        <input type="date" [(ngModel)]="dateTo">
        <div class="postit-board-toolbar-button" (click)="emitGetBoard()">
          <span>Cerca</span>
        </div>
      </div>
    </div>
  </div>
  <div class="postit-board-toolbar-group">
    <div class="postit-board-toolbar-item">
      <div class="postit-board-toolbar-button" (click)="openUpload()">
        <span>Upload</span>
      </div>
    </div>
    <div class="postit-board-toolbar-item">
      <div class="postit-board-toolbar-button" (click)="openGroupsListManagement()">
        <span>Gruppi</span>
      </div>
    </div>
    <div class="postit-board-toolbar-item">
      <div class="postit-board-toolbar-button" (click)="openActivitiesListManagement()">
        <span>Attività</span>
      </div>
    </div>
  </div>
</div>