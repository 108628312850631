import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController, AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AlertService } from 'src/app/services/alert.service';
import { AdminTerritoryDetailService } from 'src/app/services/admin-territory-detail.service';
import { NewsDetailService } from 'src/app/services/news-detail.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-detail-popover',
  templateUrl: './news-detail-popover.component.html',
  styleUrls: ['./news-detail-popover.component.scss'],
})
export class NewsDetailPopoverComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //companyOptions (territory)
  public companyOptions = [];
  //cover image
  public selectedFile = {
    name: null,
    base64: null
  };

  constructor(public translate: TranslateService,
    private alertSrv: AlertService,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    public adminTerritoryDetailSrv: AdminTerritoryDetailService,
    public newsDetailSrv: NewsDetailService,
    public domSanitizer: DomSanitizer) { }



  ngOnInit() {
    //setup nav params
    this.modalNavParams =
    {
      news: this.navParams.get("news") ? this.navParams.get("news") : null
    }
    //setup clienti
    this.getClienti();
  }

  ngAfterViewInit() {
    if (this.modalNavParams && this.modalNavParams.news && this.modalNavParams.news.id && !this.modalNavParams.news.previewMode)
      this.getAttachmentNews(this.modalNavParams.news.id)
  }

  //get clienti
  private getClienti() {
    this.adminTerritoryDetailSrv.listaAziende().then((res: any) => {
      if (res.code == 200) {
        this.companyOptions = res.payload;
      } else {
        this.companyOptions = [];
      }
    }).catch((err) => {
      
    })
  }
  //get image body
  public getAttachmentNews(newsId) {
    this.newsDetailSrv.getAttachmentNews(newsId).then((res: any) => {
      if (res.code == 200) {
        this.selectedFile["name"] = res.payload.nomeFile;
        this.selectedFile["base64"] = this.domSanitizer.bypassSecurityTrustResourceUrl(res.payload.attachmentBody);
      }
    }).catch((err) => {
      
    })

  }
}
