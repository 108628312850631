import { NgModule } from '@angular/core';
import { AdminRoleDetailPopoverComponent } from './admin-role-detail-popover.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModulesTreeComponentModule } from "../../components/modules-tree/modules-tree.component.modules"
import { HelpComponentModule } from '../../components/help/help.component.module'
import { MatTabsModule } from '@angular/material/tabs';
import { ServiceAccessConfigurationComponentModule } from '../../components/service-access-configuration/service-access-configuration.component.module'
@NgModule({
    declarations: [
        AdminRoleDetailPopoverComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        ModulesTreeComponentModule,
        HelpComponentModule,
        MatTabsModule,
        ServiceAccessConfigurationComponentModule
    ],
    exports: [
        AdminRoleDetailPopoverComponent
    ]
})
export class AdminRoleDetailPopoverComponentModule { }