import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PostitBoardToolbarComponent } from './postit-board-toolbar.component';
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [PostitBoardToolbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports: [
    PostitBoardToolbarComponent
  ]
})
export class PostitBoardToolbarComponentModule { }
