<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{('labels.select' | translate)}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<!--   -->
<ion-content  class="input-selectable-modal-component" id="pssfeselectable"
  #pssfeselectable>
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-content>
      <form [formGroup]="form" (ngSubmit)="getListByEndpointButton(form.valid)"
        (keyup)="getListByEndpointButton(form.valid, $event)">
        <ion-row>
          <!-- key -->
          <ion-col size-md="10" size-sm="10" size-xs="12">
            <div class="search-box">
              <input type="text" formControlName="key" placeholder="{{'labels.search' | translate}}"
                id="searchkey" #searchkey>
              <div class="local-cache-list" [hidden]="!localCacheList || localCacheList.length == 0">
                <div class="local-cache-list-header">
                  <span>{{'labels.local_cache_list' | translate}}:&nbsp;</span>
                </div>
                <div class="local-cache-list-item" *ngFor="let item of localCacheList">
                  <span (click)="selectLocalCacheItem(item)">{{item.text}}</span>
                  <ion-icon name="close" (click)="deleteLocalCacheItem(item)"></ion-icon>
                </div>
              </div>
            </div>
          </ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="12" class="col-button-box">
            <div class="button-box">
              <ion-button color="primary" type="submit" size="default" [disabled]="!form.valid || searchLoading">
                <ion-label>{{'buttons.search' | translate}}</ion-label>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </form>
      <!-- results -->
      <ion-row class="results-row">
        <ion-col size-md="12" size-sm="12" size-xs="12" class="h100" style="padding: 0px;">
          <div class="search-results h100" id="scrollResult" #scrollResult>
            <!-- item -->
            <div class="results" *ngIf="searchResult && searchResult.length > 0">
              <div class="results-item" *ngFor="let item of searchResult;let i = index;"
                [ngClass]="{'selected-item': isItemSelected(item)}" id="item-{{i}}" tabindex="1"
                (click)="selectItem(item)" (keyup)="selectItemByKey(item, $event)" (keydown)="$event.preventDefault()">
                <div class="item-field">
                  <div class="item-label">
                    <div [style.width]="columns.textWidth + '%'" *ngIf="columns.names.includes('text')"
                      matTooltip="{{item.text}}" [hidden]="item.text && item.text == 'MERGE'">
                      {{item.text}}
                    </div>
                    <div [style.width]="columns.text2Width + '%'" *ngIf="columns.names.includes('text2')"
                      matTooltip="{{item.text2}}" [hidden]="item.text2 && item.text2 == 'MERGE'">
                      {{item.text2}}
                    </div>
                    <div [style.width]="columns.text3Width + '%'" *ngIf="columns.names.includes('text3')"
                      matTooltip="{{item.text3}}" [hidden]="item.text3 && item.text3 == 'MERGE'">
                      {{item.text3}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-center-center results-item">
                <button [disabled]="searchLoading || !searchResult || searchResult.length >= totalResult"
                  (click)="getListByEndpointLoadMore()" tabindex="1" id="loadMore">
                  <ion-label>{{'buttons.load-more' | translate}}</ion-label>
                </button>
              </div>
            </div>
            <!-- no results -->
            <div class="no-results" *ngIf="searchResult && searchResult.length == 0">
              {{'labels.no-results-found' | translate}}
            </div>
            <!-- loading  -->
            <div class="flex-center-center" *ngIf="searchLoading">
              <ion-spinner name="lines-small"></ion-spinner>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <!-- selected list -->
  <ion-row class="selected-list-row">
    <ion-col size-md="12" size-sm="12" size-xs="12">
      <div class="selected-list">
        <div class="selected-list-header">
          <span>{{'labels.items_selected' | translate}}&nbsp;({{selectedList.length}})</span>
          <div class="pagination" *ngIf="searchResult && searchResult.length > 0">
            {{searchResult.length}}&nbsp;{{'labels.items-of' |translate}}&nbsp;{{totalResult}}
          </div>
        </div>
        <div class="selected-list-item" *ngFor="let item of selectedList">
          {{item.text}}
          <ion-icon name="close" (click)="deleteSelected(item)"></ion-icon>
        </div>
      </div>
    </ion-col>
  </ion-row>
  <!-- buttons-->
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>{{'labels.close' | translate}}</ion-label>
        </ion-button>
        <ion-button color="primary" size="default" (click)="select()"
          [disabled]="selectedList && selectedList.length == 0"
          [hidden]="!modalNavParams.input.multiple">
          <ion-label>{{'labels.select' | translate}}</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>