import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableNumero'
})
export class TableNumeroPipe implements PipeTransform {

  constructor() { }
  transform(string: string) {
    if (string && parseInt(string)) {
      string = parseInt(string).toLocaleString('it-IT');
    }
    return string;
  }
}
