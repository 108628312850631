import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableDecimale'
})
export class TableDecimalePipe implements PipeTransform {

  constructor() { }
  transform(string) {
    if (string && !(Number.isNaN(string))) {
      string = string.toString();
      if (string.indexOf(".") != -1) {
        let integer = string.substring(0, string.indexOf("."));
        integer = parseInt(integer).toLocaleString('it-IT');
        string = integer + "," + string.substring(string.indexOf(".") + 1, string.length) + (string.substring(string.indexOf(".") + 1, string.length).length < 2 ? "0" : "");
      } else {
        string = parseInt(string).toLocaleString('it-IT') + ",00";
      }
    }
    return string;
  }
}
