<!-- title card -->
<div class="page-title-card" *ngIf="crpFE">
    <img [src]="crpFE.crpIcon">
    <div class="title-data">
        <div>
            {{crpFE.crpTitle}}
        </div>
        <span>
            {{crpFE.crpSubTitle}}<ion-icon name="information-circle-outline" [matTooltip]="crpFE.descriptions['-1']"
                *ngIf="crpFE.descriptions && crpFE.descriptions['-1']" matTooltipClass="table-tooltip"
                [ngClass]="{'ml-10px': crpFE.crpSubTitle}"></ion-icon>
        </span>
    </div>
</div>
<!-- no form -->
<div *ngIf="crpFE && !crpFE.formGroup" class="no-form-box button-box">
    <ion-button color="primary" size="default" (click)="extract('EXCEL')" [disabled]="disableExtract">
        <ion-label>{{'buttons.extractExcel' | translate}}</ion-label>
    </ion-button>
    <ion-button color="primary" size="default" [disabled]="crpFE.formGroup && !crpFE.formGroup.valid"
        (click)="pollingFinished ? execute({keyUp: null, isExtract: false}) : stopPolling()">
        <ion-label>{{pollingFinished ? ('buttons.search' | translate) :
            ('buttons.stopPolling' |
            translate)}}</ion-label>
    </ion-button>
</div>
<!-- form -->
<ion-card class="page-card" *ngIf="crpFE && crpFE.formGroup">
    <ion-card-header class="cursor-pointer">
        <ion-card-title>
            <div class="title-inner">
                {{'labels.research' | translate}}
            </div>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <!-- form -->
        <div class="crp-form">
            <form [formGroup]="crpFE.formGroup" (keyup)="execute({ keyUp: $event, isExtract: false })">
                <ion-row>
                    <!-- form field  -->
                    <ion-col size-md="3" size-sm="3" size-xs="12"
                        *ngFor="let field of crpFE.formGroupConfig;let i = index;" [hidden]="field.hidden"
                        [ngClass]="{'field-readonly': field.readOnly}">
                        <ion-item class="input-field" lines="none">
                            <ion-label position="stacked">
                                {{field.label}}<span *ngIf="field.required">*</span></ion-label>
                            <!-- STRINGA -->
                            <ion-input type="text" formControlName="{{field.property}}"
                                placeholder="{{'labels.insert' | translate}}" *ngIf="field.type == 'STRINGA'"
                                [disabled]="field.readOnly">
                            </ion-input>
                            <!-- NUMERO -->
                            <ion-input type="number" formControlName="{{field.property}}"
                                placeholder="{{'labels.insert' | translate}}" *ngIf="field.type == 'NUMERO'"
                                [disabled]="field.readOnly">
                            </ion-input>
                            <!-- DATAORA -->
                            <div class="date-time-input" *ngIf="field.type == 'DATAORA'">
                                <input matInput [ngxMatDatetimePicker]="$any(formField_i)"
                                    formControlName="{{field.property}}"
                                    [ngClass]="{'empty-date-input': !crpFE.formGroup.get(field.property).value}"
                                    placeholder="{{'DATETIME' | dateFormatPlaceholder}}" [disabled]="field.readOnly">
                                <mat-datepicker-toggle matSuffix [for]="$any(formField_i)"
                                    [ngClass]="{'empty-date-input': !crpFE.formGroup.get(field.property).value}"
                                    [hidden]="field.readOnly">
                                </mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #formField_i [showSeconds]="false">
                                </ngx-mat-datetime-picker>
                            </div>
                            <!-- DATA -->
                            <div class="date-time-input" *ngIf="field.type == 'DATA'">
                                <input matInput [matDatepicker]="formField_i" formControlName="{{field.property}}"
                                    [ngClass]="{'empty-date-input': !crpFE.formGroup.get(field.property).value}"
                                    placeholder="{{'DATE' | dateFormatPlaceholder}}" [disabled]="field.readOnly">
                                <mat-datepicker-toggle matSuffix [for]="$any(formField_i)"
                                    [ngClass]="{'empty-date-input': !crpFE.formGroup.get(field.property).value}"
                                    [hidden]="field.readOnly">
                                </mat-datepicker-toggle>
                                <mat-datepicker #formField_i>
                                </mat-datepicker>
                            </div>
                            <!-- COMBO -->
                            <ion-select formControlName="{{field.property}}" [cancelText]="'buttons.cancel' | translate"
                                interface="popover" [okText]="'buttons.ok' | translate"
                                placeholder="{{'labels.select' | translate}}" multiple="false"
                                *ngIf="field.type == 'COMBO' && field.endpointpreloaded == true" [disabled]="field.readOnly">
                                <ion-select-option [value]="option.id" *ngFor="let option of field.options">
                                    {{option.desc}}</ion-select-option>
                            </ion-select>
                            <!-- MULTICOMBO -->
                            <ion-select formControlName="{{field.property}}" [cancelText]="'buttons.cancel' | translate"
                                [okText]="'buttons.ok' | translate" placeholder="{{'labels.select' | translate}}"
                                multiple="true" *ngIf="field.type == 'MULTICOMBO'" [disabled]="field.readOnly">
                                <ion-select-option [value]="option.id" *ngFor="let option of field.options">
                                    {{option.desc}}</ion-select-option>
                            </ion-select>
                            <!-- COMBO LISTA NON PRECARICATA -->
                            <ionic-selectable formControlName="{{field.property}}" itemValueField="value" [items]="null"
                                    itemTextField="text" [canSearch]="true" [isMultiple]="false"
                                    closeButtonText="{{'buttons.close' | translate}}"
                                    placeholder="{{'labels.select' | translate}}"
                                    searchFailText="{{'errors.6' | translate}}"
                                    searchPlaceholder="{{'labels.search' | translate}}" closeButtonSlot="end"
                                    (onSearch)="getListByEndpoint($event, field.endpointurl)"
                                    [searchDebounce]="500" [shouldFocusSearchbar]="true" 
                                    *ngIf="field.type == 'COMBO' && field.endpointpreloaded == false"
                                    id="selectableField"  #selectableField>
                                   <ng-template ionicSelectableSearchFailTemplate>
                                        <div [hidden]="isSelectableItemsEmpty('selectableField') == null"
                                            class="selectable-no-results">
                                            {{'errors.6' | translate}}
                                        </div>
                                    </ng-template> 
                                </ionic-selectable> 
                        </ion-item>
                    </ion-col>
                </ion-row>
            </form>
            <ion-row>
                <ion-col size-md="3" size-sm="3" size-xs="12" class="col-button-box">
                    <ion-item class="box-required" lines="none">
                        <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' |
                            translate}}
                        </ion-label>
                    </ion-item>
                </ion-col>
                <ion-col size-md="9" size-sm="9" size-xs="12" class="col-button-box">
                    <div class="button-box">
                        <ion-button color="primary" size="default" (click)="cleanFilters()">
                            <ion-label>{{'buttons.clean_filters' | translate}}</ion-label>
                        </ion-button>
                        <ion-button color="primary" size="default" (click)="extract('EXCEL')"
                            [disabled]="disableExtract">
                            <ion-label>{{'buttons.extractExcel' | translate}}</ion-label>
                        </ion-button>
                        <ion-button class="button-left" (click)="openSearchStateHistory()"
                            title="{{'labels.searchStateHistory' | translate}}">
                            <ion-icon name="list"></ion-icon>
                        </ion-button>
                        <ion-button class="button-right" color="primary" size="default"
                            [disabled]="crpFE.formGroup && !crpFE.formGroup.valid"
                            (click)="pollingFinished ? execute({keyUp: null, isExtract: false}) : stopPolling()">
                            <ion-label>{{pollingFinished ? ('buttons.search' | translate) :
                                ('buttons.stopPolling' |
                                translate)}}</ion-label>
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </div>
    </ion-card-content>
</ion-card>

<!-- queries -->
<div class="query-list" *ngIf="crpFE && crpFE.queries">
    <ion-card class="page-card" *ngFor="let query of crpFE.queries; let queryIndex = index">
        <ion-card-header
            (click)="!query.loading &&  !query.pollingStopped ? query.expanded = !query.expanded : query.expanded = query.expanded"
            class="cursor-pointer"
            [ngClass]="{'ion-card-header-danger': query.errorsOccured, 'ion-card-header-grey': query.pollingStopped}">
            <ion-card-title>
                <div class="title-inner">
                    <ion-icon name="warning" [hidden]="!query.pollingStopped"></ion-icon>
                    <ion-spinner name="lines-small" [hidden]="query.threadFinished || query.pollingStopped">
                    </ion-spinner>
                    {{query.threadFinished || query.pollingStopped ? query.qryTitle : ('buttons.loading' | translate)}}
                    <ion-icon name="information-circle-outline" [matTooltip]="crpFE.descriptions[query.idxQry]"
                        *ngIf="crpFE.descriptions && crpFE.descriptions[query.idxQry]"
                        matTooltipClass="table-tooltip"></ion-icon>
                </div>
            </ion-card-title>
        </ion-card-header>
        <ion-card-content [ngClass]="{'result-collapsed': !query.expanded, 'result-expanded': query.expanded}">
            <!-- errorsOccured -->
            <div class="query-error" *ngIf="query.threadFinished && query.errorsOccured">
                <span>
                    {{query.errorsOccured}}
                </span>
            </div>
            <!-- result -->
            <div class="query-result" *ngIf="query.threadFinished && !query.errorsOccured">
                <!-- table filter -->
                <div class="query-filter" *ngIf="query.filter">
                    <b>{{'labels.filter' |
                        translate}}:&nbsp;</b>{{query.filter.column.columnHeader}}&nbsp;{{query.filter.comparison}}&nbsp;{{query.filter.element[query.filter.column.columnDef]}}
                    <ion-icon name="close" (click)="deleteFilter(query.tableId)"></ion-icon>
                </div>
                <!-- table -->
                <div class="query-table"
                    [hidden]="query.FEReportType != FEReportType.TABLE || !query.result || !query.result.filteredData || query.result.filteredData.length == 0">
                    <app-table [tableColumns]="query.tableConfig?.tableColumns"
                        [tableDataSource]="query ? query.result : null" [fileName]="query.qryTitle"
                        [selectedListParam]="'Id'" [tablePaginatorPageSize]="10" [tableId]="query.tableId"
                        [tablePaginatorRange]="[10, 50, 100]" [paginationFrontend]="true"
                        (extractApi)="extractApi($event)" (tablePaginatorChange)="tablePaginatorChange($event)"
                        #query.tableId>
                    </app-table>
                </div>
                <!-- chart bar -->
                <div class="query-tab-chart" *ngIf="query.FEReportType != FEReportType.TABLE && query.resultChart">
                    <ion-row>
                        <ion-col size-md="12" size-sm="12" size-xs="12" *ngIf="query.resultChart.filters && query.resultChart.filters.length > 0">
                            <div class="chart-filter">
                                <div class="chart-filter-title">
                                    {{'labels.filters' | translate}}
                                </div>
                                <div class="chart-filter-data">
                                    <div class="chart-filter-active" *ngIf="query.resultChart.activeFilter">
                                        <span>{{'labels.activeFilter' |
                                            translate}}:&nbsp;</span>{{query.resultChart.activeFilter}}<ion-icon
                                            name="close"
                                            (click)="openChartComparisonPopover(query, query.resultChart.originalResult, null)">
                                        </ion-icon>
                                    </div>
                                    <div class="chart-filter-item"
                                        *ngFor="let filter of query.resultChart.filters;let filterIndex = index"
                                        (click)="openChartComparisonPopover(query, filter, 'filter-'+queryIndex+'-'+filterIndex)"
                                        id="filter-{{queryIndex}}-{{filterIndex}}">
                                        {{filter.columnHeader
                                        ? (filter.columnHeader | translate) : ''}}
                                    </div>
                                </div>
                            </div>
                        </ion-col>
                        <ion-col size-md="12" size-sm="12" size-xs="12"
                            [ngClass]="{'chart-small': query.FEReportType != 'bar' && query.FEReportType != 'line'}">
                            <div class="chart">
                                <!--<canvas baseChart [datasets]="query.resultChart.groupedData"
                                    [labels]="query.resultChart.groupedLables"
                                    [colors]="query.resultChart.lineChartColors" [options]="chartOptions"
                                    [legend]="query.FEReportType != 'bar' && query.FEReportType != 'line'"
                                    [type]="query.FEReportType">
                                </canvas>-->
                                <canvas baseChart [data]="query.resultChart.data" [type]="query.FEReportType"
                                    [options]="chartOptions"> </canvas>
                            </div>
                        </ion-col>
                    </ion-row>
                </div>
                <div class="table-no-results"
                    *ngIf="query.result && query.result.filteredData && query.result.filteredData.length == 0">
                    <span>{{'labels.results_not_found' | translate}}</span>
                </div>
                <!-- buttons -->
                <ion-row *ngIf="query.FEReportType == FEReportType.TABLE">
                    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
                        <div class="button-box">
                            <ion-button color="primary" size="default" (click)="openUpdateRowsPopover(query.tableId)"
                                [hidden]="!query.tableConfig || !query.tableConfig.updateColumns || !query.result || !query.result.filteredData || query.result.filteredData.length == 0 || !query.updateButton"
                                [disabled]="getTableByTableId(query.tableId)?.selectedList?.length == 0 || query.loading">
                                <ion-label>{{'buttons.update' | translate}}</ion-label>
                            </ion-button>
                        </div>
                    </ion-col>
                </ion-row>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-grid>
        <ion-row *ngIf="showBack">
            <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
                <div class="button-box">
                    <ion-button color="tertiary" size="default" (click)="back()">
                        <ion-label>{{'buttons.back' | translate}}</ion-label>
                    </ion-button>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>

</div>