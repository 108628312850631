<ion-content>
  <div class="iframe-box">
    <iframe [src]="link | safe">

    </iframe>
  </div>
</ion-content>
<ion-footer>
  <div class="toolbar">
    <div (click)="this.modalDismiss();"><ion-icon name="chevron-back-outline"></ion-icon><span>{{'buttons.return_to_app' | translate}}</span></div>
  </div>
</ion-footer>