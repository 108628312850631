import { NgModule } from '@angular/core';
import { WebviewModalComponent } from './webview-modal.component';
import { SafePipeModule} from '../../pipes/safe-pipe/safe.pipe.module'
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        WebviewModalComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        SafePipeModule,
        TranslateModule
    ],
    exports: [
        WebviewModalComponent
    ]
})
export class WebviewModalComponentModule {}