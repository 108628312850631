import { NgModule } from '@angular/core';
import { CustomReportConfigurationComponent } from './custom-report-configuration.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { getDateFormat, getDateTimeFormat} from 'src/app/services/app.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@NgModule({
    declarations: [
        CustomReportConfigurationComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        DragDropModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
        MatTabsModule,
        IonicSelectableComponent
    ],
    exports: [
        CustomReportConfigurationComponent
    ],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat() }
    ]
})
export class CustomReportConfigurationComponentModule { }