<ion-content class="recipients-list-popover" >
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.recipients' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <!-- form -->
      <form [formGroup]="searchForm">
        <ion-grid>
          <ion-row>
            <!-- name -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.name' | translate}}</ion-label>
                <ion-input type="text" formControlName="name" placeholder="{{'labels.insert' | translate}}" #searchInput>
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="dataSource && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)">
            <ion-col>
              <span>
                <b>{{'labels.results_not_found' | translate}}</b>
              </span>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <!-- table -->
      <div class="search-results">
        <div class="table-box">
          <table mat-table [dataSource]="dataSource" matSort>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <ng-container matColumnDef="nominativo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.nominative' | translate}} </th>
              <td mat-cell *matCellDef="let element">
                {{element.nominativo ? element.nominativo : ('labels.unavailable' | translate)}} </td>
            </ng-container>
            <ng-container matColumnDef="messaggioLetto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.read' | translate}} </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex-center-center">
                  <ion-icon color="success" name="checkmark" *ngIf="element.messaggioLetto"></ion-icon>
                  <ion-icon color="danger" name="close" *ngIf="!element.messaggioLetto"></ion-icon>
                </div>
              </td>
            </ng-container>
          </table>
        </div>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]">
        </mat-paginator>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>