<!-- item -->
<div class="tree-item">
  <!-- item data and roles -->
  <div class="tree-item-data" [hidden]="item.hideTerritory"
    [ngClass]="{'last-item' : (!item[childrenKey]) || (item[childrenKey] && item[childrenKey].length == 0), 'opened' : item['opened']}">
    <!-- item title -->
    <div class="tree-item-title">
      <div class="tree-item-checkbox" [hidden]="hideCheckboxParent">
        <input type="checkbox" [(ngModel)]="item['selected']" (click)="$event.stopPropagation(); selectCustomer(item)"
          [ngClass]="{'check-disabled' : disableTerritoryCheckbox}">
      </div>
      <div class="tree-item-role-select" title="{{'labels.select_deselect_all' | translate}}"
        *ngIf="item.ruoli && item.ruoli.length > 0 && showSeletAllRoles && !disableRoleCheckbox">
        <input type="checkbox" [(ngModel)]="flgSelectAllRoles" (click)="selectAllRoles(item);$event.stopPropagation();">
      </div>
      <div class="tree-item-name">
        {{item[titleKey]}}&nbsp;<span *ngIf="item[descriptionKey]">({{'modules.'+item[descriptionKey] |
          translate}})</span>
      </div>
      <!-- territory indicator-->
      <div class="tree-item-indicator"
        (click)="item[childrenKey] ? item['opened'] = !item['opened'] : item['opened'] = item['opened']"
        [ngClass]="{'tree-item-indicator-disabled': !item[childrenKey] || item[childrenKey].length == 0}">
        <span [hidden]="hideTerritoryCount">{{'labels.territories' | translate}}&nbsp;({{item[childrenKey] ?
          item[childrenKey].length : 0}})</span>
        <ion-icon *ngIf="item[childrenKey] && item[childrenKey].length > 0" class="territory-indicator"
          name="chevron-forward-outline" color="primary"></ion-icon>
        <ion-icon *ngIf="!item[childrenKey] || item[childrenKey].length == 0" name="chevron-forward-outline"></ion-icon>
      </div>
      <!-- roles indicator -->
      <div class="tree-item-indicator" *ngIf="showRolesIndicator" (click)="item['rolesOpened'] = !item['rolesOpened']"
        [ngClass]="{'roles-opened' : item['rolesOpened'], 'tree-item-indicator-disabled': !item.ruoli || item.ruoli.length ==  0}">
        <span>{{'labels.roles' | translate}}&nbsp;({{item.ruoli ? item.ruoli.length : 0}})</span>
        <ion-icon *ngIf="item.ruoli && item.ruoli.length > 0" class="roles-indicator" name="chevron-forward-outline">
        </ion-icon>
        <ion-icon *ngIf="!item.ruoli || item.ruoli.length ==  0" name="chevron-forward-outline"></ion-icon>
      </div>
    </div>
    <!-- item roles -->
    <div class="tree-item-roles"
      *ngIf="item.ruoli && item.ruoli.length > 0 && (!showRolesIndicator || (showRolesIndicator && item['rolesOpened']))">
      <div class="role" *ngFor="let role of item.ruoli" [hidden]="role.hideRole">
        <div class="role-checkbox" [hidden]="hideCheckboxRole">
          <input type="checkbox" [(ngModel)]="role['selected']" (ngModelChange)="roleCheckboxSelected(item, role)"
            [ngClass]="{'check-disabled' : disableRoleCheckbox}">
        </div>
        <div class="role-selected" *ngIf="role['role-selected']">
          <ion-icon name="checkmark-outline"></ion-icon>
        </div>
        <div class="role-name-clickable" [hidden]="hideSelector || !itemSelect"
          (click)="$event.stopPropagation(); selectRole(item, role)" [ngClass]>
          <ion-icon name="person-sharp" color="primary"></ion-icon>{{role.nome}}
        </div>
        <div class="role-name" [hidden]="!(hideSelector || !itemSelect)">
          {{role.nome}}
        </div>
        <ion-icon class="role-detail" name="search-outline" *ngIf="showRoleDetail"
          (click)="roleDetailPopover(role.id);$event.stopPropagation();"></ion-icon>
      </div>
    </div>
  </div>
  <!-- item to repeat -->
  <div class="child" *ngIf="item[childrenKey]" [hidden]="!item['opened']">
    <div *ngIf="item[childrenKey].length > 0">
      <div *ngFor="let child of item[childrenKey]">
        <app-infinite-tree [item]="child" [titleKey]="titleKey" [childrenKey]="childrenKey" [singleRole]="singleRole"
          [descriptionKey]="descriptionKey" [hideSelector]="hideSelector" [hideCheckboxRole]="hideCheckboxRole"
          [hideCheckboxParent]="hideCheckboxParent" [showSeletAllRoles]="showSeletAllRoles"
          [showRoleDetail]="showRoleDetail" [showRolesIndicator]="showRolesIndicator" [allOpened]="allOpened"
          [hideTerritoryCount]="hideTerritoryCount" [disableTerritoryCheckbox]="disableTerritoryCheckbox"
          [disableRoleCheckbox]="disableRoleCheckbox" (itemSelect)="selectChild($event)"></app-infinite-tree>
      </div>
    </div>
  </div>
</div>