import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MessagesBoxComponent } from './messages-box.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [
        MessagesBoxComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatTabsModule
    ],
    exports: [
        MessagesBoxComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagesBoxComponentModule {}