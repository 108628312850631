import { NgModule } from '@angular/core';
import { TablePopupInputComponent } from './table-popup-input.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { getDateFormat, getDateTimeFormat } from 'src/app/services/app.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFormatPlaceholderPipeModule } from 'src/app/pipes/date-format-placeholder-pipe/date-format-placeholder.pipe.module';

@NgModule({
    declarations: [
        TablePopupInputComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
        DateFormatPlaceholderPipeModule
    ],
    exports: [
        TablePopupInputComponent
    ],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat() },
        {
            provide: DateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: getDateFormat() },
    ]
})
export class TablePopupInputComponentModule { }