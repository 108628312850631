import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import 'hammerjs';

//pages
import { PostitBoardMainPageModule } from './pages/postit-board-main/postit-board-main.module';

//components
import { PostitBoardToolbarComponentModule } from './components/postit-board-toolbar/postit-board-toolbar.component.module';
import { PostitBoardBoardComponentModule } from './components/postit-board-board/postit-board-board.component.module';
import { PostitBoardStatsComponentModule } from './components/postit-board-stats/postit-board-stats.component.module';

//modals
import { PostitBoardListManagementComponentModule } from './modals/postit-board-list-management/postit-board-list-management.component.module';
import { PostitBoardFileUploadComponentModule } from './modals/postit-board-file-upload/postit-board-file-upload.component.module';
@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        PostitBoardMainPageModule,
        PostitBoardToolbarComponentModule,
        PostitBoardBoardComponentModule,
        PostitBoardStatsComponentModule,
        PostitBoardListManagementComponentModule,
        PostitBoardFileUploadComponentModule
    ],
    exports: [
    ],
    declarations: [
    ]
})
export class PostitBoardModule { }
