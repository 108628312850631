import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AppService } from 'src/app/services/app.service';
import { PinPopoverTypes } from './models/pin-popover.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-pin-popover',
  templateUrl: './pin-popover.component.html',
  styleUrls: ['./pin-popover.component.scss'],
})
export class PinPopoverComponent implements OnInit, AfterViewInit {
  //pin: pin corrente digitato dall'utente
  public pinForm: FormGroup;
  //pinType: tipologia di pin
  public pinType: string;
  //pinMessage: mssaggio per l'utente
  public pinMessage: string;
  //pinMaxLength: lunghezza massima del pin
  private _pinMaxLength: number;
  //pinPopoverTypes
  public pinPopoverTypes = PinPopoverTypes;
  //shiftActive
  public shiftActive: boolean = false;

  constructor(
    public translate: TranslateService,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public alertSrv: AlertService,
    private _AppSrv: AppService,
    public formBuilder: FormBuilder) {
    //setup validators
    this.pinForm = this.formBuilder.group({
      pin: [null, Validators.required]
    })
    //setup view
    this.pinType = this.navParams.get("pinType") ? this.navParams.get("pinType") : this.pinPopoverTypes.NUMERIC;
    this.pinMessage = this.navParams.get("pinMessage") ? this.navParams.get("pinMessage") : null;
    this._pinMaxLength = this.navParams.get("pinMaxLength") ? this.navParams.get("pinMaxLength") : null;
  }


  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      let input = document.getElementById("pinInput") as HTMLInputElement;
      if (input) {
        input.focus();
      }
    }, 1000)
  }

  //pinCharacterClick: gestisce il click su ogni singolo carattere
  public pinCharacterClick(pinCharacter: string) {
    switch (pinCharacter) {
      case 'confirm':
        if (this.pinForm.get("pin").value != null && this.pinForm.get("pin").value != undefined)
          this.popoverCtrl.dismiss({ pin: JSON.parse(JSON.stringify(this.pinForm.get("pin").value)) });
        break;
      case 'delete':
        this.pinForm.get("pin").setValue(this.pinForm.get("pin").value == null || this.pinForm.get("pin").value == undefined ? this.pinForm.get("pin").value : this.pinForm.get("pin").value.slice(0, -1));
        break;
      case 'shift':
        this.shiftActive = !this.shiftActive;
        break;
      default:
        if (this.pinForm.get("pin").value == null || this.pinForm.get("pin").value == undefined)
          this.pinForm.get("pin").setValue(this.shiftActive ? pinCharacter.toUpperCase() : pinCharacter);
        else
          this.pinForm.get("pin").setValue((this._pinMaxLength != null && this._pinMaxLength != undefined && this.pinForm.get("pin").value.length < this._pinMaxLength) || (this._pinMaxLength == null || this._pinMaxLength == undefined) ? this.shiftActive ? this.pinForm.get("pin").value + pinCharacter.toUpperCase() : this.pinForm.get("pin").value + pinCharacter : this.pinForm.get("pin").value);
        break;
    }
    //this.pinArrayString = this.pin != null && this.pin != undefined ? this.pin.replace(/./g, "*").split("") : null;
  }

  //pinKeyup
  public pinKeyup(event) {
    if (event && event.keyCode === 13) {
      event.preventDefault();
      this.pinCharacterClick('confirm');
    }
  }
}
