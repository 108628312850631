
import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CustomReportsService {

    constructor(public httpUtilitySrv: HttpUtilityService,
        public http: HttpClient) { }

    //get reports
    public getReports(type, title, startPosition, maxResult, orderType, orderField) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            this.http.post(`${this.httpUtilitySrv.links.apigateway}CrpReport/GetReports`, {
                "pagination": {
                    "startPosition": startPosition,
                    "maxResult": maxResult,
                    "orderField": orderField,
                    "orderType": orderType
                },
                "query": {
                    "tipo": type,
                    "titolo": title
                }
            }).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //execute
    public execute(crp) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            if (crp.formGroup && crp.formGroup.length == 0) {
                crp.formGroup = null;
            }
            this.http.post(`${this.httpUtilitySrv.links.apigateway}CrpReport/Execute`, crp).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //getExecutionStatus
    public getExecutionStatus(crpId, crpRequestToken) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/GetExecutionStatus?crpRequestToken=${crpRequestToken}&crpId=${crpId}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //getSingleResult
    public getSingleResult(crpId, crpRequestToken, idxQry) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/GetSingleResult?crpRequestToken=${crpRequestToken}&crpId=${crpId}&idxQry=${idxQry}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //updateFieldBox
    public update(multiple, crpId, crpRequestToken, value, idxQry, idxFlag, columnUpdateTargetValue, updateColumnDef, updateColumnType) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            let payload = !multiple ? {
                crpId: crpId,
                crpRequestToken: crpRequestToken,
                value: value,
                columnUpdateTargetValue: columnUpdateTargetValue,
                idxQry: idxQry,
                idxFlag: idxFlag,
                updateColumnDef: updateColumnDef,
                updateColumnType: updateColumnType
            } : {
                crpId: crpId,
                crpRequestToken: crpRequestToken,
                value: value,
                columnUpdateTargetValues: columnUpdateTargetValue,
                idxQry: idxQry,
                idxFlag: idxFlag,
                updateColumnDef: updateColumnDef,
                updateColumnType: updateColumnType
            }
            this.http.post(`${!multiple ? this.httpUtilitySrv.links.apigateway + 'CrpReport/updateFieldBox' : this.httpUtilitySrv.links.apigateway + 'CrpReport/UpdateAllRows'}`, payload).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //stop
    public stop(crpId, crpRequestToken) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/Stop?crpRequestToken=${crpRequestToken}&crpId=${crpId}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //getReportById
    public getReportById(crpId) {
        return new Promise((resolve, reject) => {
            //`${this.httpUtilitySrv.links.apigateway}getRuoli`
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/GetReportById?crpId=${crpId}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //getCrpDetails
    public getCrpDetails(crpId) {
        return new Promise((resolve, reject) => {
            //assets/endpoint/crp-config.json
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/GetCrpDetails?crpId=${crpId}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //export 
    public export(crpId, crpRequestToken, exportType: string) {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/Export?crpRequestToken=${crpRequestToken}&crpId=${crpId}&exportType=${exportType}&exportAllReport=true`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //get ruoli api request
    public getRuoli() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.server}getRuoli`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //get utenti api request
    public getUtenti() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.server}getUtenti`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //get clienti api request
    public getClienti() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.server}getClienti`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //get full clienti api request
    public getFullClienti() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.server}getFullClienti`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }


    //format date for be (date to yyyyMMdd)
    public formatDateForBe(date) {
        let dt = date ? new Date(date) : null;
        return dt ? "" + dt.getFullYear() + ((dt.getMonth() + 1) < 10 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())) : null;
    }

    //format datetime for be (date time to yyyyMMdd HH:mm)
    public formatDateTimeForBe(date) {
        let dt = date ? new Date(date) : null;
        return dt ? "" + dt.getFullYear() + ((dt.getMonth() + 1) < 10 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())) + " " + ((dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours())) + ":" + ((dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes())) : null;
    }

    //format date for fe (yyyyMMdd to yyyy-MM-dd)
    public formatDateForFe(date: string) {
        let dt: string = date == null || date == undefined || date == "" ? null : date;
        if (dt) {
            let year = dt.substring(0, 4);
            let month = dt.substring(4, 6);
            let day = dt.substring(6, 8);
            dt = `${year}-${month}-${day}`;
        }
        return dt ? dt : null;
    }

    //format datetime for fe (yyyyMMdd HH:mm to yyyy-MM-ddTHH:MM)
    public formatDateTimeForFe(date) {
        let dt: string = date == null || date == undefined || date == "" ? null : date;
        if (dt) {
            let year = dt.substring(0, 4);
            let month = dt.substring(4, 6);
            let day = dt.substring(6, 8);
            let hours = dt.substring(9, 11);
            let minutes = dt.substring(12, 14);
            dt = `${year}-${month}-${day}T${hours}:${minutes}`;
        }
        return dt ? dt : null;
    }

    //createCrp
    public createCrp(tipoReport: string, descrizione: string) {
        return new Promise((resolve, reject) => {
            //assets/endpoint/new-crp.jso
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/CreateCrp?tipoReport=${tipoReport}&descrizione=${descrizione}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //enableDisableCrp
    public enableDisableCrp(crpId, enable: boolean) {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.httpUtilitySrv.links.apigateway}CrpReport/${enable ? 'EnableCrp' : 'DisableCrp'}?crpId=${crpId}`).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //saveInstruction
    public saveInstruction(instructions: Array<any>) {
        return new Promise((resolve, reject) => {
            this.http.post(`${this.httpUtilitySrv.links.apigateway}CrpReport/SaveInstruction`, instructions).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }


    //savePermission
    public savePermission(crpId, usersIds, rolesIds, territoriesIds) {
        return new Promise((resolve, reject) => {
            this.http.post(`${this.httpUtilitySrv.links.apigateway}CrpReport/SavePermission`, {
                crpId: parseInt(crpId),
                usersIds: usersIds ? usersIds : [],
                rolesIds: rolesIds ? rolesIds : [],
                territoriesIds: territoriesIds ? territoriesIds : []
            }).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    //saveParameters
    public saveParameters(parameters) {
        return new Promise((resolve, reject) => {
            this.http.post(`${this.httpUtilitySrv.links.apigateway}CrpReport/SaveParameters`,
                parameters
            ).subscribe((res) => {
                resolve(res);
            }, (err) => {
                
                reject(err);
            })
        });
    }

    public getListByEndpoint(endPoint) {
        return new Promise((resolve, reject) => {
          this.http.get(endPoint).subscribe((res) => {
            resolve(res);
          }, (err) => {
            
            reject(err);
          })
        });
      }
}
