import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  //label
  @Input() label: string;
  //min
  @Input() min: number = 0;
  //max
  @Input() max: number = 100;
  //value
  @Input() value: number = 0;
  //ranges
  @Input() ranges: any;
  //rotationValue
  public rotationValue: number = 0;

  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public activatedRoute: ActivatedRoute,
    private loadingSrv: LoaderService) {
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label) {
      this.label = changes.label.currentValue;
    }
    if (changes.min) {
      this.min = changes.min.currentValue;
    }
    if (changes.max) {
      this.max = changes.max.currentValue;
    }
    if (changes.value) {
      this.value = changes.value.currentValue;
      this.setValue();
    }
    if (changes.ranges) {
      this.ranges = changes.ranges.currentValue;
    }
  }

  //setvalue
  private setValue() {
    //rotationValue 0-180
    setTimeout(() => {
      let delta = (this.max - this.min);
      let valueToZero = (delta * this.value) / this.max;
      let newRotationValue = (180 * valueToZero) / delta
      this.rotationValue = newRotationValue > 180 ? 180 : newRotationValue < 0 ? 0 : newRotationValue ;
    }, 300)
  }

  //getSemiCircleBackground
  public getSemiCircleBackground() {
    let bg = "var(--ion-color-success)";
    let rangesArray = this.ranges ? typeof this.ranges == 'string' ? JSON.parse(this.ranges) : this.ranges : [];
    if (!rangesArray || rangesArray.length == 0) {
      if (this.rotationValue <= 60)
        bg = "var(--ion-color-success)"
      else if (this.rotationValue > 60 && this.rotationValue <= 120)
        bg = "var(--ion-color-warning)"
      else
        bg = "var(--ion-color-danger)"
    } else if (rangesArray && rangesArray.length > 0) {
      for (let range of rangesArray) {
        if (this.value > range.lo && this.value < range.hi)
          bg = range.color;
      }
    }
    return bg;
  }
}
