import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'custom-date'
})
export class CustomDatePipe extends DatePipe 
    implements PipeTransform {


    override transform(value: Date, format?: string, timezone?: string, locale?: string): any {
        return super.transform(value, format, timezone, locale);
    }

    multipleTransform(value: Date, format?: string, timezone?: string, locale?: string): string[] {
        return [
            this.transform(value).toLowerCase(),
            this.transform(value, "short").toLowerCase(),
            this.transform(value, "medium").toLowerCase(),
            this.transform(value, "full").toLowerCase(),
            this.transform(value, "d/M/yy").toLowerCase(),
            this.transform(value, "d/MM/yy").toLowerCase(),
            this.transform(value, "M-d-yy").toLowerCase(),
            this.transform(value, "MM-d-yy").toLowerCase(),
            this.transform(value, "d-M-yy").toLowerCase(),
            this.transform(value, "d-MM-yy").toLowerCase(),
            this.transform(value, "d-MM-yyyy").toLowerCase(),
        ]
    }

    checkDate(dateToCheck: string, date: Date): boolean {
        var dateArray = this.multipleTransform(date);  
        var dateArrayFiltered = dateArray.filter(d => d.includes(dateToCheck.toLowerCase()));
        return dateArrayFiltered.length !== 0;
    }
}