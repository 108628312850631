import { NgModule } from '@angular/core';
import { VafEntityRegistrationComponent } from './vaf-entity-registration.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ViewAndFineRegistrationFormComponentModule } from '../../components/view-and-fine-registration-form/view-and-fine-registration-form.component.module';

@NgModule({
    declarations: [
        VafEntityRegistrationComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatProgressBarModule,
        ViewAndFineRegistrationFormComponentModule
    ],
    exports: [
        VafEntityRegistrationComponent
    ]
})
export class VafEntityRegistrationComponentModule { }