import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminTerritoryDetailService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //inserisci territorio api request
  public inserisciTerritorio(territory, type) {
    return new Promise((resolve, reject) => {
      let idAzienda = type == "0" ? null : type == "1" ? 0 : territory.idAzienda;
      let payload = {
        "denominazione": territory.denominazione ? territory.denominazione : null,
        "idTerritorioPadre": territory.idTerritorioPadre ? territory.idTerritorioPadre : null,
        "idAzienda": idAzienda,
        "denominazioneAzienda": territory.denominazioneAzienda ? territory.denominazioneAzienda : null,
        "acronimo": territory.acronimo ? territory.acronimo : null,
        "indirizzo": territory.indirizzo ? territory.indirizzo : null,
        "nazione": territory.nazione ? territory.nazione : null,
        "partitaIva": territory.partitaIva ? territory.partitaIva : null,
        "telefono": territory.telefono ? territory.telefono : null,
        "isClienteSafo": territory.isClienteSafo ? territory.isClienteSafo : false,
        "attivo": territory.attivo ? territory.attivo : false,
        "isClosed": territory.isClosed,
        "fax": territory.fax ? territory.fax : null,
        "newIdModuli": territory.newIdModuli ? territory.newIdModuli : [],
        "idManagerMulte": territory.idManagerMulte ? territory.idManagerMulte : null,
      }
      this.http.post(`${this.httpUtilitySrv.links.server}inserisciTerritorio`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //modifica territorio api request
  public modificaTerritorio(territory) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}modificaTerritorio`, territory).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get lista aziende api request
  public listaAziende() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}listaAziende`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get lista aziende api request
  public dettaglioTerritorio(idTerritorio) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}dettaglioTerritorio/` + idTerritorio).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }


  //elimina territorio api request
  public eliminaTerritorio(territoryId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}eliminaTerritorio/` + territoryId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }



}
