import { DcrudFormModal } from './dcrud-form-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DcrudFormModule } from '../dcrud-form-component/dcrud-form.component.module';
@NgModule({
  declarations: [
    DcrudFormModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    DcrudFormModule
  ],
  exports: [
    DcrudFormModal
  ]
})
export class DcrudFormModalModule { }

