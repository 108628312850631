import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertController, IonContent, IonInput, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { PssfeSelectableService } from '../../../services/pssfe-selectable.service';

@Component({
  selector: 'app-pssfe-selectable-prompt',
  templateUrl: './pssfe-selectable-prompt.component.html',
  styleUrls: ['./pssfe-selectable-prompt.component.scss'],
})
export class PssfeSelectablePromptComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //form
  public form: FormGroup;
  //searchLoading
  public searchLoading = false;
  //startPosition
  public startPosition = 0;
  //searchResult
  public searchResult;
  //totalResult
  public totalResult;
  //selected list
  public selectedList = [];
  //search input
  @ViewChild('searchkey', { read: ElementRef }) searchkey: ElementRef;
  //columns configuration
  public columns = {
    textWidth: 100,
    text2Width: 0,
    text3Width: 0,
    names: ["text"]
  }
  //localCacheList
  public localCacheList: Array<any> = [];

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    public httpUtilitySrv: HttpUtilityService,
    private pssfeSelectableService: PssfeSelectableService) {
    //form
    this.form = this.formBuilder.group({
      key: [null]
    })
    //setup nav params
    this.modalNavParams =
    {
      input: this.navParams.get("input") ? this.navParams.get("input") : null,
      inputList: this.navParams.get("inputList") ? this.navParams.get("inputList") : null,
      searchKey: this.navParams.get("searchKey") != null && this.navParams.get("searchKey") != undefined && this.navParams.get("searchKey") != '' ? this.navParams.get("searchKey") : null
    }
  }

  ngAfterViewInit(): void {
    let context = this;
    //setup comand keys
    document.getElementById("pssfeselectable").addEventListener("keyup", function (ev: any) {
      //38 su 40 giu
      if (context.searchResult && context.searchResult.length > 0 && ev && ev.keyCode == 38) {
        //up to search
        if (document.activeElement.id && document.activeElement.id == "item-0")
          context.searchkey.nativeElement.focus();
        //up to item
        else if (document.activeElement.id && document.activeElement.id != "item-0" && document.activeElement.id.indexOf("item-") != -1) {
          let nextIndex = parseInt(document.activeElement.id.substring(document.activeElement.id.indexOf("-") + 1, document.activeElement.id.length)) - 1;
          document.getElementById("item-" + nextIndex).focus();
        }
      }
      if (context.searchResult && context.searchResult.length > 0 && ev && ev.keyCode == 40) {
        //down to first item
        if (!document.activeElement.id || (document.activeElement.id.indexOf("item-") == -1 && document.activeElement.id != "loadMore"))
          document.getElementById("item-0").focus();
        //down to load more
        else if (document.activeElement.id && document.activeElement.id == "item-" + (context.searchResult.length - 1)) {
          context.getListByEndpointLoadMore();
        }
        //down to item
        else if (document.activeElement.id && document.activeElement.id !== "item-" + (context.searchResult.length - 1) && document.activeElement.id.indexOf("item-") != -1) {
          let nextIndex = parseInt(document.activeElement.id.substring(document.activeElement.id.indexOf("-") + 1, document.activeElement.id.length)) + 1;
          document.getElementById("item-" + nextIndex).focus();
        }
      }
      //enter per multiselect
      if (context.searchResult && context.searchResult.length > 0 && ev && ev.keyCode == 13 && ev.ctrlKey && context.modalNavParams.input.multiple && context.selectedList && context.selectedList.length > 0) {
        context.select();
      }
    })
    //setup scroll pagination
    let scrollResult = document.getElementById("scrollResult") as HTMLDivElement;
    fromEvent(this.searchkey.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.getListByEndpointButton(this.form.valid)
      });
    setTimeout(() => {
      this.searchkey.nativeElement.focus();
    }, 500)
    scrollResult.addEventListener("wheel", function (event) {
      if (event.deltaY > 0) {
        if (!context.searchLoading && context.searchResult && context.searchResult.length < context.totalResult &&
          (scrollResult.scrollHeight - scrollResult.scrollTop === scrollResult.clientHeight)) {
          context.getListByEndpointLoadMore();
        }
      }
    })
    scrollResult.addEventListener("scroll", function (event) {
      if (!context.searchLoading && context.searchResult && context.searchResult.length < context.totalResult &&
        (scrollResult.scrollHeight - scrollResult.scrollTop === scrollResult.clientHeight)) {
        context.getListByEndpointLoadMore();
      }
    })
    //search if
    setTimeout(() => {
      //populate selected list
      if (this.modalNavParams.input.value && this.modalNavParams.input.value.length > 0) {
        for (let item of this.modalNavParams.input.value) {
          this.selectedList.push(item)
        }
      }
      //auto search for searchKeyParam
      if (this.modalNavParams.searchKey) {
        this.form.get("key").setValue(this.modalNavParams.searchKey);
        this.getListByEndpointButton(this.form.valid);
      }
      //autosearch for autoload query param
      else if (this.modalNavParams.input && this.modalNavParams.input.searchEndpoint && this.modalNavParams.input.searchEndpoint.indexOf("autoload=true") != -1) {
        this.getListByEndpointButton(this.form.valid);
      }
      //get localCache
      this.localCacheList = this.pssfeSelectableService.getFieldTranslationCodeLocalCache(this.modalNavParams.input.id);
    }, 200)
  }

  ngOnInit() {
  }

  //getListByEndpoint button
  public getListByEndpointButton(valid, keyUp?) {
    if (valid && (!keyUp || (keyUp && keyUp.keyCode === 13))) {
      this.searchLoading = true;
      this.startPosition = 0;
      //build pagination
      this.pssfeSelectableService.getListByEndpoint(
        this.modalNavParams.inputList ? this.pssfeSelectableService.resolveEndpointByInputList(this.modalNavParams.input.searchEndpoint, this.modalNavParams.inputList) : this.modalNavParams.input.searchEndpoint,
        {
          value: this.form.get('key').value,
          pagination: {
            startPosition: this.startPosition,
            maxResult: this.modalNavParams.input.searchEndpoint.indexOf("autoload=true") != -1 ? 9999 : 0
          }
        }
      ).then((res: any) => {
        this.searchResult = res.result;
        this.totalResult = res.totalResults;
        this.setColumnsWidth(this.searchResult);
        this.searchLoading = false;
      }).catch((err) => {
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.searchLoading = false;
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
    
          })
        }
      })
    }
  }

  //getListByEndpoint pagination
  public getListByEndpointLoadMore() {
    this.searchLoading = true;
    this.startPosition = this.startPosition + 1;
    this.pssfeSelectableService.getListByEndpoint(
      this.modalNavParams.inputList ? this.pssfeSelectableService.resolveEndpointByInputList(this.modalNavParams.input.searchEndpoint, this.modalNavParams.inputList) : this.modalNavParams.input.searchEndpoint,
      {
        value: this.form.get('key').value,
        pagination: {
          startPosition: this.startPosition,
          maxResult: 0
        }
      }
    ).then((res: any) => {
      this.searchResult = this.searchResult.concat(res.result);
      this.totalResult = res.totalResults;
      this.searchLoading = false;
      setTimeout(() => {
        if (document.getElementById("item-" + (this.searchResult.length - res.result.length)))
          document.getElementById("item-" + (this.searchResult.length - res.result.length)).focus();
      }, 500)
    }).catch((err) => {
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.searchLoading = false;
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
  
        })
      }
    })
  }

  //select
  public select() {
    //set local cache
    this.selectedList.forEach((item) => {
      this.pssfeSelectableService.addLocalCacheItem(this.modalNavParams.input.id, item);
    })
    this.modalCtrl.dismiss({
      updated: true,
      value: this.selectedList
    })
  }

  //selectItem
  public selectItem(item) {
    if (item) {
      let exists = false;
      for (let obj of this.selectedList) {
        if (obj.value == item.value) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        if (!this.modalNavParams.input.multiple) {
          this.selectedList = [];
          this.selectedList.push(item);
          this.select();
        } else {
          this.selectedList.push(item);
        }
      }
      else
        this.deleteSelected(item);
    }
  }

  //selectItemByKey
  public selectItemByKey(item, ev) {
    if (ev && ev.keyCode && ev.keyCode == 13) {
      if (item) {
        let exists = false;
        for (let obj of this.selectedList) {
          if (obj.value == item.value) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          if (!this.modalNavParams.input.multiple) {
            this.selectedList = [];
            this.selectedList.push(item);
            this.select();
          } else {
            this.selectedList.push(item);
          }
        }
        else
          this.deleteSelected(item);
      }
    }

  }

  //deleteSelected
  public deleteSelected(item) {
    this.selectedList = this.selectedList.filter((x) => { return x.value != item.value })
  }

  //isItemSelected
  public isItemSelected(item) {
    let exists = false;
    if (item) {
      for (let obj of this.selectedList) {
        if (obj.value == item.value) {
          exists = true;
          break;
        }
      }
    }
    return exists;
  }

  //get columnsWidth
  private setColumnsWidth(list) {
    if (list && list.length > 0) {
      let columns = [];
      list.filter((x) => {
        if ((x.text != null || x.text != undefined) && !columns.includes("text"))
          columns.push("text")
        if ((x.text2 != null || x.text2 != undefined) && !columns.includes("text2"))
          columns.push("text2")
        if ((x.text3 != null || x.text3 != undefined) && !columns.includes("text3"))
          columns.push("text3")
      })
      this.columns.names = columns;
      this.columns.textWidth = columns.length == 0 ? 100 : Math.abs(100 / columns.length);
      this.columns.text2Width = columns.length == 0 ? 100 : Math.abs(100 / columns.length);
      this.columns.text3Width = columns.length == 0 ? 100 : Math.abs(100 / columns.length);
      //check merge
      if (list[0].text2 && list[0].text2 == "MERGE" && list[0].text3 && list[0].text3 == "MERGE") {
        this.columns.textWidth = 100;
        this.columns.text2Width = 0;
        this.columns.text3Width = 0;
      }
      else if (list[0].text2 && list[0].text2 == "MERGE") {
        this.columns.textWidth = this.columns.textWidth + this.columns.text2Width;
        this.columns.text2Width = 0;
      }
      else if (list[0].text3 && list[0].text3 == "MERGE") {
        this.columns.text2Width = this.columns.text2Width + this.columns.text3Width;
        this.columns.text3Width = 0;
      }
    }
  }

  //delete local cache item
  public deleteLocalCacheItem(item) {
    this.pssfeSelectableService.deleteLocalCahceItem(this.modalNavParams.input.id, item);
    this.localCacheList = this.pssfeSelectableService.getFieldTranslationCodeLocalCache(this.modalNavParams.input.id);
  }
  //select local cache item
  public selectLocalCacheItem(item) {
    this.selectItem(item);
  }
}
