import { NgModule } from '@angular/core';
import { PendingRequestPopoverComponent } from './pending-request-popover.component'
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteTreeComponentModule } from '../../components/infinite-tree/infinite-tree.component.module'
@NgModule({
    imports: [
        TranslateModule,
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteTreeComponentModule
    ],
    declarations: [
        PendingRequestPopoverComponent
    ],
    exports: [
        PendingRequestPopoverComponent
    ]
})
export class PendingRequestPopoverComponentModule { }