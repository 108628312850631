<ion-content class="postit-board-main-page">
    <div class="postit-board-main-page-content" id="postit-board-main-page-content">
        <app-postit-board-toolbar [mainLayout]="mainLayout" (getBoard)="getBoard($event)"
            (undoResourceMode)="undoResourceMode()" (uploadPhoto)="uploadPhoto($event)" #toolbarComponent></app-postit-board-toolbar>
        <app-postit-board-board [mainLayout]="mainLayout" (dragStart)="dragStart($event)" (dragEnd)="dragEnd($event)"
            (updateMainLayout)="updateMainLayoutChild($event)" (enterResourceMode)="enterResourceMode($event)" id="board" #boardComponent>
        </app-postit-board-board>
        <app-postit-board-stats [mainLayout]="mainLayout" id="stats" (dragStart)="dragStart($event)"
            (dragEnd)="dragEnd($event)"></app-postit-board-stats>
    </div>
</ion-content>