<ion-header>
    <div class="modal-page-card">
        <ion-card-header>
            <div class="modal-header">
                <div class="modal-title">
                    {{parameter.property}}
                </div>
                <div class="modal-icon">
                    <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
                </div>
            </div>
        </ion-card-header>
    </div>
</ion-header>
<ion-content id="modal-content">
    <div class="options-list" *ngIf="parameter.options">
        <ion-row>
            <ion-col size-md="3" size-sm="3" size-xs="3">
                {{'labels.id' | translate}}
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">
                {{'labels.parameterDesc' | translate}}
            </ion-col>
            <ion-col size-md="1" size-sm="1" size-xs="1">

            </ion-col>
        </ion-row>
        <div class="options-list-item" *ngFor="let option of parameter.options; let i = index;">
            <ion-row>
                <ion-col size-md="3" size-sm="3" size-xs="3">
                    <div class="option-id">
                        <input type="text" [(ngModel)]="option.id" (focus)="optionClicked(option)" (blur)="optionIdChanged(i)">
                    </div>
                </ion-col>
                <ion-col size-md="8" size-sm="8" size-xs="8">
                    <div class="option-desc">
                        <input type="text" [(ngModel)]="option.desc">
                    </div>
                </ion-col>
                <ion-col size-md="1" size-sm="1" size-xs="1">
                    <ion-icon name="close" class="cursor-pointer" color="danger" (click)="deleteOption(i)"></ion-icon>
                </ion-col>
            </ion-row>
        </div>
        <ion-row>
            <ion-col size-md="3" size-sm="3" size-xs="3">
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">
            </ion-col>
            <ion-col size-md="1" size-sm="1" size-xs="1">
                <ion-icon name="add" class="cursor-pointer" color="success" (click)="addOption()"></ion-icon>
            </ion-col>
        </ion-row>
    </div>
</ion-content>
<ion-footer>
    <ion-row>
        <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
            <div class="button-box">
                <ion-button color="success" size="small" (click)="save()">
                    <ion-label>{{'buttons.save' | translate}}</ion-label>
                </ion-button>
            </div>
        </ion-col>
    </ion-row>
</ion-footer>