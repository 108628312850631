import { tableColumn } from "src/app/shared-components/table/models/table-models"

export enum InputTypes {
    AREA_TESTO = "AREA_TESTO",
    CHECKBOX = "CHECKBOX",
    DATA = "DATA",
    DATA_ORA = "DATA_ORA",
    DATA_RANGE = "DATA_RANGE",
    FILE = "FILE",
    GRUPPO = "GRUPPO",
    MESSAGGIO = "MESSAGGIO",
    NUMERO = "NUMERO",
    PULSANTE = "PULSANTE",
    RADIO = "RADIO",
    RANGE = "RANGE",
    SELEZIONE = "SELEZIONE",
    SELEZIONE_AVANZATA = "SELEZIONE_AVANZATA",
    SEPARATORE = "SEPARATORE",
    SPAZIO_VUOTO = "SPAZIO_VUOTO",
    //STORICO_RICERCHE = "STORICO_RICERCHE",
    STATO = "STATO",
    TABELLA = "TABELLA",
    TESTO = "TESTO",
    VALUTA = "VALUTA",
    SQL_EDITOR = "SQL_EDITOR",
    UNKNOWN = "UNKNOWN"
}

export interface DcrudInput {
    inputType: InputTypes,
    max?: number,
    min?: number,
    showSeconds?: boolean,
    optionsList?: Array<any>,
    optionValue?: string,
    optionText?: string,
    optionListEndpoint?: string,
    multiple?: boolean,
    endpoint?: string,
    endpointTmp?: string,
    triggersList?: Array<TriggersListItem>,
    hidden?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    color?: "warning" | "success" | "danger" | "primary" | "secondary" | "tertiary"
    validationRules?: Array<any>,
    errorMessage?: string,
    value?: any,
    colSize?: {
        lg: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
        md: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
        sm: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
        xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    },
    label?: string,
    fileName?: string,
    step?: number,
    id: string,
    required?: boolean,
    invalid?: boolean,
    triggerDisabled?: boolean,
    loading?: boolean,
    inputList?: Array<DcrudInput>,
    alignX?: "left" | "right" | "center" | "spacebetween",
    routerUrl?: string,
    collapsed?: boolean,
    tableColumns?: Array<tableColumn>,
    tableDataSource?: any,
    inputReferenceFE?: any,
    tableTargetId?: string,
    tableSearchDoneFE?: boolean,
    nopadding?: boolean,
    tooltip?: string,
    saveState?: boolean,
    mergeValueId?: string,
    propertiesFE?: Array<string>,
    hideHeader?: boolean,
    inlineGroup?: boolean,
    hideShadow?: boolean,
    noPagination?: boolean,
    renderValue?: any,
    icon?: string,
    showSuggestion?: boolean, //mostra il tasto per i suggerimenti dell'input per TESTO, AREA_TESTO e NUMERO
    showHistorySearch?:boolean //mostra a sinistra del pulsante il tasto per lo storico ricerca per PULSANTI
}

export interface TriggersListItem {
    action: TriggerActions,
    condition?: string,
    endpoint?: string,
    description?: string,
    updateValue?: string
}

export enum TriggerActions {
    POPUP = "POPUP",
    DISPLAY = "DISPLAY",
    STORE = "STORE",
    ENABLE = "ENABLE",
    REQUIRED = "REQUIRED",
    PERMISSION_SHOW = "PERMISSION_SHOW",//se permesso rispettato il campo viene mostrato, altrimenti viene eliminato dalla lista
    PERMISSION_HIDE = "PERMISSION_HIDE",//se permesso rispettato il campo viene eliminato, altrimenti viene mostrato
    PERMISSION_NOTREADONLY = "PERMISSION_NOTREADONLY",//se permesso rispettato il campo viene abilitato alla modifica, altrimenti diventa readonly
    UPDATE_VALUE="UPDATE_VALUE" //sostituzione valore previa traduzione e risoluzione tags
}

export const DcrudComponentsList = [
    {
        inputType: InputTypes.GRUPPO, text: "Gruppo", icon: "grid-outline", properties: [
            "inputType",
            "id",
            "label",
            "hidden",
            "colSize",
            "nopadding",
            "alignX",
            "collapsed",
            "hideHeader",
            "inlineGroup",
            "hideShadow",
            "triggersList"
        ]
    },
    {
        inputType: InputTypes.SEPARATORE, text: "Separatore", icon: "code-working-outline", properties: [
            "inputType",
            "id",
            "value",
            "hidden",
            "colSize",
            "triggersList"
        ]
    },
    {
        inputType: InputTypes.SPAZIO_VUOTO, text: "Spazio vuoto", icon: "code-working-outline", properties: [
            "inputType",
            "id",
            "hidden",
            "colSize",
            "triggersList"
        ]
    },
    {
        inputType: InputTypes.TESTO, text: "Testo", icon: "text-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color",
            "showSuggestion",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "endpoint"
        ]
    },
    {
        inputType: InputTypes.AREA_TESTO, text: "Area di testo", icon: "text-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color",
            "showSuggestion",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "endpoint"
        ]
    },
    {
        inputType: InputTypes.MESSAGGIO, text: "Messaggio", icon: "chatbox-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "value",
            "hidden",
            "triggersList",
            "colSize",
            "color",
            "hideShadow"
        ]
    },
    {
        inputType: InputTypes.NUMERO, text: "Numero", icon: "swap-vertical-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color",
            "showSuggestion",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "endpoint"
        ]
    },
    {
        inputType: InputTypes.VALUTA, text: "Valuta", icon: "cash-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.FILE, text: "File", icon: "document-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "triggersList",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.SELEZIONE, text: "Selezione", icon: "list-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "multiple",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "triggersList",
            "validationRules",
            "colSize",
            "color",
            "endpoint"
        ]
    },
    {
        inputType: InputTypes.SELEZIONE_AVANZATA, text: "Selezione avanzate", icon: "list-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "multiple",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "triggersList",
            "validationRules",
            "colSize",
            "color",
            "endpoint"
        ]
    },
    {
        inputType: InputTypes.RADIO, text: "Radio", icon: "radio-button-on-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "optionsList",
            "optionValue",
            "optionText",
            "optionListEndpoint",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.DATA, text: "Data", icon: "today-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.DATA_ORA, text: "Data con ora", icon: "calendar-clear-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "showSeconds",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.DATA_RANGE, text: "Range di date", icon: "calendar-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "dataRanges",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.CHECKBOX, text: "Checkbox", icon: "checkbox-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "triggersList",
            "colSize"
        ]
    },
    {
        inputType: InputTypes.RANGE, text: "Range slider", icon: "options-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "step",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    },
    {
        inputType: InputTypes.STATO, text: "Stato", icon: "radio-button-off-outline", properties: [
            "inputType",
            "id",
            "value",
            "mergeValueId",
            "label",
            "hidden",
            "colSize",
            "color",
            "triggersList"
        ]
    },
    {
        inputType: InputTypes.PULSANTE, text: "Pulsante", icon: "square-outline", properties: [
            "inputType",
            "id",
            "label",
            "value",
            "readonly",
            "disabled",
            "hidden",
            "triggersList",
            "colSize",
            "color",
            "icon",
            "tooltip",
            "endpoint",
            "routerUrl",
            "tableTargetId",
            "showHistorySearch"
        ]
    },
    {
        inputType: InputTypes.TABELLA, text: "Tabella", icon: "browsers-outline", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "hidden",
            "colSize",
            "nopadding",
            "endpoint",
            "tableColumns",
            "noPagination",
            "triggersList"
        ]
    },
    /*{
        inputType: InputTypes.STORICO_RICERCHE, text: "Storico ricerche", icon: "list-outline", properties: [
            "inputType",
            "id",
            "label",
            "disabled",
            "hidden",
            "triggersList"
        ]
    },*/
    {
        inputType: InputTypes.SQL_EDITOR, text: "Sql editor", icon: "server", properties: [
            "inputType",
            "id",
            "mergeValueId",
            "label",
            "required",
            "readonly",
            "disabled",
            "hidden",
            "saveState",
            "max",
            "min",
            "triggersList",
            "validationRules",
            "colSize",
            "color"
        ]
    }
]

export const EndpointQueryParams = ["endpointMethod", "endpointResponseType", "endpointDownload", "endpointAutoload", "searchResultTarget", "endpointPaginationFE", "endpointDownloadFileName"];

export interface DcrudModalParams {
    apiResponse: any,
    urlParams: string
}