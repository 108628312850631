import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../app/services/language.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { MainLayout } from '../../pages/postit-board-main/postit-board-main.page';
import { BoardMainService } from '../../services/board-main.service';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostitBoardListManagementComponent, listManagementProperty } from '../../modals/postit-board-list-management/postit-board-list-management.component';
import { AppService } from 'src/app/services/app.service';
import { PostitBoardFileUploadComponent } from '../../modals/postit-board-file-upload/postit-board-file-upload.component';

@Component({
  selector: 'app-postit-board-toolbar',
  templateUrl: './postit-board-toolbar.component.html',
  styleUrls: ['./postit-board-toolbar.component.scss'],
})
export class PostitBoardToolbarComponent implements OnInit, AfterViewInit {
  //mainLAyout
  @Input() mainLayout: MainLayout;
  //activities
  public activitiesList: Array<any> = [];
  //groups
  public groupsList: Array<any> = [];
  //getBoard
  @Output() getBoard = new EventEmitter<any>();
  //dateFrom
  public dateFrom;
  //dateTo
  public dateTo;
  //undoResourceMode
  @Output() undoResourceMode = new EventEmitter<any>();
  //uploadPhoto
  @Output() uploadPhoto = new EventEmitter<any>();
  //queryParamsResolved
  private queryParamsResolved: boolean;

  constructor(
    public translate: TranslateService,
    public languageSrv: LanguageService,
    private alertCtrl: AlertController,
    private alertSrv: AlertService,
    private boardMainSrv: BoardMainService,
    private postitBoardUtilitySrv: PostitBoardUtilityService,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private appSrv: AppService,
    public popoverCrtl: PopoverController
  ) {
  }

  ngOnInit() { }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.mainLayout) {
      this.mainLayout = changes.mainLayout.currentValue;
    }
    if (this.mainLayout) {
      this.getGroupList();
    }
  }

  //emit get board
  public emitGetBoard() {
    if (this.dateFrom && this.dateTo)
      this.getBoard.emit({ dateFrom: this.dateFrom, dateTo: this.dateTo });
  }

  //emit undoResourceMode
  public emitUndoResourceMode() {
    this.undoResourceMode.emit();
  }

  //get groups list
  private getGroupList() {
    this.boardMainSrv.gruppi().then((res: any) => {
      if (res && Array.isArray(res) && res.length > 0)
        this.groupsList = res;
      else
        this.groupsList = [];
      this.getActivitiesList();
    }).catch((err: any) => {
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }


  //get activities list
  private getActivitiesList() {
    this.boardMainSrv.attivita().then((res: any) => {
      if (res && Array.isArray(res) && res.length > 0)
        this.activitiesList = this.formatActivitiesList(res);
      else
        this.activitiesList = [];
      //check for query params
      if (!this.queryParamsResolved) {
        let queryParams = this.appSrv.getQueryParams();
        if (queryParams.action == 'SHOW_ACTIVITIES') {
          this.queryParamsResolved = true;
          this.openActivitiesListManagement();
        } else if (queryParams.action == 'ADD_ACTIVITY') {
          this.queryParamsResolved = true;
          let activity = JSON.parse(queryParams.target);
          if (activity) {
            let tmpGroup = this.groupsList.filter((x) => { return x.id == activity.idGruppoAttivita })[0];
            if (tmpGroup) {
              activity.idGruppoAttivita = tmpGroup;
            }
          }
          this.openActivitiesListManagement(activity);
        } else if (queryParams.action == 'SHOW_GROUPS' || queryParams.action == 'ADD_GROUP') {
          this.queryParamsResolved = true;
          this.openGroupsListManagement();
        } else if (queryParams.action == 'UPLOAD') {
          this.queryParamsResolved = true;
          this.openUpload();
        }else if (queryParams.action == 'UPLOAD_PHOTO') {
          this.queryParamsResolved = true;
          this.openUpload();
        }
      }
    }).catch((err: any) => {
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //open activities list management
  async openActivitiesListManagement(autoAddItem?) {
    let properties: Array<listManagementProperty> = [
      {
        name: 'codice',
        type: 'TEXT',
        width: '33.33%'
      },
      {
        name: 'descrizione',
        type: 'TEXT',
        width: '33.33%'
      },
      {
        name: 'idGruppo',
        type: 'SELECTABLE',
        width: '33.33%',
        selectableOptions: this.groupsList,
        itemValueField: 'id',
        itemTextField: 'descrizione',
        isMultiple: false
      }
    ];
    const modal = await this.modalCtrl.create({
      component: PostitBoardListManagementComponent,
      cssClass: 'postit-board-list-management',
      componentProps: {
        properties: properties,
        list: this.activitiesList,
        title: "Attività",
        target: "ACTIVITIES",
        autoAddItem: autoAddItem ? autoAddItem : null
      }
    });
    return await modal.present();
  }


  //open groups list management
  async openGroupsListManagement() {
    let properties: Array<listManagementProperty> = [
      {
        name: 'codice',
        type: 'TEXT',
        width: '50%'
      },
      {
        name: 'descrizione',
        type: 'TEXT',
        width: '50%'
      }
    ];
    const modal = await this.modalCtrl.create({
      component: PostitBoardListManagementComponent,
      cssClass: 'postit-board-list-management',
      componentProps: {
        properties: properties,
        list: this.groupsList,
        title: "Gruppi",
        target: "GROUPS"
      }
    });
    return await modal.present();
  }

  //open upload
  async openUpload() {
    const modal = await this.modalCtrl.create({
      component: PostitBoardFileUploadComponent,
      cssClass: 'postit-board-upload',
      backdropDismiss: false
    });
    modal.onDidDismiss().then((res) => {
      if (res && res.data)
        this.emitUploadPhoto(res.data);
    })
    return await modal.present();
  }

  //format activities list
  private formatActivitiesList(res) {
    let tmpGroup;
    res = JSON.parse(JSON.stringify(res));
    res.forEach((activity) => {
      if (activity.idGruppoAttivita) {
        tmpGroup = this.groupsList.filter((x) => { return x.id == activity.idGruppoAttivita })[0];
        if (tmpGroup) {
          activity.idGruppoAttivita = tmpGroup;
        }
      }
    });
    return res;
  }

  //uploadPhoto
  private emitUploadPhoto(uploadPhotoObj: any) {
    if (uploadPhotoObj && uploadPhotoObj.dataMin && uploadPhotoObj.dataMax && uploadPhotoObj.risorse) {
      this.dateFrom = uploadPhotoObj.dataMin.substring(0, 10);
      this.dateTo = uploadPhotoObj.dataMax.substring(0, 10);
      this.uploadPhoto.emit(uploadPhotoObj);
    }
  }
}
