<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.edit_image' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content #content>
  <ion-row [hidden]="selectedImage">
    <!-- select image -->
    <ion-col size-md="12" size-sm="12" size-xs="12">
      <ion-item class="input-field" lines="none" lines="none">
        <input id="inputFile" type="file" (change)="fileChangeEvent($event)" accept="image/x-png,image/gif,image/jpeg">
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row [hidden]="!selectedImage" class="crop-toolbar">
    <!-- tool bar -->
    <ion-col size-md="10" size-sm="10" size-xs="12">
      <ion-item class="input-field" lines="none" lines="none" [hidden]="!selectedImage || !transparentBackground">
        <ion-range id="signatureBlueRange" #signatureBlueRange [min]="0" [max]="255" [debounce]="500"
          [(ngModel)]="signatureBlueValue" (ngModelChange)="cleanBackground(false)"
          [ngClass]="{'ion-range-disabled': !isSignatureEdit}">
          <div slot="start">
            <input type="checkbox" [(ngModel)]="isSignatureEdit" (ngModelChange)="isSignatureEditChange()">
          </div>
          <div slot="end">
          </div>
        </ion-range>
      </ion-item>
    </ion-col>
    <ion-col size-md="2" size-sm="2" size-xs="12">
      <div class="toolbar-buttons">
        <ion-icon name="image" (click)="loadImage()" title="{{'buttons.change_file' | translate}}"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12">
      <div class="image-box" [hidden]="!selectedImage">
        <image-cropper [maintainAspectRatio]="!canChangeAspectRatio" [imageBase64]="selectedImage" [output]="'base64'"
          [aspectRatio]="aspectRatio" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [transform]="transform"
          [canvasRotation]="canvasRotation"></image-cropper>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer>
  <div class="button-box p-5px">
    <ion-button (click)="imageTransform('rotate')" [hidden]="!selectedImage">
      <ion-label>{{'buttons.rotate' | translate}}</ion-label>
    </ion-button>
    <ion-button (click)="save()">
      <ion-label>{{'buttons.save' | translate}}</ion-label>
    </ion-button>
  </div>
</ion-footer>