<ion-app>

  <ion-split-pane contentId="main-content" when="(min-width: 1280px)">
    <ion-menu contentId="main-content" type="overlay" #menu
      [hidden]="!flgMenuEnabled || (fullScreen && fullScreen.modeFullScreen)">
      <ion-header class="menu-header">
        <div class="menu-logo">
          <img src="assets/images/logo_safo_services.png">
        </div>
      </ion-header>
      <ion-content class="menu-content">
        <div class="menu-wrapper">
          <ion-list id="inbox-list">
            <div *ngFor="let p of appPages;">
              <app-menu-tree [p]="p" (microFrontendClicked)="microFrontendClicked($event)"></app-menu-tree>
            </div>
          </ion-list>
        </div>
      </ion-content>
    </ion-menu>
    <div class="main-content" id="main-content">
      <ion-header [hidden]="!flgToolbarEnabled || (fullScreen && fullScreen.modeFullScreen)">
        <ion-toolbar mode="md" class="apptoolbar">
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <div class="footer-impersonate-content"
            *ngIf="impersGuid && impersGuid != '' && this.router.url.indexOf('/login') == -1">
            <ion-button color="warning" size="small" (click)="stopImpersonateUserAlert()">
              <ion-label>{{'buttons.stop_impersonate_user' | translate}}</ion-label>
            </ion-button>
          </div>
          <ion-chip slot="end">
            <span class="icon-wrap" (click)="!isMonitoringRequests ? startMonitorRequests() : endMonitorRequests()">
              <ion-icon name="options"
                [ngClass]="!isMonitoringRequests ? 'icon-hidden' : 'icon-warning'"></ion-icon></span>
            <!--<button (click)="expireSessionTest()">Expire</button>-->
            <app-pending-request-button></app-pending-request-button>
            <div class="messages" (click)="handleMessages()" [ngClass]="{'mr-0': !countMessage || countMessage == 0}"
              *ngIf="activePage.indexOf('/decurtazione-punti') == -1 && activePage.indexOf('/gem-anomalie') == -1">
              <ion-icon name="notifications"></ion-icon>
              <span *ngIf="countMessage && countMessage > 0"></span>
            </div>
            <ion-icon name="person" (click)="openRightMenu($event)" class="user-avatar"
              *ngIf="activePage.indexOf('/decurtazione-punti') == -1  && activePage.indexOf('/gem-anomalie') == -1"></ion-icon>
            <div class="language" *ngIf="showLanguageSelector">
              <div class="language-select">
                <ion-select [(ngModel)]="language" (ngModelChange)="selectLanguage($event)" interface="popover"
                  [style.background-image]="'url(./assets/icon/flags/' + language + '.png)'">
                  <ion-select-option value="it">Italiano</ion-select-option>
                  <ion-select-option value="en">English</ion-select-option>
                  <ion-select-option value="en-US"
                    *ngIf="activePage.indexOf('/decurtazione-punti') == -1">American</ion-select-option>
                  <ion-select-option value="fr"
                    *ngIf="activePage.indexOf('/decurtazione-punti') == -1">Français</ion-select-option>
                  <ion-select-option value="de"
                    *ngIf="activePage.indexOf('/decurtazione-punti') == -1">Deutsche</ion-select-option>
                  <ion-select-option value="at"
                    *ngIf="activePage.indexOf('/decurtazione-punti') == -1">Austrian</ion-select-option>
                </ion-select>
              </div>
            </div>
          </ion-chip>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-main-content">
        <ion-router-outlet></ion-router-outlet>
        <app-chat-bot *ngIf="showChatBot">
        </app-chat-bot>
      </ion-content>
      <!-- (currentHost == HOSTS.VIEW_AND_FINE && activePage != '/login' && (!appfooter || (appfooter && !appfooter.impersGuid))) 
      <ion-footer [hidden]="fullScreen && fullScreen.modeFullScreen  || isInFrame">
        <app-footer #appfooter [isMobile]="isSmallDevice"></app-footer>
      </ion-footer>-->
    </div>
  </ion-split-pane>
  <app-messages-box [countMessage]="countMessage"></app-messages-box>

  <!-- fullscreenToggle -->
  <div id="fullscreenToggle"
    [hidden]="!fullScreen || !fullScreen.modeFullScreen || denyFullScreenPages.includes(activePage)"
    class="fullscreenToggle" [style.left]="fullScreen && fullScreen.toggleX ? fullScreen.toggleX : '0px'"
    [style.top]="fullScreen && fullScreen.toggleY ? fullScreen.toggleY : '0px'">
    <img src="assets/odeva_assets/icon/exit-full-screen_white.png" title="{{'labels.exit_full_screen' | translate}}"
      (click)="$event.stopPropagation();exitFullScreen();">
    <img src="assets/odeva_assets/icon/move_white.png" title="{{'labels.move' | translate}}">
  </div>
</ion-app>