import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadingLogService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //inserisci utente api request
  public inserisciUtente(username, email, name, surname, list, language, phone, proprietario, enabled, services) {
    let servicesList = [];
    if (services) {
      for (let service of services) {
        //if (service.serviceUserName)
        if (service.serviceUserName || service.listaChiaviValoreUtenteStringa)
          servicesList.push(service);
      }
    }

    return new Promise((resolve, reject) => {
      let payload = {
        "userName": username,
        "emails": [{
          "email": email,
          "descEmail": "",
        }],
        "Nome": name,
        "Cognome": surname,
        "clienti": list,
        "language": language,
        "descUtente": '',
        "telefono": phone,
        "idProprietario": proprietario,
        "link": `${this.httpUtilitySrv.getBaseUri()}reset-password`,
        "attivo": enabled,
        "newServizi": servicesList && servicesList.length > 0 ? servicesList : null
      }
      this.http.post(`${this.httpUtilitySrv.links.server}inserisciUtente`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
