import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service'
import { LoaderService } from './loader.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public loaderSrv: LoaderService,
    public http: HttpClient) { }

  //getSezioni api request
  public getServizi(moduleidList) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        this.loaderSrv.open();
        let payload = {
          idModuli: moduleidList
        }
        this.http.post(`${this.httpUtilitySrv.links.server}getServizi`, payload).subscribe((res) => {
          this.loaderSrv.dismiss();
          resolve(res);
        }, (err) => {
          
          this.loaderSrv.dismiss();
          reject(err);
        })
      }).catch((err) => {
        this.loaderSrv.dismiss();
        reject(err)
      });
    });
  }

  //get token servizio api request
  public getTokenServizio(moduloId) {
    return new Promise((resolve, reject) => {
      let payload = {
        "idModulo": moduloId
      }
      this.http.post(`${this.httpUtilitySrv.links.server}getTokenServizio`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
