<ion-content >
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.service_configuration_template' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <!-- service -->
      <ion-row>
        <ion-col size-md="12" size-sm="12" size-xs="12" *ngIf="modalNavParams && modalNavParams.servicesList">
          <div class="modify-template-button select-service">
            <span>{{'labels.service' | translate}}:&nbsp;</span>
            <ion-select
              [disabled]="!modalNavParams.servicesList || (modalNavParams.servicesList && modalNavParams.servicesList.length == 0)"
              [(ngModel)]="modalNavParams.serviceId" multiple="false" cancelText="{{'buttons.cancel' | translate}}"
              okText="{{'buttons.select' | translate}}" (ngModelChange)="changeService()"
              placeholder="{{'labels.service' | translate}}" interface="popover"
              [ngClass]="{'disable-click': modalNavParams && modalNavParams.servicesList.length == 1}">
              <ion-select-option *ngFor="let service of modalNavParams.servicesList" value="{{service.id}}">
                {{'menu.'+service.idModulo |translate}}
              </ion-select-option>
            </ion-select>
          </div>
        </ion-col>
      </ion-row>
      <!-- selection-->
      <div class="flex-center-center mt-10px">
        <div class="new-template-button" (click)="createNew()">
          {{'buttons.new_template' | translate}}
        </div>
        <div class="modify-template-button">
          <ion-select [disabled]="!templateOption" [(ngModel)]="currentTemplateOption" multiple="false"
            cancelText="{{'buttons.cancel' | translate}}" okText="{{'buttons.select' | translate}}"
            (ngModelChange)="dettaglioTemplateServiziSafo(currentTemplateOption)"
            placeholder="{{'labels.modify_template' | translate}} ({{templateOption ? templateOption.length : 0}})"
            interface="popover">
            <ion-select-option *ngFor="let option of templateOption" value="{{option.id}}">{{option.nomeTemplate}}
            </ion-select-option>
          </ion-select>
        </div>
      </div>
      <!-- template -->
      <div class="configuration-detail">
        <!-- configs role-->
        <ion-row>
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <div class="title-grey" *ngIf="currentTemplate">
              {{currentTemplate.id ? ('labels.modify_template' | translate) : ('labels.new_template' | translate)}}
            </div>
            <!-- name -->
            <div *ngIf="currentTemplate">
              <div class="config-item">
                <div class="config-key">
                  <div class="config-input">
                    {{'labels.template_name' | translate}} *
                  </div>
                </div>
              </div>
              <div class="config-item">
                <div class="config-key">
                  <div class="config-input">
                    <input type="text" [(ngModel)]="currentTemplate['nomeTemplate']"
                      placeholder="{{'labels.insert' | translate}}">
                  </div>
                </div>
              </div>
            </div>
            <!-- list -->
            <div *ngIf="currentTemplate && currentTemplate.valori && currentTemplate.valori.length > 0">
              <div class="config-key mt-10px">
                <div class="config-input">
                  <label>{{'labels.key' | translate}} *</label>
                </div>
              </div>
              <div class="config-value mt-10px">
                <div class="config-input">
                  <label>{{'labels.value' | translate}} {{'messages.multiple_value_message' | translate}}
                    *</label>
                </div>
              </div>
              <div class="config-item" *ngFor="let config of currentTemplate.valori;let i = index">
                <div class="config-key">
                  <div class="config-input">
                    <input type="text" [(ngModel)]="config['chiave']" placeholder="{{'labels.insert' | translate}}"
                    maxlength="50">
                  </div>
                </div>
                <div class="config-value">
                  <div class="config-input">
                    <input type="text" [(ngModel)]="config['valore']" placeholder="{{'labels.insert' | translate}}"
                    maxlength="1000">
                  </div>
                </div>
                <div class="config-delete">
                  <ion-icon name="close-outline" (click)="removeConfig(i)"></ion-icon>
                </div>
              </div>
            </div>
            <!-- add -->
            <div class="flex-center-right mt-10px" *ngIf="currentTemplate">
              <div class="add-button" (click)="addConfig()">
                <ion-icon name="add-outline"></ion-icon>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <ion-row>
        <ion-col size-md="3" size-sm="3" size-xs="12" class="col-button-box">
          <ion-item class="box-required" lines="none">
            <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' | translate}}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size-md="9" size-sm="9" size-xs="12" class="col-button-box">
            <div class="button-box">
              <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
                <ion-label>{{'buttons.cancel' | translate}}</ion-label>
              </ion-button>
              <ion-button color="danger" size="default" [disabled]="!currentTemplate || !currentTemplate.id"
                (click)="deleteTemplateAlert()">
                <ion-label>{{'buttons.delete' | translate}}</ion-label>
              </ion-button>
              <ion-button color="success" size="default" [disabled]="!currentTemplate"
                (click)="saveTemplateServiziSafo()">
                <ion-label>{{'buttons.save' | translate}}</ion-label>
              </ion-button>
            </div>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>