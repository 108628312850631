import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MicroFrontendConatinerPageRoutingModule } from './micro-frontend-container-routing.module';
import { MicroFrontendConatinerPage } from './micro-frontend-container.page';
;
import { TableComponentModule } from '../../../app/shared-components/table/table.component.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MicroFrontendConatinerPageRoutingModule,

    TableComponentModule
  ],
  declarations: [MicroFrontendConatinerPage]
})
export class MicroFrontendConatinerPageModule {}
