import { NgModule } from '@angular/core';
import { PostitBoardFileUploadComponent } from './postit-board-file-upload.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicSelectableComponent } from 'ionic-selectable';
@NgModule({
    declarations: [
        PostitBoardFileUploadComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        IonicSelectableComponent
    ],
    exports: [
        PostitBoardFileUploadComponent
    ]
})
export class PostitBoardFileUploadComponentModule { }