import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AdminRoleDetailPopoverComponent } from 'src/app/modals_popsover/admin-role-detail-popover/admin-role-detail-popover.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-infinite-tree',
  templateUrl: './infinite-tree.component.html',
  styleUrls: ['./infinite-tree.component.scss'],
})

export class InfiniteTreeComponent implements OnInit, OnChanges {
  //item
  @Input() item: any;
  //name key
  @Input() titleKey: string; //key for name of section 
  //children key
  @Input() childrenKey: string; //key for name of childern list
  //description key
  @Input() descriptionKey: string; //key for description of nam. Output example: name (desccription)
  //hide checkbox parent
  @Input() hideCheckboxParent: boolean; //hide checkbox parent if true
  //hide checkbox parent
  @Input() hideCheckboxRole: boolean; //hide checkbox role if true
  //hide selector
  @Input() hideSelector: boolean; //hide selector
  //show select all roles
  @Input() showSeletAllRoles: boolean = false;
  //show role detail
  @Input() showRoleDetail: boolean = false;
  //show roles indicator
  @Input() showRolesIndicator: boolean = false;
  //all opened
  @Input() allOpened: boolean = false;
  //hide territory count
  @Input() hideTerritoryCount: boolean = false;
  //disable territory checkbox
  @Input() disableTerritoryCheckbox = false;
  //disable role checkbox
  @Input() disableRoleCheckbox = false;
  //singleRole
  @Input() singleRole: boolean = false;
  //event for select
  @Output() itemSelect = new EventEmitter<any>();
  //select all roles
  public flgSelectAllRoles = false;

  constructor(
    public translate: TranslateService,
    public popoverCtrl: PopoverController) {
    //setup input
    this.item = this.item ? this.item : null;
    this.titleKey = this.titleKey ? this.titleKey : "nome";
    this.childrenKey = this.childrenKey ? this.childrenKey : "figli";
    this.descriptionKey = this.descriptionKey ? this.descriptionKey : null;
  }


  ngOnInit() {
    if (this.allOpened) {
      this.item['opened'] = true;
      this.item['rolesOpened'] = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.item = changes.item.currentValue;
    }
    if (changes.titleKey) {
      this.titleKey = changes.titleKey.currentValue;
    }
    if (changes.childrenKey) {
      this.childrenKey = changes.childrenKey.currentValue;
    }
    if (changes.descriptionKey) {
      this.descriptionKey = changes.descriptionKey.currentValue;
    }
    if (changes.descriptionKey) {
      this.descriptionKey = changes.descriptionKey.currentValue;
    }
    if (changes.hideCheckboxRole) {
      this.hideCheckboxRole = changes.hideCheckboxRole.currentValue;
    }
    if(changes.hideCheckboxParent){
      this.hideCheckboxParent = changes.hideCheckboxParent.currentValue;
    }
    if (changes.hideSelector) {
      this.hideSelector = changes.hideSelector.currentValue;
    }
    if (changes.showSeletAllRoles) {
      this.showSeletAllRoles = changes.showSeletAllRoles.currentValue;
    }
    if (changes.showRoleDetail) {
      this.showSeletAllRoles = changes.showSeletAllRoles.currentValue;
    }
    if (changes.showRolesIndicator) {
      this.showRolesIndicator = changes.showRolesIndicator.currentValue;
    }
    if (changes.allOpened) {
      this.allOpened = changes.allOpened.currentValue;
    }
    if (changes.hideTerritoryCount) {
      this.hideTerritoryCount = changes.hideTerritoryCount.currentValue;
    }
    if (changes.disableTerritoryCheckbox) {
      this.disableTerritoryCheckbox = changes.disableTerritoryCheckbox.currentValue;
    }
    if (changes.disableRoleCheckbox) {
      this.disableRoleCheckbox = changes.disableRoleCheckbox.currentValue;
    }
    if (changes.singleRole) {
      this.singleRole = changes.singleRole.currentValue;
    }
  }


  //select role
  public selectRole(group, role) {
    let object = {
      group: group,
      role: role
    }
    this.itemSelect.emit(object);
  }

  //select customer
  public selectCustomer(group) {
    this.itemSelect.emit(group);
  }

  //selectChild
  public selectChild(evt) {
    this.itemSelect.emit(evt);
  }

  //select all roles
  public selectAllRoles(item) {
    for (let role of item.ruoli) {
      role['selected'] = !this.flgSelectAllRoles;
    }
    item['rolesOpened'] = true;
  }

  //roleCheckboxSelected
  public roleCheckboxSelected(item, role) {
    let allChecked = true;
    for (let role of item.ruoli) {
      if (!role['selected']) {
        allChecked = false;
        break;
      }
    }
    if (!allChecked)
      this.flgSelectAllRoles = false;
    if (this.singleRole && role) {
      let object = {
        group: item,
        role: role
      }
      this.itemSelect.emit(object);
    }
  }

  //open role detail popover
  async roleDetailPopover(roleId) {
    const popover = await this.popoverCtrl.create({
      component: AdminRoleDetailPopoverComponent,
      cssClass: 'admin-role-detail-popover',
      componentProps: {
        roleId: roleId ? roleId : null,
        readonly: true
      }
    });
    popover.onDidDismiss()
      .then(() => {
      });
    return await popover.present();
  }

}
