import { NgModule } from '@angular/core';
import { ServiceAccessConfigurationTemplateComponent } from './service-access-configuration-template.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceAccessConfigurationComponentModule } from '../../components/service-access-configuration/service-access-configuration.component.module'
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [
        ServiceAccessConfigurationTemplateComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        ServiceAccessConfigurationComponentModule,
        MatTabsModule
    ],
    exports: [
        ServiceAccessConfigurationTemplateComponent
    ]
})
export class ServiceAccessConfigurationTemplateComponentModule {}