import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageListService {
  //count messages
  public countMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  //observable 
  public countMessagesObservable = this.countMessages.asObservable();

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient, public authSrv: AuthService) {

  }

  //get new messages api request
  public getReceivedMessages(onlyNotRead, startPosition, maxResult) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.httpUtilitySrv.links.server}getReceivedMessages`, {
        "onlyNotRead": onlyNotRead,
        "startPosition": startPosition,
        "maxResult": maxResult
      }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //presa visione messaggio api request
  public presaVisioneMessaggio(messageId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}presaVisioneMessaggio/` + messageId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get image body api request
  public getAttachmentMessage(messageId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getAttachmentMessage/` + messageId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get count messages api request
  public getCountMessages() {
    this.authSrv.getAuthObject().then((authObject: any) => {
      if (authObject && authObject.userData && authObject.userData.idUtente) {
        this.http.get(`${this.httpUtilitySrv.links.server}getCountMessages`).subscribe((res: any) => {
          if (res.code == '200')
            this.countMessages.next(res.payload);
          else
            this.countMessages.next(0);
        }, (err) => {
          this.countMessages.next(0);
        })
      } else {
        this.countMessages.next(0);
      }
    }).catch(() => {
      this.countMessages.next(0);
    })
  }

  //add message
  public addMessage() {
    this.countMessages.next(this.countMessages.getValue() + 1);
  }

  //remove message
  public removeMessage() {
    this.countMessages.next(this.countMessages.getValue() > 0 ? this.countMessages.getValue() - 1 : 0);
  }


  //presa visione messaggio tutti api request
  public presaVisioneMessaggioTutti(messageId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}presaVisioneMessaggioTutti/` + messageId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
