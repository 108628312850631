import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TableExtractionService } from 'src/app/services/table-extraction.service';
import { PendingRequestPopoverComponent } from 'src/app/modals_popsover/pending-request-popover/pending-request-popover.component';

@Component({
  selector: 'app-pending-request-button',
  templateUrl: './pending-request-button.component.html',
  styleUrls: ['./pending-request-button.component.scss'],
})
export class PendingRequestButtonComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  //pendingRequestsNumber
  public pendingRequestsNumber: number = 0;

  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public activatedRoute: ActivatedRoute,
    private loadingSrv: LoaderService,
    private tableExtractionSrv: TableExtractionService) {
    this.tableExtractionSrv.currentLoadingExtractionsIdObservable.subscribe((res) => {
      if (res) {
        this.pendingRequestsNumber = res.length;
      }
    })
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  async openPendingRequestPopover(event) {
    const popover = await this.popoverCtrl.create({
      component: PendingRequestPopoverComponent,
      cssClass: 'pending-request-popover',
      event: event,
      showBackdrop: false
    });
    return await popover.present();
  }
}
