
import { AppDriverFlags, AppDriverState, AtDamages, Vehicle } from "./app-driver.models";
export const defaultAppDriverState: AppDriverState = { vehicles: [], mostUsedServices: [], user: null };
export const defaultVehicleState: Vehicle = {
    startDate: null,
    endDate: null,
    model: null,
    make: null,
    mileage: null,
    replacement: null,
    id: null,
    idBrand: null,
    idUser: null,
    licensePlate: null,
    code: null,
    registrationDate: null,
    chassisNumber: null,
    fullDescription: null,
    type: null,
    thumbnailPrimaryImageUrl: null,
    thumbailSecondaryImageUrl: null,
    isActive: null,
    services: []
}
export const defaultAtDamages: AtDamages = {
    front: [
        { id: "paraurti_anteriore", value: false },
        { id: "specchietto_sx", value: false },
        { id: "parafango_anteriore_dx", value: false },
        { id: "ruota_anteriore_dx", value: false },
        { id: "faro_anteriore_sx", value: false },
        { id: "parafango_anteriore_sx", value: false },
        { id: "cofano_anteriore", value: false },
        { id: "ossatura_vani_porta_sx", value: false },
        { id: "parabrezza_anteriore", value: false },
        { id: "ossatura_vani_porta_dx", value: false },
        { id: "specchietto_dx", value: false },
        { id: "tetto", value: false },
        { id: "faro_anteriore_dx", value: false },
        { id: "ruota_anteriore_sx", value: false }
    ],
    left: [
        { id: "cerchio_posteriore_sx", value: false },
        { id: "ruota_posteriore_sx", value: false },
        { id: "ruota_anteriore_sx", value: false },
        { id: "cerchio_anteriore_sx", value: false },
        { id: "tetto", value: false },
        { id: "faro_anteriore_sx", value: false },
        { id: "paraurti_anteriore", value: false },
        { id: "specchietto_sx", value: false },
        { id: "sottoporta_sx", value: false },
        { id: "cofano_anteriore", value: false },
        { id: "porta_posteriore_sx", value: false },
        { id: "finestrino_anteriore_sx", value: false },
        { id: "parabrezza_anteriore", value: false },
        { id: "porta_anteriore_sx", value: false },
        { id: "parafango_anteriore_sx", value: false },
        { id: "faro_posteriore_sx", value: false },
        { id: "parafango_posteriore_sx", value: false },
        { id: "paraurti_posteriore", value: false },
        { id: "ossatura_vani_porta_sx", value: false },
        { id: "finestrino_posteriore_sx", value: false },
        { id: "lunotto_posteriore", value: false },
        { id: "maniglie_sx", value: false }
    ],
    back: [
        { id: "paraurti_posteriore", value: false },
        { id: "faro_posteriore_dx", value: false },
        { id: "specchietto_dx", value: false },
        { id: "tetto", value: false },
        { id: "portello_posteriore", value: false },
        { id: "montante_posteriore_sx", value: false },
        { id: "ruota_posteriore_sx", value: false },
        { id: "specchietto_sx", value: false },
        { id: "ruota_posteriore_dx", value: false },
        { id: "montante_posteriore_dx", value: false },
        { id: "lunotto_posteriore", value: false },
        { id: "faro_posteriore_sx", value: false },
    ],
    right: [
        { id: "cerchio_posteriore_dx", value: false },
        { id: "ruota_posteriore_dx", value: false },
        { id: "ruota_anteriore_dx", value: false },
        { id: "cerchio_anteriore_dx", value: false },
        { id: "tetto", value: false },
        { id: "faro_anteriore_dx", value: false },
        { id: "paraurti_anteriore", value: false },
        { id: "specchietto_dx", value: false },
        { id: "sottoporta_dx", value: false },
        { id: "cofano_anteriore", value: false },
        { id: "porta_posteriore_dx", value: false },
        { id: "finestrino_anteriore_dx", value: false },
        { id: "parabrezza_anteriore", value: false },
        { id: "porta_anteriore_dx", value: false },
        { id: "parafango_anteriore_dx", value: false },
        { id: "faro_posteriore_dx", value: false },
        { id: "parafango_posteriore_dx", value: false },
        { id: "paraurti_posteriore", value: false },
        { id: "ossatura_vani_porta_dx", value: false },
        { id: "finestrino_posteriore_dx", value: false },
        { id: "lunotto_posteriore", value: false },
        { id: "maniglie_dx", value: false }
    ],
    other: [
        { id: "lostKeys", value: false },
        { id: "damagedInterior", value: false },
        { id: "lockCompromised", value: false },
        { id: "mechanicalDamage", value: false },
    ],
    selectedDamagesList: []
}
export const defaultAppDriverFlags: AppDriverFlags = { doNotShowMapTutorial: false, rememberMe: true, defaultRange: 30 };
export enum AppDriverDocumentTypes {
    COMPLAINT_IMAGE = 23,
    SIGNATURE_IMAGE = 24,
    CID_IMAGE = 30,
    DAMAGES_IMAGE = 31,
    RMK_PHOTOS = 44
}
export const defaultDarkMap: any[] = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
            {
                "color": "#f1e7f7"
            }
        ]
    }
]