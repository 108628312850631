import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-custom-report-combo-edit',
  templateUrl: './custom-report-combo-edit.component.html',
  styleUrls: ['./custom-report-combo-edit.component.scss'],
})
export class CustomReportComboEditComponent implements OnInit, AfterViewInit {
  //parameter
  public parameter;
  //optionEditing
  private _optionEditing;

  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public alertCtrl: AlertController,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private loaderSrv: LoaderService,
    private navParams: NavParams,
    public popoverCtrl: PopoverController) {
    //set parameter
    this.parameter = this.navParams.get("parameter") ? this.navParams.get("parameter") : null;
    this.parameter["options"] = this.parameter["options"] ? this.parameter["options"] : [];
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  //save
  public save() {
    this.popoverCtrl.dismiss({ parameter: this.parameter });
  }
  //deleteOption
  public deleteOption(index) {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.deleteOptionMessage') }, () => {
      this.parameter.options.splice(index, 1);
    })
  }
  //addOption
  public addOption() {
    this.parameter.options.push({ id: this._getAvailableIndex().toString(), desc: null });
  }
  //getAvailableIndex
  private _getAvailableIndex() {
    let index = 0;
    if (this.parameter.options.length == 0)
      index = 0;
    else
      for (let option of this.parameter.options) {
        if (!isNaN(parseInt(option.id)) && parseInt(option.id) > index)
          index = parseInt(option.id);
      }
    return index + 1;
  }
  //optionIdChanged
  public optionIdChanged(index) {
    if (this.parameter.options.length > 1) {
      let option = this.parameter.options.filter((option) => { return option.id == this.parameter.options[index].id })[1];
      if (option) {
        this.alertSrv.errorAlert(this.translate.instant('messages.crpOptionIdExistsMessage'));
        this.parameter.options[index].id = this._optionEditing ? this._optionEditing.id : this._getAvailableIndex();
      }
    }
  }
  //optionClicked
  public optionClicked(option) {
    this._optionEditing = JSON.parse(JSON.stringify(option));
  }
}
