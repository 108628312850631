import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MicroFrontendConatinerPage } from './pages/micro-frontend-container/micro-frontend-container.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/extra-login/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/:token&:id&:page&:action',
    loadChildren: () => import('./pages/extra-login/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'admin-role-search',
    loadChildren: () => import('./pages/admin-role-search/admin-role-search.module').then(m => m.AdminRoleSearchPageModule)
  },
  {
    path: 'admin-user-search',
    loadChildren: () => import('./pages/admin-user-search/admin-user-search.module').then(m => m.AdminUserSearchPageModule)
  },
  {
    path: 'admin-message-search',
    loadChildren: () => import('./pages/admin-messages-history/admin-messages-history.module').then(m => m.AdminMessagesHistoryPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/extra-login/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password/:resetcode',
    loadChildren: () => import('./pages/extra-login/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'imma-home',
    loadChildren: () => import('../projects/imma-int/pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'imma-data-integration-search',
    loadChildren: () => import('../projects/imma-int/pages/data-integration-search/data-integration-search.module').then(m => m.DataIntegrationSearchPageModule)
  },
  {
    path: 'imma-data-integration-detail',
    loadChildren: () => import('../projects/imma-int/pages/data-integration-detail/data-integration-detail.module').then(m => m.DataIntegrationDetailPageModule)
  },
  {
    path: 'decurtazione-punti/:token&:id&:language',
    loadChildren: () => import('./pages/extra-login/decurtazione-punti/decurtazione-punti.module').then(m => m.DecurtazionePuntiPageModule)
  },
  {
    path: 'decurtazione-punti',
    loadChildren: () => import('./pages/extra-login/decurtazione-punti/decurtazione-punti.module').then(m => m.DecurtazionePuntiPageModule)
  },
  {
    path: 'agency-registration',
    loadChildren: () => import('./pages/extra-login/agency-registration/agency-registration.module').then(m => m.AgencyRegistrationPageModule)
  },
  {
    path: 'agency-registration/:tokenValue&:tokenName&:tokenUrl',
    loadChildren: () => import('./pages/extra-login/agency-registration/agency-registration.module').then(m => m.AgencyRegistrationPageModule)
  },
  {
    path: 'admin-territory-search',
    loadChildren: () => import('./pages/admin-territory-search/admin-territory-search.module').then(m => m.AdminTerritorySearchPageModule)
  },
  {
    path: 'home-page',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePagePageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'admin-message-detail',
    loadChildren: () => import('./pages/admin-message-detail/admin-message-detail.module').then(m => m.AdminMessageDetailPageModule)
  },
  {
    path: 'dashboardpbi',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'dashboardpbi/:src',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'admin-user-massive-create',
    loadChildren: () => import('./pages/admin-user-massive-create/admin-user-massive-create.module').then(m => m.AdminUserMassiveCreatePageModule)
  },
  {
    path: 'admin-news-detail',
    loadChildren: () => import('./pages/admin-news-detail/admin-news-detail.module').then(m => m.AdminNewsDetailPageModule)
  },
  {
    path: 'admin-news-search',
    loadChildren: () => import('./pages/admin-news-history/admin-news-history.module').then(m => m.AdminMessagesHistoryPageModule)
  },
  {
    path: 'odeva',
    loadChildren: () => import('../projects/odeva/pages/odeva.module').then(m => m.OdevaModule)
  },
  {
    path: 'view-and-fine',
    loadChildren: () => import('../projects/view-and-fine/vaf.module').then(m => m.ViewAndFineModule)
  },
  {
    path: 'ald',
    loadChildren: () => import('../projects/ald/ald.module').then(m => m.AldModule)
  },
  {
    path: 'admin-postit-board-main',
    loadChildren: () => import('../projects/postit-board/pages/postit-board-main/postit-board-main.module').then(m => m.PostitBoardMainPageModule)
  },
  {
    path: 'admin-postit-board-main/:page&:action&:target',
    loadChildren: () => import('../projects/postit-board/pages/postit-board-main/postit-board-main.module').then(m => m.PostitBoardMainPageModule)
  },
  {
    path: 'custom-report-detail',
    loadChildren: () => import('./pages/custom-reports-page/custom-reports.module').then(m => m.CustomReportsPageModule)
  },
  {
    path: 'custom-report-detail/:crpId&:autoload',
    loadChildren: () => import('./pages/custom-reports-page/custom-reports.module').then(m => m.CustomReportsPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/custom-reports-page/custom-reports.module').then(m => m.CustomReportsPageModule)
  },
  {
    path: 'report/:crpId&:autoload',
    loadChildren: () => import('./pages/custom-reports-page/custom-reports.module').then(m => m.CustomReportsPageModule)
  },
  {
    path: 'custom-report-search',
    loadChildren: () => import('./pages/custom-reports-search/custom-reports-search.module').then(m => m.CustomReportsSearchPageModule)
  },
  {
    path: 'custom-report-search/:crpId&:crpTitle&crpSubTitle&:crpIcon',
    loadChildren: () => import('./pages/custom-reports-search/custom-reports-search.module').then(m => m.CustomReportsSearchPageModule)
  },
  {
    path: 'route',
    children: [{
      path: '**',
      component: MicroFrontendConatinerPage
    }
    ]
  },
  {
    path: 'form',
    loadChildren: () => import('./pages/dcrud/dcrud-form-page/dcrud-form-page.page.module').then(m => m.DcrudFormPageModule)
  },
  {
    path: 'form/:CodicePagina',
    loadChildren: () => import('./pages/dcrud/dcrud-form-page/dcrud-form-page.page.module').then(m => m.DcrudFormPageModule)
  },
  {
    path: 'int-form',
    loadChildren: () => import('./pages/dcrud/dcrud-form-page/dcrud-form-page.page.module').then(m => m.DcrudFormPageModule)
  },
  {
    path: 'int-form/:CodicePagina',
    loadChildren: () => import('./pages/dcrud/dcrud-form-page/dcrud-form-page.page.module').then(m => m.DcrudFormPageModule)
  },
  {
    path: 'form-editor',
    loadChildren: () => import('./pages/dcrud/dcrud-editor/dcrud-editor.module').then(m => m.DcrudEditorModule)
  },
  {
    path: 'form-editor/:CodicePagina',
    loadChildren: () => import('./pages/dcrud/dcrud-editor/dcrud-editor.module').then(m => m.DcrudEditorModule)
  },
  {
    path: 'arval-landing',
    loadChildren: () => import('./pages/arval-landing/arval-landing.module').then(m => m.ArvalLandingpageModule)
  },
  {
    path: 'file-upload',
    loadChildren: () => import('./pages/file-upload/file-upload.module').then((m => m.FileUploadPageModule))
  },
  {
    path: 'legacy',
    loadChildren: () => import('./pages/legacy-frontend-container/legacy-frontend-container.module').then((m => m.LegacyFrontendContainerPageModule))
  },
  {
    path: 'legacy/:legacyUrl',
    loadChildren: () => import('./pages/legacy-frontend-container/legacy-frontend-container.module').then((m => m.LegacyFrontendContainerPageModule))
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
