import { NgModule } from '@angular/core';
import { TableSettingsComponent } from './table-settings.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModulesTreeComponentModule } from "../../components/modules-tree/modules-tree.component.modules"
import { HelpComponentModule } from '../../components/help/help.component.module'
import { MatTabsModule } from '@angular/material/tabs';
import { ServiceAccessConfigurationComponentModule } from '../../components/service-access-configuration/service-access-configuration.component.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        TableSettingsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        ModulesTreeComponentModule,
        HelpComponentModule,
        MatTabsModule,
        ServiceAccessConfigurationComponentModule,
        DragDropModule
    ],
    exports: [
        TableSettingsComponent
    ]
})
export class TableSettingsComponentModule { }