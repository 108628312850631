<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{modalNavParams.news.title}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="news-detail-popover">
  <div class="news-header">
    {{modalNavParams.news.date | date:'dd-MM-yyyy HH:mm'}}
    <div class="highlight-news" *ngIf="modalNavParams.news.inEvidenza">
      {{'labels.highlight_news' | translate}}
    </div>
  </div>
  <div class="news" [innerHtml]="modalNavParams.news.body"></div>
</ion-content>
<ion-footer>
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <a [href]="selectedFile.base64" download="{{selectedFile.name}}" class="download">
          <span *ngIf="selectedFile['name']">{{'labels.attached_file' | translate}}:
            &nbsp;{{selectedFile['name']}}</span>
        </a>
        <ion-button color="primary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>{{'buttons.close' | translate}}</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>