import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { tableColumn, columnTypes } from '../table/models/table-models';
import { tableIds } from '../table/models/table-ids';
declare var require: any;
var Excel = require('exceljs');
import * as fs from 'file-saver';
import { TableExtractionService } from 'src/app/services/table-extraction.service';

@Component({
  selector: 'app-table-extraction',
  templateUrl: './table-extraction.component.html',
  styleUrls: ['./table-extraction.component.scss'],
})
export class TableExtractionComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  //loading
  public loading = false;
  //tableColumns
  @Input() tableColumns;
  //extractApi
  @Output() extractApi = new EventEmitter<any>();
  //extraction id
  @Input() extractionId;
  //size
  @Input() size: string = "small";
  //tableId
  @Input() tableId: string;
  //button text
  @Input() buttonText: string = null;
  //skip extraction message
  @Input() skipExtrationMessage: boolean = false;
  //noExtractColumnsList
  @Input() noExtractColumnsList: Array<string> = [];
  //fileName
  @Input() fileName: string;

  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public activatedRoute: ActivatedRoute,
    private loadingSrv: LoaderService,
    public tableExtractionSrv: TableExtractionService) {
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableColumns) {
      this.tableColumns = changes.tableColumns.currentValue;
    }
    if (changes.extractionId) {
      this.extractionId = changes.extractionId.currentValue;
    }
    if (changes.size) {
      this.size = changes.size.currentValue;
    }
    if (changes.tableId) {
      this.tableId = changes.tableId.currentValue;
    }
    if (changes.buttonText) {
      this.buttonText = changes.buttonText.currentValue;
    }
    if (changes.skipExtrationMessage) {
      this.skipExtrationMessage = changes.skipExtrationMessage.currentValue;
    }
    if (changes.fileName) {
      this.fileName = changes.fileName.currentValue;
    }
  }

  //--- GENERATE EXCEL ---//
  //extractApiEmitter
  public extractApiEmitter() {
    if (this.extractApi) {
      if (!this.skipExtrationMessage) {
        this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.extractionMessage') }, () => {
          if (this.extractionId) {
            this.tableExtractionSrv.addLoadingExtractionId(this.extractionId);
          }
          this.loading = true;
          this.extractApi.emit({ keyUp: null, isExtract: true, tableId: this.tableId });
        })
      } else {
        this.extractApi.emit({ keyUp: null, isExtract: true, tableId: this.tableId });
      }
    }
  }

  //extractTable called from parent after getExtractionData
  public extractTable(apiResponse) {
    if (apiResponse) {
      if ((!this.tableColumns || this.tableColumns.length == 0) && apiResponse && Array.isArray(apiResponse) && apiResponse.length > 0) {
        apiResponse.forEach((item) => {
          this.extendNestedJson(item, item, null);
        });
      }
      let temp;
      let workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet("workbook");
      let header = this.tableColumns ? this.getHeadersFromColumns(this.tableColumns) : this.getHeadersFromApiResponse(apiResponse);
      worksheet.addRow(header);
      for (let apiItem of apiResponse) {
        temp = []
        if (this.tableColumns) {
          this.tableColumns.forEach((column: tableColumn) => {
            if (column.columnType != columnTypes.SELECT && column.columnType != columnTypes.DETAIL && !column.columnHidden) {
              temp.push(this.formatDataForExcel(apiItem, column));
            }
          })
        } else {
          header.forEach((header) => {
            if (apiItem[header] != null && apiItem[header] != undefined)
              temp.push(apiItem[header]);
            else
              temp.push('-');
          })
        }
        worksheet.addRow(temp);
      }
      let dt = new Date();
      let fname = this.fileName ? this.fileName : this.translate.instant('labels.extraction') + "_" + (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()) + "-" + ((dt.getMonth() + 1) < 10 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + dt.getFullYear() + "_" + dt.getHours() + "-" + dt.getMinutes();
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
      this.loading = false;
    } else {
      this.loading = false;
    }
    if (this.extractionId)
      this.tableExtractionSrv.deleteLoadingExtractionId(this.extractionId);
  }

  //extend nested json
  private extendNestedJson(item: any, originalItem: any, path: string) {
    if (typeof (item) == 'object') {
      for (let property in item) {
        if (!Array.isArray(item[property]) && typeof (item[property]) == 'object') {
          for (let subProperty in item[property]) {
            if (typeof (item[property][subProperty]) == 'object') {
              this.extendNestedJson(item[property][subProperty], originalItem, property + "." + subProperty);
            }
            else
              originalItem[property + "." + subProperty] = item[property][subProperty];
          }
          delete item[property];
        } else if (path && !Array.isArray(item[property])) {
          originalItem[path + "." + property] = item[property];
        }
      }
    }
  }

  //getHeadersFromApiResponse
  private getHeadersFromApiResponse(apiResponse: any) {
    let headers = [];
    if (apiResponse && apiResponse.length > 0) {
      apiResponse.forEach((row) => {
        for (let property in row) {
          if (!headers.includes(property) && (!this.noExtractColumnsList || !this.noExtractColumnsList.includes(property)))
            headers.push(property)
        }
      });
    }
    return headers;
  }

  //getHeadersFromColumns
  private getHeadersFromColumns(tableColumns: Array<tableColumn>) {
    let headers = [];
    if (tableColumns) {
      tableColumns.forEach((column) => {
        if (column.columnType != columnTypes.SELECT && column.columnType != columnTypes.DETAIL && !column.columnHidden) {
          headers.push(column.columnHeader ? this.translate.instant(column.columnHeader) : '');
        }
      })
    }
    return headers;
  }

  //format date for excel
  private formatDataForExcel(apiItem: any, column: tableColumn) {
    let valueTmp, apiItemValue = apiItem[column.columnDef], dateTmp: Date;
    if (apiItemValue != null && apiItemValue != undefined) {
      switch (column.columnType) {
        case columnTypes.BOOLEAN:
          valueTmp = apiItemValue ? this.translate.instant("labels.yes") : this.translate.instant("labels.no");
          break;
        case columnTypes.SELECT:
          break;
        case columnTypes.DATE:
          dateTmp = new Date(apiItemValue);
          valueTmp = (dateTmp.getDate() < 10 ? "0" + dateTmp.getDate() : dateTmp.getDate()) + "/" + ((dateTmp.getMonth() + 1) < 10 ? "0" + (dateTmp.getMonth() + 1) : (dateTmp.getMonth() + 1)) + "/" + dateTmp.getFullYear();
          break;
        case columnTypes.DATE_TIME:
          dateTmp = new Date(apiItemValue);
          valueTmp = (dateTmp.getDate() < 10 ? "0" + dateTmp.getDate() : dateTmp.getDate()) + "/" + ((dateTmp.getMonth() + 1) < 10 ? "0" + (dateTmp.getMonth() + 1) : (dateTmp.getMonth() + 1)) + "/" + dateTmp.getFullYear() + " " + dateTmp.getHours() + ":" + dateTmp.getMinutes();
          break;
        case columnTypes.TEXT:
          valueTmp = apiItemValue;
          break;
        case columnTypes.TOOLTIP:
          valueTmp = apiItemValue.replace(/\n/g, ', ');
          break;
        case columnTypes.TRANSLATE:
          valueTmp = this.translate.instant(column.columnParam + "." + apiItemValue);
          break;
        default:
          valueTmp = apiItemValue;
          break;
      }
    } else {
      valueTmp = "";
    }
    return valueTmp;
  }

  //isExtractionIdLoading
  public isExtractionIdLoading() {
    return this.extractionId ? this.tableExtractionSrv.isExtractionIdLoading(this.extractionId) : false;
  }
  //--- END GENERATE EXCEL ---//
}
