import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, IonButton, ModalController, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { PssfeSelectablePromptComponent } from './pssffe-selectable-prompt/pssfe-selectable-prompt.component';

@Component({
  selector: 'pssfe-selectable',
  templateUrl: './pssfe-selectable.component.html',
  styleUrls: ['./pssfe-selectable.component.scss'],
})
export class PssfeSelectableComponent implements OnInit, OnChanges {
  @Input() input;
  @Input() inputList;
  @Input() searchKey;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public httpUtilitySrv: HttpUtilityService,
    public modalCtrl: ModalController) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.input)
      this.input = changes.input.currentValue;
    if (changes && changes.inputList)
      this.inputList = changes.inputList.currentValue;
    if (changes && changes.searchKey)
      this.searchKey = changes.searchKey.currentValue;
    if (changes && changes.value)
      this.value = changes.value.currentValue;
    if (changes && changes.disabled)
      this.disabled = changes.disabled.currentValue;
    if (changes && changes.readonly)
      this.readonly = changes.readonly.currentValue;
  }

  ngOnInit() {

  }

  //openPssfeSelectable: apre il prompt di selezione
  async openPssfeSelectable() {
    if (this.value)
      this.input["value"] = JSON.parse(JSON.stringify(this.value));
    const modalCtrl = await this.modalCtrl.create({
      component: PssfeSelectablePromptComponent,
      cssClass: 'pssfe-selectable-prompt-modal',
      showBackdrop: false,
      componentProps: {
        input: this.input,
        inputList: this.inputList,
        searchKey: this.searchKey
      }
    });
    modalCtrl.onDidDismiss().then((res) => {
      if (res && res.data && res.data.updated) {
        this.value = res.data.value;
        this.valueChange.emit(this.value);
      }
    })
    return await modalCtrl.present();
  }
}
