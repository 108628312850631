import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import PostitBoardLinksJSON from '../assets/postit-board-links.json';

@Injectable({
    providedIn: 'root'
})
export class PostitBoardLinkService {
    private linksProd: PostitBoardModel = PostitBoardLinksJSON.prod;
    private linksPreProd: PostitBoardModel = PostitBoardLinksJSON.preProd;
    private linksTest: PostitBoardModel = PostitBoardLinksJSON.test;
    private linksLocal: PostitBoardModel = PostitBoardLinksJSON.local;

    public links: PostitBoardModel;

    constructor() {
        if (environment["production"])
            this.links = this.linksProd;
        else if (environment["preProduction"])
            this.links = this.linksPreProd;
        else if (environment["test"])
            this.links = this.linksTest;
        else if (environment["local"])
            this.links = this.linksLocal;
    }
}

export interface PostitBoardModel {
    server: string
}