import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { NavParams, Platform } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth-service.service';


@Component({
  selector: 'app-settings',
  templateUrl: './right-menu-popover.component.html',
  styleUrls: ['./right-menu-popover.component.scss'],
})
export class RightMenuComponent implements OnInit {
  //popover navParams
  public popoverNavParams;
  //customer list collapsed
  public customerListCollapsed = true;
  //customer list
  public customerList = [];
  public currentUser = {
    customer: '',
    userNameApp: '',
    role: {
      nome: '',
      id: null
    },
  }

  constructor(
    private authSrv: AuthService,
    public translate: TranslateService,
    private languageSrv: LanguageService,
    public navParams: NavParams,
    public popoverCtrl: PopoverController) {
    //setup popoverNavParams
    this.popoverNavParams = this.navParams.get("parent");
    //setup customer list
    this.customerList = this.popoverNavParams.currentUser.customerList;
  }

  ngOnInit() {
    this.authSrv.getAuthObject().then((authObject: any) => {
      this.currentUser = {
        userNameApp: authObject.userData.nome + " " + authObject.userData.cognome,
        role: authObject.role,
        customer: authObject.customer
      }

    })
  }

  //check current role
  public checkCurrentRole(tree) {
    if (tree && this.currentUser && this.currentUser.role && this.currentUser.role.id) {
      for (let territory of tree) {
        if (territory.ruoli) {
          for (let role of territory.ruoli) {
            if (role.id == this.currentUser.role.id)
              role["role-selected"] = true;
            else
              role["role-selected"] = false;
          }
        }
      }
    }
  }


}
