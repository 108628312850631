import { NgModule } from '@angular/core';
import { MenuTreeComponent } from './menu-tree.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { TranslateMenuPipeModule } from 'src/app/pipes/translate-menu-pipe/translate-menu.pipe.module'; 
@NgModule({
    declarations: [
        MenuTreeComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        RouterModule,
        TranslateMenuPipeModule
    ],
    exports: [
        MenuTreeComponent
    ]
})
export class MenuTreeComponentModule {}