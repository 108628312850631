import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AppDriverFlags, AppDriverNotification, AppDriverState, Vehicle } from '../models/app-driver.models';
import { defaultAppDriverFlags } from '../models/app-driver-default.models';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

@Injectable({
    providedIn: 'root'
})
export class AppDriverStateService {
    private _queryParams: any;
    constructor(private _storage: Storage, private _activatedRoute: ActivatedRoute) {
        this._activatedRoute.queryParams.subscribe((params) => {
            this._queryParams = params;
        })
    }

    //--- query params ---//
    public getQueryParams(): any {
        return this._queryParams;
    }
    //--- end query params ---//

    //--- storage ---//
    public async setState(appState: AppDriverState) {
        await SecureStoragePlugin.set({ key: "APP_DRIVER_STATE", value: JSON.stringify(appState) });
    }
    public async getState(): Promise<AppDriverState> {
        let stateString: { value: string } = await SecureStoragePlugin.get({ key: "APP_DRIVER_STATE" });
        return JSON.parse(stateString.value);
    }
    public async setCurrentVehicle(vehicle: Vehicle) {
        await this._storage.create();
        await this._storage.set("APP_DRIVER_VEHICLE", vehicle);
    }
    public async getCurrentVehicle(): Promise<Vehicle> {
        await this._storage.create();
        return await this._storage.get("APP_DRIVER_VEHICLE");
    }
    public async removeVehicle(vehicleId: number) {
        await this._storage.create();
        let appState = await this.getState();
        appState.vehicles = appState.vehicles.filter((vehicle) => { return vehicle.id != vehicleId });
        await this.setState(appState);
    }
    public async getFlags(): Promise<AppDriverFlags> {
        await this._storage.create();
        let flags: AppDriverFlags = await this._storage.get("APP_DRIVER_FLAGS");
        if (flags == null || flags == undefined)
            flags = JSON.parse(JSON.stringify(defaultAppDriverFlags));
        return flags;
    }
    public async setFlags(flags: AppDriverFlags) {
        await this._storage.create();
        await this._storage.set("APP_DRIVER_FLAGS", flags);
    }
    public async getCurrentNotification(): Promise<AppDriverNotification> {
        await this._storage.create();
        let currentNotification: AppDriverNotification = await this._storage.get("APP_DRIVER_CURRENT_NOTIFICATION");
        return currentNotification;
    }
    public async setCurrentNotification(notification: AppDriverNotification) {
        await this._storage.create();
        await this._storage.set("APP_DRIVER_CURRENT_NOTIFICATION", notification);
    }
    //--- end storage ---//

}

