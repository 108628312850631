<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'postitBoard.labels.fileUpload' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="postit-board-file-upload-component">
  <ion-card class="modal-page-card">
    <ion-card-content>
      <form [formGroup]="uploadForm">
        <ion-row>
          <!-- header -->
          <ion-col size-md="12" size-sm="12" size-xs="12" class="p-0">
            <div class="group-header">
              <span>{{'postitBoard.labels.uploadData' | translate}}</span>
            </div>
          </ion-col>
          <!-- dateStart -->
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <ion-item class="input-field" lines="none">
              <ion-label position="stacked">
                {{'postitBoard.labels.startDate' | translate}}</ion-label>
              <ion-input type="date" formControlName="dateStart"
                placeholder="{{'postitBoard.labels.insert' | translate}}" [disabled]="uploadLoading">
              </ion-input>
            </ion-item>
          </ion-col>
          <!-- file -->
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <div class="file-box">
              <input type="file" formControlName="file" id="fileInput" (change)="fileChangeEvent()"
                [ngClass]="{'input-file-disabled': uploadLoading}">
              <div class="image-box">
                <img [hidden]="!base64" [src]="base64" accept="image/png, image/jpg, image/jpeg">
              </div>
            </div>
          </ion-col>
          <!-- upload -->
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <div class="flex-center-center">
              <ion-button color="success" size="default" (click)="upload()"
                [disabled]="!uploadForm.get('dateStart').value || !base64 || uploadLoading">
                <ion-spinner name="lines-small" [hidden]="!uploadLoading"></ion-spinner>
                <ion-label>{{'postitBoard.buttons.upload' | translate }}</ion-label>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="uploadResponse">
          <!-- header -->
          <ion-col size-md="12" size-sm="12" size-xs="12" class="p-0" id="preview-title">
            <div class="group-header">
              <span>{{'postitBoard.labels.preview' | translate}}</span>
            </div>
          </ion-col>
          <!-- image -->
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <div class="preview-image">
              <img [src]="'data:image/png;base64,'+uploadResponse?.fileCheck">
            </div>
          </ion-col>
          <!-- header -->
          <ion-col size-md="12" size-sm="12" size-xs="12" class="p-0"
            *ngIf="uploadResponse.attivitaNonRiconosciute && uploadResponse.attivitaNonRiconosciute.length > 0">
            <div class="group-header">
              <span>{{'postitBoard.labels.notFoundActivities' | translate}}</span>
            </div>
          </ion-col>
          <!-- activities not found -->
          <ion-col size-md="12" size-sm="12" size-xs="12" class="p-0"
            *ngIf="uploadResponse.attivitaNonRiconosciute && uploadResponse.attivitaNonRiconosciute.length > 0">
            <div class="preview-activities-list">
              <div class="upload-activities-list-item" *ngFor="let activity of uploadResponse.attivitaNonRiconosciute">
                {{activity}}
              </div>
            </div>
          </ion-col>
          <!-- confirm -->
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <div class="flex-center-center">
              <ion-button color="success" size="default" (click)="confirm()">
                <ion-label>{{'postitBoard.buttons.confirm' | translate }}</ion-label>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <!-- buttons-->
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>{{'postitBoard.buttons.close' | translate }}</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>