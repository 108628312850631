import { Injectable, EventEmitter } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { Subject, Observable } from 'rxjs';
import { AuthService } from './auth-service.service';
import { IHttpConnectionOptions, HubConnectionBuilder } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  //hub connection
  public hubConnection: signalR.HubConnection;
  //signal recived
  SignalRecived = new EventEmitter<any>();
  //attempt connection count
  public connectionCount: number = 0;
  //connected
  public connected = false;

  constructor(public httpUtilitySrv: HttpUtilityService, public authSrv: AuthService) {

  }

  public buildConnection = () => {
    this.authSrv.getAuthObject().then((authObject: any) => {
      if (authObject && authObject.userData && authObject.userData.token) {
        const options: IHttpConnectionOptions = {
          accessTokenFactory: () => {
            return authObject.userData.token;
          }
        };
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(`${this.httpUtilitySrv.links.chathub}`, options)
          .build()
        this.startConnection();
      }
    })
  }

  public startConnection = () => {
    let context = this;
    this.hubConnection
      .start()
      .then(() => {
        context.connected = true;
        this.registerSignalEvents();
      })
      .catch(err => {
        context.connectionCount++;
        setTimeout(function () {
          if (context.connectionCount < 3) {
            context.startConnection();
          }
        }, 3000)
      })
  }

  private registerSignalEvents() {
    this.hubConnection.on("ReceiveMessage", (user: any, message: any) => {
      this.SignalRecived.emit({ user: user, message: message })
    })
  }

  //only for testing
  public sendMessage() {
    this.hubConnection.invoke("SendMessage", "mario", "ciao mondo").
      catch(function (err) {
        return console.error(err.toString());
      });
  }

  //disconnected
  private disconnected() {
    this.connected = false;
    this.connectionCount = 0;
    if (this.hubConnection)
      this.hubConnection.stop();
  }

  //connect
  private connect() {
    this.buildConnection();
    //this.startConnection();
  }

  //manage signalr
  public manageSignalrConnection() {
    this.authSrv.getAuthObject().then((authObject: any) => {
      if (authObject && authObject.userData && authObject.userData.idUtente) {
        if (!this.connected) {
          this.connect();
        }
      } else {
        this.disconnected();
      }
    })
  }


}