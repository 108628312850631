import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageVariablesService } from './storage-variables.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { Device, GetLanguageCodeResult } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private platform: Platform,
    public storageVariableSrv: StorageVariablesService,
    public translate: TranslateService, public httpUtilitySrv: HttpUtilityService,
    private _http: HttpClient, private _appSrv: AppService) {
    this.getLanguage().then((lan: any) => {
      this.translate.setDefaultLang(lan);
      //getTranslations
      this.getTranslations(lan).then((res) => {
        if (res) {
          let unflattedJson = this.unflatJson(JSON.parse(JSON.stringify(res)));
          this.translate.setTranslation(lan, unflattedJson, true);
          setTimeout(() => {
            this.translate.use(lan);
            this._getSubBaseRules(lan);
          }, 500)
        }
      }).catch(() => {
      })
    });
  }

  //get language
  public getLanguage() {
    return new Promise<string>(async (resolve, reject) => {
      let language;
      if (["localImmaExt", "testImmaExt", "preProductionImmaExt", "productionImmaExt"].includes(this.httpUtilitySrv.getEnvironment()))
        resolve("it");
      else {
        this.storageVariableSrv.getLanguageObject().then(async (lan: any) => {
          //setup from local
          if (lan && lan.language) {
            language = lan.language;
            resolve(language);
          }
          //setup from browser
          else if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
            language = this.getBrowserLanguageJsonReferenceBrowser();
            resolve(language);
          } else if (this.platform.is('capacitor')) {
            let languageCode: GetLanguageCodeResult = await Device.getLanguageCode();
            resolve(languageCode.value.indexOf('it') != -1 ? 'it' : 'en');
          }
        }).catch(() => {
          resolve('en');
        })
      }
    })
  }

  //getBrowserLanguageJsonReference: return name of local json based on browser language
  public getBrowserLanguageJsonReferenceBrowser() {
    let language = 'en'; //default language english
    language = navigator.language;
    if (language.indexOf("en-US") != -1 && !this.platform.is('android') && !this.platform.is('ios'))
      language = "en-US";
    else if (language.indexOf("en-") != -1)
      language = "en";
    else if (language.indexOf("it-") != -1)
      language = "it";
    else if (language.indexOf("fr-") != -1) {
      language = "fr";
    }
    else
      language = "en";
    return language;
  }

  //instant
  public instant(path) {
    return this.translate.instant(path);
  }

  /*translate paths
  /resolve translated string path (ex: ['labels.hello': "hello!", 'messages.example_message': "Eaxmple message."]) 
  /from given path list (ex: ['labels.hello', 'messages.example_message'])
  */
  public transaltePathsPromise(paths) {
    return new Promise((resolve) => {
      this.translate.get(paths).subscribe((translationList) => {
        resolve(translationList);
      })
    })
  }

  //change language
  public changeLanguage(language: string) {
    if (language) {
      //language = language.toLowerCase();
      if (["it", "en", "en-US", "fr", "de", "at", "us"].includes(language)) {
        this.getTranslations(language).then((res) => {
          this.storageVariableSrv.setLanguageObject(language);
          this.translate.setDefaultLang(language);
          setTimeout(() => {
            if (res) {
              let unflattedJson = this.unflatJson(JSON.parse(JSON.stringify(res)))
              this.translate.setTranslation(language, unflattedJson, true);
            }
            this.translate.use(language);
            //mfe propagation
            if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
              (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ language: language }, "*");
            }
          }, 500)
        }).catch(() => {
          this.storageVariableSrv.setLanguageObject(language)
          this.translate.setDefaultLang(language);
          this.translate.use(language);
          //mfe propagation
          if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
            (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ language: language }, "*");
          }
        })
      }
    }
  }

  //getTranslations
  public getTranslations(language, baseUrl?: string) {
    return new Promise((resolve, reject) => {
      let url = baseUrl ? (baseUrl + "/translations/" + language + ".json") : `${this.httpUtilitySrv.links.apigateway}traduzioni/external/getTranslations?codiceApplicazione=portalesafofe&codiceLingua=${language}`;
      this._http.get(url).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    })
  }

  //unflatJson
  public unflatJson(json) {
    if (Object(json) !== json || Array.isArray(json)) return json;
    try {
      var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
        resultholder = {};
      for (var p in json) {
        var cur = resultholder,
          prop = "",
          m;
        while (m = regex.exec(p)) {
          cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
          prop = m[2] || m[1];
        }
        if (typeof cur == 'object')
          cur[prop] = json[p];
      }
      return resultholder[""] || resultholder;
    } catch (error) {
      return json;
    }
  }

  //getSubBaseRules
  private async _getSubBaseRules(lan: string) {
    let subBaseRules = await this._appSrv.getSubBaseRules();
    if (subBaseRules.folderPath) {
      this.getTranslations(lan, subBaseRules.folderPath).then((res) => {
        if (res) {
          let unflattedJson = this.unflatJson(JSON.parse(JSON.stringify(res)));
          this.translate.setTranslation(lan, unflattedJson, true);
          setTimeout(() => {
            this.translate.use(lan);
          }, 500)
        }
      }).catch(() => {
      })
    }
  }
}

