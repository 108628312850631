import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AdminRoleDetailService } from '../../services/admin-role-detail.service'
import { AlertService } from 'src/app/services/alert.service';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-selectable-modules',
  templateUrl: './selectable-modules.component.html',
  styleUrls: ['./selectable-modules.component.scss'],
})
export class SelectableModules implements OnInit {
  //modal nabparams
  public modalNavParams;

  constructor(public router: Router,
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    public adminRoleDetailSrv: AdminRoleDetailService,
    public storageVariablesSrv: StorageVariablesService) {
    //setup nav params
    this.modalNavParams =
    {
      tree: this.navParams.get("tree") ? this.navParams.get("tree") : null,
      checkboxDisabled: this.navParams.get("checkboxDisabled") ? this.navParams.get("checkboxDisabled") : false
    }
  }

  ngOnInit() {

  }

  //select
  public select() {
    this.popoverCtrl.dismiss({ tree: this.modalNavParams.tree })
  }

  //on module select
  public handleCheckbox(event) {
    if (event.value) {
      this.checkParents(this.modalNavParams.tree, event);
      this.searchForModule(this.modalNavParams.tree, event, "checkChildren");
    }
    else {
      this.searchForModule(this.modalNavParams.tree, event, "uncheckChildren");
      this.searchForModule(this.modalNavParams.tree, event, "uncheckParent");
    }

  }

  //check parents
  public checkParents(tree, event) {
    if (tree && event.parentId) {
      let checked = false;
      for (let module of tree) {
        if (module.id == event.parentId) {
          module["abilitato"] = true;
          checked = true;
        }
        if (!checked && module.figli)
          this.checkParents(module.figli, event)
        else if (checked && module.idPadre) {
          event.parentId = module.idPadre;
          this.checkParents(this.modalNavParams.tree, event)
        }
      }
    }
  }

  //search for module(){}
  public searchForModule(tree, event, type) {
    if (tree) {
      for (let module of tree) {
        if (type == 'uncheckChildren' && event.id && module.id == event.id) {
          this.uncheckChildren(module.figli)
          break;
        } else if (type == 'uncheckParent' && event.parentId && module.id == event.parentId) {
          this.uncheckParent(module, event.id)
          break;
        } else if (type == 'checkChildren' && event.id && module.id == event.id) {
          this.checkChildren(module.figli)
        }
        else
          this.searchForModule(module.figli, event, type)
      }
    }
  }

  //uncheck parent
  public uncheckParent(parent, childId) {
    if (parent && parent.figli && (parent.idtipoModulo == 1 || parent.idtipoModulo == 6)) {
      let atLeastOneSelected = false;
      for (let module of parent.figli) {
        if (module.id == childId) {
          if (!module["abilitato"]) {
            atLeastOneSelected = true;
            break;
          }
        } else if (module["abilitato"]) {
          atLeastOneSelected = true;
          break;
        }
      }
      if (!atLeastOneSelected) {
        parent["abilitato"] = false;
        if (parent.idPadre)
          this.searchForModule(this.modalNavParams.tree, {parentId: parent.idPadre}, 'uncheckParent')
      }
    }
  }

  //uncheck children
  public uncheckChildren(tree) {
    if (tree) {
      for (let module of tree) {
        module["abilitato"] = false;
        this.uncheckChildren(module.figli);
      }
    }
  }

  //check children
  public checkChildren(tree) {
    if (tree) {
      for (let module of tree) {
        module["abilitato"] = true;
        this.checkChildren(module.figli);
      }
    }
  }


}
