import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ViewAndFineRegistrationFormComponent } from '../../components/view-and-fine-registration-form/view-and-fine-registration-form.component';
import { VafAuthorityService } from '../../services/vaf-authority.service';
import { VafListsService } from '../../services/vaf-lists.service';
import { VafUtilitySrv } from '../../services/vaf-utility.service';

@Component({
  selector: 'app-vaf-entity-registration',
  templateUrl: './vaf-entity-registration.component.html',
  styleUrls: ['./vaf-entity-registration.component.scss'],
})
export class VafEntityRegistrationComponent implements OnInit {
  //form
  public form: FormGroup;
  //modal nav params
  public modalNavParams;
  //publicisReasonRequired
  public isReasonRequired = false;
  //options
  public countriesOptions: Array<any> = [];
  //entity-registration-form
  @ViewChild("entityRegistrationForm") entityRegistrationForm: ViewAndFineRegistrationFormComponent;
  //fiscalCode
  public fiscalCode: string;
  public fiscalCodeCheck: boolean = false;
  //showRegistrationSuccess
  public showRegistrationSuccess: boolean = false;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public navParams: NavParams,
    private alertSrv: AlertService,
    private vafListsSrv: VafListsService,
    private loaderSrv: LoaderService,
    private authoritySrv: VafAuthorityService,
    private vafUtilitySrv: VafUtilitySrv) {
    //setup validators
    this.form = this.formBuilder.group({
      motivazione: [null]
    })
  }

  ngOnInit() {
    this.getLists();
  }

  //getLists
  private getLists() {
    this.vafListsSrv.getCommonTypeLists().then((res: any) => {
      this.countriesOptions = res.countries;
    })
  }

  //createAuthority
  public createAuthority() {
    this.loaderSrv.open();
    this.authoritySrv.createAuthority(
      this.entityRegistrationForm.issuingAuthorities,
      this.entityRegistrationForm.authorityForm.get("name").value,
      this.entityRegistrationForm.authorityForm.get("fiscalCode").value,
      this.countriesOptions.filter((x) => { return x.id == this.entityRegistrationForm.authorityForm.get("country").value })[0],
      this.entityRegistrationForm.authorityForm.get("address").value,
      this.entityRegistrationForm.authorityForm.get("place").value,
      this.entityRegistrationForm.authorityForm.get("city").value,
      this.entityRegistrationForm.authorityForm.get("province").value,
      this.entityRegistrationForm.authorityForm.get("zipCode").value,
      this.entityRegistrationForm.referentForm.get("referentName").value,
      this.entityRegistrationForm.referentForm.get("referentSurname").value,
      this.entityRegistrationForm.referentForm.get("referentEmail").value,
      this.entityRegistrationForm.referentForm.get("referentPhone").value,
    ).then((res: any) => {
      if (res) {
        //this.alertSrv.successAlert(this.translate.instant("viewAndFine.messages.entityCreateSuccessMessage"));
        this.showRegistrationSuccess = true;
        //this.modalCtrl.dismiss();
      } else {
        this.showRegistrationSuccess = false;
        this.alertSrv.errorAlert(this.translate.instant("viewAndFine.messages.entityCreateFailMessage"));
      }
      this.loaderSrv.dismiss();
    }).catch((err) => {
      this.showRegistrationSuccess = false;
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.alertSrv.errorAlert(this.vafUtilitySrv.getHttpCatchError(err));
;
      }
    })
  }

  //checkAuthorityExistence
  public checkAuthorityExistence() {
    this.loaderSrv.open();
    this.authoritySrv.checkAuthorityExistence(this.fiscalCode).then((res) => {
      if (res) {
        this.fiscalCodeCheck = false;
        this.alertSrv.errorAlert(this.translate.instant("viewAndFine.messages.fiscalCodeExistsMessage"));
      }
      else {
        this.fiscalCodeCheck = true;
        this.entityRegistrationForm.authorityForm.get("fiscalCode").setValue(JSON.parse(JSON.stringify(this.fiscalCode)));
      }
      this.loaderSrv.dismiss();
    }).catch((err) => {
      this.loaderSrv.dismiss();
      
    })
  }
}
