import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Platform, ModalController, AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from '../../services/loader.service'
import { AdminUserDetailService } from '../../services/admin-user-detail.service'
import { SelectableTreePopoverComponent } from '../selectable-tree-popover/selectable-tree-popover.component';
import { SectionService } from 'src/app/services/section.service';
import { AuthService } from '../../services/auth-service.service';
import { Router } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';

@Component({
  selector: 'app-admin-user-massive-create-settings',
  templateUrl: './admin-user-massive-create-settings.component.html',
  styleUrls: ['./admin-user-massive-create-settings.component.scss'],
})
export class AdminUserMassiveCreateSettingsComponent implements OnInit {
  //data integration form
  public userDetailForm: FormGroup;
  //modal nav params
  public modalNavParams;
  //type options
  public customerOption = [];
  //territories options
  public customerOnlyOption = [];
  //roles list
  public rolesList = [];
  //role id list (used for user detail)
  public roleIdList = [];
  //section id
  public sectionIdList = [];
  //current Territory Id
  public currentTerritoryId = null;
  //currentTerritoryName
  public currentTerritoryName = null;
  //current User Id
  public currentUserId = null;
  //user credentials
  public userCredentials = [];
  //
  public pathToolIdListPivot = [];
  //
  public roleObjList = [];
  //
  public pathToolStringListPivot = [];
  // ownerName
  public ownerName;


  constructor(private httpUtilitySrv: HttpUtilityService,
    public translate: TranslateService,
    private alertSrv: AlertService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loadingSrv: LoaderService,
    public navParams: NavParams,
    public adminUserDetailSrv: AdminUserDetailService,
    public sectionSrv: SectionService,
    private authSrv: AuthService,
    public storaveVariableSrv: StorageVariablesService,
    private router: Router) {
    //setup validators
    this.userDetailForm = this.formBuilder.group({
      language: ["it", Validators.required],
      enabled: [true, Validators.required],
      currentTerritoryIsOwner: [0]
    })
    //setup nav params
    this.modalNavParams =
    {

    }
  }

  ngOnInit() {
    this.getClienti();
    this.getSezioni();
    this.authSrv.getAuthObject().then((authObject: any) => {
      this.currentTerritoryId = authObject.customerId;
      this.currentTerritoryName = authObject.customer;
      this.currentUserId = authObject.userData.idUtente;
    });
  }

  //get clienti
  private getClienti() {
    this.adminUserDetailSrv.getClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOnlyOption = res.payload;
      } else {
        this.customerOnlyOption = [];
      }
    }).catch((err) => {
      
    })
  }

  //get full clienti
  private getFullClienti() {
    this.adminUserDetailSrv.getFullClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOption = res.payload;
      } else {
        this.customerOption = [];
      }
    }).catch((err) => {
      
    })
  }

  //get sezioni
  private getSezioni() {
    this.sectionSrv.getSezioni("amministrazione.gestione_utenti").then((res: any) => {
      if (res.code == 200) {
        this.sectionIdList = res.payload;
        this.getFullClienti();
      }
    }).catch((err) => {
      
    })
  }

  //hide section
  public hideSection(id) {
    return !this.sectionIdList.includes(id);
  }

  //----- selectable tree ------//
  //selectable tree popover
  async selectableTreePopover(type) {
    const popover = await this.popoverCtrl.create({
      component: SelectableTreePopoverComponent,
      cssClass: 'selectable-tree-popover',
      componentProps: {
        title: this.translate.instant('labels.select'),
        tree: type == 'customer' ? this.customerOnlyOption : this.customerOption,
        titleKey: "denominazione",
        childrenKey: "figli",
        hideCheckboxParent: type == 'customer' ? false : true,
        hideCheckboxRole: type == 'customer' ? true : false,
        single: type == 'customer' ? true : false,
        showRolesIndicator: type == 'customer' ? false : true
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          if (type == 'customer') {
            this.modalNavParams.idProprietario = null;
            this.setOwnerId(res.data.tree);
          } else {
            this.rolesList = [];
            this.getRoleList(res.data.tree)
            this.roleIdList = [];
            this.roleObjList = [];
            this.getRoleIdList(res.data.tree, 0, null);
          }
        }
      });
    return await popover.present();
  }

  //get role list
  public getRoleList(tree) {
    let currentCustomer = {};
    currentCustomer["ruoli"] = [];
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (i == "ruoli" && tree[i].length > 0) {
          currentCustomer["idCliente"] = tree["idCliente"];
          for (let role of tree[i]) {
            if (role.selected)
              currentCustomer["ruoli"].push(role)
          }
          if (currentCustomer["ruoli"].length > 0)
            this.rolesList.push(currentCustomer)
        }
        this.getRoleList(tree[i]);
      }
    }
  }

  /*//get role id list
  public getRoleIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].id && tree[i].selected)
          this.roleIdList.push(tree[i].id)
        this.getRoleIdList(tree[i]);
      }
    }
  }*/

  public getRoleIdList(tree, fatherId: number, fatherName: string) {
    if (!fatherId || fatherId == 0) {
      this.pathToolIdListPivot = [];
      this.pathToolStringListPivot = [];
    }
    for (let territory of tree) {
      if (fatherId && fatherId != 0) {
        let pos = this.pathToolIdListPivot.findIndex(x => x === fatherId);
        this.pathToolIdListPivot = this.pathToolIdListPivot.slice(0, pos + 1);
        let posString = this.pathToolStringListPivot.findIndex(x => x === fatherName);
        this.pathToolStringListPivot = this.pathToolStringListPivot.slice(0, posString + 1);
      }
      this.pathToolIdListPivot.push(territory.idCliente);
      this.pathToolStringListPivot.push(territory.denominazione);
      if (territory.ruoli && territory.ruoli.length > 0) {
        for (let role of territory.ruoli) {
          if (role['selected']) {
            this.roleObjList.push(
              {
                id: role.id,
                roleName: role.nome,
                territoriesPath: this.pathToolIdListPivot,
                territoriesPathName: this.pathToolStringListPivot
              });
            this.roleIdList.push(role.id);
          }
        }
      }
      if (territory.figli && territory.figli.length > 0) {
        this.getRoleIdList(territory.figli, territory.idCliente, territory.denominazione)
      }
    }
  }

  //get pathIdlist
  private getPathRoleList(roleList) {
    let list = [];
    for (let role of roleList) {
      for (let id of role.territoriesPath) {
        if (!list.includes(id))
          list.push(id);
      }
    }
    return list;
  }

  //set setOwnerId (advanced module)
  public setOwnerId(tree) {
    if (this.sectionIdList.includes("amministrazione.gestione_utenti.set_owner")) {
      for (let i in tree) {
        if (!!tree[i] && typeof (tree[i]) == "object") {
          if (tree[i].idCliente && tree[i].selected) {
            this.modalNavParams.idProprietario = tree[i].idCliente;
            this.ownerName = tree[i].denominazione;
            break;
          }
          this.setOwnerId(tree[i]);
        }
      }
    }
  }

  //set setOwnerId (simplified module)
  private setOwnerIdAsCurrentTerritory(value) {
    if (this.sectionIdList.includes("amministrazione.gestione_utenti.set_owner_simplified")) {
      if (value == "1") {
        this.modalNavParams.idProprietario = this.currentTerritoryId;
        this.ownerName = this.currentTerritoryName;
      } else if (value == "2") {
        this.modalNavParams.idProprietario = null;
        this.ownerName = null;
      }
    }
  }

  //merge roleIdList
  public setStartingOwnerId(tree) {
    if (this.modalNavParams.idProprietario != null) {
      for (let i in tree) {
        if (!!tree[i] && typeof (tree[i]) == "object") {
          if (tree[i].idCliente && this.modalNavParams.idProprietario == tree[i].idCliente) {
            tree[i].selected = true;
            break;
          }
          this.setStartingOwnerId(tree[i]);
        }
      }
    }
  }

  public mergeRoleIdList(tree, idTerritoriDaEsplodere) {
    for (let item of tree) {
      //check if role is selected
      if (item.ruoli)
        for (let role of item.ruoli) {
          if (role.id && this.roleIdList.includes(role.id)) {
            role['selected'] = true;
            item['rolesOpened'] = true;
          }
        }
      //check for opened
      if (idTerritoriDaEsplodere.includes(item.idCliente)) {
        item['opened'] = true;
      }
      if (item.figli)
        this.mergeRoleIdList(item.figli, idTerritoriDaEsplodere);
    }
  }

  //----- end selectable tree ------//

  //saveSettings
  public saveSettings() {
    this.setOwnerIdAsCurrentTerritory(this.userDetailForm.get("currentTerritoryIsOwner").value);
    this.popoverCtrl.dismiss({
      settings: {
        roles: this.rolesList,
        rolesIdList: this.roleIdList,
        language: this.userDetailForm.get('language').value,
        enabled: this.userDetailForm.get('enabled').value,
        currentTerritoryIsOwner: this.userDetailForm.get('currentTerritoryIsOwner').value,
        owner: this.modalNavParams.idProprietario,
        ownerName: this.ownerName,
        roleObjList: this.roleObjList
      }
    })
  }

}
