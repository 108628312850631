import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertController, IonContent, IonInput, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BoardMainService } from '../../services/board-main.service';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';

export interface listManagementProperty {
  name: string, //property name
  type: string, //input type: 'TEXT', 'NUMBER', 'SELECTABLE'
  width: string, //width of property: '50px', '30%'
  selectableOptions?: Array<any>, //optional options for selectable input type
  itemTextField?: string, //optional for selectable
  itemValueField?: string //optional for selectable
  isMultiple?: boolean //optional for selectable
}

@Component({
  selector: 'app-postit-board-list-management',
  templateUrl: './postit-board-list-management.component.html',
  styleUrls: ['./postit-board-list-management.component.scss'],
})
export class PostitBoardListManagementComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //addRow
  public rowAddRow: any;
  //currentBeforeFocusValue
  public currentBeforeFocusValue;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    private boardMainSrv: BoardMainService,
    private postitBoardUtilitySrv: PostitBoardUtilityService,
    private loaderSrv: LoaderService) {
    //setup nav params
    this.modalNavParams =
    {
      list: this.navParams.get("list") ? this.navParams.get("list") : null,
      properties: this.navParams.get("properties") ? this.navParams.get("properties") : null,
      title: this.navParams.get("title") ? this.navParams.get("title") : null,
      target: this.navParams.get("target") ? this.navParams.get("target") : null,
      autoAddItem: this.navParams.get("autoAddItem") ? this.navParams.get("autoAddItem") : null,
      readonly: this.navParams.get("readonly") ? this.navParams.get("readonly") : false,
      highlightRow: this.navParams.get("highlightRow") ? this.navParams.get("highlightRow") : null, //propertyTarget, valueTarget
      refreshEnpointParams: this.navParams.get("refreshEnpointParams") ? this.navParams.get("refreshEnpointParams") : null
    }

  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.rowAddRow = {};
    if (this.modalNavParams.properties && this.modalNavParams.autoAddItem && this.modalNavParams.target) {
      for (let property of this.modalNavParams.properties) {
        this.rowAddRow[property.name] = this.modalNavParams.autoAddItem[property.name];
      }
      this.addRow();
    } else {
      //generate rowAddRow
      if (this.modalNavParams.properties) {
        for (let property of this.modalNavParams.properties) {
          this.rowAddRow[property.name] = null
        }
      }
    }
    this.refreshRows(null, this.modalNavParams.highlightRow);
  }

  //--- ROWS MANAGEMENT ---//
  public addRow() {
    if (this.modalNavParams.target) {
      let endpoint;
      switch (this.modalNavParams.target) {
        case 'ACTIVITIES':
          endpoint = `attivita/add?codice=${this.rowAddRow['codice']}&descrizione=${this.rowAddRow['descrizione']}&idGruppo=${this.rowAddRow['idGruppo'] ? this.rowAddRow['idGruppo'].id : null}`;
          break
        case 'GROUPS':
          endpoint = `gruppo/add?codice=${this.rowAddRow['codice']}&descrizione=${this.rowAddRow['descrizione']}`;
          break
      }
      this.loaderSrv.open();
      this.boardMainSrv.genericGet(endpoint).then((res) => {
        for (let property of this.modalNavParams.properties) {
          this.rowAddRow[property.name] = null;
        }
        this.loaderSrv.dismiss();
        this.refreshRows(null, null);
      }).catch((err) => {
        this.loaderSrv.dismiss();
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    }
  }
  public editRow(row) {
    if (this.modalNavParams.target) {
      let endpoint;
      switch (this.modalNavParams.target) {
        case 'ACTIVITIES':
          endpoint = `attivita/update?id=${row['id']}&codice=${row['codice']}&descrizione=${row['descrizione']}${row['idGruppo'] ? ('&idGruppo='+row['idGruppo'].id) : ''}`;
          break;
        case 'GROUPS':
          endpoint = `gruppo/update?id=${row['id']}&codice=${row['codice']}&descrizione=${row['descrizione']}`;
          break
      }
      this.loaderSrv.open();
      this.boardMainSrv.genericGet(endpoint).then((res) => {
        this.loaderSrv.dismiss();
        this.refreshRows(row, null);
      }).catch((err) => {
        this.loaderSrv.dismiss();
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    }
  }
  public deleteRow(row) {
    if (this.modalNavParams.target) {
      this.alertSrv.warningConfirmAlert({ message: this.translate.instant('postitBoard.messages.deleteRowMessage') }, () => {
        let endpoint;
        switch (this.modalNavParams.target) {
          case 'ACTIVITIES':
            endpoint = `attivita/delete?id=${row.id}`;
            break;
          case 'GROUPS':
            endpoint = `gruppo/delete?id=${row.id}`;
            break;
        }
        this.loaderSrv.open();
        this.boardMainSrv.genericGet(endpoint).then((res) => {
          this.loaderSrv.dismiss();
          this.refreshRows(null, null);
        }).catch((err) => {
          this.loaderSrv.dismiss();
          if (err && err.status && err.status != 401 && err.status != 403) {
            this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
              this.alertSrv.errorAlert(err);
            })
          }
        })
      })
    }
  }
  private refreshRows(row, highlightRow) {
    if (this.modalNavParams.target) {
      let endpoint;
      switch (this.modalNavParams.target) {
        case 'ACTIVITIES':
          endpoint = `attivita/list`;
          break;
        case 'GROUPS':
          endpoint = "gruppo/list";
          break;
        case 'SECTION_DETAIL':
          endpoint = "gruppo/GetDettaglioGruppo" + (this.modalNavParams.refreshEnpointParams ? this.modalNavParams.refreshEnpointParams : "");
          break;
      }
      this.loaderSrv.open();
      this.boardMainSrv.genericGet(endpoint).then((res: any) => {
        if (this.modalNavParams.target == "ACTIVITIES")
          res = this.formatActivitiesList(res);
        //check update row
        if (row) {
          let resRow = res.filter((x) => { return x.id == row.id })[0];
          if (resRow) {
            this.modalNavParams.list.forEach((element, index) => {
              if (element.id == resRow.id){
                resRow["hasChanges"] = false;
                this.modalNavParams.list[index] = resRow;
              }
            })
          }
        } else {
          this.modalNavParams.list = res;
        }
        this.loaderSrv.dismiss();
        //check highlightRow
        if (highlightRow && highlightRow.propertyTarget && (highlightRow.valueTarget || highlightRow.valueTarget == 0)) {
          setTimeout(() => {
            let currentRow = this.modalNavParams.list.filter((row) => { return row[highlightRow.propertyTarget] == highlightRow.valueTarget })[0];
            if (currentRow) {
              let currentRowDiv: HTMLDivElement = document.getElementById('row' + currentRow[highlightRow.propertyTarget]) as HTMLDivElement;
              currentRowDiv.style.background = "rgba(var(--ion-color-warning-rgb), 0.4)";
              currentRowDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500)
        }
      }).catch((err) => {
        this.loaderSrv.dismiss();
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    }
  }
  //setHasChanges
  public setHasChanges(row) {
    row['hasChanges'] = true;
  }

  //selectableSelect
  public selectableSelect(row, property: listManagementProperty) {
    if (!property.isMultiple && row[property.name] == this.currentBeforeFocusValue) {
      row[property.name] = null;
      row["hasChanges"] = true;
    }
  }

  //setCurrentBeforeFocusValue
  public setCurrentBeforeFocusValue(row, property: listManagementProperty) {
    this.currentBeforeFocusValue = row[property.name];
  }

  //format activities list
  private formatActivitiesList(res) {
    let tmpGroup, tmpOptions;
    res = JSON.parse(JSON.stringify(res));
    res.forEach((activity) => {
      if (activity.idGruppo) {
        tmpOptions = this.modalNavParams.properties.filter((x) => { return x.name == "idGruppo" })[0];
        tmpGroup = tmpOptions.selectableOptions.filter((x) => { return x.id == activity.idGruppo })[0];
        if (tmpGroup) {
          activity.idGruppo = tmpGroup;
        }
      }
    });
    return res;
  }
  //--- END ROWS MANAGEMENT ---//
}