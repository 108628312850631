import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatPlaceholder'
})
export class DateFormatPlaceholder implements PipeTransform {

  constructor() {}
  transform(type: "DATE" | "DATETIME" | "DATETIMESECONDS") {
    if (['en-US'].includes(navigator.languages[0]))
      return type == "DATE" ? "mm/dd/yyyy" : type == "DATETIME" ? "mm/dd/yyyy hh:mm" : "mm/dd/yyyy hh:mm:ss";
    return type == "DATE" ? "dd/mm/yyyy" : type == "DATETIME" ? "dd/mm/yyyy hh:mm" : "dd/mm/yyyy hh:mm:ss";
  }
}
