import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AlertController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, single } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingLogService } from 'src/app/services/loading-log.service';

@Component({
  selector: 'app-loading-log-popover',
  templateUrl: './loading-log-popover.component.html',
  styleUrls: ['./loading-log-popover.component.scss'],
})
export class LoadingLogPopoverComponent implements OnInit, AfterViewInit {
  //popover params
  public modalNavParams;
  //progressValue
  public progressValue: number = 0;
  //progress value int
  public progressValueInt: number = 0;
  //offset
  public offset: number;
  //logs
  public logs = [];
  //stopProcess
  public stopProcess = false;
  //errors count
  public errorsCount = 0;

  constructor(
    public translate: TranslateService,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public alertSrv: AlertService,
    public loadingLogSrv: LoadingLogService) {
    //setup nav params
    this.modalNavParams =
    {
      type: this.navParams.get("type") ? this.navParams.get("type") : null,
      list: this.navParams.get("list") ? this.navParams.get("list") : null
    }
    this.offset = 1 / this.modalNavParams.list.length;
  }


  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.modalNavParams.type == "USER_MASSIVE_CREATE" && this.modalNavParams.list)
      this.inserisciUtente(this.modalNavParams.list, 0, this.offset);
  }


  //abortProcess
  public abortProcess() {
    this.alertSrv.warningConfirmAlert({message:this.translate.instant('messages.stop_process_message')}, () => {
      this.stopProcess = true;
    })
  }


  //inserisci utente 
  private inserisciUtente(list, index, value) {
    this.loadingLogSrv.inserisciUtente(
      list[index].username,
      list[index].email,
      list[index].name,
      list[index].surname,
      list[index].roles,
      list[index].language,
      list[index].phone,
      list[index].idProprietario,
      list[index].enabled,
      list[index].userCredentials
    ).then((res: any) => {
      if (res.code == 200) {
        this.logs.push({ username: list[index].username, message: this.translate.instant('errors.' + res.code), icon: "checkmark", color: "success" });
        this.handleApiResult(this.modalNavParams.list, index, value);
      } else {
        this.logs.push({ username: list[index].username, message: this.translate.instant('errors.' + res.code), icon: "warning", color: "danger" })
        this.errorsCount++;
        this.handleApiResult(this.modalNavParams.list, index, value);
      }
    }).catch((err) => {
      this.logs.push({ username: list[index].username, message: this.translate.instant(err), icon: "warning", color: "danger" })
      this.errorsCount++;
      this.handleApiResult(this.modalNavParams.list, index, value);
    })
  }

  //handle api result
  private handleApiResult(list, index, value) {
    this.progressValue = value;
    this.progressValueInt++;
    if (this.progressValueInt < this.modalNavParams.list.length && !this.stopProcess) {
      if (this.modalNavParams.type == "USER_MASSIVE_CREATE")
        this.inserisciUtente(list, index + 1, value + this.offset);
    }
    else {
      if (this.stopProcess)
        this.logs.push({ username: this.translate.instant('messages.process_stopped'), message: null, icon: "warning", color: "danger" })
      else if (this.errorsCount > 0)
        this.logs.push({ username: this.translate.instant('messages.process_ended_with_errors'), message: null, icon: "warning", color: "warning" })
      else
        this.logs.push({ username: this.translate.instant('messages.process_ended_without_errors'), message: null, icon: "checkmark-done", color: "success" })
    }
  }

}
