import { NgModule } from '@angular/core';
import { HelpComponent } from './help.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        HelpComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule
    ],
    exports: [
        HelpComponent
    ]
})
export class HelpComponentModule {}