<div class="chat-bot" [class.chat-bot-opened]="chatBot.show === true" [class.chat-bot-closed]="chatBot.show === false"
  [hidden]="chatBot.show === null">
  <div class="chat-bot-inner">
    <div class="chat-bot-title">
      <div class="cbt-data">
        <div class="cbt-data-top">
          <div class="cbtd-title">{{'labels.chatbot' | translate}}
            <span
              [matTooltip]="('labels.token' | translate)+ ': ' +chatBot.currentQuota.current + ' / ' + chatBot.currentQuota.max"
              matTooltipClass="table-tooltip">
              <ion-icon
                [color]="chatBot.currentQuota.percentage >= 90 ? 'danger': chatBot.currentQuota.percentage < 90  && chatBot.currentQuota.percentage >= 20 ? 'warning' : 'success'"
                [name]="chatBot.currentQuota.percentage >= 90 ? 'battery-dead': chatBot.currentQuota.percentage < 90  && chatBot.currentQuota.percentage >= 20 ? 'battery-half' : 'battery-full'"></ion-icon>
            </span>
          </div>
          <div class="cbtd-service" *ngIf="chatBot.currentConfig">{{chatBot.currentConfig.FEOptionSelected.nome}}</div>
        </div>
        <div class="cbtd-providers" *ngIf="chatBot.currentConfig?.FEOptionSelected?.providers?.length">
          <div class="cbtdp-title" (click)="chatBot.showChatData = !chatBot.showChatData">
            <span>{{'labels.chatPath' | translate}}</span><ion-icon name="chevron-forward"
              [class.rotate90]="chatBot.showChatData"></ion-icon>
          </div>
          <div class="cbtdp-data" [class.cbtdp-data-opened]="chatBot.showChatData === true"
            [class.cbtdp-data-closed]="chatBot.showChatData === false" [hidden]="chatBot.showChatData === null">
            <div *ngFor="let p of chatBot.currentConfig.FEOptionSelected.providers"><span>{{p.descrizione + ':
                '}}</span>{{p.FEValue.text+ ' '}}</div>
            <div class="extract" (click)="openTablesPrompt()" [hidden]="!chatBot.datasetJson"><ion-icon
              src="assets/icon/table.svg" [matTooltip]="'buttons.extractExcel' | translate"
                matTooltipClass="table-tooltip"></ion-icon>{{('labels.referenceData' | translate)}}
            </div>
          </div>
        </div>
      </div>
      <div class="title-buttons">
        <!--<ion-icon name="browsers-outline" (click)="openTablesPrompt()" [hidden]="!chatBot.datasetJson"
          title="{{'buttons.extractExcel' | translate }}"></ion-icon>-->
        <ion-icon name="refresh" (click)="refreshChatBot()"
          [hidden]="!chatBot.currentConfig?.FEOptionSelected && chatBot.chat.length == 0"></ion-icon>
        <ion-icon name="remove" (click)="chatBot.show = false"></ion-icon>
      </div>
    </div>
    <div class="chat-bot-chat" id="chatDom">
      <div class="chat-bot-message" *ngFor="let message of chatBot.chat;let i = index;"
        [ngClass]="{'message-left':['ai', 'error'].includes(message.type),'message-right':message.type == 'human'}"
        [class.message-error]="message.type == 'error'" id="message-{{i}}">
        <div class="chat-bot-message-inner">
          <div class="cbmi-text">{{message.testo}}</div>
          <div class="cbmi-options" *ngIf="message.opzioni">
            <div class="cmbio-option" *ngFor="let option of message.opzioni" [matTooltip]="option.descrizione"
              matTooltipClass="table-tooltip" (click)="handleOption(option, message, i)"
              [class.cmbio-option-disabled]="i != (chatBot.chat.length - 1)"
              [class.cmbio-option-back]="option.codice == 'BACK'">
              {{option.nome}}
            </div>
          </div>
          <div class="cmbi-buttons" *ngIf="message.type == 'ai'">
            <span *ngIf="message.token"
              [matTooltip]="('labels.utilized' | translate) +': '+message.token + ' ' + ('labels.token' | translate)"
              matTooltipClass="table-tooltip">
              <ion-icon name="battery-half"></ion-icon>
            </span>
            <ion-icon src="assets/icon/table.svg" (click)="ask(message, true)"
              [hidden]="message.datasetJson != null || (message.opzioni != null && message.opzioni.length)  || i != (chatBot.chat.length - 1)"
              [matTooltip]="'buttons.extractExcel' | translate" matTooltipClass="table-tooltip" class="cp"></ion-icon>
            <ion-icon name="eye" (click)="openTablesPrompt(message.datasetJson)" [hidden]="!message.datasetJson"
              [matTooltip]="'buttons.extractExcel' | translate" matTooltipClass="table-tooltip" class="cp"></ion-icon>
          </div>
        </div>
        <div class="chat-bot-message-inner providers" *ngIf="message.FEOptionSelected?.providers?.length">
          <div class="cbmi-text">{{'labels.providersMessage' | translate}}</div>
          <div class="cbmi-form">
            <ion-row>
              <ion-col size-md="12" size-sm="12" size-xs="12"
                *ngFor="let provider of message.FEOptionSelected.providers; let pIndex = index"
                [hidden]="pIndex > 0 && message.FEOptionSelected.providers[pIndex-1].FEValue == null">
                <ion-item lines="none" [class.selectable-disabled]="i != (this.chatBot.chat.length - 1)">
                  <ion-label position="stacked">
                    {{(provider.descrizione) | translate}}</ion-label>
                  <ionic-selectable [(ngModel)]="provider.FEValue"
                    [itemValueField]="provider.campoEndPointDaPrendere ? provider.campoEndPointDaPrendere : 'value'"
                    [items]="null" itemTextField="text" [canSearch]="true" [isMultiple]="false"
                    closeButtonText="{{'buttons.close' | translate}}" placeholder="{{'labels.select' | translate}}"
                    searchPlaceholder="{{'labels.search' | translate}} ({{'labels.atLeast3Characters' | translate}})"
                    closeButtonSlot="end" (ngModelChange)="providerChanged(message, pIndex)"
                    (onSearch)="getListByEndpoint($event, message, provider, 3)" [searchDebounce]="500"
                    [shouldFocusSearchbar]="true" [shouldFocusSearchbar]="true" id="selectable" #selectable>
                  </ionic-selectable>
                </ion-item>
              </ion-col>
              <ion-col size-md="12" size-sm="12" size-xs="12">
                <ion-button expand="block" (click)="ask(message)"
                  [disabled]="confirmProvidersDisabled(message, i)"><ion-spinner *ngIf="chatBot.isLoading"
                    name="lines-small"></ion-spinner><ion-label>{{'labels.confirm'|
                    translate}}</ion-label></ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
      <div class="chat-bot-thinking" *ngIf="chatBot.isLoading" id="thinkingDom">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="chat-bot-input">
      <input [disabled]="chatBot.isLoading || textDisabled" placeholder="{{'labels.ask' | translate}}"
        [(ngModel)]="text" (keyup)="searchKeyUp($event)" id="textDom">
      <ion-button size="small" (click)="ask(null)" [disabled]="!text || text == '' || chatBot.isLoading"><ion-spinner
          [hidden]="!chatBot.isLoading" name="lines-small"></ion-spinner> <ion-icon name="send"
          [hidden]="chatBot.isLoading"></ion-icon></ion-button>
    </div>
  </div>
</div>
<div class="chat-bot-button" (click)="handleFButton()" [hidden]="chatBot.show"
  [class.chat-bot-button-pinned]="chatBot.isButtonPinned" (mouseover)="chatBot.isButtonPinned = false">
  <ion-icon name="chatbox-ellipses-outline"></ion-icon>
</div>