import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonicSlides, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageListService } from '../../services/message-list.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-messages-box',
  templateUrl: './messages-box.component.html',
  styleUrls: ['./messages-box.component.scss'],
})
export class MessagesBoxComponent implements OnInit {
  //message loading
  public messageLoading = false;
  //attachment loading
  public attachmentLoading = false;
  //current attachment
  public currentAttachment = null;
  //slider index
  public sliderIndex = 1;
  //show messages
  public showMessages = false;
  //parent count message
  @Input() countMessage = 0;
  //ionic slider
  swiperModules = [IonicSlides];
  @ViewChild('slides') slides: ElementRef | undefined;
  //message list
  public messageList = null;
  //current view (messages, list)
  public currentView = 'messages'
  //loadMoreLoading
  public loadMoreLoading = false;
  //listPosition
  public listPosition = 0;
  //list total result
  public listTotalResult;
  //onlyNotRead
  public onlyNotRead = true;
  //maxResult
  public maxResult: number = 20;
  //showNewMessagesToast
  public showNewMessagesToast = false;
  //newMessagesCount
  public newMessagesCount = 0;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public popoverCtrl: PopoverController,
    public loadingSrv: LoaderService,
    private messageListSrv: MessageListService,
    public domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countMessage && !changes.countMessage.firstChange) {
      this.newMessagesCount = changes.countMessage.currentValue - changes.countMessage.previousValue;
    }
  }

  //get messagesList
  public messagesList(loadMore, nextslide?) {
    if (loadMore) {
      this.listPosition++;
    } else {
      this.sliderIndex = 1;
      this.listPosition = 0;
    }
    this.messageLoading = true;
    this.loadMoreLoading = true;
    this.messageListSrv.getReceivedMessages(this.onlyNotRead, this.listPosition ? this.listPosition : 0, this.maxResult).then((res: any) => {
      if (res.code == 200) {
        //check ###REFRESHVERSION### for app refresh
        if (res.payload && Array.isArray(res.payload) && res.payload.filter((message) => { return message.body.indexOf("###RV###") != -1 && message.read == false })[0]) {
          let refreshMessage = res.payload.filter((message) => { return message.body.indexOf("###RV###") != -1 && message.read == false })[0];
          this.messageListSrv.presaVisioneMessaggio(refreshMessage.id).then((res: any) => {
            if (res && res.code == 200 && res.status == 'OK')
              window.location.reload();
          })
        } else {
          //common
          this.listTotalResult = res.totalResults;
          this.messageLoading = false;
          this.messageList = this.messageList ? this.messageList : [];
          this.messageList = loadMore ? this.messageList.concat(res.payload) : res.payload;
          this.showNewMessagesToast = false;
          //list view
          this.loadMoreLoading = false;
          if (this.currentView == 'list') {
            setTimeout(() => {
              var element = document.getElementById('item-' + (this.messageList.length - res.payload.length));
              element.scrollIntoView({ behavior: 'smooth' });
            }, 100)
          }
          //slide view
          if (!nextslide) {
            if (this.slides) {
              this.sliderIndex = 1;
              this.slides?.nativeElement.swiper.update();
              this.slides?.nativeElement.swiper.slideTo(0);
            }
            if (this.messageList[0].hasAttachment && this.messageList[0].status && this.messageList[0].status != 3)
              this.getAttachmentMessage(this.messageList[0].id);
            else
              this.currentAttachment = null;
          } else {
            setTimeout(() => {
              this.slides?.nativeElement.swiper.slideNext();
            }, 500)
          }
        }
      } else if (res.code == 6) {
        this.loadMoreLoading = false;
        this.messageLoading = false;
        this.messageList = [];
      } else {
        this.loadMoreLoading = false;
        this.messageLoading = false;
        this.messageList = [];
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      this.loadMoreLoading = false;
    })
  }

  //get image body
  public getAttachmentMessage(messageId) {
    this.attachmentLoading = true;
    this.messageListSrv.getAttachmentMessage(messageId).then((res: any) => {
      this.attachmentLoading = false;
      if (res.code == 200) {
        this.currentAttachment = res.payload;
        this.currentAttachment.attachmentBody = this.domSanitizer.bypassSecurityTrustResourceUrl(this.currentAttachment.attachmentBody);
      }
    }).catch((err) => {
      this.attachmentLoading = false;
    })
  }

  //presa visione messaggio
  public presaVisioneMessaggio(messageId) {
    this.messageLoading = true;
    this.messageListSrv.presaVisioneMessaggio(messageId).then((res: any) => {
      if (res.code == 200) {
        this.messageLoading = false;
        if (this.onlyNotRead) {
          this.messageList = this.messageList.filter((x) => { return x.id != messageId });
          this.listTotalResult--;
        } else {
          for (let item of this.messageList)
            if (item.id == messageId) {
              item.read = true;
              break;
            }
        }
        this.messageListSrv.removeMessage();
        if (this.sliderIndex > this.messageList.length)
          this.sliderIndex = this.messageList.length
        if (this.messageList.length == 0)
          this.closeMessages();
      } else {
        this.messageLoading = false;
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      this.messageLoading = false;

    })
  }

  //readAll
  public readAll() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.read_all_message') }, () => {
      this.presaVisioneMessaggioTutti(this.messageList[0].id);
    });
  }

  //presaVisioneMessaggioTutti
  public presaVisioneMessaggioTutti(messageId) {
    this.messageListSrv.presaVisioneMessaggioTutti(messageId).then((res: any) => {
      if (res.code == 200) {
        this.messageLoading = false;
        this.messageListSrv.getCountMessages();
        this.closeMessages();
      } else {
        this.messageLoading = false;
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      this.messageLoading = false;
    })
  }
  /*
  public readLater(messageId) {
    this.messageList = this.messageList.filter((x) => { return x.id != messageId })
    if (this.sliderIndex > this.messageList.length)
      this.sliderIndex = this.messageList.length
    if (this.messageList.length == 0)
      this.closeMessages();
  }*/

  //slider controller
  public next() {
    if (this.sliderIndex < this.listTotalResult) {
      if (this.sliderIndex == this.messageList.length) {
        this.messagesList(true, true);
      } else {
        this.slides?.nativeElement.swiper.slideNext();
      }
    }
  }
  public prev() {
    this.slides?.nativeElement.swiper.slidePrev();
  }


  //slides changed
  public slideChanged() {
    this.slides?.nativeElement.swiper.getActiveIndex().then(index => {
      this.sliderIndex = index + 1;
      if (this.messageList[this.sliderIndex - 1].hasAttachment == true && this.messageList[this.sliderIndex - 1].status && this.messageList[this.sliderIndex - 1].status != 3)
        this.getAttachmentMessage(this.messageList[this.sliderIndex - 1].id);
      else
        this.currentAttachment = {
          attachmentBody: null
        };
    });
  }

  //open messages
  public openMessages() {
    let prevValue = this.showMessages;
    this.showMessages = true;
    if (prevValue) {
      this.showNewMessagesToast = true;
    } else {
      this.messagesList(false);
      this.currentView = "messages";
    }
  }

  //close messages
  public closeMessages() {
    this.showMessages = false;
  }

  //open message list
  public handleMessages() {
    this.showMessages = !this.showMessages;
    if (this.showMessages) {
      this.onlyNotRead = true;
      this.messagesList(false);
      this.currentView = 'messages';
    }
  }

  //switch view
  public switchView(view, index?) {
    this.currentView = view;
    if (this.currentView == 'list') {
      this.listPosition = 0;
      if (this.messageList && this.messageList.length > 0) {
        var element = document.getElementById('item-' + index);
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 500)
      }
    } else if (this.currentView == 'messages') {
      if (this.slides) {
        this.sliderIndex = index + 1;
        this.slides?.nativeElement.swiper.update();
        this.slides?.nativeElement.swiper.slideTo(index);
      }
      if (this.messageList && this.messageList.length > 0) {
        if (!index && this.messageList[0].hasAttachment && this.messageList[0].status && this.messageList[0].status != 3)
          this.getAttachmentMessage(this.messageList[0].id);
        else if (index && this.messageList[index].hasAttachment && this.messageList[index].status && this.messageList[index].status != 3)
          this.getAttachmentMessage(this.messageList[index].id);
        else
          this.currentAttachment = null;
      }
    }
  }

  //load more
  public loadMore() {
    this.messagesList(true);
  }

}
