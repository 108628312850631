<div class="table-td">
    <!-- SELECT -->
    <input type="checkbox" *ngIf="column.columnType == tableColumnTypes.SELECT" [(ngModel)]="element['selected']"
        (ngModelChange)="emitGetAllSelected()">
    <!-- TEXT -->
    <span *ngIf="column.columnType == tableColumnTypes.TEXT" [ngClass]="{'text-link': column.columnEndpoint}"
        (click)="emitOpenLink(column, element)">{{element[column.columnDef] ?
        element[column.columnDef] : '-'}}</span>
    <!-- DATE -->
    <span *ngIf="column.columnType == tableColumnTypes.DATE">{{element[column.columnDef] ?
        (element[column.columnDef] | dateFormat:'DATE') : '-'}}</span>
    <!-- DATE_TIME -->
    <span *ngIf="column.columnType == tableColumnTypes.DATE_TIME">{{element[column.columnDef] ?
        (element[column.columnDef] | dateFormat:'DATETIME') : '-'}}</span>
    <!-- BOOLEAN -->
    <span *ngIf="column.columnType == tableColumnTypes.BOOLEAN">{{element[column.columnDef] ?
        ('labels.yes' | translate) : ('labels.no' | translate)}}</span>
    <!-- BOOLEAN_CHECKBOX -->
    <input type="checkbox" *ngIf="column.columnType == tableColumnTypes.BOOLEAN_CHECKBOX"
        [(ngModel)]="element[column.columnDef]" class="boolean-checkbox">
    <!-- TOOLTIP -->
    <span *ngIf="column.columnType == tableColumnTypes.TOOLTIP" matTooltip="{{element[column.columnDef]}}"
        matTooltipClass="table-tooltip">
        <ion-icon name="information" class="cursor-pointer"></ion-icon>
    </span>
    <!-- TRANSLATE -->
    <span *ngIf="column.columnType == tableColumnTypes.TRANSLATE">
        {{element[column.columnDef] && column.columnParam ? ((column.columnParam + '.' +
        element[column.columnDef]) | translate) : '-' }}
    </span>
    <!-- NUMERO -->
    <span *ngIf="column.columnType == tableColumnTypes.NUMERO" class="numero">{{element[column.columnDef] ?
        (element[column.columnDef]) : '-'}}</span>
    <!-- NUMERO_SEPARATORI -->
    <span *ngIf="column.columnType == tableColumnTypes.NUMERO_SEPARATORI" class="numero">{{element[column.columnDef] ?
        (element[column.columnDef] | NumberSeparator ) : '-'}}</span>
    <!-- DECIMALE -->
    <span *ngIf="column.columnType == tableColumnTypes.DECIMALE" class="decimale">{{element[column.columnDef] ?
        (element[column.columnDef] | tableDecimale) : '-'}}</span>
    <!-- DETAIL -->
    <span *ngIf="column.columnType == tableColumnTypes.DETAIL" class="detail-column">
        <img src="{{column.columnParam ? column.columnParam : 'assets/icon/view-details.png'}}"
            (click)="emitGoDetail(column, element)" *ngIf="!column.columnDynamicDetailList"
            title="{{column.columnHeader | translate}}">
        <div class="detail-icon" *ngIf="column.columnDynamicDetailList && column.columnDynamicDetailList.length > 0">
            <span *ngFor="let item of column.columnDynamicDetailList">
                <img src="{{item.iconPath ? item.iconPath : 'assets/icon/view-details.png'}}"
                    (click)="emitGoDynamicDetail(item, element)"
                    *ngIf="element[item.conditionColumnDef] == item.conditionValue"
                    title="{{item.titleTranslatePath | translate}}">
            </span>
        </div>
    </span>
    <!-- INPUT_STRINGA INPUT_POPUP_STRINGA-->
    <div class="input-column"
        *ngIf="column.columnType == tableColumnTypes.INPUT_STRINGA || column.columnType == tableColumnTypes.INPUT_POPUP_STRINGA">
        <input type="text" [(ngModel)]="element[column.columnDef]" placeholder="{{'labels.insert' | translate}}"
            *ngIf="column.columnType == tableColumnTypes.INPUT_STRINGA ">
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_STRINGA ">{{element[column.columnDef]
            ?
            element[column.columnDef] : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_STRINGA"
            (click)="emitEditCell(column, element)"></ion-icon>
    </div>
    <!-- INPUT_NUMERO INPUT_POPUP_NUMERO-->
    <div class="input-column"
        *ngIf="column.columnType == tableColumnTypes.INPUT_NUMERO || column.columnType == tableColumnTypes.INPUT_POPUP_NUMERO">
        <input type="number" [(ngModel)]="element[column.columnDef]" placeholder="{{'labels.insert' | translate}}"
            *ngIf="column.columnType == tableColumnTypes.INPUT_NUMERO ">
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_NUMERO ">{{element[column.columnDef]
            ?
            element[column.columnDef] : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_NUMERO"
            (click)="emitEditCell(column, element)"></ion-icon>
    </div>
    <!-- INPUT_DATA INPUT_POPUP_DATA-->
    <div class="input-column"
        *ngIf="column.columnType == tableColumnTypes.INPUT_DATA || column.columnType == tableColumnTypes.INPUT_POPUP_DATA">
        <div class="date-time-input" *ngIf="column.columnType == tableColumnTypes.INPUT_DATA ">
            <input matInput [matDatepicker]="datePicker_tdIndex" [(ngModel)]="element[column.columnDef]"
                [ngClass]="{'empty-date-input': !element[column.columnDef]}"
                placeholder="{{'DATE' | dateFormatPlaceholder}}">
            <mat-datepicker-toggle matSuffix [for]="$any(datePicker_tdIndex)"
                [hidden]="column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA"
                [ngClass]="{'empty-date-input': !element[column.columnDef]}">
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker_tdIndex>
            </mat-datepicker>
        </div>
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_DATA ">{{element[column.columnDef]
            ?
            (element[column.columnDef] | dateFormat:'DATE') : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_DATA"
            (click)="emitEditCell(column, element)"></ion-icon>
    </div>
    <!-- INPUT_DATAORA INPUT_POPUP_DATAORA-->
    <div class="input-column"
        *ngIf="column.columnType == tableColumnTypes.INPUT_DATAORA || column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA">
        <div class="date-time-input" *ngIf="column.columnType == tableColumnTypes.INPUT_DATAORA ">
            <input matInput [ngxMatDatetimePicker]="$any(datePicker_tdIndex)" [(ngModel)]="element[column.columnDef]"
                [ngClass]="{'empty-date-input': !element[column.columnDef]}"
                placeholder="{{'DATETIME' | dateFormatPlaceholder}}">
            <mat-datepicker-toggle matSuffix [for]="$any(datePicker_tdIndex)"
                [hidden]="column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA"
                [ngClass]="{'empty-date-input': !element[column.columnDef]}">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker #datePicker_tdIndex [showSeconds]="false">
            </ngx-mat-datetime-picker>
        </div>
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA ">{{element[column.columnDef]
            ?
            (element[column.columnDef] | dateFormat:'DATETIME') : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA"
            (click)="emitEditCell(column, element)"></ion-icon>

    </div>
    <!-- INPUT_COMBO INPUT_POPUP_COMBO-->
    <div class="input-column"
        *ngIf="(column.columnType == tableColumnTypes.INPUT_COMBO || column.columnType == tableColumnTypes.INPUT_POPUP_COMBO) && column.columnParam">
        <ion-select [(ngModel)]="element[column.columnDef]" [cancelText]="'buttons.cancel' | translate"
            interface="popover" [okText]="'buttons.ok' | translate" placeholder="{{'labels.select' | translate}}"
            multiple="false" [compareWith]="compareWith" *ngIf="column.columnType == tableColumnTypes.INPUT_COMBO ">
            <ion-select-option [value]="option" *ngFor="let option of column.columnParam">
                {{option.desc}}</ion-select-option>
        </ion-select>
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_COMBO ">{{element[column.columnDef]
            ?
            element[column.columnDef].desc : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_COMBO"
            (click)="emitEditCell(column, element)"></ion-icon>

    </div>
    <!-- INPUT_MULTICOMBO -->
    <div class="input-column"
        *ngIf="(column.columnType == tableColumnTypes.INPUT_MULTICOMBO || column.columnType == tableColumnTypes.INPUT_POPUP_MULTICOMBO)  && column.columnParam">
        <ion-select [(ngModel)]="element[column.columnDef]" [cancelText]="'buttons.cancel' | translate"
            [okText]="'buttons.ok' | translate" placeholder="{{'labels.select' | translate}}" multiple="true"
            [compareWith]="compareWith" *ngIf="column.columnType == tableColumnTypes.INPUT_MULTICOMBO ">
            <ion-select-option [value]="option" *ngFor="let option of column.columnParam">
                {{option.desc}}</ion-select-option>
        </ion-select>
        <span class="input-span" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_COMBO ">
            <span *ngIf="element[column.columnDef]">
                <span *ngFor="let option of element[column.columnDef];let i = index">
                    <span *ngIf="i != 0">,&nbsp;</span>{{option.desc}}
                </span>
            </span>
            <span *ngIf="!element[column.columnDef]">-</span>
        </span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_MULTICOMBO"
            (click)="emitEditCell(column, element)"></ion-icon>

    </div>
    <!-- INPUT_RADIO -->
    <div class="input-column"
        *ngIf="(column.columnType == tableColumnTypes.INPUT_RADIO || column.columnType == tableColumnTypes.INPUT_POPUP_RADIO) && column.columnParam">
        <div class="radio-container" *ngIf="column.columnType == tableColumnTypes.INPUT_RADIO ">
            <div *ngFor="let radio of column.columnParam" class="input-radio"
                [ngClass]="{'radio-selected': element[column.columnDef] && element[column.columnDef].id  == radio.id}">
                <input id="{{tdIndex}}-{{radio.id}}-{{radio.desc}}" type="radio" name="{{tdIndex}}-{{column.columnDef}}"
                    [value]="radio" [(ngModel)]="element[column.columnDef]">
                <label for="{{tdIndex}}-{{radio.id}}-{{radio.desc}}">{{radio.desc}}</label>
            </div>
        </div>
        <span class="input-span"
            *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_RADIO ">{{element[column.columnDef]
            ?
            element[column.columnDef].desc : '-'}}</span>
        <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_RADIO"
            (click)="emitEditCell(column, element)"></ion-icon>

    </div>
    <!-- INPUT_BOOLEAN INPUT_POPUP_BOOLEAN-->
    <div class="input-column"
        *ngIf="column.columnType == tableColumnTypes.INPUT_BOOLEAN || column.columnType == tableColumnTypes.INPUT_POPUP_BOOLEAN">
        <div class="input-boolean" [ngClass]="{'input-boolean-checkbox-edit': !element[column.columnDef+'EditMode']}">
            <input type="checkbox" [(ngModel)]="element[column.columnDef]"
                [ngClass]="{'input-popup': !element[column.columnDef+'EditMode']}">
            <ion-icon name="create-outline" *ngIf="column.columnType == tableColumnTypes.INPUT_POPUP_BOOLEAN"
                (click)="emitEditCell(column, element)"></ion-icon>
        </div>
    </div>
    <!-- ICONA -->
    <div class="input-icona-wrapper"
        *ngIf="column.columnType == tableColumnTypes.ICONA && column.columnParam && column.columnParam.length > 0">
        <div class="input-icona" *ngFor="let condition of column.columnParam"
            [ngClass]="{'input-icona-full': condition.label}"
            [hidden]="condition.conditionValue && isString(condition.conditionValue) && condition.conditionValue.indexOf('#EVAL#') != -1 ? !evalIconaCondition(condition.conditionValue) : !condition.conditionColumnDef ? false  :  [condition.conditionColumnDef] != condition.conditionValue">
            <div class="input-icona-inner"
                [ngClass]="{'text-link': (column.columnEndpoint || column.columnCallback) && !condition.disabled}"
                (click)="emitOpenLink(column, element, condition)">
                <ion-icon [name]="condition.icon"
                    [matTooltip]="condition.tooltip ? (condition.tooltip | translate) : null"
                    matTooltipClass="table-tooltip" [color]="condition.color ? condition.color : 'primary'"></ion-icon>
                <span *ngIf="condition.label">{{condition.label | translate}}</span>
            </div>
        </div>
    </div>
    <!-- HTML -->
    <div *ngIf="column.columnType == tableColumnTypes.HTML" class="input-html-wrapper">
        <div class="innner-html" [innerHtml]="element[column.columnDef]">

        </div>
    </div>
</div>