<ion-content class="recipients-list-popover" >
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.user_massive_reate_loading' | translate}}
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <div class="progress-bar">
        <ion-progress-bar [color]="stopProcess ? 'danger' : 'success'" value="{{progressValue}}" mode="md">
        </ion-progress-bar>
      </div>
      <div class="count">
        <span><b>{{'labels.processed' | translate}}:</b>&nbsp;{{progressValueInt +"/"+
          modalNavParams.list.length}}</span>&nbsp;
        <span *ngIf="logs.length > 0"><b>{{'labels.errors' |
            translate}}:</b>&nbsp;{{errorsCount}}</span>
      </div>
      <div class="log">
        <b>{{'labels.logs' | translate}}</b>
        <div class="log-list">
          <div class="log-item" *ngFor="let log of logs">
            <ion-icon name="{{log.icon}}" color="{{log.color}}"></ion-icon>&nbsp;{{log.username}}
            &nbsp; {{log.message}}
          </div>
        </div>
      </div>
      <div class="button-box">
        <ion-button color="danger" size="default" (click)="abortProcess()"
          [disabled]="stopProcess || progressValueInt == modalNavParams.list.length">
          <ion-label>{{'buttons.stop_process' | translate}}</ion-label>
        </ion-button>
        <ion-button color="primary" size="default" (click)="popoverCtrl.dismiss()"
          [disabled]="progressValueInt != modalNavParams.list.length && !stopProcess">
          <ion-label>{{'buttons.close' | translate}}</ion-label>
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>