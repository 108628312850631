import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class PostitBoardUtilityService {


    constructor(private translate: TranslateService) {

    }

    //getRequestError
    public getRequestError(requestResponse) {
        return new Promise((resolve) => {
            this.translate.get('odeva-trans.errors').subscribe((errors: any) => {
                //errore tradotto da codice errore
                if (requestResponse && requestResponse.error && requestResponse.error.value && requestResponse.error.value.code)
                    resolve(errors[requestResponse.error.value.code] ? errors[requestResponse.error.value.code] : errors["com-error"]);
                //errore da 400 lista
                else if (requestResponse && requestResponse.error && requestResponse.error.errors) {
                    resolve(requestResponse.error.errors)
                }
                //tutto il resto
                else if (requestResponse && requestResponse.error)
                    resolve(requestResponse.error)
                //stringa
                else if (typeof (requestResponse) == 'string')
                    resolve(requestResponse)
                //nesun codice
                else
                    resolve(errors["com-error"])
            });
        })
    };

    //is touchscreen device
    public isTouchScreenDevice() {
        return ('ontouchstart' in window || navigator.maxTouchPoints) && screen.width < 700;
    };

    //getDate
    public getDate(date: Date) {
        return date ? date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) : null;
    }
}
