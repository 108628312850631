import { Component, OnInit} from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { ServiceAccessConfigurationTemplateService } from '../../services/service-sccess-configuration-template.service'
import { SectionService } from 'src/app/services/section.service';

import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-service-access-configuration-template',
  templateUrl: './service-access-configuration-template.component.html',
  styleUrls: ['./service-access-configuration-template.component.scss'],
})
export class ServiceAccessConfigurationTemplateComponent implements OnInit {
  //nav params
  public modalNavParams;
  //new template
  public currentTemplate;
  //template option
  public templateOption = [];
  //current template option
  public currentTemplateOption = "null";

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public loadingSrv: LoaderService,
    public navParams: NavParams,
    public ServiceAccessConfigurationTemplateSrv: ServiceAccessConfigurationTemplateService,
    public sectionSrv: SectionService,
    public storaveVariableSrv: StorageVariablesService) {

    //setup nav params
    this.modalNavParams =
    {
      serviceId: this.navParams.get("serviceId") ? this.navParams.get("serviceId").toString() : null,
      servicesList: this.navParams.get("servicesList") ? this.navParams.get("servicesList") : [],
    }
  }

  ngOnInit() {
    this.getListaTemplateServiziSafo();
    this.modalNavParams = this.modalNavParams;
  }

  //add config
  public addConfig() {
    if (this.currentTemplate && this.currentTemplate.valori)
      this.currentTemplate.valori.push({ chiave: null, valore: null });
    else {
      this.currentTemplate.valori = [{ chiave: null, valore: null }];
    }
  }
  //remove config
  public removeConfig(index) {
    this.currentTemplate.valori.splice(index, 1);
  }
  //create new
  public createNew() {
    this.currentTemplate = {
      nomeTemplate: null,
      valori: null,
      idServizio: this.modalNavParams.serviceId
    };
  }

  //saveTemplateServiziSafo
  public saveTemplateServiziSafo() {
    this.loadingSrv.open();
    this.ServiceAccessConfigurationTemplateSrv.saveTemplateServiziSafo(
      this.currentTemplate
    ).then((res: any) => {
      if (res.code == 200) {
        this.loadingSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.popoverCtrl.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loadingSrv.dismiss();

    })
  }

  //getListaTemplateServiziSafo
  public getListaTemplateServiziSafo() {
    this.loadingSrv.open();
    this.ServiceAccessConfigurationTemplateSrv.getListaTemplateServiziSafo(
      this.modalNavParams.serviceId
    ).then((res: any) => {
      if (res.code == 200 || res.code == 6) {
        this.templateOption = res.payload;
        this.loadingSrv.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loadingSrv.dismiss();

    })
  }

  //dettaglioTemplateServiziSafo
  public dettaglioTemplateServiziSafo(templateId) {
    this.loadingSrv.open();
    this.ServiceAccessConfigurationTemplateSrv.dettaglioTemplateServiziSafo(
      templateId
    ).then((res: any) => {
      if (res.code == 200) {
        this.currentTemplate = res.payload;
        this.loadingSrv.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loadingSrv.dismiss();

    })
  }

  //delete template alert
  async deleteTemplateAlert() {
    let message = '<div class="alert-service-title title-warning">' +
      '<ion-icon name="warning-outline"></ion-icon>' +
      '&nbsp;' + '<span>' + this.translate.instant('labels.operation_warning') + '</span>' +
      '</div>' +
      '<div class="alert-service-message">' +
      '<span>' + this.translate.instant('messages.delete_template_message') + '</span>' +
      '</div>';
    const alert = await this.alertCtrl.create({
            cssClass: 'alert-service',
      message: message,
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.eliminaTemplateServiziSafo()
          }
        }
      ]
    });
    await alert.present()
  }

  //eliminaTemplateServiziSafo
  public eliminaTemplateServiziSafo() {
    this.loadingSrv.open();
    this.ServiceAccessConfigurationTemplateSrv.eliminaTemplateServiziSafo(
      this.currentTemplateOption
    ).then((res: any) => {
      if (res.code == 200) {
        this.loadingSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.popoverCtrl.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loadingSrv.dismiss();

    })
  }

  //change service
  public changeService(){
    this.getListaTemplateServiziSafo();
    this.currentTemplate = null;
    this.currentTemplateOption = null;
  }

}
