<div class="login-container">
  <!--<ng-particles [id]="'login-particles'" [options]="particlesOptions"></ng-particles>-->
  <div class="language">
    <div class="language">
      <div class="language-select">
        <ion-select [(ngModel)]="language" (ngModelChange)="selectLanguage($event)" interface="popover"
          [style.background-image]="'url(./assets/icon/flags/' + language + '.png)'">
          <ion-select-option value="it">Italiano</ion-select-option>
          <ion-select-option value="en">English</ion-select-option>
          <ion-select-option value="en-US">American</ion-select-option>
          <ion-select-option value="fr">Français</ion-select-option>
          <ion-select-option value="de">Deutsche</ion-select-option>
          <ion-select-option value="at">Austrian</ion-select-option>
        </ion-select>
      </div>
    </div>
  </div>
  <div class="login-center">
    <div class="login-logo">
      <div class="logo"></div>
    </div>

    <!-- login before-->
    <div class="login-before" [hidden]="!customerListCollapsed">
      <!-- login left -->
      <div class="login-right">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a lectus a tortor vestibulum consequat
          et non leo.
        </span>
      </div>
      <!-- login right -->
      <div class="login-left">
        <mat-tab-group #matTabGroup>
          <mat-tab label="{{'viewAndFine.labels.login' | translate}}">
            <form (keyup)="loginWithCredentials(loginFormGroup.valid, $event)" [formGroup]="loginFormGroup"
              (ngSubmit)="loginWithCredentials(loginFormGroup.valid)">
              <ion-item class="input-field" lines="none">
                <ion-icon name="person-circle" slot="start"></ion-icon>
                <ion-input name="username" autocomplete="on" type="text" formControlName="email"
                  placeholder="{{'labels.username' | translate}}"></ion-input>
              </ion-item>
              <ion-item class="input-field no-p-right">
                <ion-icon name="lock-open" slot="start"></ion-icon>
                <ion-input [type]="showPassword ? 'text' : 'password'" formControlName="password" name="password"
                  placeholder="{{'labels.password' | translate}}" autocomplete="on">
                </ion-input>
                <ion-icon *ngIf="!showPassword" class="clickable" slot="end" name="eye"
                  (click)="showPassword = !showPassword">
                </ion-icon>
                <ion-icon *ngIf="showPassword" class="clickable" slot="end" name="eye-off"
                  (click)="showPassword = !showPassword"></ion-icon>
              </ion-item>
              <div class="flex-center-right mt-10px">
                <ion-button color="primary" type="submit" [disabled]="!loginFormGroup.valid">
                  {{'buttons.login' | translate}}
                </ion-button>
              </div>
              <div class="flex-center-right">
                <div class="forgot-password" (click)="forgotPassword()">
                  <span>
                    {{'labels.forgot_password' | translate}}
                  </span>
                </div>
              </div>
            </form>
          </mat-tab>
          <mat-tab label="{{'viewAndFine.labels.register' | translate}}">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a lectus a tortor vestibulum consequat
              et non leo.
            </span>
            <div class="flex-center-right">
              <div class="button-box">
                <ion-button color="primary" (click)="openRegistrationForm()">
                  {{'viewAndFine.labels.register' | translate}}
                </ion-button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!-- login after-->
    <div class="login-after" [hidden]="customerListCollapsed">
      <div class="customer-list">
        <div class="customer-list-message" id="customer-list">
          {{'messages.welcome_safoapp_message' | translate}}
        </div>
        <app-infinite-tree *ngFor="let item of searchList" [item]="item" [titleKey]="'denominazione'"
          [childrenKey]="'figli'" [hideCheckboxParent]="true" [hideCheckboxRole]="true" [allOpened]="true"
          (itemSelect)="selectRole($event)" [hideTerritoryCount]="true">
        </app-infinite-tree>
        <div class="flex-center-center">
          <div class="forgot-password" (click)="logout()">
            <span>
              {{'labels.logout' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>