<div class="dcrud-input" *ngIf="input && input.inputType != InputTypes.GRUPPO &&  input.inputType != InputTypes.UNKNOWN"
    id="{{input.id}}" (contextmenu)="openInputSettingsPromt($event)">
    <!-- INPUT -->
    <div class="dcrud-input-wrapper" [ngClass]="{'dcrud-wrapper-editor-mode': editorMode}">
        <div class="dcrud-input-top" *ngIf="input.label">
            <div class="dcrud-input-label" title="{{input.label | translate}}"><span class="dcrud-input-label-inner">{{input.label |
                    translate}}</span><span *ngIf="input.required">*</span></div>
            <div class="dcrud-input-buttons" *ngIf="!editorMode">
                <ion-icon *ngIf="input.tooltip" name="information-circle-outline" class="cursor-pointer"
                    [matTooltip]="input.tooltip" matTooltipClass="table-tooltip"></ion-icon>
                <ion-icon name="arrow-forward" *ngIf="input.routerUrl" (click)="goToPage()"
                    title="{{'labels.view_detail' | translate}}"></ion-icon>
                <ion-icon name="close"
                    [hidden]="input.readonly || input.disabled || input.inputType == InputTypes.STATO"
                    (click)="deleteValue()"></ion-icon>
                <ion-spinner name="crescent" *ngIf="input.loading"></ion-spinner>
            </div>
        </div>
        <div class="dcrud-input-bottom"
            [ngClass]="{'dcrud-input-disabled': (input.disabled && input.inputType != InputTypes.PULSANTE) || input.triggerDisabled, 'dcrud-input-readonly': input.readonly}">
            <!-- TESTO -->
            <input *ngIf="input.inputType == InputTypes.TESTO" type="text" placeholder="{{'labels.insert' | translate}}"
                (ngModelChange)="onModelChangeSubject.next($event)" [(ngModel)]="input.value"
                id="dcrud-input-{{inputId}}" [maxlength]="input.max" [minlength]="input.min"
                [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off"
                [ngClass]="{'input-with-suggestion': input.showSuggestion}">
            <!-- AREA_TESTO -->
            <div *ngIf="input.inputType == InputTypes.AREA_TESTO" class="dcrud-textarea-wrapper"
                [ngClass]="{'input-with-showSuggestion': input.showSuggestion}">
                <div class="dcrud-textarea" (keyup)="textAreaKeyUp($event)" (keydown)="textAreaKeyDown($event)"
                    [textContent]="input.renderValue" [attr.contenteditable]="!input.readonly && !input.disabled"
                    id="dcrud-input-{{inputId}}"
                    [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
                </div>
                <span class="dcrud-textarea-placeholder"
                    [hidden]="input.value != null && input.value != undefined && input.value != ''">
                    {{'labels.insert' | translate}}
                </span>
            </div>
            <!-- NUMERO -->
            <input *ngIf="input.inputType == InputTypes.NUMERO" type="NUMBER"
                [ngClass]="{'input-with-suggestion': input.showSuggestion}"
                placeholder="{{'labels.insert' | translate}}" (ngModelChange)="onModelChangeSubject.next($event)"
                [(ngModel)]="input.value" id="dcrud-input-{{inputId}}" [max]="input.max" [min]="input.min"
                [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
            <!-- VALUTA -->
            <input *ngIf="input.inputType == InputTypes.VALUTA" type="text"
                placeholder="{{'labels.insert' | translate}}" [ngModel]="input.value"
                (ngModelChange)="onModelChangeSubject.next($event)" (keypress)="valutaKeyDown($event)"
                (ngModelChange)="valutaTransform($event, input)" id="dcrud-input-{{inputId}}"
                [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
            <!-- DATA -->
            <div *ngIf="input.inputType == InputTypes.DATA" class="dcrud-input-mat-datetime-wrapper">
                <input matInput [matDatepicker]="inputId" placeholder="{{'DATE' | dateFormatPlaceholder}}"
                    [(ngModel)]="input.value" (dateChange)="inputChanged($event)"
                    (ngModelChange)="onModelChangeSubject.next($event)" class="dcrud-input-mat-datetime"
                    [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="$any(inputId)" [hidden]="input.readonly || input.disabled">
                </mat-datepicker-toggle>
                <mat-datepicker #inputId [ngClass]="{'dcrud-date-empty': !input.value}">
                </mat-datepicker>
            </div>
            <!-- DATA_ORA -->
            <div *ngIf="input.inputType == InputTypes.DATA_ORA" class="dcrud-input-mat-datetime-wrapper">
                <input matInput [ngxMatDatetimePicker]="inputId" placeholder="{{'DATETIME' | dateFormatPlaceholder}}"
                    [(ngModel)]="input.value" (dateChange)="inputChanged($event)"
                    (ngModelChange)="onModelChangeSubject.next($event)" class="dcrud-input-mat-datetime"
                    [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="$any(inputId)" [hidden]="input.readonly || input.disabled">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #inputId [showSeconds]="input.showSeconds"
                    [ngClass]="{'dcrud-date-empty': !input.value}">
                </ngx-mat-datetime-picker>
            </div>
            <!-- DATA_RANGE -->
            <div *ngIf="input.inputType == InputTypes.DATA_RANGE" class="dcrud-input-mat-datetime-wrapper">
                <mat-date-range-input [rangePicker]="inputId">
                    <input matStartDate [(ngModel)]="input.inputList[0].value"
                        placeholder="{{'DATE' | dateFormatPlaceholder}}"
                        [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off"
                        (ngModelChange)="onModelChangeSubject.next($event)">
                    <input matEndDate [(ngModel)]="input.inputList[1].value"
                        placeholder="{{'DATE' | dateFormatPlaceholder}}"
                        [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'"
                        (ngModelChange)="onModelChangeSubject.next($event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="$any(inputId)"></mat-datepicker-toggle>
                <mat-date-range-picker #inputId></mat-date-range-picker>
            </div>
            <!-- RADIO -->
            <div *ngIf="input.inputType == InputTypes.RADIO" class="dcrud-radio-wrapper">
                <div *ngFor="let radio of input.optionsList; let radioIndex = index;" class="dcrud-input-radio"
                    [ngClass]="{'dcrud-radio-selected': input.optionValue && input.value ? input.value[input.optionValue] == radio[input.optionValue] : input.value == radio, 'dcrud-input-disabled': input.disabled, 'dcrud-input-readonly': input.readonly}">
                    <input id="dcrud-input-{{inputId}}{{radioIndex}}" type="radio" name="dcrud-input-{{inputId}}"
                        [value]="radio" [(ngModel)]="input.value" (change)="inputChanged($event)" autocomplete="off">
                    <label for="dcrud-input-{{inputId}}{{radioIndex}}">{{input.optionText ? (radio[input.optionText] |
                        translate) :
                        (radio | translate)}}</label>
                </div>
            </div>
            <!-- CHECKBOX -->
            <div *ngIf="input.inputType == InputTypes.CHECKBOX" class="dcrud-checkbox-wrapper">
                <input type="checkbox" (ngModelChange)="onModelChangeSubject.next($event)" [(ngModel)]="input.value"
                    id="dcrud-input-{{inputId}}">
                <!--<div *ngFor="let radio of [false, true];let radioIndex = index;" class="dcrud-input-radio"
                    [ngClass]="{'dcrud-radio-selected': input.value == radio}" autocomplete="off">
                    <input id="{{inputId}}{{radio}}{{radioIndex}}" type="radio" name="{{inputId}}" [value]="radio"
                        [(ngModel)]="input.value" (change)="inputChanged($event)">
                    <label for="{{inputId}}{{radio}}{{radioIndex}}">{{('labels.check_'+radio) | translate}}</label>
                </div>-->
            </div>
            <!-- SELEZIONE -->
            <div *ngIf="input.inputType == InputTypes.SELEZIONE" class="dcrud-selezione-wrapper">
                <pssfe-dropdown [optionsList]="input.optionsList" [optionValue]="input.optionValue"
                    [optionText]="input.optionText" [multiple]="input.multiple" [searchEndpoint]="input.endpointTmp"
                    [searchPlaceholder]="'labels.search' | translate" [(value)]="input.value"
                    (valueChange)="inputChanged($event)" [placeholder]="'labels.select' | translate" [canClean]="true"
                    [searchValue]="null" [noResultText]="'labels.results_not_found' | translate"
                    [ngClass]="{'dcrud-input-empty': !input.value}" [inputId]="'dcrud-input-'+inputId">
                </pssfe-dropdown>
            </div>
            <!-- SELEZIONE_AVANZATA -->
            <div *ngIf="input.inputType == InputTypes.SELEZIONE_AVANZATA" class="dcrud-selezione-avanzata-wrapper">
                <pssfe-selectable [input]="input" [inputList]="inputList" [(value)]="input.value"
                    [ngClass]="{'dcrud-input-empty': !input.value}">
                </pssfe-selectable>
            </div>
            <!-- FILE -->
            <div *ngIf="input.inputType == InputTypes.FILE" class="dcrud-file-wrapper">
                <div class="dcrud-file" (click)="fileTrigger()" [ngClass]="{'dcrud-input-empty': !input.value}"
                    autocomplete="off">
                    {{input.fileName? input.fileName : ('labels.select' | translate)}}
                </div>
                <input type="file" placeholder="{{'labels.insert' | translate}}" (change)="inputChanged($event)"
                    id="dcrud-input-{{inputId}}">
            </div>
            <!-- MESSAGGIO -->
            <div *ngIf="input.inputType == InputTypes.MESSAGGIO" class="dcrud-messaggio-wrapper"
                [ngClass]="{'hide-shadow': input.hideShadow}">
                <div class="dcrud-messaggio">
                    <ion-icon name="{{!input.color || input.color == 'primary' || input.color == 'secondary' || input.color == 'tertiary' ? 'information-outline' : 
                    input.color  == 'success' ? 'checkmark-outline' : 'warning-outline'}}"
                        color="{{!input.color ? 'PRIMARY' : input.color}}"></ion-icon>
                    <span>{{input.value | translate}}</span>
                </div>
            </div>
            <!-- RANGE -->
            <div *ngIf="input.inputType == InputTypes.RANGE" class="dcrud-range-wrapper">
                <ion-range [pin]="true" [(ngModel)]="input.value" [step]="input.step ? input.step : 1"
                    [snaps]="input.step ? true : false" [min]="input.min" [max]="input.max"
                    (ngModelChange)="onModelChangeSubject.next($event)">
                    <div slot="start"> {{input.min}} </div>
                    <div slot="end"> {{input.max}} </div>
                </ion-range>
            </div>
            <!-- SEPARATORE -->
            <div *ngIf="input.inputType == InputTypes.SEPARATORE" class="dcrud-separatore-wrapper">
                <div class="dcrud-separatore">
                    <span>{{input.value | translate}}</span>
                </div>
            </div>
            <!-- STATO -->
            <div *ngIf="input.inputType == InputTypes.STATO" class="dcrud-stato-wrapper">
                <ion-icon name="ellipse" *ngIf="input.color" [color]="input.color"></ion-icon>
                <span *ngIf="input.value !=null && input.value !=undefined">{{input.value | translate}}</span>
            </div>
            <!-- PULSANTE -->
            <div *ngIf="input.inputType == InputTypes.PULSANTE" class="dcrud-pulsante-wrapper">
                <ion-button class="dcrud-search-history" (click)="openSearchStateHistory()"
                    *ngIf="input.showHistorySearch" title="{{'labels.searchStateHistory' | translate}}">
                    <ion-icon name="list"></ion-icon>
                </ion-button>
                <ion-button [color]="input.color" (click)="buttonClicked()"
                    [disabled]="input.disabled || input.triggerDisabled"
                    title="{{input.tooltip ? (input.tooltip | translate) : ''}}"
                    [ngClass]="{'icon-label': input.icon != null && input.icon != undefined && input.value != null && input.value != undefined, 'dcrud-button-search-history':input.showHistorySearch}">
                    <ion-icon *ngIf="input.icon" [name]="input.icon"> </ion-icon>
                    <ion-label>
                        {{input.value | translate}}
                    </ion-label>
                </ion-button>
            </div>
            <!-- TABELLA -->
            <div *ngIf="input.inputType == InputTypes.TABELLA && input.tableDataSource" class="dcrud-tabella-wrapper"
                [ngClass]="{'dcrud-tabella-wrapper-empty': !input.tableSearchDoneFE, 'dcrud-nopagination-table': input.noPagination}">
                <app-table *ngIf="input.tableColumns" [tableColumns]="input.tableColumns"
                    [tableDataSource]="input.tableDataSource" [tablePaginatorPageSize]="input.noPagination ? 10000 : 10"
                    [tableId]="input.id" [tablePaginatorRange]="input.noPagination ? [] :  [10, 50, 100]"
                    (extractApi)="extractTable($event)" [hideExtraction]="false"
                    [paginationFrontend]="endpointPaginationFE" #input.id (tableSortChange)="tableSortChange()"
                    [goDetailPassColumn]="true" (tablePaginatorChange)="tablePaginatorChange($event)"
                    [hidden]="input.tableDataSource.data.length == 0">
                </app-table>
                <div *ngIf="input.tableDataSource.data.length == 0 && input.tableSearchDoneFE">
                    <b>{{'labels.results_not_found' | translate}}</b>
                </div>
            </div>
            <!-- STORICO_RICERCHE
            <div *ngIf="input.inputType == InputTypes.STORICO_RICERCHE" class="dcrud-storico-ricerche-wrapper">
                <ion-button color="primary" (click)="openSearchStateHistory()"
                    title="{{'labels.searchStateHistory' | translate}}">
                    <ion-icon name="list"></ion-icon>
                </ion-button>
            </div>
             -->
            <!-- SQL_EDITOR -->
            <div *ngIf="input.inputType == InputTypes.SQL_EDITOR" class="dcrud-sqleditor-wrapper">
                <div class="dcrud-sqleditor" (keyup)="sqlEditorKeyUp($event)" (keydown)="sqlEditorKeyDown($event)"
                    [attr.contenteditable]="!input.readonly && !input.disabled" [textContent]="input.renderValue"
                    id="dcrud-input-{{inputId}}"
                    [style.color]="input.color ? 'var(--ion-color-'+input.color+')' : 'inherit'" autocomplete="off">
                </div>
                <div class="dcrud-sqleditor-rowcounter" *ngIf="input.renderValue">
                    <div class="dcrud-sqleditor-rowcounter-item"
                        *ngFor="let item of input.renderValue.split('\n'); let i = index">
                        {{i}}
                    </div>
                </div>
                <div class="dcrud-sqleditor-overlay" [innerHTML]="input.inputReferenceFE">

                </div>
                <span class="dcrud-sqleditor-placeholder"
                    [hidden]="input.value != null && input.value != undefined && input.value != ''">
                    {{'labels.insert' | translate}}
                </span>
            </div>
            <!-- SUGGESTION -->
            <div class="dcrud-suggestion" *ngIf="input.showSuggestion">
                <ion-icon name="help-circle" (click)="openInputSuggestion()"></ion-icon>
                <pssfe-dropdown [optionsList]="input.optionsList" [optionValue]="input.optionValue"
                    [optionText]="input.optionText" [multiple]="false" [searchEndpoint]="input.endpointTmp"
                    [searchPlaceholder]="'labels.search' | translate" (valueChange)="suggestionChanged($event)"
                    [placeholder]="'labels.select' | translate" [canClean]="true" [searchValue]="null"
                    [noResultText]="'labels.results_not_found' | translate"
                    [inputId]="'dcrud-input-suggestion-'+inputId" [hidden]="true">
                </pssfe-dropdown>
            </div>
        </div>
        <!-- INKBAR && input.inputType != InputTypes.STORICO_RICERCHE.toString()-->
        <div class="dcrud-inkbar" *ngIf="input.inputType != InputTypes.SEPARATORE.toString() && input.inputType != InputTypes.MESSAGGIO.toString() && input.inputType != InputTypes.SPAZIO_VUOTO.toString() && 
            input.inputType !=  InputTypes.PULSANTE.toString() && input.inputType != InputTypes.GRUPPO.toString() && input.inputType != InputTypes.TABELLA.toString()
            " [ngClass]="{'dcrud-inkbar-invalid': input.invalid}">
        </div>
        <!-- ERROR MESSAGE -->
        <div *ngIf="input.errorMessage" class="input-errorMessage">
            <span>{{input.errorMessage | translate}}</span>
        </div>
    </div>
</div>
<!-- GROUP -->
<div class="dcrud-input-group" *ngIf="input && input.inputType == InputTypes.GRUPPO && input.inputList"
    [ngClass]="{'dcrud-input-gruppo-editormode': editorMode}" (drop)="inputDrop($event)" (dragover)="dragover($event)"
    id="{{input.id}}" (contextmenu)="openInputSettingsPromt($event)">
    <div class="dcrud-input-group-section" [ngClass]="{'section-shadow': !input.hideShadow}"
        [style.margin]="input.hideHeader && input.nopadding ? '0px' : '10px'">
        <div class="section-title" (click)="input['collapsed'] = !input['collapsed']" *ngIf="!input.hideHeader">
            <div class="section-title-label">{{input.label | translate}}<ion-icon *ngIf="input.tooltip"
                    name="information-circle-outline" class="cursor-pointer" [matTooltip]="input.tooltip"
                    matTooltipClass="table-tooltip"></ion-icon></div>
            <ion-icon name="chevron-down-outline"
                [style.transform]="input.collapsed ? 'rotate(-90deg)' : 'rotate(0deg)'"></ion-icon>
        </div>
        <div class="dcrud-input-group-inner" *ngIf="input.inlineGroup"
            [style.justifyContent]="input.alignX == 'spacebetween' ? 'space-between' : input.alignX == 'right' ? 'flex-end' : 'flex-start'"
            [style.padding]="input.nopadding ? '0px' : '10px'">
            <app-dcrud-input *ngFor="let inputInner of input.inputList" [inputList]="inputList" [input]="inputInner"
                [isDcrudModal]="isDcrudModal" [editorMode]="editorMode" (buttonClicked)="buttonClicked()"
                [ngClass]="{'unknown-input': inputInner.inputType == InputTypes.UNKNOWN}"></app-dcrud-input>
        </div>
        <ion-row class="row-section" [hidden]="input.collapsed" *ngIf="!input.inlineGroup"
            [style.padding]="input.nopadding && input.inputList && input.inputList.length == 1 && input.inputList[0].inputType == InputTypes.TABELLA.toString() ? '0px' : '10px'">
            <ion-col *ngFor="let inputInner of input.inputList"
                [style.padding]="input.nopadding && input.inputList && input.inputList.length == 1 && input.inputList[0].inputType == InputTypes.TABELLA.toString() ? '0px' : '10px'"
                [ngClass]="{'dcrud-spazio-vuoto' : inputInner.inputType == InputTypes.SPAZIO_VUOTO, 'dcrud-hidden-input': inputInner.hidden && !editorMode, 'unknown-input': inputInner.inputType == InputTypes.UNKNOWN}"
                [attr.size-lg]="inputInner.hidden && !editorMode ? '0' : inputInner.colSize && inputInner.colSize.lg ? inputInner.colSize.lg : '3'"
                [attr.size-md]="inputInner.hidden && !editorMode ? '0' : inputInner.colSize && inputInner.colSize.md ? inputInner.colSize.md : '3'"
                [attr.size-sm]="inputInner.hidden && !editorMode ? '0' : inputInner.colSize && inputInner.colSize.sm ? inputInner.colSize.sm : '4'"
                [attr.size-xs]="inputInner.hidden && !editorMode ? '0' : inputInner.colSize && inputInner.colSize.xs ? inputInner.colSize.xs : '12'">
                <app-dcrud-input [inputList]="inputList" [input]="inputInner" [isDcrudModal]="isDcrudModal"
                    (buttonClicked)="buttonClicked()" [editorMode]="editorMode"></app-dcrud-input>
            </ion-col>
        </ion-row>
    </div>
</div>