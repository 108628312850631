import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ViewAndFineLinkService } from './vaf-link.service';
import { AuthService } from 'src/app/services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class VafListsService {
  //common type list
  public commonTypeLists;
  //lst role call
  public lastRoleIdCall;

  constructor(public httpUtilitySrv: HttpUtilityService, private authSrv: AuthService,
    public http: HttpClient, public translate: TranslateService, public linkSrv: ViewAndFineLinkService) { }

  //getCommonTypeLists
  public getCommonTypeLists() {
    return new Promise((resolve, reject) => {
      this.authSrv.getAuthObject().then((authObj: any) => {
        let currentRoleIdCall = authObj && authObj.role && authObj.role.id ? authObj.role.id : null;
        if (!this.commonTypeLists || this.lastRoleIdCall != currentRoleIdCall) {
          let url = currentRoleIdCall ? "ViewAndFine/BE/Internal/GetCommonTypeLists" : "ViewAndFine/BE/External/GetCommonTypeLists";
          this.http.get(`${this.linkSrv.links.server}${url}`).subscribe((res) => {
            this.lastRoleIdCall = currentRoleIdCall;
            this.commonTypeLists = res;
            resolve(res);
          }, (err) => {
            
            reject(err);
          })
        } else {
          resolve(this.commonTypeLists)
        }
      })
    });
  }

  //getCustomerList
  public getCustomersList(isBO?,authorityId?) { // aggiunta pramatero isBO -> 
    return new Promise((resolve, reject) => {
      authorityId = authorityId ? authorityId : '';// no autId ma isBO 
      let url = isBO ? "ViewAndFine/BE/BackOffice/GetCustomersList" : "ViewAndFine/BE/Authority/GetCustomersList";
      this.http.get(`${this.linkSrv.links.server}${url}${isBO ? '?idAuthority=' + authorityId : ''}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getIssuingAuthoritiesList
  public getIssuingAuthoritiesList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/GetIssuingAuthoritiesList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getViewAllocatedLotsList
  public getViewAllocatedLotsList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/GetViewAllocatedLotsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getViewAllocatedLotsListBO
  public getViewAllocatedLotsListBO() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/BackOffice/GetViewAllocatedLotsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getFineAllocatedLotsList
  public getFineAllocatedLotsList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/GetFineAllocatedLotsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getFineAllocatedLotsListBO
  public getFineAllocatedLotsListBO() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/BackOffice/GetFineAllocatedLotsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getViolationsList
  public getViolationsList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/GetViolationsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getViolationsListBO
  public getViolationsListBO() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/BackOffice/GetViolationsList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  ///getDocumentTypesList
  public getDocumentTypesList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/getDocumentTypesList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  ///getDocumentTypesListBO
  public getDocumentTypesListBO() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/BackOffice/getDocumentTypesList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getUtenti
  public getUtenti() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getAllUsers`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  ///getDocumentTypesList 
  public getAuthoritiesQuickList() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/BackOffice/GetAuthoritiesQuickList`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }
}
