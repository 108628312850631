<ion-content class="right-menu-popover">
  <ion-list>
    <ion-item class="user-data" class="user-data-item">
      <ion-icon class="icon-red" name="person"></ion-icon>
      <div class="chip-data">
        <div class="chip-data-inner">
          <div class="user">
            {{'labels.user'|translate}}:&nbsp;<span>{{currentUser.userNameApp}}</span>
          </div>
          <div class="data">
            {{'labels.role' | translate}}:&nbsp;<span>{{currentUser.customer}} / {{currentUser.role.nome}}</span>
          </div>
        </div>
      </div>
    </ion-item>
    <ion-item (click)="customerListCollapsed = !customerListCollapsed"
      [hidden]="customerList && customerList.length == 1 && customerList[0].ruoli && customerList[0].ruoli.length == 1 && (!customerList[0].figli || (customerList[0].figli && customerList[0].figli.length == 0))">
      <ion-icon class="icon-red" name="people"></ion-icon>
      <ion-label>{{ 'menu.roles' | translate}}</ion-label>
      <ion-icon name="caret-down-circle"></ion-icon>
    </ion-item>
    <ion-item [ngClass]="{'customer-list-collapsed': customerListCollapsed}">
      <div class="customer-list">
        <app-infinite-tree *ngFor="let item of customerList" [item]="item" [titleKey]="'denominazione'"
          [childrenKey]="'figli'" [hideCheckboxParent]="true" [hideCheckboxRole]="true" [allOpened]="true"
          [hideTerritoryCount]="true" (itemSelect)="popoverNavParams.selectRole($event);popoverCtrl.dismiss();">
        </app-infinite-tree>
      </div>
      <!--
      <ion-item class="search-bar" lines="none">
        <ion-icon name="search"></ion-icon>
        <ion-input type="text" [(ngModel)]="customerListKey" placeholder="{{'labels.search' | translate}}"
          (keyup)="filterCustomerList()"></ion-input>
      </ion-item>
      <ion-item *ngFor="let item of customerList.searchList;let i = index">
        <div class="customer-box">
          <div class="customer-name">
            <span>{{item.cliente.denominazione}}</span>
          </div>
          <div class="role-box">
            <select (change)="popoverNavParams.selectRole($event.target.value, item.cliente.denominazione, item.cliente.idCliente);popoverCtrl.dismiss()">
              <option value="" disabled selected>Seleziona un ruolo</option>
              <option *ngFor="let role of item.ruoli; let j = index" value='{"nome":"{{role.nome}}", "id":{{role.id}}}'>{{role.nome}}</option>
            </select>
          </div>
        </div>
      </ion-item>
    -->
    </ion-item>
    <ion-item (click)="popoverNavParams.enterFullScreen();this.popoverCtrl.dismiss();">
      <ion-icon class="icon-red" name="expand"></ion-icon>
      <ion-label>{{ 'labels.full_screen' | translate}}</ion-label>
    </ion-item>
    <ion-item (click)="popoverNavParams.openSettings();this.popoverCtrl.dismiss();">
      <ion-icon class="icon-red" name="settings"></ion-icon>
      <ion-label>{{ 'menu.settings' | translate}}</ion-label>
    </ion-item>
    <ion-item (click)="popoverNavParams.logout();this.popoverCtrl.dismiss();">
      <ion-icon class="icon-red" name="log-out"></ion-icon>
      <ion-label>{{ 'menu.logout' | translate}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>