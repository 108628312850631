<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.territory_detail' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="admin-territory-detail-popover">
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-content>
      <!-- territory -->
      <form [formGroup]="territoryDetailForm">
        <ion-grid>
          <!-- input -->
          <ion-row>
            <ion-col size-md="12" size-sm="12" size-xs="12" class="flex-center-spacebetween">
              <div class="inline-block"><b>{{'labels.territory_data' | translate}}</b></div>
            </ion-col>
            <!-- description -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.description' | translate}} *</ion-label>
                <ion-input type="text" formControlName="description" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- type -->
            <ion-col size-md="6" size-sm="6" size-xs="12"
              [hidden]="modalNavParams && modalNavParams.territory && modalNavParams.territory.idCliente">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.type' | translate}} *</ion-label>
                <ion-select formControlName="type" placeholder="{{'labels.select' | translate}}">
                  <ion-select-option value="0">{{'labels.no_company' | translate }}
                  </ion-select-option>
                  <ion-select-option value="1">{{'labels.new_company' | translate }}
                  </ion-select-option>
                  <ion-select-option value="2">{{'labels.existing_company' | translate }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <!-- active -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.active' | translate}}</ion-label>
                <ion-checkbox type="checkbox" formControlName="active"
                  [disabled]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-checkbox>
              </ion-item>
              <div class="validation-message"
                *ngIf="!territoryDetailForm.get('active').value && modalNavParams.territory && (territoryDetailForm.get('active').dirty || modalNavParams.territory && territoryDetailForm.get('active').touched)">
                {{'messages.not_active_territory' | translate}}</div>
            </ion-col>
            <!-- isClosed -->
            <ion-col size-md="6" size-sm="6" size-xs="12" [hidden]="!sectionIdList || !sectionIdList.includes('amministrazione.gestione_territori.set_as_closed')">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.isClosed' | translate}}</ion-label>
                <ion-checkbox type="checkbox" formControlName="isClosed"
                  [disabled]="modalNavParams.territory && modalNavParams.territory.isTheRoot || (modalNavParams.territory && modalNavParams.territory.figli && modalNavParams.territory.figli.length > 0)">
                </ion-checkbox>
              </ion-item>
            </ion-col>
            <!-- roles -->
            <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.territory">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.roles' | translate}}</ion-label>
                <ion-input type="text" placeholder="0" [(ngModel)]="modalNavParams.territory.numeroRuoli"
                  [ngModelOptions]="{standalone: true}" [readonly]="true" class="input-cursor-pointer"
                  (click)="goAdminRoleSearch()">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- users -->
            <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.territory">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.users' | translate}}</ion-label>
                <ion-input type="text" placeholder="0" [(ngModel)]="modalNavParams.territory.numeroUtenti"
                  (click)="goAdminUserSearch()" [ngModelOptions]="{standalone: true}" [readonly]="true"
                  class="input-cursor-pointer">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- low level territory -->
            <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.territory">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.low_territories' | translate}}</ion-label>
                <ion-input type="text" placeholder="0" [(ngModel)]="modalNavParams.territory.numeroTerritoriFigli"
                  [ngModelOptions]="{standalone: true}" [readonly]="true">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- id -->
            <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams.territory">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.id' | translate}}</ion-label>
                <ion-input type="text" formControlName="id" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="true">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <!-- new company-->
      <form [formGroup]="companyDetailForm"
        [hidden]="!territoryDetailForm.get('type') || territoryDetailForm.get('type').value != '1'">
        <ion-grid>
          <ion-row>
            <ion-col size-md="12" size-sm="12" size-xs="12">
              <b>{{ modalNavParams && modalNavParams.territory ? ('labels.company_data' | translate) :
                ('labels.new_company_data' | translate)}}</b>
            </ion-col>
            <!-- description -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.description' | translate}} *</ion-label>
                <ion-input type="text" formControlName="description" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- acronym -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.acronym' | translate}} *</ion-label>
                <ion-input type="text" formControlName="acronym" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- address -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.address' | translate}} *</ion-label>
                <ion-input type="text" formControlName="address" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- nation -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.nation' | translate}} *</ion-label>
                <ion-input type="text" formControlName="nation" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- vatNumber -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.vat_number' | translate}} *</ion-label>
                <ion-input type="text" formControlName="vatNumber" placeholder="{{'labels.vat_number' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- phone -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.phone' | translate}} *</ion-label>
                <ion-input type="text" formControlName="phone" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- fax -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.fax' | translate}} *</ion-label>
                <ion-input type="text" formControlName="fax" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
            <!-- isClienteSafo -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.safo_customer' | translate}}</ion-label>
                <ion-checkbox type="checkbox" formControlName="isClienteSafo"
                  [disabled]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-checkbox>
              </ion-item>
            </ion-col>
            <!-- IdManagerMulte -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.multimanager_id' | translate}}</ion-label>
                <ion-input type="number" formControlName="idManagerMulte" placeholder="{{'labels.insert' | translate}}"
                  [readonly]="modalNavParams.territory && modalNavParams.territory.isTheRoot">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <!-- existing company -->
      <form [formGroup]="existingCompanyForm"
        [hidden]="!territoryDetailForm.get('type') || territoryDetailForm.get('type').value != '2'">
        <ion-grid>
          <ion-row>
            <ion-col size-md="12" size-sm="12" size-xs="12">
              <b>{{'labels.select_company' | translate}}</b>
            </ion-col>
            <!-- customer -->
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ionic-selectable formControlName="companyId" [items]="companyOptions"
                  itemTextField="denominazioneAzienda" [canSearch]="true" [isMultiple]="false"
                  closeButtonText="{{'buttons.cancel' | translate}}" placeholder="{{'labels.select' | translate}}"
                  searchFailText="{{'errors.6' | translate}}" searchPlaceholder="{{'labels.search' | translate}}"
                  closeButtonSlot="end">
                </ionic-selectable>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <!-- modules -->
      <ion-grid *ngIf="modulesOptions && modulesOptions.length > 0">
        <ion-row>
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <b>{{'labels.modules' | translate}}</b>
          </ion-col>
          <ion-col size-md="12" size-sm="12" size-xs="12">
            <app-modules-tree *ngFor="let item of modulesOptions" [item]="item"
              (handleCheckbox)="handleCheckbox($event)"
              [checkboxDisabled]="modalNavParams.territory && !!modalNavParams.territory.isTheRoot"></app-modules-tree>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <!-- buttons-->
  <ion-row>
    <ion-col size-md="3" size-sm="3" size-xs="12">
      <ion-item class="box-required" lines="none">
        <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' | translate}}
        </ion-label>
      </ion-item>
    </ion-col>
    <ion-col size-md="9" size-sm="9" size-xs="12" class="col-button-box">
        <div class="button-box">
          <ion-button color="danger" size="default" (click)="modalCtrl.dismiss()">
            <ion-label>{{'buttons.cancel' | translate}}</ion-label>
          </ion-button>
          <ion-button color="danger" size="default" (click)="eliminaTerritorioAlert()"
            *ngIf="modalNavParams.territory && !modalNavParams.territory.isTheRoot"
            [disabled]="modalNavParams.territory.isTheRoot || modalNavParams.territory.numeroRuoli > 0 || modalNavParams.territory.numeroUtenti > 0  || modalNavParams.territory.numeroTerritoriFigli > 0">
            {{'buttons.delete_territory' | translate}}
          </ion-button>
          <app-help [color]="'danger'" [title]="'labels.territory_elimination'" [message]="'help.delete_territory_help'"
            *ngIf="modalNavParams.territory && !modalNavParams.territory.isTheRoot && (modalNavParams.territory.numeroRuoli > 0 || modalNavParams.territory.numeroUtenti > 0  || modalNavParams.territory.numeroTerritoriFigli > 0)">
          </app-help>
          <ion-button color="success" size="default"
            *ngIf="!modalNavParams.territory || (modalNavParams.territory && !modalNavParams.territory.isTheRoot)"
            [disabled]="saveDisabled()" (click)="saveTerritorio()">
            <ion-label>{{'buttons.save' | translate}}</ion-label>
          </ion-button>
        </div>
    </ion-col>
  </ion-row>
</ion-footer>