import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { MicroFrontEndContainerService } from 'src/app/services/micro-frontend-container.service';

@Component({
  selector: 'app-micro-frontend-container-page',
  templateUrl: './micro-frontend-container.page.html',
  styleUrls: ['./micro-frontend-container.page.scss'],
})
export class MicroFrontendConatinerPage implements OnInit, AfterViewInit, OnDestroy {
  //router subscription
  private _activatedRouteSub: Subscription;

  constructor(private _activateRoute: ActivatedRoute, private authSrv: AuthService, private microFESrv: MicroFrontEndContainerService, private httpUtilitySrv: HttpUtilityService) {
    //listen micro frontend messages
    let context = this;
    window.onmessage = function (event) {
      if (event && event.data) {
        //httpError
        if (event.data.httpError)
          context.microFESrv.loadMicroFrontEndBaseUrl(null, null, null);
        //handle message
        context.microFESrv.onMicroFrontEndMessage(event.data);
      }
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    if (!this._activatedRouteSub)
      this._activatedRouteSub = this._activateRoute.params.subscribe(() => {
        let currentUrl = window.location.href;
          //controllo se /route/ esistente (è un microfrontend) e se la route attuale è diversa da quella precedente
          if (currentUrl && currentUrl.indexOf("/route/") != -1 && (!this.microFESrv.lastRoute || this.microFESrv.lastRoute.indexOf(currentUrl) == -1)) {
            this.authSrv.getAuthObject().then((authObject: any) => {
              this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
                let microFEBase: string = window.location.href.toString();
                this.microFESrv.loadMicroFrontEndBaseUrl(microFEBase, authObject, headers?.role?.id);
              });
            });
          }
      })
  }

  ngOnDestroy(): void {
    this.microFESrv.lastRoute = null;
    if (this._activatedRouteSub)
      this._activatedRouteSub.unsubscribe();
    //this.microFESrv.loadMicroFrontEndBaseUrl(null, null, null);
  }
}
