<div class="footer-box" [ngClass]="{'mobile-footer': isMobile}">
  <div class="footer-content" *ngIf="showFooter && (!impersGuid || impersGuid == '' || this.router.url.indexOf('/login') != -1)">   
    <div class="copyright">© Copyright 2020 by Safo Group SpA. All Rights Reserved.</div>
    <div class="links">        
      <a href="https://safogroup.eu/privacy-policy/" target="_blank">{{'labels.privacy_policy' | translate}}</a>&nbsp;/&nbsp;<a href="https://safogroup.eu/cookie-policy/" target="_blank">{{'labels.cookie_policy' | translate}}</a>
    </div>
  </div>
  <div class="footer-impersonate-content" *ngIf="impersGuid && impersGuid != '' && this.router.url.indexOf('/login') == -1">
    <div class="alert-impers" [hidden]="isMobile">{{'labels.stop_impersonate' | translate}}</div>
    <ion-button color="warning" size="small" (click)="stopImpersonateUserAlert()">
      <ion-label>{{'buttons.stop_impersonate_user' | translate}}</ion-label>
    </ion-button>  
  </div> 
</div>