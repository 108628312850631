import { NgModule } from '@angular/core';
import { IonicModule } from"@ionic/angular";
import { TranslateMenuPipe } from './translate-menu.pipe';
@NgModule({
    declarations: [
        TranslateMenuPipe
    ],
    imports: [
        IonicModule
    ],
    exports: [
        TranslateMenuPipe
    ]
})
export class TranslateMenuPipeModule {}