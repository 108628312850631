import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  public askFromPageSubject: BehaviorSubject<ChatMessage> = new BehaviorSubject<ChatMessage>(null);

  constructor(public httpUtilitySrv: HttpUtilityService,
    private _http: HttpClient, public translate: TranslateService) { }

  //askFromPage
  public askFromPage(message) {
    this.askFromPageSubject.next(message);
  }

  //genericGet
  public genericGet(url: string) {
    return new Promise((resolve, reject) => {
      this._http.get(url).subscribe({
        next: (res) => { resolve(res) },
        error: (err) => { reject(err) }
      })
    });
  }

  //ask
  public ask (payload: Ask){
    return new Promise((resolve, reject) => {
      this._http.post(`https://test.safogroup.eu/safoms-apigateway/Assistant/Domanda`, payload).subscribe(
        {
          next: (res)=>{resolve(res)},
          error: (err)=>{reject(err)}
        }
      )
    });
  }

}


export class ChatBotConfig {
  public show: boolean;
  public bottom: number;
  public right: number;
  public chat: Array<ChatMessage>;
  public currentSessionId: number;
  public currentConfig: ChatMessage;
  public currentServiceId: number;
  public isLoading: boolean;
  public isButtonPinned: boolean;
  public datasetJson: any;
  public showChatData: boolean;
  public currentQuota: {
    percentage: number,
    max: number,
    current: number
  };

  constructor(isReset?: boolean, chatBotConfigOverride?: ChatBotConfig) {
    this.show = isReset ? true : null;
    this.bottom = 10;
    this.right = 10;
    this.chat = [];
    this.currentConfig = null;
    this.currentServiceId = null;
    this.currentSessionId = null;
    this.isLoading = false;
    this.isButtonPinned = true;
    this.datasetJson = null;
    this.showChatData = null;
    this.currentQuota = chatBotConfigOverride ? chatBotConfigOverride.currentQuota : { percentage: 0, max: 100, current: 0 };
  }
}
export interface ChatMessage {
  idSessioneAI: number,
  opzioni: Array<MessageOption>,
  FEOptionSelected?: MessageOption,
  testo: string,
  type: 'human' | 'ai' | 'error',
  datasetJson: any
  quotaAssegnata: number,
  quotaUsata: number,
  token: number
}
export interface MessageOption {
  id: number,
  idTipoRisposta: number,//2 = navigazione
  tipoRisposta: string,
  codice: string,
  categoria: string,
  codiceCategoria: string,
  nome: string,
  descrizione: string,
  providers: Array<MessageOptionProvider>,
  idServizioPadre: number
}

export interface MessageOptionProvider {
  id: number,
  descrizione: string,
  endPoint: string,
  campoDaMappare: string,
  campoEndPointDaPrendere: string,
  FEValue: any
}

export interface Ask {
  testo: string,
  sessione: {
    idSessioneAI: number,
    parametriPrecompilati: Array<{
      key: string,
      value: string
    }>
  },
  idServizio: number
}