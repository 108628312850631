import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VafUtilitySrv {

    constructor() { }

    //get http catch error
    public getHttpCatchError(error) {
        let returnError = "";
        if (typeof (error) == 'string')
            returnError = error;
        else if (typeof (error) == 'object') {
            if (error.error && error.error.errors) {
                for (let propery in error.error.errors) {
                    if (error.error.errors[propery] && Array.isArray((error.error.errors[propery]))) {
                        error.error.errors[propery].forEach((err) => {
                            returnError = returnError + "</br>" + err;
                        })
                    } else {
                        returnError = returnError + "</br>" + error.error.errors[propery];
                    }
                }
            }
            else if (error.error)
                returnError = error.error;
            else if (error.message)
                returnError = error.message;
        }
        return returnError;
    }

    //format matDatePickerDate
    public formatMatDatePickerDate(date) {
        let dt = date ? new Date(date) : null;
        return dt ? dt.getFullYear() + "-" + ((dt.getMonth() + 1 < 10) ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())
            + "T" + (dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()) + ":" + (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes())) : null;
    }

    //format dateInputDate
    public formatDateInputDate(date) {
        let dt = date ? new Date(date) : null;
        return dt ? dt.getFullYear() + "-" + ((dt.getMonth() + 1 < 10) ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())) : null;
    }

    //expandCollapseElement
    public expandCollapseElementById(elementId) {
        let element = document.getElementById(elementId);
        if (element) {
            //expand
            let height = element.getBoundingClientRect().height;
            if (height <= 20) {
                element.style.overflowY = "auto";
                element.style.height = element.scrollHeight + "px";
            }
            //collapse
            else {
                element.style.overflowY = "hidden";
                element.style.height = "0px";
            }
        }
    }
}
