<ion-header>
    <div class="modal-page-card">
        <ion-card-header>
            <div class="modal-header">
                <div class="modal-title">
                    <span *ngIf="crpFE">{{crpFE.crpId}}&nbsp;-&nbsp;</span>{{crpFE ? crpFE.crpTitle : ('labels.newCrp' |
                    translate)}}
                </div>
                <div class="modal-icon">
                    <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
                </div>
            </div>
        </ion-card-header>
    </div>
</ion-header>
<ion-content id="modal-content">
    <div class="content-wrapper" [ngClass]="{'content-wrapper-vertical': layoutConfig.vertical}">
        <!-- newcrp wrapper -->
        <div class="newcrp-wrapper" [hidden]="crpFE">
            <form [formGroup]="newCrpForm">
                <ion-grid>
                    <ion-row>
                        <!-- tipoReport -->
                        <ion-col size-md="12" size-sm="12" size-xs="12">
                            <ion-item class="input-field" lines="none">
                                <ion-label position="stacked">
                                    {{'labels.type' | translate}}*</ion-label>
                                <ion-input type="text" formControlName="tipoReport"
                                    placeholder="{{'labels.insert' | translate}}">
                                </ion-input>
                            </ion-item>
                        </ion-col>
                        <!-- descrizione -->
                        <ion-col size-md="12" size-sm="12" size-xs="12">
                            <ion-item class="input-field" lines="none">
                                <ion-label position="stacked">
                                    {{'labels.title' | translate}}*</ion-label>
                                <ion-input type="text" formControlName="descrizione"
                                    placeholder="{{'labels.insert' | translate}}">
                                </ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </form>
        </div>
        <!-- query wrapper -->
        <div class="query-wrapper" [hidden]="!crpFE || !sectionIdList.includes('reporting.custom-report-configuration')"
            [style.minWidth]="layoutConfig.vertical ? '100%' : (layoutConfig.queryWrapperWidth + 'px')"
            [style.width]="layoutConfig.vertical ? '100%' : (layoutConfig.queryWrapperWidth + 'px')"
            [style.maxWidth]="layoutConfig.vertical ? '100%' : (layoutConfig.queryWrapperWidth + 'px')"
            [style.minHeight]="layoutConfig.vertical ? (layoutConfig.queryWrapperHeight + 'px') : '100%'"
            [style.height]="layoutConfig.vertical ? (layoutConfig.queryWrapperHeight + 'px') : '100%'"
            [style.maxHeight]="layoutConfig.vertical ? (layoutConfig.queryWrapperHeight + 'px') : '100%'"
            (dragover)="dragover($event)">
            <!-- query -->
            <div class="crp-query" *ngIf="crpFE">
                <div class="query">
                    <div class="query-top">
                        <span>
                            <ion-icon name="grid-outline" class="cursor-pointer" (click)="switchLayout()"
                                title="{{'labels.changeLayout'|translate}}"></ion-icon>
                            <ion-icon name="refresh" class="cursor-pointer" (click)="refreshCrp()"
                                title="{{'labels.refreshDetail'|translate}}"></ion-icon>
                            <ion-icon name="clipboard-outline" class="cursor-pointer"
                                title="{{'labels.copy_to_clipboard' | translate}} query"
                                (click)="copyToClipboard(crpFE.query)"></ion-icon>
                        </span>
                        <span>
                            {{'labels.enabled' | translate }}:&nbsp;
                            <ion-icon name="square-outline" class="cursor-pointer" *ngIf="!crpFE.enabled"
                                (click)="enableDisableCrp()"></ion-icon>
                            <ion-icon name="checkbox" class="cursor-pointer" *ngIf="crpFE.enabled"
                                (click)="enableDisableCrp()"></ion-icon>
                        </span>
                    </div>
                    <div class="query-body">{{crpFE.query}}</div>
                </div>
            </div>
            <!-- resizer -->
            <div class="query-wrapper-resizer" draggable="true" (dragend)="resizeQueryWrapper($event)"
                (dragstart)="dragStart($event)" (drop)="drop($event)"></div>
        </div>
        <!-- tabs wrapper -->
        <div class="tabs-wrapper" [hidden]="!crpFE"
            [style.minWidth]="layoutConfig.vertical ? '100%' : layoutConfig.tabsWrapperWidth"
            [style.width]="layoutConfig.vertical ? '100%' : layoutConfig.tabsWrapperWidth"
            [style.maxWidth]="layoutConfig.vertical ? '100%' : layoutConfig.tabsWrapperWidth"
            [style.minHeight]="layoutConfig.vertical ? (layoutConfig.tabsWrapperHeight) : '100%'"
            [style.height]="layoutConfig.vertical ? (layoutConfig.tabsWrapperHeight) : '100%'"
            [style.maxHeight]="layoutConfig.vertical ? (layoutConfig.tabsWrapperHeight) : '100%'"
            (dragover)="dragover($event)">
            <!-- tabs -->
            <mat-tab-group class="crp-configuration-tabs" *ngIf="crpFE" [(selectedIndex)]="currentTabindex">
                <mat-tab label="{{'labels.instructions' | translate}}" [hidden]="!crpFE">
                    <div class="crp-tab-inner" *ngIf="crpFE">
                        <!-- instructions -->
                        <ion-list class="crp-instructions" *ngIf="crpFE.instructions">
                            <!-- <ion-reorder-group [disabled]="false"
                                (ionItemReorder)="handleInstructionsChange($any($event))">-->
                            <ion-item *ngFor="let instruction of crpFE.instructions;let i = index;">
                                <div class="instruction-label">
                                    <div>{{i==0 ? ('labels.globalInstruction' |
                                        translate) : i}}</div>
                                </div>
                                <ion-input class="instruction-input" type="text" [(ngModel)]="instruction.text"
                                    (ngModelChange)="handleInstructionsChange()"
                                    placeholder="{{'labels.insert' | translate}}">
                                </ion-input>
                            </ion-item>
                        </ion-list>
                    </div>
                </mat-tab>
                <mat-tab label="{{'labels.crpPermissions' | translate}}">
                    <div class="crp-tab-inner" *ngIf="crpFE.permissions">
                        <ion-row>
                            <!-- territory -->
                            <ion-col size-md="12" size-sm="12" size-xs="12">
                                <ion-item class="input-field" lines="none">
                                    <ion-label position="stacked">{{'labels.territories_recivers' | translate}}
                                        ({{customerIdList.length}})
                                    </ion-label>
                                    <button [style.opacity]="customerIdList?.length > 0 ? '1' : '0.3'" type="button"
                                        class="select-customers" (click)="selectableTreePopoverCustomer()"><span
                                            *ngIf="!customerIdList || customerIdList.length == 0">{{'buttons.select' |
                                            translate}}</span>
                                        <span *ngIf="customerIdList && customerIdList.length > 0"><span
                                                *ngFor="let customer of customerIdList; let i = index;"><span
                                                    *ngIf="i != 0">,&nbsp;</span>{{customer.denominazione}}</span></span>
                                    </button>
                                </ion-item>
                            </ion-col>
                            <!-- roles -->
                            <ion-col size-md="12" size-sm="12" size-xs="12">
                                <ion-item class="input-field" lines="none" (click)="selectableTreePopoverRole()">
                                    <ion-label position="stacked">{{'labels.roles_recivers' | translate}}
                                        ({{roleIdList.length}})
                                    </ion-label>
                                    <button [style.opacity]="roleIdList?.length > 0 ? '1' : '0.3'" class="select-roles"
                                        type="button" ><span
                                            *ngIf="!roleIdList || roleIdList.length == 0">{{'buttons.select' |
                                            translate}}</span>
                                        <span *ngIf="roleIdList && roleIdList.length > 0"><span
                                                *ngFor="let role of roleIdList; let i = index;"><span
                                                    *ngIf="i != 0">,&nbsp;</span>{{role.nome}}</span></span></button>
                                </ion-item>
                            </ion-col>
                            <!-- userList-->
                            <ion-col size-md="12" size-sm="12" size-xs="12">
                                <ion-item class="input-field" lines="none">
                                    <ion-label position="stacked">{{'labels.users_recivers' | translate}}</ion-label>
                                    <ionic-selectable [(ngModel)]="userIdList"
                                        (ngModelChange)="handlePermissionsChange()" [items]="usersOption"
                                        itemValueField="idUtente" itemTextField="nominativo" [canSearch]="true"
                                        [isMultiple]="true" closeButtonText="{{'buttons.cancel' | translate}}"
                                        placeholder="{{'labels.select' | translate}}"
                                        searchFailText="{{'errors.6' | translate}}"
                                        searchPlaceholder="{{'labels.search' | translate}}" closeButtonSlot="end">
                                    </ionic-selectable>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </div>
                </mat-tab>
                <mat-tab label="{{'labels.crpParameters' | translate}}" *ngIf="crpFE && crpFE.parameters">
                    <div class="crp-tab-inner">
                        <table>
                            <tr>
                                <th class="cell-center">
                                </th>
                                <th>
                                    {{'labels.name' | translate}}
                                </th>
                                <th>
                                    {{'labels.label' | translate}}
                                </th>
                                <th>
                                    {{'labels.defaultValue' | translate}}</th>
                                <th class="cell-center">
                                    {{'labels.hidden' | translate}}
                                </th>
                                <th class="cell-center">
                                    {{'labels.required' | translate}}
                                </th>
                                <th class="cell-center">
                                    {{'labels.readonly' | translate}}
                                </th>
                                <th>
                                    {{'labels.user' | translate}}
                                </th>
                                <th>
                                    {{'labels.role' | translate}}
                                </th>
                                <th>
                                    {{'labels.territory' | translate}}
                                </th>
                            </tr>
                            <tr *ngFor="let parameter of crpFE.parameters;let i=index;">
                                <td class="cell-center">
                                    <ion-icon name="duplicate-outline" color="success" class="cursor-pointer"
                                        *ngIf="parameter.defaultParameterFE" (click)="duplicateParameter(i)"
                                        title="{{'labels.duplicate'|translate}}"></ion-icon>
                                    <ion-icon name="close" color="danger" class="cursor-pointer"
                                        *ngIf="!parameter.defaultParameterFE" (click)="deleteParameter(i)"></ion-icon>
                                </td>
                                <td class="cell-text">
                                    {{parameter.property}}
                                </td>
                                <td class="cell-text">
                                    <input type="text" placeholder="{{'labels.insert' | translate}}"
                                        [(ngModel)]="parameter.label"
                                        (ngModelChange)="handleParameterChanged(parameter)">
                                </td>
                                <td class="cell-parameter">
                                    <div class="parameter">
                                        <div class="parameter-input">
                                            <!-- STRINGA -->
                                            <input type="text" [(ngModel)]="parameter.value"
                                                (ngModelChange)="handleParameterChanged(parameter)"
                                                placeholder="{{'labels.insert' | translate}}"
                                                *ngIf="parameter.type == 'STRINGA'">
                                            <!-- NUMERO -->
                                            <input type="number" [(ngModel)]="parameter.value"
                                                (ngModelChange)="handleParameterChanged(parameter)"
                                                placeholder="{{'labels.insert' | translate}}"
                                                *ngIf="parameter.type == 'NUMERO'">
                                            <!-- DATAORA -->
                                            <div class="date-time-input" *ngIf="parameter.type == 'DATAORA'">
                                                <input matInput [ngxMatDatetimePicker]="$any(parameter_i)"
                                                    [(ngModel)]="parameter.value"
                                                    (ngModelChange)="handleParameterChanged(parameter)"
                                                    [ngClass]="{'empty-date-input': !parameter.value}"
                                                    placeholder="{{'labels.insert' | translate}}"
                                                    [disabled]="parameter.getdateFE">
                                                <mat-datepicker-toggle matSuffix [for]="$any(parameter_i)"
                                                    [ngClass]="{'empty-date-input': !parameter.value}"
                                                    [hidden]="parameter.getdateFE">
                                                </mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #parameter_i [showSeconds]="false">
                                                </ngx-mat-datetime-picker>
                                            </div>
                                            <!-- DATA -->
                                            <input type="date" [(ngModel)]="parameter.value"
                                                (ngModelChange)="handleParameterChanged(parameter)"
                                                placeholder="{{'labels.insert' | translate}}"
                                                [ngClass]="{'empty-date-input': !parameter.value}"
                                                *ngIf="parameter.type == 'DATA'" [disabled]="parameter.getdateFE">
                                            <!-- COMBO -->
                                            <ion-select [(ngModel)]="parameter.value"
                                                (ngModelChange)="handleParameterChanged(parameter)"
                                                [cancelText]="'buttons.cancel' | translate" interface="popover"
                                                [okText]="'buttons.ok' | translate"
                                                placeholder="{{'labels.select' | translate}}" multiple="false"
                                                *ngIf="parameter.type == 'COMBO' && parameter.endpointpreloaded == true">
                                                <ion-select-option [value]="option.id"
                                                    *ngFor="let option of parameter.options ? parameter.options : endpointList">
                                                     {{option.desc}}</ion-select-option>
                                            </ion-select>
                                            <!-- COMBO LISTA NON PRECARICATA -->
                                            <ionic-selectable [(ngModel)]="parameter.value" itemValueField="value" [items]="null"
                                            (ngModelChange)="handleParameterChanged(parameter.value)"
                                            itemTextField="text" [canSearch]="true" [isMultiple]="false" interface="popover"
                                            closeButtonText="{{'buttons.close' | translate}}"
                                            placeholder="{{'labels.select' | translate}}"
                                            searchFailText="{{'errors.6' | translate}}"
                                            searchPlaceholder="{{'labels.search' | translate}}" closeButtonSlot="end"
                                            (onSearch)="getListByEndpoint($event, parameter.endpointurl)"
                                            [searchDebounce]="500" [shouldFocusSearchbar]="true" 
                                            *ngIf="parameter.type == 'COMBO' && parameter.endpointpreloaded == false"
                                            id="selectableField"  #selectableField>
                                        <ng-template ionicSelectableSearchFailTemplate>
                                                <div [hidden]="isSelectableItemsEmpty('selectableField') == null"
                                                    class="selectable-no-results">
                                                    {{'errors.6' | translate}}
                                                </div>
                                            </ng-template> 
                                        </ionic-selectable>
                                            <!-- MULTICOMBO -->
                                            <ion-select [(ngModel)]="parameter.value"
                                                (ngModelChange)="handleParameterChanged(parameter)"
                                                [cancelText]="'buttons.cancel' | translate"
                                                [okText]="'buttons.ok' | translate"
                                                placeholder="{{'labels.select' | translate}}" multiple="true"
                                                *ngIf="parameter.type == 'MULTICOMBO'">
                                                <ion-select-option [value]="option.id"
                                                    *ngFor="let option of parameter.options">
                                                    {{option.desc}}</ion-select-option>
                                            </ion-select>
                                        </div>
                                        <div class="parameter-buttons">
                                            <ion-icon name="today" *ngIf="['DATA', 'DATAORA'].includes(parameter.type)"
                                                [color]="parameter.getdateFE ? 'primary' : 'tertiary'"
                                                (click)="parameter.getdateFE = !parameter.getdateFE;cleanParameter(parameter)"
                                                title="{{'labels.crpgetdate'|translate}}"></ion-icon>
                                            <ion-icon name="settings-outline"
                                                *ngIf="['COMBO','MULTICOMBO'].includes(parameter.type) && sectionIdList.includes('reporting.custom-report-configuration') && parameter.options"
                                                (click)="openComboEdit(parameter, $event)"
                                                title="{{'labels.crpConfigCombo'|translate}}"></ion-icon>
                                            <ion-icon name="close" (click)="cleanParameter(parameter)"></ion-icon>
                                        </div>
                                    </div>
                                </td>
                                <td class="cell-center">
                                    <input type="checkbox" [(ngModel)]="parameter.hidden"
                                        (ngModelChange)="handleParameterChanged(parameter)">
                                </td>
                                <td class="cell-center">
                                    <input type="checkbox" [(ngModel)]="parameter.required"
                                        (ngModelChange)="handleParameterChanged(parameter)">
                                </td>
                                <td class="cell-center cell-readonly">
                                    <input type="checkbox" [(ngModel)]="parameter.readOnly"
                                        (ngModelChange)="handleParameterChanged(parameter)">
                                </td>
                                <td class="cell-text">
                                    <ionic-selectable
                                        [disabled]="!usersOption || usersOption.length == 0 || parameter.territoryFE?.length > 0 || parameter.roleFE?.length > 0 "
                                        *ngIf="!parameter.defaultParameterFE" [(ngModel)]="parameter.userFE"
                                        (ngModelChange)="handleParameterChanged(parameter)" [items]="usersOption"
                                        itemValueField="idUtente" itemTextField="nominativo" [canSearch]="true"
                                        [isMultiple]="false" closeButtonText="{{'buttons.cancel' | translate}}"
                                        placeholder="{{'labels.select' | translate}}"
                                        searchFailText="{{'errors.6' | translate}}"
                                        searchPlaceholder="{{'labels.search' | translate}}" closeButtonSlot="end">
                                    </ionic-selectable>
                                </td>
                                <td class="cell-text">
                                    <div *ngIf="!parameter.defaultParameterFE">
                                        <button [style.opacity]="parameter.roleFE?.length > 0 ? '1' : '0.3'"
                                            [disabled]="!defaultRoleOption || defaultRoleOption.length == 0 || parameter.territoryFE?.length > 0 || parameter.userFE "
                                            class="select-roles" type="button"
                                            (click)="selectableTreePopoverRole(parameter)"><span
                                                *ngIf="!parameter.roleFE || parameter.roleFE.length == 0">{{'buttons.select'
                                                |
                                                translate}}</span>
                                            <span *ngIf="parameter.roleFE && parameter.roleFE.length > 0"><span
                                                    *ngFor="let role of parameter.roleFE; let i = index;"><span
                                                        *ngIf="i != 0">,&nbsp;</span>{{role.nome}}</span></span></button>
                                    </div>
                                </td>
                                <td class="cell-text">
                                    <div *ngIf="!parameter.defaultParameterFE">
                                        <button [style.opacity]="parameter.territoryFE?.length > 0 ? '1' : '0.3'"
                                            [disabled]="!defaultCustomerOption || defaultCustomerOption.length == 0 || parameter.userFE || parameter.roleFE?.length > 0 "
                                            class="select-customers" type="button"
                                            (click)="selectableTreePopoverCustomer(parameter)"><span
                                                *ngIf="!parameter.territoryFE || parameter.territoryFE.length == 0">{{'buttons.select'
                                                |
                                                translate}}</span>
                                            <span
                                                *ngIf="parameter.territoryFE && parameter.territoryFE.length > 0"><span
                                                    *ngFor="let customer of parameter.territoryFE; let i = index;"><span
                                                        *ngIf="i != 0">,&nbsp;</span>{{customer.denominazione}}</span></span></button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ion-content>
<ion-footer>
    <ion-row *ngIf="!crpFE">
        <ion-col size-md="6" size-sm="6" size-xs="6">
            <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' | translate}}
            </ion-label>
        </ion-col>
        <ion-col size-md="6" size-sm="6" size-xs="6" class="col-button-box">
            <div class="button-box">
                <ion-button color="success" size="default" [disabled]="!newCrpForm.valid" (click)="createCrp()">
                    <ion-label>{{'buttons.save' | translate}}</ion-label>
                </ion-button>
            </div>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="crpFE">
        <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
            <div class="button-box">
                <ion-button color="success" size="default" [disabled]="saveDisabled()" (click)="save()">
                    <ion-label>{{currentTabindex == 0?
                        ('labels.saveInstructions' | translate) : currentTabindex == 1 ?
                        ('labels.savePermissions' | translate) : ('labels.saveParameters' | translate)}}</ion-label>
                </ion-button>
            </div>
        </ion-col>
    </ion-row>
</ion-footer>