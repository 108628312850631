import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, IonButton, ModalController, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { PssfeDropdownPromptComponent } from './pssfe-dropdown-prompt/pssfe-dropdown-prompt.component';

@Component({
  selector: 'pssfe-dropdown',
  templateUrl: './pssfe-dropdown.component.html',
  styleUrls: ['./pssfe-dropdown.component.scss'],
})
export class PssfeDropdownComponent implements OnInit, OnChanges {
  //optionsList: lista delle opzioni per la selezione
  @Input() optionsList: Array<any>;
  //optionValue: proprietà di riferimento per il valore. Occorre quando le opzioni sono oggetti
  @Input() optionValue: string;
  //optionText: proprietà per testo da mostrare per le opzioni
  @Input() optionText: string;
  //multiple: abilita selezione multipla
  @Input() multiple: boolean;
  //searchEndpoint: endpoint per la ricerca lato server
  @Input() searchEndpoint: string;
  //searchPlaceholder: placeholder per casella ricerca
  @Input() searchPlaceholder: string;
  //value: valore per ngModel
  @Input() value: any;
  //placeholder: placeholder per input
  @Input() placeholder: string;
  //canClean: permette la pulizia della selezione
  @Input() canClean: boolean;
  //searchValue: valore della casella di ricerca
  @Input() searchValue: string;
  //readonly: sola lettura 
  @Input() readonly: boolean;
  //disabled: sola lettura e opacita'ridotta
  @Input() disabled: boolean;
  //noResultText: testo per nessun risultato
  @Input() noResultText: string;
  //inputId
  @Input() inputId: string;
  //valueChange: output per 2 way binding
  @Output() valueChange = new EventEmitter<any>();


  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public httpUtilitySrv: HttpUtilityService,
    public modalCtrl: ModalController) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.optionsList)
      this.optionsList = changes.optionsList.currentValue;
    if (changes && changes.optionValue)
      this.optionValue = changes.optionValue.currentValue;
    if (changes && changes.multiple)
      this.multiple = changes.multiple.currentValue;
    if (changes && changes.searchEndpoint)
      this.searchEndpoint = changes.searchEndpoint.currentValue;
    if (changes && changes.searchPlaceholder)
      this.searchPlaceholder = changes.searchPlaceholder.currentValue;
    if (changes && changes.value)
      this.value = changes.value.currentValue;
    if (changes && changes.placeholder)
      this.placeholder = changes.placeholder.currentValue;
    if (changes && changes.searchValue)
      this.searchValue = changes.searchValue.currentValue;
    if (changes && changes.optionText)
      this.optionText = changes.optionText.currentValue;
    if (changes && changes.readonly)
      this.readonly = changes.readonly.currentValue;
    if (changes && changes.disabled)
      this.disabled = changes.disabled.currentValue;
    if (changes && changes.noResultText)
      this.noResultText = changes.noResultText.currentValue;
    if (changes && changes.inputId)
      this.inputId = changes.inputId.currentValue;
  }

  ngOnInit() {

  }

  //openPssfeDropdown: apre il prompt di selezione
  async openPssfeDropdown() {
    /*let domButton = document.getElementById(this.inputId ? this.inputId : "pss-dropdown-button");
    if (domButton)
      event = { target: domButton }*/
    const popover = await this.modalCtrl.create({
      component: PssfeDropdownPromptComponent,
      cssClass: 'pssfe-dropdown-prompt',
      showBackdrop: true,
      componentProps: {
        optionsList: this.optionsList,
        optionValue: this.optionValue,
        optionText: this.optionText,
        multiple: this.multiple,
        searchEndpoint: this.searchEndpoint,
        searchPlaceholder: this.searchPlaceholder,
        value: this.value,
        placeholder: this.placeholder,
        canClean: this.canClean,
        searchValue: this.searchValue,
        noResultText: this.noResultText
      }
    });
    popover.onDidDismiss().then((res) => {
      if (res && res.data && res.data.updated) {
        if (this.multiple && res.data.value && res.data.value.length == 0)
          res.data.value = null;
        this.value = res.data.value;
        this.valueChange.emit(this.value);
      }
    })
    return await popover.present();
  }
}
