import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-modules-tree',
  templateUrl: './modules-tree.component.html',
  styleUrls: ['./modules-tree.component.scss'],
})
export class ModulesTreeComponent implements OnInit {
  //item
  @Input() item: any;
  //checkbox disabled
  @Input() checkboxDisabled: boolean = false;
  //handle checkbox
  @Output() handleCheckbox = new EventEmitter<any>();

  constructor(public translate: TranslateService,
    private languageSrv: LanguageService) {
    //setup input
    this.item = this.item ? this.item : null;
    this.checkboxDisabled = this.checkboxDisabled ? this.checkboxDisabled : false;
  }

  ngOnInit() {

  }

  //handleCheckboxParent
  public handleCheckboxParent(value, id, parendId) {
    this.handleCheckbox.emit({
      value: value,
      id: id,
      parentId: parendId
    })
  }

  //handleCheckboxChild
  public handleCheckboxChild(event) {
    this.handleCheckbox.emit(event)
  }

}
