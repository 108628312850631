import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private authSubject = new Subject<any>();


  constructor(public storage: Storage, private _platform: Platform) { }

  //publish emitter for auth variables change
  public publishAuthData(data: any) {
    this.authSubject.next(data);
  }

  //observable for auth variables change
  public getObservable(): Subject<any> {
    return this.authSubject;
  }

  //set auth token
  public setAuthObject(authObject) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      SecureStoragePlugin.set({ key: 'authObject', value: authObject ? JSON.stringify(authObject) : null });
      if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
        let token = authObject?.userData?.token;
        (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ token: token }, "*");
      }
    } else {
      this.storage.create();
      this.storage.set('authObject', authObject);
      this.storage.get('authObject').then((authObject) => {
        if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
          let token = authObject?.userData?.token;
          (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ token: token }, "*");
        }
      })
    }
  }
  public async setAuthObjectAsync(authObject) {
    if (this._platform.is('ios') || this._platform.is('android')) {
      await SecureStoragePlugin.set({ key: 'authObject', value: authObject ? JSON.stringify(authObject) : null });
      if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
        let token = authObject?.userData?.token;
        (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ token: token }, "*");
      }
    } else {
      await this.storage.create();
      await this.storage.set('authObject', authObject);
      let newAuthObject = await this.storage.get('authObject');
      if (Array.from(document.getElementsByClassName("micro-frontend-iframe"))[0]) {
        let token = newAuthObject?.userData?.token;
        (document.getElementsByClassName("micro-frontend-iframe")[0] as HTMLIFrameElement)?.contentWindow?.postMessage({ token: token }, "*");
      }
    }
  }

  //get auth token
  public getAuthObject() {
    return new Promise((resolve, reject) => {
      if (this._platform.is('ios') || this._platform.is('android')) {
        SecureStoragePlugin.get({ key: 'authObject' }).then((res) => {
          resolve(res.value ? JSON.parse(res.value) : null);
        }).catch((err) => {
          reject(null);
        });
      } else {
        this.storage.create().then(() => {
          this.storage.get('authObject').then((res) => {
            resolve(res);
          }).catch((err) => {
            reject(null);
          })
        }).catch(() => {
          reject(null);
        })
      }
    });
  }

  //set last role 
  public setLastRoleObject(lastRoleObject) {
    this.storage.set('lastRoleObject', lastRoleObject);
  }
  //get auth token
  public getLastRoleAndAuthObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.getAuthObject().then((authObject) => {
          this.storage.get('lastRoleObject').then((lastRoleObject) => {
            resolve({ authObject: authObject, lastRoleObject: lastRoleObject });
          }).catch((err) => {
            reject(null);
          })
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
}
