import { NgModule } from '@angular/core';
import { NewsDetailPopoverComponent } from './news-detail-popover.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        NewsDetailPopoverComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        NewsDetailPopoverComponent
    ]
})
export class NewsDetailPopoverComponentModule {}