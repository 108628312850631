<div class="postit-board-stats" *ngIf="mainLayout" [style.height]="mainLayout.statsHeight + 'px'"
    [style.min-height]="mainLayout.statsHeight + 'px'" [style.max-height]="mainLayout.statsHeight + 'px'">
    <div class="postit-board-stats-left" [style.width]="mainLayout.statsLeftWidth + 'px'"
        [style.min-width]="mainLayout.statsLeftWidth + 'px'" [style.max-width]="mainLayout.statsLeftWidth + 'px'">
        <!-- charts -->
        <div class="charts" *ngIf="statsCharts && statsCharts.length > 0">
            <div class="chart" *ngFor="let chart of statsCharts"
                [ngClass]="{'doughnut-chart': chart.type == 'doughnut', 'bar-chart': chart.type == 'bar'}">
                <!--<canvas baseChart [datasets]="chart.values" [labels]="chart.labels" [colors]="chart.colors"
                    [options]="chart.options" [legend]="false" [chartType]="chart.type">
                </canvas>-->
            </div>
        </div>
        <div draggable="true" class="board-resizer" (dragstart)="emitDragStart('statsLeftResizer', $event)"
            (dragend)="emitDragEnd($event)">
        </div>
    </div>
    <div class="postit-board-stats-right" [style.width]="mainLayout.statsRightWidth + 'px'"
        [style.min-width]="mainLayout.statsRightWidth + 'px'" [style.max-width]="mainLayout.statsRightWidth + 'px'">
        <div class="stats-header">
            {{'postitBoard.labels.currentActivitiesAt' | translate}}&nbsp;{{todayLabel | date:'dd/MM/yyyy'}}
        </div>
        <div class="table">
            <table mat-table [dataSource]="statsDataSource" matSort>
                <tr mat-header-row *matHeaderRowDef="statsDisplayedColumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: statsDisplayedColumns"></tr>
                <ng-container matColumnDef="resource">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.resource' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="td-center">
                        {{element.resource}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.type' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="table-sections" *ngIf="element.sections && element.sections.length > 0">
                            <div class="table-section type-section" *ngFor="let section of element.sections"
                                [style.background]="getColor(section.idTipoAttivita)"></div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.group' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="table-sections" *ngIf="element.sections && element.sections.length > 0">
                            <div class="table-section" *ngFor="let section of element.sections">
                                {{section.attivita ? section.attivita.gruppo : ''}}
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="table-sections" *ngIf="element.sections && element.sections.length > 0">
                            <div class="table-section" *ngFor="let section of element.sections">
                                {{section.attivita && section.attivita.attivita ? section.attivita.attivita+'('
                                +section.attivita.codice+ ')' : section.tipoAttivita}}
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="days">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.days' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="table-sections" *ngIf="element.sections && element.sections.length > 0">
                            <div class="table-section" *ngFor="let section of element.sections">
                                {{section.days}}
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'postitBoard.labels.status' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="table-sections" *ngIf="element.sections && element.sections.length > 0">
                            <div class="table-section" *ngFor="let section of element.sections">
                                {{section.status}}
                            </div>
                        </div>
                    </td>
                </ng-container>
            </table>
        </div>
    </div>
</div>