import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertController, IonContent, IonInput, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { tableIds } from 'src/app/shared-components/table/models/table-ids';
import { columnTypes, tableColumn } from 'src/app/shared-components/table/models/table-models';
import { TableComponent } from 'src/app/shared-components/table/table.component';
import { BoardMainService } from '../../services/board-main.service';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';

@Component({
  selector: 'app-postit-board-file-upload',
  templateUrl: './postit-board-file-upload.component.html',
  styleUrls: ['./postit-board-file-upload.component.scss'],
})
export class PostitBoardFileUploadComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //uploadForm
  public uploadForm: FormGroup;
  //base64
  public base64: string;
  //messageRecivedSub
  //private messageRecivedSub;
  //uploadLoading
  public uploadLoading: boolean = false;
  //uploadResponse
  public uploadResponse;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    private boardMainSrv: BoardMainService,
    private postitBoardUtilitySrv: PostitBoardUtilityService,
    private loaderSrv: LoaderService,
    private signalRSrv: SignalRService,
    private authSrv: AuthService) {
    //setup nav params
    this.modalNavParams =
    {

    }
    //setup validators
    this.uploadForm = this.formBuilder.group({
      file: [null],
      dateStart: [null]
    })
  }

  /*ngOnDestroy(): void {
    if (this.messageRecivedSub)
      this.messageRecivedSub.unsubscribe();
  }*/

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    //setup signalR
    /*this.authSrv.getAuthObject().then((res: any) => {
      this.messageRecivedSub = this.signalRSrv.SignalRecived.subscribe((signal: any) => {
        if (signal.user.includes(res.userData.idUtente)) {

        }
      })
    })*/
  }

  //fileChangeEvent
  public fileChangeEvent() {
    let file = (document.getElementById("fileInput") as HTMLInputElement).files[0];
    let reader = new FileReader();
    let context = this;
    reader.onload = function () {
      context.base64 = reader.result as string;
    };
    reader.onerror = function (event) {
    };
    reader.readAsDataURL(file);
  }

  //upload
  public upload() {
    this.uploadLoading = true;
    let formdata = new FormData();
    let file = (document.getElementById("fileInput") as HTMLInputElement).files[0];
    formdata.append("FileUploaded", file);
    formdata.append("DataInizio", this.uploadForm.get("dateStart").value);
    this.boardMainSrv.uploadPhoto(formdata).then((res: any) => {
      this.uploadLoading = false;
      if (res) {
        this.uploadResponse = res;
        setTimeout(() => {
          let previewTitle = document.getElementById("preview-title");
          if (previewTitle)
            previewTitle.scrollIntoView({ behavior: 'smooth' });
        }, 500)
      } else {
        this.alertSrv.errorAlert("");
      }
    }).catch((err) => {
      this.uploadLoading = false;
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //confirm
  public confirm() {
    this.loaderSrv.open();
    this.boardMainSrv.confirm(this.uploadResponse.codiceAcquisizione, this.uploadResponse.dataMin, this.uploadResponse.dataMax).then((res: any) => {
      this.loaderSrv.dismiss();
      this.modalCtrl.dismiss({ dataMin: this.uploadResponse.dataMin, dataMax: this.uploadResponse.dataMax, risorse: this.uploadResponse.risorse })
    }).catch((err) => {
      this.loaderSrv.dismiss();
      this.uploadLoading = false;
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //save
  public save() {
    this.modalCtrl.dismiss();
  }
}
