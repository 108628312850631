import { NgModule } from '@angular/core';
import { TableTdComponent } from './table-td.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatPipeModule } from 'src/app/pipes/date-format/date-format.pipe.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableNumeroPipeModule } from '../pipes/table-numero/table-numero.pipe.module';
import { TableDecimalePipeModule } from '../pipes/table-decimale/table-decimale.pipe.module';
import { getDateFormat, getDateTimeFormat} from 'src/app/services/app.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFormatPlaceholderPipeModule } from 'src/app/pipes/date-format-placeholder-pipe/date-format-placeholder.pipe.module';
import { NumberSeparatorPipePipeModule } from 'src/app/pipes/number-separator-pipe/number-separator.pipe.module';
@NgModule({
    declarations: [
        TableTdComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
DateFormatPipeModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        DragDropModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
        TableNumeroPipeModule,
        TableDecimalePipeModule,
        DateFormatPlaceholderPipeModule,
        NumberSeparatorPipePipeModule
    ],
    exports: [
        TableTdComponent
    ],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat()},
        {
            provide: DateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: getDateFormat() },
    ]
})
export class TableTdComponentModule { }