import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController, Platform, ViewWillLeave } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';


@Component({
  selector: 'app-webview-modal',
  templateUrl: './webview-modal.component.html',
  styleUrls: ['./webview-modal.component.scss'],
})
export class WebviewModalComponent implements OnInit, ViewWillLeave {

  //link
  public link = null;
  //router event
  public routerEvt;

  constructor(public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navParams: NavParams,
    public translate: TranslateService,
    private languageSrv: LanguageService,
    public platform: Platform,
    private router: Router) {
    //setup link
    this.link = this.navParams.get("link");
    //observable for router change
    this.routerEvt = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.modalCtrl.dismiss();
      }
    });
  }

  ionViewWillLeave() {
    if (this.routerEvt)
      this.routerEvt.unsubscribe();
  }

  ngOnInit() { }

  //dismiss modal
  async modalDismiss() {
    const alert = await this.alertCtrl.create({
            cssClass: 'alert-service',
      header: this.translate.instant('labels.close_web'),
      message: this.translate.instant('messages.close_web_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.modalCtrl.dismiss();
          }
        }
      ]
    });

    await alert.present()
  }

}
