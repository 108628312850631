import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ServiceAccessConfigurationTemplateComponent } from '../../modals_popsover/service-access-configuration-template/service-access-configuration-template.component'
import { ServiceAccessConfigurationService } from '../../services/service-sccess-configuration.service'
@Component({
  selector: 'app-service-access-configuration',
  templateUrl: './service-access-configuration.component.html',
  styleUrls: ['./service-access-configuration.component.scss'],
})
export class ServiceAccessConfigurationComponent implements OnInit {
  //configuration type ("user" || "role")
  @Input() configurationType;
  //service list
  @Input() servicesList = [];
  //current value (used for old value detection)
  public clickedValue;
  //currentTemplateOption
  public currentTemplateOption;
  //templateOption
  public templateOption = [];
  //templateListLoading
  public templateListLoading = false;
  //currentKeyValueListProperty
  public currentKeyValueListProperty;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public popoverCtrl: PopoverController,
    public loadingSrv: LoaderService,
    public ServiceAccessConfigurationSrv: ServiceAccessConfigurationService) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configurationType) {
      this.configurationType = changes.configurationType.currentValue;
      this.currentKeyValueListProperty = this.configurationType == 'role' ? "listaChiaviValoreRuolo" : "listaChiaviValoreUtente";
    }

    if (changes.servicesList && this.servicesList && this.servicesList.length > 0 && this.configurationType == 'role')
      this.getListaTemplateServiziSafo(null, this.servicesList[0].id);
  }

  //add config
  public addConfig(service) {
    if (service[this.currentKeyValueListProperty])
      service[this.currentKeyValueListProperty].push({ chiave: null, valore: null, sovrascrivibileDaUtente: false });
    else {
      service[this.currentKeyValueListProperty] = [{ chiave: null, valore: null, sovrascrivibileDaUtente: false }];
    }
  }
  //remove config
  public removeConfig(service, index) {
    service[this.currentKeyValueListProperty].splice(index, 1);
  }
  //openAccessConfigurationTemplate
  async openAccessConfigurationTemplate(serviceId, moduleId) {
    const modal = await this.popoverCtrl.create({
      component: ServiceAccessConfigurationTemplateComponent,
      cssClass: 'service-access-configuration-template',
      componentProps: { serviceId: serviceId, moduleId: moduleId, servicesList: this.servicesList }
    });
    modal.onDidDismiss()
      .then(() => {
        this.getListaTemplateServiziSafo(null, serviceId);
      });
    return await modal.present();
  }

  //getListaTemplateServiziSafo
  public getListaTemplateServiziSafo(event, id?) {
    if (this.configurationType == 'role') {
      this.templateListLoading = true;
      this.ServiceAccessConfigurationSrv.getListaTemplateServiziSafo(
        id ? id : this.servicesList[event.index].id
      ).then((res: any) => {
        if (res.code == 200 || res.code == 6) {
          this.currentTemplateOption = null;
          this.templateOption = res.payload;
        } else {
          this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
        }
        this.templateListLoading = false;
      }).catch((err) => {

        this.templateListLoading = false;
      })
    }
  }

  //dettaglioTemplateServiziSafo
  public dettaglioTemplateServiziSafo(service, templateId) {
    this.loadingSrv.open();
    this.ServiceAccessConfigurationSrv.dettaglioTemplateServiziSafo(
      templateId
    ).then((res: any) => {
      if (res.code == 200) {
        if (res.payload && res.payload.valori) {
          res.payload.valori.forEach((item) => {
            item['sovrascrivibileDaUtente'] = false;
          });
        }
        service[this.currentKeyValueListProperty] = res.payload.valori;
        this.loadingSrv.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loadingSrv.dismiss();

    })
  }
}
