import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ViewAndFineLinkService } from './vaf-link.service';
import { VafUtilitySrv } from './vaf-utility.service';

@Injectable({
  providedIn: 'root'
})
export class VafAuthorityService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient, public translate: TranslateService, public linkSrv: ViewAndFineLinkService,
    private vafUtilitySrv: VafUtilitySrv) { }

  //getAuthorityDetails
  public getAuthorityDetails() {
    return new Promise((resolve, reject) => {
      //`${this.linkSrv.links.server}DataEntry/GetDataEntryList`
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/Authority/GetAuthorityDetails`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //updateCustomerCodes
  public updateCustomerCodes(customerList, idAuthority?) {
    return new Promise((resolve, reject) => {
      //`${this.linkSrv.links.server}DataEntry/GetDataEntryList` /ViewAndFine​/BE​/BackOffice​/UpdateCustomerCodes?idAuthority=
      let url = idAuthority ? "ViewAndFine/BE/BackOffice/UpdateCustomerCodes?idAuthority=" + idAuthority : "ViewAndFine/BE/Authority/UpdateCustomerCodes"
      this.http.post(`${this.linkSrv.links.server + url}`, customerList).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //createAuthority
  public createAuthority(issuingAuthorities, name, fiscalCode, country, address, place, city, province, zipCode, referentName, referentSurname, referentEmail, referentPhone) {

    return new Promise((resolve, reject) => {
      //`${this.linkSrv.links.server}DataEntry/GetDataEntryList`
      this.http.post(`${this.linkSrv.links.server}ViewAndFine/BE/External/CreateAuthority`, {
        issuingAuthorities: issuingAuthorities,
        name: name,
        fiscalCode: fiscalCode,
        country: country,
        address: address,
        place: place,
        city: city,
        province: province,
        zipCode: zipCode,
        referentName: referentName,
        referentSurname: referentSurname,
        referentEmail: referentEmail,
        referentPhone: referentPhone
      }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //checkAuthorityExistence
  public checkAuthorityExistence(fiscalCode) {
    return new Promise((resolve, reject) => {
      //`${this.linkSrv.links.server}DataEntry/GetDataEntryList`
      this.http.get(`${this.linkSrv.links.server}ViewAndFine/BE/External/CheckAuthorityExistence?fiscalCode=${fiscalCode}`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

}
