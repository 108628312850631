import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service.service';
import { Router } from '@angular/router';
import { StorageVariablesService } from './storage-variables.service';

@Injectable({
  providedIn: 'root'
})
export class SectionService {
  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient, public authSrv: AuthService,
    public router: Router, public storaveVariableSrv: StorageVariablesService) { }

  //get sezioni api request
  public getSezioni(sectionId: string) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        let payload = {
          "idRuolo": headers.role.id,
          "idModulo": sectionId,
        }
        this.http.post(`${this.httpUtilitySrv.links.server}getSezioni`, payload).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }

  //logout 
  public logout() {
    this.authSrv.setAuthObject(null);
    this.storaveVariableSrv.setAdminImpersGuidObject(null);
    this.router.navigateByUrl('/login');
  }

  /*
    Menu (updated 31/08/2020)
    HOME home
    -"home.badge_da_integrare"
    -"badge_da_integrare"

    HISTORY SEARCH history_search
    -"history_search.history_search_function"

    PLATE SEARCH plate_search
    -"plate_search.plate_search_function"
    -"plate_search.plate_search_function.integration_create"

    NOMINATIVE_SEARCH nominative_search
    -"nominative_search.nominative_search_function"
    -"nominative_search.nominative_search_function.integration_create"

    HISTORY SEARCH history_search
    -"nominative_search.nominative_search_function.integration_create"

    DATA INTEGRATION SEARCH data_integration_search
    -"data_integration_search.data_integration_search_function"
    -"data_integration_search.data_integration_search_function.cancel_request"
    -"data_integration_search.data_integration_search_function.close_request"
    -"data_integration_search.data_integration_search_function.reopen_request"

  */
}
