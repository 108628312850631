import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PostitBoardUtilityService } from '../../services/postit-board-utility.service';
import { BoardMainService } from '../../services/board-main.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PostitBoardToolbarComponent } from '../../components/postit-board-toolbar/postit-board-toolbar.component';
import { PostitBoardBoardComponent } from '../../components/postit-board-board/postit-board-board.component';
import { AppService } from 'src/app/services/app.service';


@Component({
  selector: 'app-postit-board-main',
  templateUrl: './postit-board-main.page.html',
  styleUrls: ['./postit-board-main.page.scss'],
})
export class PostitBoardMainPage implements OnInit, AfterViewInit, OnDestroy {
  //router sub
  public routerSub;
  //main layout
  public mainLayout: MainLayout;
  //current drag
  public currentDrag: CurrentDrag;
  @ViewChild('boardComponent') boardComponent: PostitBoardBoardComponent;
  //toolbar
  @ViewChild('toolbarComponent') toolbarComponent: PostitBoardToolbarComponent;
  //fullscreenSub
  private fullscreenSub;

  constructor(
    private loaderSrv: LoaderService,
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public activatedRoute: ActivatedRoute,
    private boardMainSrv: BoardMainService,
    private postitBoardUtilitySrv: PostitBoardUtilityService,
    private appSrv: AppService) {
    this.mainLayout = {
      boardHeight: 0,
      statsHeight: 0,
      statsLeftWidth: 0,
      statsRightWidth: 0,
      toolbarHeight: 30,
      resourceMode: {
        resource: null
      },
      daysHeader: [],
      board: null,
      activitiesTypesList: []
    }
    this.currentDrag = {
      target: null,
      startX: null,
      startY: null
    }
  }
  ngOnDestroy(): void {
    if (this.routerSub)
      this.routerSub.unsubscribe();
    if (this.fullscreenSub)
      this.fullscreenSub.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url && event.url.indexOf("/admin-postit-board-main") != -1) {
          this.fullscreenSub = this.appSrv.fullScreen.subscribe((fullScreen) => {
            setTimeout(() => {
              this.updateMainLayout('DEFAULT', this.mainLayout);
            }, 500)
          })
          this.setupMainLayout();
        }
      }
    })
  }

  ngOnInit() {
  }

  //--- MAIN LAOUT MANAGEMENT ---//
  private setupMainLayout() {
    let newMainLayout = JSON.parse(JSON.stringify(this.mainLayout));
    setTimeout(() => {
      let page = document.getElementById("postit-board-main-page-content");
      if (page) {
        let pageHeight = page.getBoundingClientRect().height != 0 ? page.getBoundingClientRect().height : window.innerHeight;
        let pageWidth = page.getBoundingClientRect().width != 0 ? page.getBoundingClientRect().width : window.innerWidth;
        newMainLayout.boardHeight = 500;
        newMainLayout.statsHeight = pageHeight - newMainLayout.boardHeight - newMainLayout.toolbarHeight;
        newMainLayout.statsLeftWidth = pageWidth / 3;
        newMainLayout.statsRightWidth = pageWidth - newMainLayout.statsLeftWidth;
        this.mainLayout = newMainLayout;
        this.getActivitiesTypesList();
      }
    }, 500);
  }
  private updateMainLayout(action: string, newMainLayout: MainLayout) {//'DEFAULT' || 'BOARD_HEIGHT'
    let page = document.getElementById("postit-board-main-page-content");
    if (page) {
      switch (action) {
        case 'BOARD_HEIGHT':
          if (newMainLayout.boardHeight < (page.getBoundingClientRect().height - 100)) {
            newMainLayout.boardHeight = newMainLayout.boardHeight;
          } else {
            newMainLayout.boardHeight = page.getBoundingClientRect().height - 100;
          }
          break;
        case 'DEFAULT':
        default:
          break;
      }
      let pageHeight = page.getBoundingClientRect().height;
      let pageWidth = page.getBoundingClientRect().width;
      newMainLayout.statsHeight = pageHeight - newMainLayout.boardHeight - newMainLayout.toolbarHeight;
      newMainLayout.statsRightWidth = pageWidth - newMainLayout.statsLeftWidth;
      this.mainLayout = newMainLayout;
      if (action == 'SETUP') {
        setTimeout(() => {
          this.setBoardHeightByRows(newMainLayout);
        }, 1000)
      }
    }
  }
  private setBoardHeightByRows(newMainLayout: MainLayout) {
    if (newMainLayout && newMainLayout.board && newMainLayout.board.risorse.length > 0) {
      let boardInner = document.getElementById('board-inner');
      if (boardInner) {
        newMainLayout.boardHeight = boardInner.getBoundingClientRect().height + 33;//33=8bottom + 8top + 17scrollbar
        this.updateMainLayout('BOARD_HEIGHT', newMainLayout);
      }
    }
  }
  public updateMainLayoutChild(event: { action: string, mainLayout: MainLayout }) {
    this.updateMainLayout(event.action, event.mainLayout);
  }
  //--- END MAIN LAOUT MANAGEMENT ---//

  //--- DRAG AND DROP ---//
  //dragstart
  public dragStart(ev) {
    this.currentDrag.target = ev.target;
    this.currentDrag.startX = ev.event.pageX;
    this.currentDrag.startY = ev.event.pageY;
    ev.event.dataTransfer.setData("text", ev.event.target.id);
  }
  //dragEnd
  public dragEnd(ev: any) {
    let containerTarget, newMainLayout: MainLayout = JSON.parse(JSON.stringify(this.mainLayout));
    switch (this.currentDrag.target) {
      case 'boardResizer':
        containerTarget = document.getElementById("board");
        if (containerTarget) {
          newMainLayout.boardHeight = ev.pageY - containerTarget.getBoundingClientRect().top;
          this.updateMainLayout('DEFAULT', newMainLayout);
        }
        break;
      case 'statsLeftResizer':
        containerTarget = document.getElementById("stats");
        if (containerTarget) {
          newMainLayout.statsLeftWidth = ev.pageX - containerTarget.getBoundingClientRect().left;
          this.updateMainLayout('DEFAULT', newMainLayout);
        }
        break;
    }
  }
  //--- END DRAG AND DROP ---//

  //--- CONFIGURATION ---//
  public getActivitiesTypesList() {
    this.loaderSrv.open();
    this.boardMainSrv.listaTipoAttivita().then((res: any) => {
      this.loaderSrv.dismiss();
      let newMainLayout: MainLayout = JSON.parse(JSON.stringify(this.mainLayout));
      newMainLayout.activitiesTypesList = res;
      let today = new Date();
      let dateStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let dateEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let dateFrom = dateStart.getFullYear() + "-" + (dateStart.getMonth() + 1 < 10 ? "0" + (dateStart.getMonth() + 1) : dateStart.getMonth() + 1) + "-" + (dateStart.getDate() < 10 ? "0" + dateStart.getDate() : dateStart.getDate());
      let dateTo = dateEnd.getFullYear() + "-" + (dateEnd.getMonth() + 1 < 10 ? "0" + (dateEnd.getMonth() + 1) : dateEnd.getMonth() + 1) + "-" + (dateEnd.getDate() < 10 ? "0" + dateEnd.getDate() : dateEnd.getDate());
      this.toolbarComponent.dateFrom = dateFrom;
      this.toolbarComponent.dateTo = dateTo;
      this.getBoard({ dateFrom: dateFrom, dateTo: dateTo, newMainLayout: newMainLayout });
    }).catch((err: any) => {
      this.loaderSrv.dismiss();
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }
  //--- END CONFIGURATION ---//

  //--- BOARD ---//
  public getBoard(event: { dateFrom: string, dateTo: string, newMainLayout?: MainLayout }) {
    this.loaderSrv.open();
    this.boardMainSrv.board(event.dateFrom, event.dateTo).then((res: any) => {
      this.loaderSrv.dismiss();
      event.newMainLayout = event.newMainLayout ? event.newMainLayout : JSON.parse(JSON.stringify(this.mainLayout));
      event.newMainLayout.board = JSON.parse(JSON.stringify(res))
      this.addCompactDays(event.newMainLayout);
      //this.undoResourceMode(true);
    }).catch((err: any) => {
      this.loaderSrv.dismiss();
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.postitBoardUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }
  private addCompactDays(newMainLayout: MainLayout) {
    let compactDays = [];
    if (newMainLayout.board && newMainLayout.board.risorse && newMainLayout.board.risorse.length > 0) {
      newMainLayout.board.risorse.forEach((resource) => {
        compactDays = [];
        //check for odd days
        for (let i = 0; i < resource.giorni.length; i += 2) {
          if (resource.giorni[i + 1]) {
            if (resource.giorni[i].data != resource.giorni[i + 1].data) {
              resource.giorni.splice(i, 0, { data: resource.giorni[i].data });
            }
          } else {
            resource.giorni.push({ data: resource.giorni[i].data })
          }
          let color1 = newMainLayout.activitiesTypesList.filter((x) => { return x.idTipoAttivita == resource.giorni[i].idTipoAttivita })[0];
          resource.giorni[i]['color'] = color1 ? color1.colore : "#fff";
          let color2 = newMainLayout.activitiesTypesList.filter((x) => { return x.idTipoAttivita == resource.giorni[i + 1].idTipoAttivita })[0];
          resource.giorni[i + 1]['color'] = color2 ? color2.colore : "#fff";
          compactDays.push([resource.giorni[i], resource.giorni[i + 1]])
        }
        resource["compactDays"] = compactDays;
      });
    }
    this.getDaysHeader(newMainLayout);
  }
  private getDaysHeader(newMainLayout: MainLayout) {
    let date: Date;
    newMainLayout.daysHeader = [];
    if (newMainLayout.board && newMainLayout.board.risorse && newMainLayout.board.risorse[0] && newMainLayout.board.risorse[0].giorni) {
      for (let i = 0; i < newMainLayout.board.risorse[0].giorni.length; i += 2) {
        date = new Date(newMainLayout.board.risorse[0].giorni[i].data);
        newMainLayout.daysHeader.push(date.getDate() + " " + this.translate.instant('postitBoard.months.' + (date.getMonth() + 1)));
      }
      setTimeout(() => {
        //check for query params
        let queryParams = this.appSrv.getQueryParams();
        if (queryParams.action == 'RESOURCE' && queryParams.target && !newMainLayout.resourceMode.resource) {
          let resourceTarget = newMainLayout.board.risorse.filter((x) => { return x.idx == queryParams.target })[0];
          if (resourceTarget)
            this.enterResourceMode({ resource: resourceTarget, newMainLayout: newMainLayout });
          else
            this.updateMainLayout('SETUP', newMainLayout);
        } else {
          this.updateMainLayout('SETUP', newMainLayout);
        }
      }, 500)
    }
  }

  public enterResourceMode(event: { resource, newMainLayout: MainLayout }) {
    event.newMainLayout = event.newMainLayout ? event.newMainLayout : JSON.parse(JSON.stringify(this.mainLayout));
    let compactDaysResourceMode = [];
    for (let i = 0; i < event.newMainLayout.daysHeader.length; i++) {
      compactDaysResourceMode.push({ date: event.newMainLayout.daysHeader[i], sections: event.resource.compactDays[i] });
    }
    event.resource["compactDaysResourceMode"] = compactDaysResourceMode;
    event.newMainLayout.resourceMode.resource = event.resource;
    setTimeout(() => {
      this.updateMainLayout('SETUP', event.newMainLayout);
    }, 500)
  }
  public undoResourceModeBoard(newMainLayout: MainLayout, skipResize?: boolean) {
    newMainLayout = newMainLayout ? newMainLayout : JSON.parse(JSON.stringify(this.mainLayout));
    newMainLayout.resourceMode.resource = null;
    if (!skipResize) {
      setTimeout(() => {
        this.updateMainLayout('SETUP', newMainLayout);
      }, 500)
    }
  }
  //--- END BOARD ---//

  //--- TOOLBAR ---//
  public undoResourceMode(skipResize?: boolean) {
    this.undoResourceModeBoard(null, skipResize);
  }

  public uploadPhoto(event) {
    if (event && event.dataMin && event.dataMax && event.risorse) {
      event.newMainLayout = JSON.parse(JSON.stringify(this.mainLayout));
      event.newMainLayout.board = { risorse: event.risorse };
      this.addCompactDays(event.newMainLayout);
    }
  }
  //---END TOOLBAR ---//
}

export interface MainLayout {
  boardHeight: number,
  statsHeight: number,
  statsLeftWidth: number,
  statsRightWidth: number,
  toolbarHeight: number,
  resourceMode: resourceMode,
  daysHeader: Array<string>,
  board: any,
  activitiesTypesList: Array<any>
}

export interface resourceMode {
  resource: any
}
export interface CurrentDrag {
  target: string,
  startX: number,
  startY: number
}

