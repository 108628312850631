<!-- authority -->
<mat-expansion-panel [expanded]="true" class="vaf-registration-exp">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ion-icon name="square-outline" [hidden]="authorityForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            <ion-icon name="checkbox-outline" [hidden]="!authorityForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            {{'viewAndFine.labels.autorita' | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="authorityForm">
        <ion-row>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.name' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'name')">*</span></ion-label>
                    <ion-input type="text" formControlName="name"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.fiscalCode' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'fiscalCode')">*</span></ion-label>
                    <ion-input type="text" formControlName="fiscalCode"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}"
                        [readonly]="readonly || fromExternalRegistration">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.country' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'country')">*</span></ion-label>
                    <ion-select formControlName="country" multiple="false"
                        [cancelText]="'viewAndFine.buttons.cancel' | translate"
                        [okText]="'viewAndFine.buttons.select' | translate"
                        placeholder="{{'viewAndFine.labels.select' | translate}}"
                        [ngClass]="{'selectable-readonly': readonly || !countriesOptions || countriesOptions.length == 0}">
                        <ion-select-option *ngFor="let option of countriesOptions" [value]="option.id">
                            {{option.codeOrRef}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.dateOfInsert' | translate}}</ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(dateOfInsert)"
                            placeholder="{{'viewAndFine.labels.dateOfInsert' | translate}}"
                            formControlName="dateOfInsert" class="input-mat-datetime" [disabled]="readonly">
                        <mat-datepicker-toggle matSuffix [for]="$any(dateOfInsert)" [hidden]="readonly">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #dateOfInsert [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.address' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'address')">*</span></ion-label>
                    <ion-input type="text" formControlName="address"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.place' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'place')">*</span></ion-label>
                    <ion-input type="text" formControlName="place"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.city' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'city')">*</span></ion-label>
                    <ion-input type="text" formControlName="city"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.province' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'province')">*</span></ion-label>
                    <ion-input type="text" formControlName="province"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.zipCode' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'zipCode')">*</span></ion-label>
                    <ion-input type="text" formControlName="zipCode"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.code' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(authorityForm, 'code')">*</span></ion-label>
                    <ion-input type="text" formControlName="code"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.enabledChannels' | translate}}</ion-label>
                    <ion-select multiple="true" formControlName="enabledChannels"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}"
                        [ngClass]="{'selectable-readonly': readonly || !enabledChannelsOptions || enabledChannelsOptions.length == 0}">
                        <ion-select-option *ngFor="let option of enabledChannelsOptions" [value]="option.id">
                            {{option.description}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
    </form>
</mat-expansion-panel>

<!-- referent -->
<mat-expansion-panel [expanded]="true" class="vaf-registration-exp">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ion-icon name="square-outline" [hidden]="referentForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            <ion-icon name="checkbox-outline" [hidden]="!referentForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            {{'viewAndFine.labels.referent' | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="referentForm">
        <ion-row>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.nome' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(referentForm, 'referentName')">*</span></ion-label>
                    <ion-input type="text" formControlName="referentName"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.cognome' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(referentForm, 'referentSurname')">*</span>
                    </ion-label>
                    <ion-input type="text" formControlName="referentSurname"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.email' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(referentForm, 'referentEmail')">*</span></ion-label>
                    <ion-input type="text" formControlName="referentEmail"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.phone' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(referentForm, 'referentPhone')">*</span></ion-label>
                    <ion-input type="text" formControlName="referentPhone"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </form>
</mat-expansion-panel>

<!-- enti -->
<mat-expansion-panel [expanded]="true" class="vaf-registration-exp">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ion-icon name="square-outline" [hidden]="isIssuingAuthoritiesValid() || hideHeaderCheckbox" color="light">
            </ion-icon>
            <ion-icon name="checkbox-outline" [hidden]="!isIssuingAuthoritiesValid() || hideHeaderCheckbox"
                color="light">
            </ion-icon>
            {{'viewAndFine.labels.issuingAuthorityCodes' | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="entity-list">
        <ion-row class="entity-list-item" *ngFor="let ente of issuingAuthorities; let enteI = index"
            [ngClass]="{'entity-error': ente.errore}">
            <ion-col [attr.size-md]="fromExternalRegistration ? '6' : '3'"
                [attr.size-sm]="fromExternalRegistration ? '6' : '3'" [attr.size-xs]="'12'">
                <ion-item class="input-field" lines="none" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.nome' | translate}}<span class="required-field"
                            [hidden]="readonly && !editPostITApprove">*</span></ion-label>
                    <ion-input type="text" placeholder="{{'viewAndFine.labels.insert' | translate}}"
                        [(ngModel)]="ente.description" [readonly]="(readonly || sameAuthorityData) && !editPostITApprove"
                        (focusout)="checkIssuingAuthoritiesValid()">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col [attr.size-md]="fromExternalRegistration ? '5' : '2'"
                [attr.size-sm]="fromExternalRegistration ? '5' : '2'" [attr.size-xs]="'12'">
                <ion-item class="input-field" lines="none" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.code' | translate}}<span class="required-field"
                            [hidden]="readonly && !editPostITApprove">*</span></ion-label>
                    <ion-input type="text" placeholder="{{'viewAndFine.labels.insert' | translate}}"
                        [(ngModel)]="ente.code" [readonly]="(readonly || sameAuthorityData) && !editPostITApprove" (focusout)="checkIssuingAuthoritiesValid()">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col [attr.size-md]="fromExternalRegistration ? '0' : '3'"
                [attr.size-sm]="fromExternalRegistration ? '0' : '3'" [attr.size-xs]="'12'"
                [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.services' | translate}}<span
                            class="required-field" [hidden]="readonly && !editPostITApprove">*</span></ion-label>
                    <ion-select [(ngModel)]="ente.serviceTypeId" [cancelText]="'buttons.cancel' | translate"
                        [okText]="'buttons.ok' | translate" placeholder="{{'labels.select' | translate}}"
                        multiple="false" [ngClass]="{'selectable-readonly': (readonly || sameAuthorityData)  && !editPostITApprove}" interface="popover">
                        <ion-select-option *ngFor="let option of issuingAuthoritiesServiceTypesOptions"
                            [value]="option.id">
                            {{option.codeOrRef}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col [attr.size-md]="fromExternalRegistration ? '0' : '3'"
                [attr.size-sm]="fromExternalRegistration ? '0' : '3'" [attr.size-xs]="'12'"
                [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.enteManagerMulteId' | translate}}<span
                            class="required-field" [hidden]="readonly && !editPostITApprove">*</span></ion-label>
                    <ion-input type="text" placeholder="{{'viewAndFine.labels.insert' | translate}}"
                        [(ngModel)]="ente.enteManagerMulteId" [readonly]="(readonly || sameAuthorityData)  && !editPostITApprove"
                        (focusout)="checkIssuingAuthoritiesValid()">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="1" size-sm="1" size-xs="12" *ngIf="issuingAuthorities.length > 1">
                <div class="delete-entity">
                    <ion-icon name="close" color="danger" class="cursor-pointer align-self-center" slot="end"
                        (click)="deleteEntity(enteI)" [hidden]="(readonly || sameAuthorityData)  && !editPostITApprove">
                    </ion-icon>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!readonly || editPostITApprove">
            <ion-col size-md="11" size-sm="11" size-xs="11">
                <div class="same-authority-data" [ngClass]="{'same-authority-disabled': !authorityForm.valid}"
                    *ngIf="fromExternalRegistration">
                    <input type="checkbox" [(ngModel)]="sameAuthorityData" (ngModelChange)="sameAuthorityDataChanged()">
                    <span>{{'viewAndFine.labels.sameAuthorityData' | translate}}</span>
                </div>
            </ion-col>
            <ion-col size-md="1" size-sm="1" size-xs="1">
                <div class="delete-entity" *ngIf="!sameAuthorityData">
                    <ion-icon name="add" color="success" class="cursor-pointer mt-10" (click)="addIssuingAuthority()">
                    </ion-icon>
                </div>
            </ion-col>
        </ion-row>
    </div>
</mat-expansion-panel>

<!-- contract -->
<mat-expansion-panel [expanded]="true" class="vaf-registration-exp" [hidden]="fromExternalRegistration">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ion-icon name="square-outline" [hidden]="contractForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            <ion-icon name="checkbox-outline" [hidden]="!contractForm.valid || hideHeaderCheckbox" color="light">
            </ion-icon>
            {{'viewAndFine.labels.contratto' | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="contractForm">
        <ion-row *ngIf="contractSuspended">
            <div class="suspended-message">
                <ion-icon name="warning"></ion-icon>{{'viewAndFine.messages.contractSuspended' | translate}}
            </div>
        </ion-row>
        <ion-row>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.contractNumber' | translate}}</ion-label>
                    <ion-input type="text" formControlName="contractNumber"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="true">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.contractDate' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(contractForm, 'contractDate')">*</span></ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(contractDate)"
                            placeholder="{{'viewAndFine.labels.contractDate' | translate}}"
                            formControlName="contractDate" class="input-mat-datetime" [disabled]="true">
                        <mat-datepicker-toggle matSuffix [for]="$any(contractDate)" [hidden]="true">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #contractDate [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.dateOfInsert' | translate}}</ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(dateOfInsertContract)"
                            placeholder="{{'viewAndFine.labels.dateOfInsert' | translate}}"
                            formControlName="dateOfInsert" class="input-mat-datetime" [disabled]="true">
                        <mat-datepicker-toggle matSuffix [for]="$any(dateOfInsertContract)" [hidden]="true">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #dateOfInsertContract [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.contractStart' | translate}}<span
                            class="required-field"
                            [hidden]="readonly || !isFieldRequired(contractForm, 'contractStart')">*</span></ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(contractStart)"
                            placeholder="{{'viewAndFine.labels.contractStart' | translate}}"
                            formControlName="contractStart" class="input-mat-datetime" [disabled]="readonly">
                        <mat-datepicker-toggle matSuffix [for]="$any(contractStart)" [hidden]="readonly">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #contractStart [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.contractEnd' | translate}}<span
                            class="required-field"
                            [hidden]="(readonly || !isFieldRequired(contractForm, 'contractEnd')) && !editPostITApprove">*</span></ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(contractEnd)"
                            placeholder="{{'viewAndFine.labels.contractEnd' | translate}}" formControlName="contractEnd"
                            class="input-mat-datetime" [disabled]="readonly && !editPostITApprove">
                        <mat-datepicker-toggle matSuffix [for]="$any(contractEnd)" [hidden]="readonly && !editPostITApprove">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #contractEnd [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">
                        {{'viewAndFine.labels.contractSigned' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(contractForm, 'contractSigned')">*</span></ion-label>
                    <ion-select formControlName="contractSigned" [cancelText]="'buttons.cancel' | translate"
                        interface="popover" [okText]="'buttons.ok' | translate"
                        placeholder="{{'labels.select' | translate}}" multiple="false"
                        [ngClass]="{'selectable-readonly': readonly}">
                        <ion-select-option [value]="1">{{'viewAndFine.labels.notDefined' | translate}}
                        </ion-select-option>
                        <ion-select-option [value]="2">{{'viewAndFine.labels.yes' | translate}}</ion-select-option>
                        <ion-select-option [value]="3">{{'viewAndFine.labels.no' | translate}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="fromExternalRegistration">
                <ion-item class="input-field" lines="none" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.contractStatus' | translate}}</ion-label>
                    <ion-select formControlName="contractStatusId" [cancelText]="'buttons.cancel' | translate"
                        [okText]="'buttons.ok' | translate" placeholder="{{'labels.select' | translate}}"
                        multiple="false" [ngClass]="{'selectable-readonly': true}">
                        <ion-select-option *ngFor="let option of contractStatusIdOptions" [value]="option.id">
                            {{'viewAndFine.options.contractStatus.'+option.id | translate}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!contractForm.get('userBO').value">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.userBO' | translate}}</ion-label>
                    <ion-input type="text" formControlName="userBO"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="true">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.inChargeDateBO' | translate}}</ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(inChargeDateBO)"
                            placeholder="{{'viewAndFine.labels.inChargeDateBO' | translate}}"
                            formControlName="inChargeDateBO" class="input-mat-datetime" [disabled]="true">
                        <mat-datepicker-toggle matSuffix [for]="$any(inChargeDateBO)" [hidden]="true">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #inChargeDateBO [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.completionDateBO' | translate}}</ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(completionDateBO)"
                            placeholder="{{'viewAndFine.labels.completionDateBO' | translate}}"
                            formControlName="completionDateBO" class="input-mat-datetime" [disabled]="true">
                        <mat-datepicker-toggle matSuffix [for]="$any(completionDateBO)" [hidden]="true">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #completionDateBO [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!contractForm.get('userIT').value">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.userIT' | translate}}</ion-label>
                    <ion-input type="text" formControlName="userIT"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="true">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.completionDate' | translate}}</ion-label>
                    <div class="datetime-wrapper"><input matInput [ngxMatDatetimePicker]="$any(completionDate)"
                            placeholder="{{'viewAndFine.labels.completionDate' | translate}}"
                            formControlName="completionDate" class="input-mat-datetime" [disabled]="true">
                        <mat-datepicker-toggle matSuffix [for]="$any(completionDate)" [hidden]="true">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #completionDate [showSeconds]="false">
                        </ngx-mat-datetime-picker>
                    </div>
                </ion-item>
            </ion-col>
            <ion-col size-md="12" size-sm="12" size-xs="12">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.notes' | translate}}<span class="required-field"
                            [hidden]="readonly || !isFieldRequired(contractForm, 'notes')">*</span></ion-label>
                    <ion-textarea rows="3" formControlName="notes"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="readonly">
                    </ion-textarea>
                </ion-item>
            </ion-col>
            <ion-col size-md="12" size-sm="12" size-xs="12" [hidden]="!contractForm.get('reasonRejection').value">
                <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'viewAndFine.labels.reasonRejection' | translate}}</ion-label>
                    <ion-textarea rows="3" formControlName="reasonRejection"
                        placeholder="{{'viewAndFine.labels.insert' | translate}}" [readonly]="true">
                    </ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
    </form>
</mat-expansion-panel>