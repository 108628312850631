import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  //header
  @Input() title: string;
  //message
  @Input() message: string;
  //type
  @Input() type: string;
  //color 
  @Input() color: string;


  constructor(public translate: TranslateService,
    private languageSrv: LanguageService,
    public alertCtrl: AlertController) {
    //setup input
    this.title = this.title ? this.title : null;
    this.message = this.message ? this.message : null;
    this.type = this.type ? this.type : null;
    this.color = this.color ? this.color : 'primary';
  }

  ngOnInit() {

  }

  //showHelp
  async showHelp() {
    const alert = await this.alertCtrl.create({
            cssClass: 'alert-service',
      header: this.translate.instant(this.title),
      message: this.translate.instant(this.message),
      buttons: [
        {
          text: this.translate.instant('buttons.ok'),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present()
  }


}
