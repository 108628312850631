import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { PostitBoardMainPageRoutingModule } from './postit-board-main-routing.module';
import { PostitBoardMainPage } from './postit-board-main.page';
import { IonicSelectableComponent } from 'ionic-selectable';
import { PostitBoardToolbarComponentModule } from '../../components/postit-board-toolbar/postit-board-toolbar.component.module';
import { PostitBoardBoardComponentModule } from '../../components/postit-board-board/postit-board-board.component.module';
import { PostitBoardStatsComponentModule } from '../../components/postit-board-stats/postit-board-stats.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    PostitBoardMainPageRoutingModule,
    IonicSelectableComponent,
    PostitBoardToolbarComponentModule,
    PostitBoardBoardComponentModule,
    PostitBoardStatsComponentModule
  ],
  declarations: [PostitBoardMainPage]
})
export class PostitBoardMainPageModule { }

