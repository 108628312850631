export enum columnTypes {
  TEXT = "TEXT", //display simple text
  DATE = "DATE", //display date dd/MM/yyyy
  DATE_TIME = "DATE_TIME", //display date time dd/MM/yyyy HH:mm
  BOOLEAN = "BOOLEAN", //display yes or no
  BOOLEAN_CHECKBOX = "BOOLEAN_CHECKBOX", //display checkbox readonly
  DETAIL = "DETAIL", //deprecata in favore di ICONA
  SELECT = "SELECT", //display a checkbox for row selection
  TOOLTIP = "TOOLTIP", //display info icon with tooltip 
  TRANSLATE = "TANSLATE", //display translatet text
  NUMERO = "NUMERO", //display number with separator 1300 -> 1.300
  NUMERO_SEPARATORI = "NUMERO_SEPARATORI", //display number with thousands separators
  DECIMALE = "DECIMALE", //display number with separator and decimals 00 ex 1300 -> 1.300,00
  INPUT_STRINGA = "INPUT_STRINGA", //display input text 2 way binding 
  INPUT_NUMERO = "INPUT_NUMERO", //display input number 2 way binding 
  INPUT_DATA = "INPUT_DATA",//display input date 2 way binding 
  INPUT_DATAORA = "INPUT_DATAORA",//display input datetime 2 way binding 
  INPUT_COMBO = "INPUT_COMBO", //display input select 2 way binding 
  INPUT_MULTICOMBO = "INPUT_MULTICOMBO",//display input select multiple 2 way binding 
  INPUT_BOOLEAN = "INPUT_BOOLEAN",//display input checkbox 2 way binding 
  INPUT_RADIO = "INPUT_RADIO",//display input radio 2 way binding 
  INPUT_POPUP_STRINGA = "INPUT_POPUP_STRINGA", //display button with modal on click
  INPUT_POPUP_NUMERO = "INPUT_POPUP_NUMERO", //display button with modal on click
  INPUT_POPUP_DATA = "INPUT_POPUP_DATA", //display button with modal on click
  INPUT_POPUP_DATAORA = "INPUT_POPUP_DATAORA", //display button with modal on click
  INPUT_POPUP_COMBO = "INPUT_POPUP_COMBO", //display button with modal on click
  INPUT_POPUP_MULTICOMBO = "INPUT_POPUP_MULTICOMBO", //display button with modal on click
  INPUT_POPUP_BOOLEAN = "INPUT_POPUP_BOOLEAN", //display button with modal on click
  INPUT_POPUP_RADIO = "INPUT_POPUP_RADIO", //display button with modal on click
  INPUT_POPUP_DECIMALE = "INPUT_POPUP_DECIMALE",
  INPUT_DECIMALE = "INPUT_DECIMALE",
  ICONA = "ICONA",//display icon. Column param [{ color: "warning", icon: "alarm", tooltip: "In attesa", translationTooltipPath: null, label: null, labelTranslationPath: null, conditionValue: "1", conditionColumnDef: "idTipoStatus" }]
  HTML = "HTML", //display rendered html from string
}

export interface tableColumn {
  columnDef: string, //name or id of column (must match backend property)
  columnType: string, //type of column (1 of columnTypes enum)
  columnParam: any | Array<columnIconaCondition>, //extra param for column ex:  transaltion path for translate or icon path for detail
  columnIndex: number, //column index for coumns order
  columnWidth: number, //column width in px
  columnWidthPercentage?: number,
  columnHidden: boolean, //column visibility in table
  columnSortable: boolean, //enable or disable column sort
  columnHeader: string //column header without translation
  columnCallback: any, //column callback funtion ex: function for detail,
  columnDynamicDetailList: Array<columnDynamicDetail>, //icons and conditions list
  columnHeaderCallback?: any,//callback for request
  columnHeaderComparisonOptions?: Array<string>, //options available for comparison example ">", "<", "="...
  columnMessage?: string, //useed for input popup
  mobileSortId?: any,
  mobileSortDirection?: 'asc' | 'desc',
  columnEndpoint?: string,
  columnStickyLeft?: boolean,
  columnStyleProp?: {
    offsetLeft: string,
    position: string,
    zIndex: string,
    background: string
  }
}

export interface storageTableColumn {
  columnDef: string, //name or id of column (must match backend property)
  columnIndex: number, //column index for coumns order
  columnWidth: number, //column width in px
  columnHidden: boolean, //column visibility in table
  columnStickyLeft: boolean //column sticky on left
}

export interface columnDynamicDetail {
  cursorDefault: boolean, //show default cursor
  iconPath: string, //icon path ex: 'assets/icons/myimage.png'
  callback: any, //callback
  titleTranslatePath: string, //title translate path ex: 'path.labels.title'
  conditionColumnDef: string, //condition target property ex: 'myColumn'
  conditionValue: any //condition value ex: '1', 'hello', true, 1
}

export interface columnIconaCondition {
  color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger",
  icon: string,
  conditionValue: string | number | boolean,//se la stringa comincia per #EVAL# il risultato sarà l'eval della condizione con i campi rimpiazzati es: "#EVAL#[mioCampo] != null"
  tooltip?: string,
  label?: string,
  disabled?: boolean,
  conditionColumnDef?:string
}

export interface tableSettingsEvent {
  tableColumns: Array<tableColumn> //array of columns null for reset
}

export interface tableSearchEvent {
  keyUp: any,
  isExtract: boolean,
  origin?: string,
  tableId?: string,
  searchStateObject?: any
}

export const tableExtractPageSize = 10000;