
import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';
import { InputTypes, DcrudInput, TriggersListItem, TriggerActions } from '../pages/dcrud/models/dcrud.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { Icon } from 'ionicons/dist/types/components/icon/icon';

@Injectable({
    providedIn: 'root'
})
export class DcrudService {
    //getFormDetail subject
    public updateFormDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    //drop subject
    public inputDrop: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    //inputSettingsPromt subject
    public inputSettingsPrompt: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    //closeModalCallback
    public closeModalCallback: Subject<any> = new Subject<any>();
    //emptyStringsObjectList
    private _emptyStringsObjectList = ["id", "valuesEndpoint", "valuesEndpointPayload", "permissionModule", "title", "subtitle", "description", "icon"]

    constructor(public httpUtilitySrv: HttpUtilityService,
        public http: HttpClient) { }

    //getFormDetail
    public getFormDetail(queryParams, internal?: boolean) {
        return new Promise((resolve, reject) => {
            //``assets/endpoint/${urlParams.get("pageId")}.json`  `${this.httpUtilitySrv.links.apigateway}RestGenerator/CustomPages/getpagina${queryParams}`
            this.http.get(internal ? `assets/dcrud-pages/${new URLSearchParams(window.location.search).get("CodicePagina")}.json` : `${this.httpUtilitySrv.links.apigateway}RestGenerator/CustomPages/getpagina${queryParams}`, { observe: 'response' }).subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
        });
    }

    //setPagina
    public setPagina(dcrudobject: any) {
        return new Promise((resolve, reject) => {
            //``assets/endpoint/${urlParams.get("pageId")}.json`  `${this.httpUtilitySrv.links.apigateway}RestGenerator/CustomPages/getpagina${queryParams}`
            this.http.post(`${this.httpUtilitySrv.links.apigateway}RestGenerator/CustomPages/setPagina`, dcrudobject).subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
        });
    }

    //update form detail
    public updateFormDetailFromChild() {
        this.updateFormDetail.next(true);
    }

    //resolve endpoint by table row
    public resolveEndpointByTableRow(endpoint, tableRow) {
        tableRow = JSON.parse(JSON.stringify(tableRow));
        let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag;
        while (endpointTmp.indexOf("]") != -1) {
            currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
            //value exists in tableRow
            if (tableRow[currentTag] != null) {
                //format value
                endpointBe = endpointBe.toString().replace("[" + currentTag + "]", tableRow[currentTag]);
            }
            //value doesn't exist in tableRow
            else {
                endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "");
            }
            endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
        }
        return endpointBe;
    }

    //resolve endpoint by inputList
    public resolveEndpointByFlatInputList(endpoint, flatinputList: Array<DcrudInput>) {
        let flatinputListTmp = JSON.parse(JSON.stringify(flatinputList));
        let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag, valueTmp;
        while (endpointTmp.indexOf("]") != -1) {
            currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
            for (let input of flatinputListTmp) {
                //value exists in inputList
                if (input.id == currentTag && input.value != null) {
                    //format value
                    valueTmp = JSON.parse(JSON.stringify(input));
                    this.formatValueForBE(valueTmp);
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", valueTmp.value != null ? valueTmp.value : "");
                }
                //value doesn't exist in inputList
                else if (input.id == currentTag && input.value == null) {
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "");
                }
            }
            endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
        }
        return endpointBe;
    }

    //formatUrlByUrlAndURLSearchParams
    public formatUrlByUrlAndURLSearchParams(url: string, searchParams: any, searchParams2?: any) {
        let urlRes = JSON.parse(JSON.stringify(url)), currentTag, searchParamJson = {}, searchParamJson2 = {};
        if (searchParams)
            new URLSearchParams(searchParams).forEach((value, key) => {
                searchParamJson[key] = value;
            })
        if (searchParams2)
            new URLSearchParams(searchParams2).forEach((value, key) => {
                searchParamJson2[key] = value;
            })
        while (urlRes.indexOf("]") != -1) {
            currentTag = urlRes.substring(urlRes.indexOf("[") + 1, urlRes.indexOf("]"));
            //value exists in searchparamJson or searchParamsJson2
            if (searchParamJson[currentTag] != null) {
                urlRes = urlRes.toString().replace("[" + currentTag + "]", searchParamJson[currentTag]);
            } else if (searchParamJson2[currentTag] != null) {
                urlRes = urlRes.toString().replace("[" + currentTag + "]", searchParamJson2[currentTag]);
            }
            //value doesn't exist in inputList
            else {
                urlRes = urlRes.toString().replace("[" + currentTag + "]", "");
            }
            urlRes = urlRes.substring(urlRes.indexOf("]") + 1);
        }
        return urlRes;
    }

    //resolveConditionByFlatInputList
    public resolveConditionByFlatInputList(endpoint, flatinputList: Array<DcrudInput>) {
        let flatinputListTmp = JSON.parse(JSON.stringify(flatinputList));
        let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag, valueTmp;
        while (endpointTmp.indexOf("]") != -1) {
            currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
            for (let input of flatinputListTmp) {
                //value exists in inputList
                if (input.id == currentTag && input.value != null && !input.errorMessage) {
                    //format value
                    valueTmp = JSON.parse(JSON.stringify(input));
                    this.formatValueForBE(valueTmp);
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", valueTmp.value != null && valueTmp.value != '' ? `"${valueTmp.value}"` : "null");
                }
                //value doesn't exist in inputList
                else if (input.id == currentTag && input.value == null) {
                    endpointBe = endpointBe.toString().replace("[" + currentTag + "]", "null");
                }
            }
            endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
        }
        return endpointBe;
    }

    //format single value for be
    public formatValueForBE(input: DcrudInput) {
        let valueTmp;
        if (input.value != null && input.value != undefined && input.value != '') {
            switch (input.inputType) {
                case InputTypes.DATA:
                    valueTmp = this.formatDate(input.value);
                    break;
                case InputTypes.DATA_ORA:
                    valueTmp = this.formatDateTime(input.value);
                    break;
                case InputTypes.SELEZIONE:
                case InputTypes.SELEZIONE_AVANZATA:
                case InputTypes.RADIO:
                    if (Array.isArray(input.value)) {
                        valueTmp = [];
                        input.value.forEach((option) => {
                            if (input.optionValue)
                                valueTmp.push(option[input.optionValue])
                            else
                                valueTmp.push(option)
                        });
                        valueTmp = valueTmp.length > 0 ? valueTmp : null;
                    } else {
                        if (input.optionValue)
                            valueTmp = input.value[input.optionValue];
                        else
                            valueTmp = input.value;
                    }
                    break;
                case InputTypes.VALUTA:
                    valueTmp = parseFloat(input.value.toString().replace(/\./g, "").replace(/\,/g, "."));
                    break;
                default:
                    valueTmp = input.value;
                    break;
            }
        } else {
            valueTmp = null;
        }
        input.value = valueTmp;
    }

    //format date
    public formatDate(date) {
        let dt = date ? new Date(date) : null;
        return dt ? dt.getFullYear() + "-" + ((dt.getMonth() + 1 < 10) ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())) : null;
    }

    //format date time
    public formatDateTime(date) {
        let dt = date ? new Date(date) : null;
        return dt ? dt.getFullYear() + "-" + ((dt.getMonth() + 1 < 10) ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + ((dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())) + "T" + ((dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours())) + ":" + ((dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes())) : null;
    }

    //getFlatINputList1to1
    public getFlatINputList1to1(inputList: Array<DcrudInput>, flatInputList: Array<DcrudInput>) {
        if (inputList) {
            inputList.forEach((input) => {
                //inputList exists
                if (input.inputList && input.inputList.length > 0) {
                    //check for composite input
                    if (input.inputType == InputTypes.DATA_RANGE && input.inputList.length == 2) {
                        flatInputList.push(input.inputList[0]);
                        flatInputList.push(input.inputList[1]);
                    } else {
                        flatInputList.push(input);
                    }
                    this.getFlatINputList1to1(input.inputList, flatInputList);
                }//inputList doesn't exist
                flatInputList.push(input);
            });
        }
    }

    //getFlatINputListDeepCopy
    public getFlatINputListDeepCopy(inputList: Array<DcrudInput>) {
        let flatInputListTmp: Array<DcrudInput> = [];
        this.getFlatINputList1to1(inputList, flatInputListTmp);
        let returnFlatInputList: Array<DcrudInput> = [];
        for (let input of flatInputListTmp) {
            returnFlatInputList.push({
                inputType: input.inputType,
                max: input.max,
                min: input.min,
                showSeconds: input.showSeconds,
                optionsList: input.optionsList,
                optionValue: input.optionValue,
                optionText: input.optionText,
                multiple: input.multiple,
                endpoint: input.endpoint,
                endpointTmp: input.endpointTmp,
                triggersList: input.triggersList,
                hidden: input.hidden,
                readonly: input.readonly,
                disabled: input.disabled,
                color: input.color,
                validationRules: input.validationRules,
                errorMessage: input.errorMessage,
                value: input.value,
                colSize: input.colSize,
                label: input.label,
                fileName: input.fileName,
                step: input.step,
                id: input.id,
                required: input.required,
                invalid: input.invalid,
                triggerDisabled: input.triggerDisabled,
                loading: input.loading,
                alignX: input.alignX,
                routerUrl: input.routerUrl,
                collapsed: input.collapsed,
                tableColumns: input.tableColumns,
                tableTargetId: input.tableTargetId,
                nopadding: input.nopadding,
                tooltip: input.tooltip,
                saveState: input.saveState,
                mergeValueId: input.mergeValueId,
                hideHeader: input.hideHeader,
                inlineGroup: input.inlineGroup,
                hideShadow: input.hideShadow,
                icon: input.icon
                //tableDataSource: input.tableDataSource && input.tableDataSource.data ? input.tableDataSource.data : null
            })
        }
        return returnFlatInputList;
    }



    //format value for fe
    public formatInputListValuesForFE(inputList: Array<DcrudInput>) {
        if (inputList && inputList.length > 0) {
            let valueTmp, optionTmp;
            inputList.forEach((input) => {
                //format value
                if (input.value != null && input.value != undefined) {
                    switch (input.inputType) {
                        case InputTypes.SELEZIONE:
                        case InputTypes.SELEZIONE_AVANZATA:
                        case InputTypes.RADIO:
                            //array
                            if (Array.isArray(input.value)) {
                                valueTmp = [];
                                input.value.forEach((option) => {
                                    //[1] => [{id: 1, desc:"item1"}]
                                    if (input.optionValue && input.optionsList && input.optionsList.length > 0) {
                                        optionTmp = input.optionsList.filter((o) => { return o[input.optionValue] == option })[0];
                                        if (optionTmp)
                                            valueTmp.push(optionTmp)
                                    }
                                    //[1] => [1]
                                    else
                                        valueTmp.push(option)
                                });
                            }
                            //single object
                            else {
                                //1 => {id: 1, desc:"item1"}
                                if (input.optionValue && input.optionsList && input.optionsList.length > 0) {
                                    optionTmp = input.optionsList.filter((o) => { return o[input.optionValue] == input.value })[0];
                                    valueTmp = optionTmp;
                                }
                                //1 => 1
                                else
                                    valueTmp = input.value
                            }
                            break;
                        case InputTypes.VALUTA:
                            valueTmp = input.value.toString().replace(/\./g, "").replace(/,/g, ".");
                            valueTmp = parseFloat(valueTmp).toFixed(2).toString() != 'NaN' ? this.currencyTransform(parseFloat(valueTmp).toFixed(2).toString()) : null
                            break;
                        default:
                            valueTmp = input.value;
                            break;
                    }
                    input.value = valueTmp
                }
                if (input.inputList && input.inputList.length > 0) {
                    this.formatInputListValuesForFE(input.inputList);
                }
            })
        }
    }

    //getAllFormValuesFromFlatInputList
    public getAllFromValuesFromFlatInpuList(flatInputList: Array<DcrudInput>) {
        flatInputList = JSON.parse(JSON.stringify(flatInputList));
        for (let input of flatInputList) {
            this.formatValueForBE(input);
        }
        let formObject = {};
        for (let input of flatInputList) {
            if (![InputTypes.PULSANTE].includes(input.inputType))
                formObject[input.id] = input.value != undefined && input.value != null ? input.value : null;
        }
        return formObject;
    }

    //currencyTransform
    public currencyTransform(event) {
        if (event != '' && event != undefined && event != null) {
            if (/^[0-9,.]*$/.test(event)) {
                event = event.toString().replace(/\D/g, "");
                //add comma if required 
                if (event.length > 2) {
                    event = event.substring(0, event.length - 2) + "," + event.substring(event.length - 2);
                    event = event.toString().replace(/,\./g, ",").replace(/\.,/g, ",");
                }
                if (event.includes(",")) {
                    let decimal = event.substring(0, event.indexOf(","));
                    decimal = parseInt(decimal).toLocaleString('it-IT');
                    event = decimal + event.substring(event.indexOf(","), event.length);
                }
            } else {
                event = event.slice(0, -1);
            }
        } else {
            event = '';
        }

        return event;
    }

    //inputDropFromChild
    public inputDropFromChild(event) {
        this.inputDrop.next(event);
    }

    //inputDropFromChild
    public inputSettingsPromptFromChild(event) {
        this.inputSettingsPrompt.next(event);
    }


    //update endpoints
    public updateEndpoints(inputList) {
        let flatInputListDeepCopy = this.getFlatINputListDeepCopy(inputList);
        let flatInputList = [];
        this.getFlatINputList1to1(inputList, flatInputList);
        for (let input of flatInputList) {
            if (input.inputType == InputTypes.SELEZIONE && input.endpoint) {
                input.endpointTmp = this.resolveEndpointByFlatInputList(JSON.parse(JSON.stringify(input.endpoint)), flatInputListDeepCopy);
            }
        }
    }

    //closeModalCallback
    public closeModalCallbackEmit(params: { navigateByUrl }) {
        this.closeModalCallback.next(params)
    }

    //clean white space object strings
    public cleanWhiteSpaceObjectStrings(object: any) {
        if (object) {
            for (let property in object) {
                if (object[property] == "" && this._emptyStringsObjectList.includes(property))
                    object[property] = null
                else if (typeof object[property] == 'object')
                    this.cleanWhiteSpaceObjectStrings(object[property]);
            }
        }
    }

    //handleAnauthorizedInput
    public handleAnauthorizedInput(modules, getDatailResponse) {
        if (getDatailResponse && getDatailResponse.inputList) {
            let permissionTrigger_show: TriggersListItem, permissionTrigger_notreadonly: TriggersListItem, permissionTrigger_hide: TriggersListItem, input: DcrudInput;
            for (let i = 0; i < getDatailResponse.inputList.length; i++) {
                input = getDatailResponse.inputList[i];
                permissionTrigger_show = null;
                permissionTrigger_notreadonly = null;
                permissionTrigger_hide = null;
                if (input.triggersList) {
                    //PERMISSION_SHOW
                    permissionTrigger_show = input.triggersList.filter((trigger: TriggersListItem) => { return trigger.action == TriggerActions.PERMISSION_SHOW && trigger.condition })[0];
                    if (permissionTrigger_show && modules.indexOf('#' + permissionTrigger_show.condition + '#') == -1) {
                        Object.assign(input, { id: new Date().getTime().toString(), inputType: InputTypes.UNKNOWN, colSize: { lg: 0, md: 0, sm: 0, xs: 0 }, hidden: true, inputList: null });
                    } else {
                        //PERMISSION_HIDE
                        permissionTrigger_hide = input.triggersList.filter((trigger: TriggersListItem) => { return trigger.action == TriggerActions.PERMISSION_HIDE && trigger.condition })[0];
                        if (permissionTrigger_hide && modules.indexOf('#' + permissionTrigger_hide.condition + '#') != -1) {
                            Object.assign(input, { id: new Date().getTime().toString(), inputType: InputTypes.UNKNOWN, colSize: { lg: 0, md: 0, sm: 0, xs: 0 }, hidden: true, inputList: null });
                        } else {
                            //PERMISSION_NOTREADNOLY
                            permissionTrigger_notreadonly = input.triggersList.filter((trigger: TriggersListItem) => { return trigger.action == TriggerActions.PERMISSION_NOTREADONLY && trigger.condition })[0];
                            if (permissionTrigger_notreadonly && modules.indexOf('#' + permissionTrigger_notreadonly.condition + '#') == -1) {
                                input.readonly = true;
                            }
                            if (input.inputList) {
                                this.handleAnauthorizedInput(modules, input);
                            }
                        }
                    }
                } else if (input.inputList) {
                    this.handleAnauthorizedInput(modules, input);
                }
            }
        }
    }
}
