import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AlertController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-selectable-tree-popover',
  templateUrl: './selectable-tree-popover.component.html',
  styleUrls: ['./selectable-tree-popover.component.scss'],
})
export class SelectableTreePopoverComponent implements OnInit, AfterViewInit {
  //popover params
  public modalNavParams;
  //can select all
  public canSelectAll_flag = false;
  //searchable tree
  public searchableTree = [];

  constructor(private platform: Platform,
    public translate: TranslateService,
    private languageSrv: LanguageService,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController) {
    //setup nav params
    this.modalNavParams =
    {
      title: this.navParams.get("title") ? this.navParams.get("title") : this.translate.instant('labels.select'),
      tree: this.navParams.get("tree") && this.isIterable(this.navParams.get("tree")) ? this.navParams.get("tree") : [],
      titleKey: this.navParams.get("titleKey") ? this.navParams.get("titleKey") : null,
      childrenKey: this.navParams.get("childrenKey") ? this.navParams.get("childrenKey") : null,
      descriptionKey: this.navParams.get("descriptionKey") ? this.navParams.get("descriptionKey") : null,
      hideCheckboxParent: this.navParams.get("hideCheckboxParent") ? this.navParams.get("hideCheckboxParent") : false,
      hideCheckboxRole: this.navParams.get("hideCheckboxRole") ? this.navParams.get("hideCheckboxRole") : false,
      single: this.navParams.get("single") ? this.navParams.get("single") : false,
      showRoleDetail: this.navParams.get("showRoleDetail") ? this.navParams.get("showRoleDetail") : false,
      showRolesIndicator: this.navParams.get("showRolesIndicator") ? this.navParams.get("showRolesIndicator") : false,
      allOpened: this.navParams.get("allOpened") ? this.navParams.get("allOpened") : false,
      message: this.navParams.get("message") ? this.navParams.get("message") : null,
      disableTerritoryCheckbox: this.navParams.get("disableTerritoryCheckbox") ? this.navParams.get("disableTerritoryCheckbox") : null,
      disableRoleCheckbox: this.navParams.get("disableRoleCheckbox") ? this.navParams.get("disableRoleCheckbox") : false,
      readOnly: this.navParams.get("readOnly") ? this.navParams.get("readOnly") : false,
      hideTerritoryCount: this.navParams.get("hideTerritoryCount") ? this.navParams.get("hideTerritoryCount") : false,
      showOnlyListTerritory: this.navParams.get("showOnlyListTerritory") ? this.navParams.get("showOnlyListTerritory") : null,
      showSeletAllRoles: this.navParams.get("showSeletAllRoles") ? this.navParams.get("showSeletAllRoles") : false,
      singleRole: this.navParams.get("singleRole") ? this.navParams.get("singleRole") : false
    }
    //check for showOnlyListTerritory
    if (this.modalNavParams.showOnlyListTerritory) {
      this.showOnlyListTerritory(this.modalNavParams.tree, this.modalNavParams.showOnlyListTerritory);
    }
    //open first child
    this.modalNavParams.tree[0]['opened'] = true;
    if (this.modalNavParams.tree && this.modalNavParams.tree.length) {
      this.searchableTree = JSON.parse(JSON.stringify(this.modalNavParams.tree));
    }
  }


  ngOnInit() {
    this.canSelectAll(this.searchableTree);
  }

  ngAfterViewInit() {

  }

  //is iterable
  private isIterable(obj) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  //select all
  public selectAll(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        tree[i]['selected'] = true;
        this.canSelectAll_flag = false;
        this.selectAll(tree[i]);
      } else {
      }
    }
  }

  //deselect all
  public deselectAll(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        tree[i]['selected'] = false;
        this.canSelectAll_flag = true;
        this.deselectAll(tree[i]);
      }
    }
  }

  //can select all
  public canSelectAll(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {

        if (!tree[i]['selected'])
          this.canSelectAll_flag = true;
        this.canSelectAll(tree[i]);
      } else {

      }
    }
  }

  //search
  public search(tree, key) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {

        if (!tree[i]['selected'])
          this.canSelectAll_flag = true;
        this.search(tree[i], key);
      } else {

      }
    }
  }

  //on item select
  public onItemSelect(item: any) {
    if (this.modalNavParams && this.modalNavParams.singleRole)
      this._singleRoleSelected(this.modalNavParams.tree, item)
    else if (this.modalNavParams && this.modalNavParams.single)
      this.singleSelected(this.modalNavParams.tree, item.idCliente)
    else if (item.figli && item.figli.length > 0 && !this.modalNavParams.single) {
      this.selectAllChildrenaAlert(item.idCliente, !item['selected'])
    }
  }

  //select all children
  async selectAllChildrenaAlert(parentId, value) {
    let title = value ? "labels.select_all_children" : "labels.deselect_all_children";
    let message = value ? "messages.select_children_message" : "messages.deselect_children_message";
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: [
        {
          text: this.translate.instant('buttons.no'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.si'),
          handler: () => {
            this.searchForChildrenParent(this.modalNavParams.tree, parentId, value)
          }
        }
      ]
    });

    await alert.present()
  }

  //search for children parent
  public searchForChildrenParent(tree, parentId, value) {
    for (let i in tree) {
      if (tree[i] && typeof (tree[i]) == "object") {
        if (tree[i]['idCliente'] && tree[i]['idCliente'] == parentId) {
          tree[i]['opened'] = true;
          this.selectAllChildren(tree[i], value);
        }
        else
          this.searchForChildrenParent(tree[i], parentId, value);
      }
    }
  }

  //selectAllChildren
  public selectAllChildren(tree, value) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i]['idCliente'])
          tree[i]['selected'] = value;
        this.selectAllChildren(tree[i], value);
      }
    }
  }

  //single selected
  public singleSelected(tree, idCliente) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {

        if (tree[i]['idCliente'] && tree[i]['idCliente'] != idCliente)
          tree[i]['selected'] = false
        this.singleSelected(tree[i], idCliente);
      } else {

      }
    }
  }


  //select
  public select() {
    this.popoverCtrl.dismiss({ tree: this.modalNavParams.tree })
  }

  //filterShowOnlyListTerritory
  public showOnlyListTerritory(tree, showOnlyListTerritory) {
    for (let territory of tree) {
      if (showOnlyListTerritory.includes(territory.idCliente)) {
        territory['hideTerritory'] = false;
        if (territory.ruoli && territory.ruoli.length > 0) {
          for (let role of territory.ruoli) {
            role['hideRole'] = role['selected'] ? false : true;
          }
        }
      } else {
        territory['hideTerritory'] = true;
      }
      if (territory.figli && territory.figli.length > 0)
        this.showOnlyListTerritory(territory.figli, showOnlyListTerritory)
    }
  }

  //onMouseLeave
  public onMouseLeave() {
    if (this.modalNavParams.hideHeader && this.modalNavParams.hideFooter)
      this.popoverCtrl.dismiss();
  }

  //singleRoleSelected
  private _singleRoleSelected(tree, item) {
    for (let territory of tree) {
      if (territory.idCliente == item.group.idCliente) {
        if (territory.ruoli && territory.ruoli.length > 0) {
          territory.ruoli.forEach((role) => {
            if (role.id == item.role.id)
              role["selected"] = item.role.selected;
            else
              role["selected"] = false;
          })
        }
      } else if (territory.ruoli && territory.ruoli.length > 0) {
        territory.ruoli.forEach((role) => {
          role["selected"] = false;
        })
      }
      if (territory.figli && territory.figli.length > 0)
        this._singleRoleSelected(territory.figli, item)
    }
  }
}
