import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { columnTypes } from '../models/table-models';
@Component({
  selector: 'app-table-td',
  templateUrl: './table-td.component.html',
  styleUrls: ['./table-td.component.scss'],
})

export class TableTdComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  //column
  @Input() column;
  //element
  @Input() element;
  //tdIndex
  @Input() tdIndex;
  //tableColumnTypes
  public tableColumnTypes = columnTypes;
  //emitters
  @Output() getAllSelected = new EventEmitter<any>();
  @Output() goDetail = new EventEmitter<any>();
  @Output() goDynamicDetail = new EventEmitter<any>();
  @Output() editCell = new EventEmitter<any>();
  @Output() saveCell = new EventEmitter<any>();
  @Output() openLink = new EventEmitter<any>();

  constructor(
    public translate: TranslateService,
    private storage: Storage,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private appSrv: AppService
  ) {

  }

  ngOnDestroy(): void { }

  ngOnInit() { }

  ngAfterViewInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.column)
      this.column = changes.column.currentValue;
    if (changes.element)
      this.element = changes.element.currentValue;
    if (changes.tdIndex)
      this.tdIndex = changes.tdIndex.currentValue;
  }

  //--- EMITTERs ---//
  public emitGetAllSelected() {
    this.getAllSelected.emit();
  }
  public emitGoDetail(column, element) {
    this.goDetail.emit({ column: column, element: element });
  }
  public emitGoDynamicDetail(item, element) {
    this.goDynamicDetail.emit({ item: item, element: element });
  }
  public emitEditCell(column, element) {
    this.editCell.emit({ column: column, element: element, tdIndex: this.tdIndex });
  }
  public emitSaveCell(column, element) {
    this.saveCell.emit({ column: column, element: element });
  }
  public emitOpenLink(column, element, condition?) {
    this.openLink.emit({ column: column, element: element, condition: condition });
  }
  //--- END EMITTERS ---/

  public compareWith = function (a, b) { return a.id == b.id };

  public evalIconaCondition(condition: string) {
    if(condition && condition.indexOf("#EVAL#") != -1){
      let elementDeepCopy = JSON.parse(JSON.stringify(this.element));
      let conditionTmp: string = JSON.parse(JSON.stringify(condition.replace("#EVAL#", ""))), conditionReturn: string = JSON.parse(JSON.stringify(condition.replace("#EVAL#", ""))), currentTag;
      while (conditionTmp.indexOf("]") != -1) {
        currentTag = conditionTmp.substring(conditionTmp.indexOf("[") + 1, conditionTmp.indexOf("]"));
        //value exists in tableRow
        if (elementDeepCopy[currentTag] != null) {
          //format value
          conditionReturn = conditionReturn.toString().replace("[" + currentTag + "]", elementDeepCopy[currentTag]);
        }
        //value doesn't exist in tableRow
        else {
          conditionReturn = conditionReturn.toString().replace("[" + currentTag + "]", null);
        }
        conditionTmp = conditionTmp.substring(conditionTmp.indexOf("]") + 1);
      }
      return eval(conditionReturn);
    }
    return false;
  }

  public isString(data: any){
    return data != null && data != undefined ? typeof data == 'string' : false;
  }
}



