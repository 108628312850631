import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, AlertController, PopoverController, NavParams } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CustomReportsService } from 'src/app/services/custom-reports.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { SelectableTreePopoverComponent } from 'src/app/modals_popsover/selectable-tree-popover/selectable-tree-popover.component';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { CustomReportComboEditComponent } from '../custom-report-combo-edit/custom-report-combo-edit.component';
import { ToastrService } from "ngx-toastr";
import { IonicSelectableComponent } from 'ionic-selectable';
@Component({
  selector: 'app-custom-report-configuration',
  templateUrl: './custom-report-configuration.component.html',
  styleUrls: ['./custom-report-configuration.component.scss'],
})
export class CustomReportConfigurationComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //sectionIdList
  public sectionIdList = [];
  //crpFE
  public crpFE;
  //customer id
  public customerIdList = [];
  //customer options
  public customerOption = [];
  //default customer option
  public defaultCustomerOption = [];
  //role id list
  public roleIdList = [];
  //role option
  public roleOption = [];
  //default role option
  public defaultRoleOption = [];
  //user options
  public usersOption = [];
  //user id list
  public userIdList = [];
  //customerTmp
  private _customerTmp;
  //roleTmp
  private _roleTmp;
  //currentTabIndex
  public currentTabindex = 0;
  //sections changed
  public instructionsChanged: boolean = false;
  public permissionsChanged: boolean = false;
  public parametersChanged: boolean = false;
    // selectable combo
    @ViewChild('selectableField') selectableField: IonicSelectableComponent;
  //layout config
  public layoutConfig = {
    queryWrapperWidth: 400,
    tabsWrapperWidth: "calc(100% - 400px)",
    queryWrapperHeight: 200,
    tabsWrapperHeight: "calc(100% - 200px)",
    //contentWrapperHeight: "100%",
    vertical: false,
  }
  //newCrpForm
  public newCrpForm: FormGroup;

  //temp list from endpoint
  public endpointList;

  constructor(
    public translate: TranslateService,
    public alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private loaderSrv: LoaderService,
    private customReportsSrv: CustomReportsService,
    private httpUtilitySrv: HttpUtilityService,
    private navParams: NavParams,
    public popoverCtrl: PopoverController,
    private _storageVariableSrv: StorageVariablesService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    //setup validators
    this.newCrpForm = this.formBuilder.group({
      tipoReport: [null, Validators.compose([Validators.required])],
      descrizione: [null, Validators.compose([Validators.required])]
    })
    //setup nav params
    this.modalNavParams =
    {
      crp: this.navParams.get("crp") ? this.navParams.get("crp") : null,
      sectionIdList: this.navParams.get("sectionIdList") ? this.navParams.get("sectionIdList") : []
    }
    this.crpFE = this.modalNavParams.crp;
    this.sectionIdList = this.modalNavParams.sectionIdList;
    //if only permission edit: hide query wrapper
    if (!this.sectionIdList.includes('reporting.custom-report-configuration')) {
      this.layoutConfig = {
        queryWrapperWidth: 0,
        tabsWrapperWidth: "calc(100%)",
        queryWrapperHeight: 0,
        tabsWrapperHeight: "calc(100%)",
        vertical: false,
      }
    }
  }

  ngAfterViewInit(): void {
    //crp configuration detail
    if (this.crpFE) {
      if (this.sectionIdList.includes('reporting.custom-report-configuration')) {
        this._setLayout();
      }
      this._getCrpDetails(this.crpFE.crpId);
    }
    //new crp
    else {
      this.modalCtrl.getTop().then((res: HTMLIonModalElement) => {
        if (res.classList.contains("crp-configuration-modal"))
          res.classList.add("crp-new-configuration-modal")
      })
    }
  }

  ngOnInit() {
  }

  //_setLayout
  private _setLayout() {
    setTimeout(() => {
      this._storageVariableSrv.getCrpLayoutConfig().then((crpLayoutConfig: any) => {
        if (crpLayoutConfig)
          this.layoutConfig = crpLayoutConfig;
        let modalContent = document.getElementById("modal-content");
        if (modalContent) {
          if (!this.layoutConfig.vertical) {
            if (this.layoutConfig.queryWrapperWidth > ((modalContent.getBoundingClientRect().width) / 100 * 60)) {
              this.layoutConfig.queryWrapperWidth = ((modalContent.getBoundingClientRect().width) / 100 * 60);
              this.layoutConfig.tabsWrapperWidth = "calc(100% - " + this.layoutConfig.queryWrapperWidth + "px)";
            }
          }
          else {
            if (this.layoutConfig.queryWrapperHeight > ((modalContent.getBoundingClientRect().height) / 100 * 60)) {
              this.layoutConfig.queryWrapperHeight = ((modalContent.getBoundingClientRect().height) / 100 * 60);
              this.layoutConfig.tabsWrapperHeight = "calc(100% - " + this.layoutConfig.queryWrapperHeight + "px)";
            }
          }
        }
        //this.layoutConfig.contentWrapperHeight = modalContent.getBoundingClientRect().height + "px";
      });
    }, 1000)
  }


  //getCrpDetails
  private _getCrpDetails(crpId) {
    this.loaderSrv.open();
    this.customReportsSrv.getCrpDetails(crpId).then((res: any) => {
      this.loaderSrv.dismiss();
      this._setCrpDetails(res);
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
          this.modalCtrl.dismiss();
        })
      }
    })
  }


  //setCrpDetails
  private _setCrpDetails(crp) {
    //get crp, permissions, formGroup
    if (crp) {
      this.crpFE["enabled"] = crp.enabled;
      this.crpFE["query"] = crp.query;
      this.crpFE["instructions"] = crp.instructions;
      //check for empty instructions
      if (!this.crpFE["instructions"] || this.crpFE["instructions"].length == 0)
        this.crpFE["instructions"] = [{ crpId: this.crpFE.crpId, text: null }];
      //format values for frontend
      if (crp.formGroupConfig && crp.formGroupConfig.length > 0) {
        let valueTmp;
        crp.formGroupConfig.forEach((formField) => {
          //format value for frontend
          switch (formField.type) {
            case 'DATA':
              formField["getdateFE"] = formField.value == "getdate()" ? true : false;
              valueTmp = formField.value == "getdate()" ? null : this.customReportsSrv.formatDateForFe(formField.value);
              break;
            case 'DATAORA':
              formField["getdateFE"] = formField.value == "getdate()" ? true : false;
              valueTmp = formField.value == "getdate()" ? null : this.customReportsSrv.formatDateTimeForFe(formField.value);
              break;
              case 'COMBO':
                if((formField.options == null || formField.options.length == 0) && formField.endpointpreloaded) {
                  valueTmp = formField.value;
                  this.customReportsSrv.getListByEndpoint(formField.endpointurl).then((res: any) => {
                      this.endpointList = res.result.map((item: any) => {
                        return {
                          id: String(item.value) ?? this.getFirstTwoProperties(item)[0], 
                          desc: item.text ?? this.getFirstTwoProperties(item)[1]
                        }
                      });
                   }).catch((err) => {
                     if (err && err.status && err.status != 401 && err.status != 403) {
                       this.httpUtilitySrv.getRequestError(err).then((err: any) => {
                         this.alertSrv.errorAlert(err);
                       })
                     }
                   })
                 }
                else if((formField.options == null || formField.options.length == 0) && !formField.endpointpreloaded) {
                  let splitValue = formField.value 
                  ? JSON.parse(formField.value?.replace(/\\/g, '')).text ?? this.getFirstTwoProperties(JSON.parse(formField.value?.replace(/\\/g, '')))[1]
                  : formField.value;
                  valueTmp = formField.value ? JSON.parse(formField.value) : formField.value;
                  this.customReportsSrv.getListByEndpoint(formField.endpointurl+splitValue ?? '').then((res: any) => {
                    this.endpointList = res.result;
                   }).catch((err) => {
                     if (err && err.status && err.status != 401 && err.status != 403) {
                       this.httpUtilitySrv.getRequestError(err).then((err: any) => {
                         this.alertSrv.errorAlert(err);
                       })
                     }
                   })
                 }
                 else {valueTmp = formField.value;}
                break;
              default:
                valueTmp = formField.value;
                break;
          }
          formField.value = valueTmp;
        });
      }
      this.crpFE["parameters"] = crp.formGroupConfig && crp.formGroupConfig.length > 0 ? crp.formGroupConfig : null;
      //permissions
      this.crpFE["permissions"] = crp.permissions;
      if (!this.crpFE.permissions) {
        this.crpFE.permissions = { usersIds: [], territoriesIds: [], rolesIds: [] }
      }
      this._getUtenti(this.crpFE.permissions.usersIds, this.crpFE.permissions.territoriesIds, this.crpFE.permissions.rolesIds);
    }
  }
  //--- selectable users ---//
  //get utenti
  private _getUtenti(usersIdsList, customersIdsList, rolesIdsList) {
    this.customReportsSrv.getUtenti().then((res: any) => {
      if (res.code == 200) {
        this.usersOption = res.payload;
        if (usersIdsList && usersIdsList.length > 0)
          this.userIdList = this._getUsersListByUsersIdsList(usersIdsList);
        //get clienti
        if (customersIdsList && customersIdsList.length > 0) {
          this._getClienti(customersIdsList, rolesIdsList);
        } else {
          this._getClienti(null, rolesIdsList);
        }
      } else {
        this.usersOption = [];
      }
    }).catch((err) => {

    })
  }
  private _getUsersListByUsersIdsList(usersIdsList) {
    let list = [], userTmp;
    if (this.usersOption && this.usersOption.length > 0) {
      for (let userId of usersIdsList) {
        userTmp = this.usersOption.filter((user) => { return user.idUtente == userId })[0];
        if (userTmp) {
          list.push(userTmp);
        }
      }
    }
    return list;
  }
  private _getUsersIdListByUsers(users: Array<any>) {
    let usersTmp: Array<string> = [];
    if (users && users.length > 0) {
      for (let user of users) {
        usersTmp.push(user.idUtente);
      }
    }
    return usersTmp;
  }
  //--- end selectable users ---//

  //----- selectable tree customers ------//
  //get clienti
  private _getClienti(customersIdsList, rolesIdsList) {
    this.customReportsSrv.getClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOption = res.payload;
        this.defaultCustomerOption = JSON.parse(JSON.stringify(res.payload));
        if (customersIdsList && customersIdsList.length > 0)
          this.customerIdList = this._getCustmerListByCustomerIdsList(customersIdsList);
        //get full clienti
        if (rolesIdsList && rolesIdsList.length > 0) {
          this._getFullClienti(rolesIdsList);
        } else {
          this._getFullClienti();
        }
      } else {
        this.customerOption = [];
      }
    }).catch((err) => {

    })
  }
  //selectable tree popover
  async selectableTreePopoverCustomer(parameter?) {
    let tree = !parameter ? this.customerOption : JSON.parse(JSON.stringify(this.defaultCustomerOption));
    if (parameter && parameter.territoryFE) {
      this._selectCustomerByCustomer(tree, parameter.territoryFE[0]);
    }
    const popover = await this.popoverCtrl.create({
      component: SelectableTreePopoverComponent,
      cssClass: 'selectable-tree-popover',
      componentProps: {
        title: this.translate.instant('labels.territories'),
        tree: tree,
        titleKey: "denominazione",
        childrenKey: "figli",
        hideCheckboxParent: false,
        hideCheckboxRole: true,
        disableTerritoryCheckbox: false,
        readOnly: false,
        allOpened: true,
        single: !parameter ? false : true
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          if (!parameter) {
            this.customerIdList = [];
            this._getSelectedCustomerList(res.data.tree, this.customerIdList);
            this.handlePermissionsChange()
          } else {
            parameter.territoryFE = [];
            this._getSelectedCustomerList(res.data.tree, parameter.territoryFE);
            this.handleParameterChanged(parameter);
          }
        }
      });
    return await popover.present();
  }
  //get customer list by customer ids list: get list of objects by selected ids and set "selected" flags in options
  private _getCustmerListByCustomerIdsList(customerIdsList) {
    let list = [];
    if (this.customerOption && this.customerOption.length > 0) {
      for (let customerId of customerIdsList) {
        this._customerTmp = null;
        this._getCustomerById(customerId, this.customerOption);
        if (this._customerTmp) {
          this._customerTmp["selected"] = true;
          list.push(this._customerTmp);
        }
      }
    }
    return list;
  }
  //get customer by id
  private _getCustomerById(customerId, tree) {
    if (!this._customerTmp)
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].idCliente && tree[i].idCliente == customerId) {
          this._customerTmp = tree[i];
          break;
        } else if (tree[i].figli && tree[i].figli.length > 0) {
          this._getCustomerById(customerId, tree[i].figli);
        }
      }
  }
  //get selected customer list
  private _getSelectedCustomerList(tree, listTmp) {
    for (let item of tree) {
      if (item.idCliente != null && item.idCliente != undefined && item.selected)
        listTmp.push(item)
      if (item.figli && item.figli.length > 0)
        this._getSelectedCustomerList(item.figli, listTmp);
    }
  }
  //select customer by customer
  private _selectCustomerByCustomer(tree, territory) {
    if (tree && territory) {
      tree.forEach((innerTerritory) => {
        if (innerTerritory.idCliente == territory.idCliente)
          innerTerritory["selected"] = true;
        if (innerTerritory.figli && innerTerritory.figli.length > 0)
          this._selectCustomerByCustomer(innerTerritory.figli, territory)
      })
    }
  }
  //get customer id list by customers list
  private _getCustomersIdListByCustomers(customers: Array<any>) {
    let customersTmp: Array<string> = [];
    if (customers && customers.length > 0) {
      for (let customer of customers) {
        customersTmp.push(customer.idCliente);
      }
    }
    return customersTmp;
  }
  //----- end selectable tree ------//

  //----- selectable tree role ------//
  //get full clienti
  private _getFullClienti(roleIdsList?) {
    this.customReportsSrv.getFullClienti().then((res: any) => {
      if (res.code == 200) {
        this.roleOption = res.payload;
        this.defaultRoleOption = JSON.parse(JSON.stringify(res.payload));
        if (roleIdsList && roleIdsList.length > 0)
          this.roleIdList = this._getRoleListByRoleIdsList(roleIdsList);
        //format crp parameters data
        if (this.crpFE["parameters"]) {
          //get parameters user,territory, role
          let userTmp;
          for (let field of this.crpFE.parameters) {
            if (!field.userId && !field.territoryId && !field.roleId)
              field["defaultParameterFE"] = true;
            else {
              if (field.userId && this.usersOption && this.usersOption.length > 0) {
                userTmp = this.usersOption.filter((user) => { return user.idUtente == field.userId })[0];
                field["userFE"] = userTmp ? userTmp : null;
              }
              if (field.territoryId && this.customerOption && this.customerOption.length > 0) {
                this._customerTmp = null;
                this._getCustomerById(field.territoryId, this.customerOption);
                field["territoryFE"] = this._customerTmp ? [this._customerTmp] : [];
              }
              if (field.roleId && this.roleOption && this.roleOption.length > 0) {
                this._roleTmp = null;
                this._getRoleById(field.roleId, this.roleOption);
                field["roleFE"] = this._roleTmp ? [this._roleTmp] : [];
              }
            }
          }
        }
      }
    }).catch((err) => {

    })
  }
  //selectable tree popover
  async selectableTreePopoverRole(parameter?) {
    let tree = !parameter ? this.roleOption : JSON.parse(JSON.stringify(this.defaultRoleOption));
    if (parameter && parameter.roleFE) {
      this._selectRoleByRole(tree, parameter.roleFE[0]);
    }
    const popover = await this.popoverCtrl.create({
      component: SelectableTreePopoverComponent,
      cssClass: 'selectable-tree-popover',
      componentProps: {
        title: this.translate.instant('labels.roles'),
        tree: tree,
        titleKey: "denominazione",
        childrenKey: "figli",
        hideCheckboxParent: true,
        hideCheckboxRole: false,
        showRoleDetail: false,
        showRolesIndicator: true,
        disableRoleCheckbox: false,
        readOnly: false,
        allOpened: true,
        single: false,
        singleRole: parameter ? true : false,
        showSeletAllRoles: parameter ? true : false
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          if (!parameter) {
            this.roleIdList = [];
            this._getSelectedRoleList(res.data.tree, this.roleIdList);
            this.handlePermissionsChange();
          } else {
            parameter.roleFE = [];
            this._getSelectedRoleList(res.data.tree, parameter.roleFE);
            this.handleParameterChanged(parameter);
          }
        }
      });
    return await popover.present();
  }
  //get role list by customer ids list: get selected roles object by ids and set "selected" true
  private _getRoleListByRoleIdsList(roleIdsList) {
    let list = [];
    if (this.roleOption && this.roleOption.length > 0) {
      for (let roleId of roleIdsList) {
        this._roleTmp = null;
        this._getRoleById(roleId, this.roleOption);
        if (this._roleTmp) {
          this._roleTmp["selected"] = true;
          list.push(this._roleTmp);
        }
      }
    }
    return list;
  }
  //get role by id
  private _getRoleById(roleId, tree) {
    if (!this._roleTmp)
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].ruoli && tree[i].ruoli.length > 0) {
          for (let r = 0; r < tree[i].ruoli.length; r++) {
            if (tree[i].ruoli[r].id == roleId) {
              this._roleTmp = tree[i].ruoli[r];
              break;
            }
          }
        }
        if (!this._roleTmp && tree[i].figli && tree[i].figli.length > 0) {
          this._getRoleById(roleId, tree[i].figli);
        }
      }
  }
  //get selected role list
  private _getSelectedRoleList(tree, listTmp) {
    for (let item of tree) {
      if (item.ruoli && item.ruoli.length > 0) {
        for (let role of item.ruoli) {
          if (role.selected)
            listTmp.push(role);
        }
      }
      if (item.figli && item.figli.length > 0)
        this._getSelectedRoleList(item.figli, listTmp);
    }
  }
  private _selectRoleByRole(tree, role) {
    if (tree && role) {
      tree.forEach((territory) => {
        if (territory.idCliente == role.cliente && territory.ruoli && territory.ruoli.length > 0) {
          territory.ruoli.forEach((innerRole) => {
            if (innerRole.id == role.id)
              innerRole["selected"] = true;
          });
        }
        if (territory.figli && territory.figli.length > 0)
          this._selectRoleByRole(territory.figli, role)
      })
    }
  }
  //get role id list by roles list
  private _getRolesIdListByRoles(roles: Array<any>) {
    let rolesTmp: Array<string> = [];
    if (roles && roles.length > 0) {
      for (let role of roles) {
        rolesTmp.push(role.id);
      }
    }
    return rolesTmp;
  }
  //----- end selectable tree ------//

  //saveDisabled
  public saveDisabled() {
    if (this.crpFE) {
      if (this.currentTabindex == 0)
        return !this.instructionsChanged;
      if (this.currentTabindex == 1)
        return !this.permissionsChanged;
      if (this.currentTabindex == 2)
        return !this.parametersChanged;
      return true
    } else
      return false
  }

  //--- data changedd ---//
  //handleInstructionsChange
  public handleInstructionsChange(ev?) {
    //swap items if reorder
    if (ev) {
      let tmp = this.crpFE.instructions[ev.detail.to];
      this.crpFE.instructions[ev.detail.to] = this.crpFE.instructions[ev.detail.from];
      this.crpFE.instructions[ev.detail.from] = tmp;
      //complete ion reorder
      ev.detail.complete();
    }
    //set instructionsChanged true
    this.instructionsChanged = true;
  }
  public handlePermissionsChange() {
    this.permissionsChanged = true;
  }
  public deleteInstruction(index) {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.deleteInstructionMessage') }, () => {
      this.crpFE.instructions.splice(index, 1);
      this.instructionsChanged = true;
    })
  }
  public addInstruction() {
    this.crpFE.instructions.push({ text: null });
    this.instructionsChanged = true;
  }
  public handleParameterChanged(parameter) {
    if (['DATA', 'DATAORA'].includes(parameter.type))
      parameter.getdateFE = false;
    this.parametersChanged = true;
  }
  public cleanParameter(parameter) {
    parameter["value"] = null;
    this.parametersChanged = true;
  }
  //--- end data changed ---//

  //--- resize query wrapper ---//
  //dragstart
  public dragStart(ev: any) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  //dragover
  public dragover(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  //drop
  public drop(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  //resizeQueryWrapper
  public resizeQueryWrapper(event) {
    event.preventDefault();
    event.stopPropagation();
    let modalContent = document.getElementById("modal-content");
    if (!this.layoutConfig.vertical) {
      if ((event.pageX - modalContent.getBoundingClientRect().left) > 10 && (event.pageX - modalContent.getBoundingClientRect().left) < (modalContent.getBoundingClientRect().width - 10)) {
        let width = event.pageX - modalContent.getBoundingClientRect().left;
        this.layoutConfig.queryWrapperWidth = width;
        this.layoutConfig.tabsWrapperWidth = "calc(100% - " + this.layoutConfig.queryWrapperWidth + "px)";
      }
    } else {
      if ((event.pageY - modalContent.getBoundingClientRect().top) > 10 && (event.pageY - modalContent.getBoundingClientRect().top) < (modalContent.getBoundingClientRect().height - 10)) {
        let height = event.pageY - modalContent.getBoundingClientRect().top;
        this.layoutConfig.queryWrapperHeight = height;
        this.layoutConfig.tabsWrapperHeight = "calc(100% - " + this.layoutConfig.queryWrapperHeight + "px)";
      }
    }
    this._storageVariableSrv.setCrpLayoutConfig(this.layoutConfig);
  }

  //duplicateParameter
  public duplicateParameter(index) {
    let newParaeters = JSON.parse(JSON.stringify(this.crpFE.parameters[index]));
    newParaeters["defaultParameterFE"] = false;
    this.crpFE.parameters.splice(index + 1, 0, newParaeters)
  }

  //switchLayout
  public switchLayout() {
    this.layoutConfig.vertical = !this.layoutConfig.vertical;
    this._storageVariableSrv.setCrpLayoutConfig(this.layoutConfig);
  }

  //deleteParameter
  public deleteParameter(index) {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.deleteParameterMessage') }, () => {
      if (!this.crpFE.parameters[index].defaultParameterFE) {
        this.crpFE.parameters.splice(index, 1);
        this.parametersChanged = true;
      }
    })
  }

  //openComboEdit
  async openComboEdit(parameter, event) {
    const popover = await this.popoverCtrl.create({
      component: CustomReportComboEditComponent,
      cssClass: 'crp-combo-edit',
      event: event,
      showBackdrop: false,
      componentProps: {
        parameter: JSON.parse(JSON.stringify(parameter))
      }
    });
    popover.onDidDismiss().then((res) => {
      if (res && res.data && res.data.parameter) {
        for (let innerParameter of this.crpFE.parameters) {
          if (innerParameter.property == res.data.parameter.property && ["COMBO", "MULTICOMBO"].includes(innerParameter.type)) {
            innerParameter.options = res.data.parameter.options;
            innerParameter.value = null;
          }
        }
        this.parametersChanged = true;
      }
    })
    return await popover.present();
  }
  //--- end resize query wrapper ---//

  //--- saves ---//
  public enableDisableCrp() {
    this.alertSrv.warningConfirmAlert({ message: !this.crpFE.enabled ? this.translate.instant("messages.enableCrpMessage") : this.translate.instant("messages.disableCrpMessage") }, () => {
      this.loaderSrv.open();
      this.customReportsSrv.enableDisableCrp(this.crpFE.crpId, this.crpFE.enabled ? false : true).then(() => {
        this.loaderSrv.dismiss();
        this._getCrpDetails(this.crpFE.crpId);
      }).catch((err) => {
        this.loaderSrv.dismiss();
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    })
  }
  public save() {
    //save instructions
    if (this.currentTabindex == 0) {
      let instructionsTmp = JSON.parse(JSON.stringify(this.crpFE.instructions));
      for (let i = 0; i < instructionsTmp.length; i++) {
        if (i == 0)
          instructionsTmp[i]["idxQuery"] = "-1";
        else
          instructionsTmp[i]["idxQuery"] = "" + (i - 1);
      }
      this._saveInstructions(instructionsTmp);
    }
    //save  permissions
    else if (this.currentTabindex == 1) {
      this._savePermissions(this._getUsersIdListByUsers(this.userIdList), this._getRolesIdListByRoles(this.roleIdList), this._getCustomersIdListByCustomers(this.customerIdList));
    }
    //save parameters
    else if (this.currentTabindex == 2) {
      let parametersTmp = JSON.parse(JSON.stringify(this.crpFE.parameters)),
        tmpTerritory, tmpRole;
      //check duplicate default(no user,territory, role)
      let isValidList: boolean = true;
      for (let parameter of parametersTmp) {
        if (!parameter.defaultParameterFE && (!parameter.territoryFE || parameter.territoryFE.length == 0) && (!parameter.roleFE || parameter.roleFE.length == 0) && !parameter.userFE) {
          isValidList = false;
          break;
        }
      }
      if (isValidList) {
        //format value for backend
        for (let parameter of parametersTmp) {
          if ((parameter.value == null || parameter.value == undefined || parameter.value == "") && !parameter.getdateFE)
            parameter.value = null;
          else {
            if (parameter.type == "DATA") {
              if (parameter.getdateFE)
                parameter.value = "getdate()";
              else
                parameter.value = this.customReportsSrv.formatDateForBe(parameter.value);
            }
            if (parameter.type == "DATAORA") {
              if (parameter.getdateFE)
                parameter.value = "getdate()";
              else
                parameter.value = this.customReportsSrv.formatDateTimeForBe(parameter.value);
            }
            if(parameter.type == 'COMBO'){
              if(!parameter.endpointpreloaded){
                  parameter.value = JSON.stringify(parameter.value);
              } else {
                parameter.value = parameter.value;
              }
            }
          }
          //check for territory, role, user
          if (parameter.userFE) {
            parameter.userId = parameter.userFE.idUtente;
          }
          if (parameter.territoryFE) {
            tmpTerritory = this._getCustomersIdListByCustomers(parameter.territoryFE)[0];
            parameter.territoryId = tmpTerritory ? tmpTerritory : null;
          }
          if (parameter.roleFE) {
            tmpRole = this._getRolesIdListByRoles(parameter.roleFE)[0];
            parameter.roleId = tmpRole ? tmpRole : null;
          }
          //clean FE parameters
          delete parameter.userFE;
          delete parameter.territoryFE;
          delete parameter.roleFE;
          delete parameter.getdateFE;
          delete parameter.defaultParameterFE;
        }
        this._saveParameters(parametersTmp);
      } else {
        this.alertSrv.errorAlert(this.translate.instant("messages.duplicateDefaultParameter"));
      }
    }
  }
  //createCrp
  public createCrp() {
    this.loaderSrv.open();
    this.customReportsSrv.createCrp(this.newCrpForm.get("tipoReport").value, this.newCrpForm.get("descrizione").value).then((res: any) => {
      this.loaderSrv.dismiss();
      if (res) {
        this.modalCtrl.getTop().then((modal: HTMLIonModalElement) => {
          if (modal.classList.contains("crp-new-configuration-modal"))
            modal.classList.remove("crp-new-configuration-modal");
          this._setLayout();
          this.crpFE = res;
          this.crpFE["crpTitle"] = this.newCrpForm.get("descrizione").value;
          this._setCrpDetails(res);
        })
      }
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
    })
  }

  //saveInstructions
  private _saveInstructions(instructions) {
    this.loaderSrv.open();
    this.customReportsSrv.saveInstruction(instructions).then(() => {
      this.loaderSrv.dismiss();
      this.alertSrv.successAlert(this.translate.instant("labels.operation_succeed"));
      this.instructionsChanged = false;
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
        this.refreshCrp();
      }
    })
  }

  //savePermissions
  private _savePermissions(usersIds, rolesIds, territoriesIds) {
    this.loaderSrv.open();
    this.customReportsSrv.savePermission(this.crpFE.crpId, usersIds, rolesIds, territoriesIds).then(() => {
      this.loaderSrv.dismiss();
      this.alertSrv.successAlert(this.translate.instant("labels.operation_succeed"));
      this.permissionsChanged = false;
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
      this.refreshCrp();
    })
  }

  //saveParameters
  private _saveParameters(parameters) {
    this.loaderSrv.open();
    this.customReportsSrv.saveParameters(parameters).then(() => {
      this.loaderSrv.dismiss();
      this.alertSrv.successAlert(this.translate.instant("labels.operation_succeed"));
      this.parametersChanged = false;
      this.refreshCrp();
    }).catch((err) => {
      this.loaderSrv.dismiss();
      if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
        })
      }
      this.refreshCrp();
    })
  }
  //--- end saves ---//


  //refreshCrp
  public refreshCrp() {
    this._getCrpDetails(this.crpFE.crpId)
  }

  //copyToClipboard
  public copyToClipboard(text: string) {
    let queryTxt = 'update CrpReportsPortaleSafo\n set crpreport=\'' + text.replace(/'/g, "''") + '\' where id=' + this.crpFE.crpId;
    if (!navigator.clipboard) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = queryTxt;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    } else {
      let context = this;
      navigator.clipboard.writeText(queryTxt).then(
        function () {
          context.toastr.success(context.translate.instant("messages.copiedToClipboardMessage"), "", { timeOut: 5000, toastClass: "ngx-toastr success-toast" });
        })
        .catch(
          function () {
            context.toastr.error(context.translate.instant("messages.errCopiedToClipboardMessage"), "", { timeOut: 5000, toastClass: "ngx-toastr error-toast" });
          });
    }

  }
    //COMBO Selectable methods
 public getListByEndpoint (event, endPointUrl) {
  if(event.text != null && event.text != undefined && event.text != "")
  {
    endPointUrl = endPointUrl + event.text;
    event.component.startSearch();
    event.component.showLoading();
    this.customReportsSrv.getListByEndpoint(endPointUrl).then((res: any) => {
      event.component.items = res.result;
      event.component.hideLoading();
      event.component.endSearch();
    }).catch((err) => {
      if (err && err.status && err.status != 401 && err.status != 403) {
        this.httpUtilitySrv.getRequestError(err).then((err: any) => {
          this.alertSrv.errorAlert(err);
          event.component.hideLoading();
          event.component.endSearch();
        })
      }
    })
    }else {
      event.component.items = null;
    }
  }

  public isSelectableItemsEmpty(id) {
    let selectable;
    if (id == 'selectableField') {
      selectable = this.selectableField;
    }
    return selectable ? selectable.items : null;
  }

  private getFirstTwoProperties(response) {
    const firstTwoProperties = [];

    let count = 0;
    for (const key in response) {
        if (response.hasOwnProperty(key) && count < 2) {
            firstTwoProperties.push(response[key]);
            count++;
        }
    }

    return firstTwoProperties;
}

}
