<ion-content>
  <ion-card class="modal-page-card" [ngClass]="{'comparator-card': modalNavParams.showComparison}">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{modalNavParams.showComparison ? ('labels.compare' | translate) :
          ('labels.updateField' | translate)}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <ion-row>
        <ion-col size-md="12" size-sm="12" size-xs="12"
          *ngIf="modalNavParams.message && !modalNavParams.showComparison">
          <div class="message">{{modalNavParams.message}}</div>
        </ion-col>
        <ion-col *ngFor="let column of modalNavParams.columns;" [attr.size-md]="'12'" [attr.size-sm]="'12'"
          [attr.size-xs]="'12'" class="input-box">
          <div class="message-column"
            *ngIf="column.columnMessage && !modalNavParams.message && !modalNavParams.showComparison">
            {{column.columnMessage}}</div>
          <ion-label class="input-label">{{column.columnHeader ? (column.columnHeader | translate) :''}} </ion-label>
          <div class="input-box-data">
            <!-- COMPARATOR -->
            <div class="input-comparator"
              *ngIf="modalNavParams.showComparison && modalNavParams.columns[0].columnHeaderComparisonOptions">
              <select [(ngModel)]="comparison">
                <option *ngFor="let option of modalNavParams.columns[0].columnHeaderComparisonOptions">{{option}}
                </option>
              </select>
            </div>
            <!-- TEXT TANSLATE INPUT_STRINGA INPUT_POPUP_STRINGA-->
            <div class="input-column"
              *ngIf="column.columnType == tableColumnTypes.TEXT || column.columnType == tableColumnTypes.TRANSLATE  || column.columnType == tableColumnTypes.INPUT_STRINGA || column.columnType == tableColumnTypes.INPUT_POPUP_STRINGA">
              <input type="text" [(ngModel)]="modalNavParams.element[column.columnDef]"
                placeholder="{{'labels.insert' | translate}}">
            </div>
            <!-- NUMERO DECIMALE INPUT_NUMERO INPUT_POPUP_NUMERO INPUT_DECIMALE INPUT_POPUP_DECIMALE -->
            <div class="input-column"
              *ngIf="column.columnType == tableColumnTypes.NUMERO || column.columnType == tableColumnTypes.DECIMALE || column.columnType == tableColumnTypes.INPUT_NUMERO || column.columnType == tableColumnTypes.INPUT_POPUP_NUMERO ||
            column.columnType == tableColumnTypes.INPUT_DECIMALE || column.columnType == tableColumnTypes.INPUT_POPUP_DECIMALE || column.columnType == tableColumnTypes.NUMERO_SEPARATORI">
              <input type="number" [(ngModel)]="modalNavParams.element[column.columnDef]"
                placeholder="{{'labels.insert' | translate}}">
            </div>
            <!-- DATE INPUT_DATA INPUT_POPUP_DATA-->
            <div class="input-column"
              *ngIf="column.columnType == tableColumnTypes.DATE || column.columnType == tableColumnTypes.INPUT_DATA  || column.columnType == tableColumnTypes.INPUT_POPUP_DATA">
              <div class="date-time-input">
                <input matInput [matDatepicker]="datetimepicker" [(ngModel)]="modalNavParams.element[column.columnDef]"
                  [ngClass]="{'empty-date-input': !modalNavParams.element[column.columnDef]}"
                  placeholder="{{'labels.insert' | translate}}">
                <mat-datepicker-toggle matSuffix [for]="$any(datetimepicker)"
                  [ngClass]="{'empty-date-input': !modalNavParams.element[column.columnDef]}">
                </mat-datepicker-toggle>
                <mat-datepicker #datetimepicker>
                </mat-datepicker>
              </div>
            </div>
            <!-- DATE_TIME INPUT_DATAORA INPUT_POPUP_DATAORA-->
            <div class="input-column"
              *ngIf="column.columnType == tableColumnTypes.DATE_TIME || column.columnType == tableColumnTypes.INPUT_DATAORA  || column.columnType == tableColumnTypes.INPUT_POPUP_DATAORA">
              <div class="date-time-input">
                <input matInput [ngxMatDatetimePicker]="$any(datetimepicker)"
                  [(ngModel)]="modalNavParams.element[column.columnDef]"
                  [ngClass]="{'empty-date-input': !modalNavParams.element[column.columnDef]}"
                  placeholder="{{'labels.insert' | translate}}">
                <mat-datepicker-toggle matSuffix [for]="$any(datetimepicker)"
                  [ngClass]="{'empty-date-input': !modalNavParams.element[column.columnDef]}">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datetimepicker [showSeconds]="false">
                </ngx-mat-datetime-picker>
              </div>
            </div>
            <!-- INPUT_COMBO INPUT_POPUP_COMBO-->
            <div class="input-column"
              *ngIf="(column.columnType == tableColumnTypes.INPUT_COMBO || column.columnType == tableColumnTypes.INPUT_POPUP_COMBO) && column.columnParam">
              <ion-select [(ngModel)]="modalNavParams.element[column.columnDef]"
                [cancelText]="'buttons.cancel' | translate" interface="popover" [okText]="'buttons.ok' | translate"
                placeholder="{{'labels.select' | translate}}" multiple="false">
                <ion-select-option [value]="option" *ngFor="let option of column.columnParam">
                  {{option.desc}}</ion-select-option>
              </ion-select>
            </div>
            <!-- INPUT_MULTICOMBO INPUT_POPUP_MULTICOMBO-->
            <div class="input-column"
              *ngIf="(column.columnType == tableColumnTypes.INPUT_MULTICOMBO || column.columnType == tableColumnTypes.INPUT_POPUP_MULTICOMBO) && column.columnParam">
              <ion-select [(ngModel)]="modalNavParams.element[column.columnDef]"
                [cancelText]="'buttons.cancel' | translate" [okText]="'buttons.ok' | translate"
                placeholder="{{'labels.select' | translate}}" multiple="true">
                <ion-select-option [value]="option" *ngFor="let option of column.columnParam">
                  {{option.desc}}</ion-select-option>
              </ion-select>
            </div>
            <!-- INPUT_RADIO INPUT_POPUP_RADIO-->
            <div class="input-column"
              *ngIf="(column.columnType == tableColumnTypes.INPUT_RADIO || column.columnType == tableColumnTypes.INPUT_POPUP_RADIO) && column.columnParam">
              <div class="radio-container">
                <div *ngFor="let radio of column.columnParam" class="input-radio"
                  [ngClass]="{'radio-selected': modalNavParams.element[column.columnDef] && modalNavParams.element[column.columnDef].id == radio.id}">
                  <input id="{{radio.id}}-{{radio.desc}}" type="radio" name="{{column.columnDef}}" [value]="radio"
                    [(ngModel)]="modalNavParams.element[column.columnDef]">
                  <label for="{{radio.id}}-{{radio.desc}}">{{radio.desc}}</label>
                </div>
              </div>
            </div>
            <!-- BOOLEAN BOOLEAN_CHECKBOX INPUT_BOOLEAN INPUT_POPUP_BOOLEN-->
            <div class="input-column" *ngIf="column.columnType == tableColumnTypes.BOOLEAN || column.columnType == tableColumnTypes.INPUT_BOOLEAN || column.columnType == tableColumnTypes.BOOLEAN_CHECKBOX 
            || column.columnType == tableColumnTypes.INPUT_POPUP_BOOLEAN">
              <input type="checkbox" [(ngModel)]="modalNavParams.element[column.columnDef]">
            </div>
          </div>

        </ion-col>
      </ion-row>
      <ion-row>
        <div class="button-box" *ngIf="!modalNavParams.showComparison">
          <ion-button color="tertiary" size="default" class="small-button" (click)="popoverCtrl.dismiss()">
            <ion-label>{{'buttons.cancel' | translate}}</ion-label>
          </ion-button>
          <ion-button color="success" size="default" class="small-button" (click)="save()">
            <ion-label>{{'buttons.save' | translate}}</ion-label>
          </ion-button>
        </div>
        <div class="button-box" *ngIf="modalNavParams.showComparison">
          <ion-button size="small" class="small-button" (click)="save()">
            <ion-label>{{'buttons.applyCompare' | translate}}</ion-label>
          </ion-button>
        </div>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>