import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/internal/operators/map';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

@Component({
  selector: 'app-recipients-list-popover',
  templateUrl: './recipients-list-popover.component.html',
  styleUrls: ['./recipients-list-popover.component.scss'],
})
export class RecipientsListPopoverComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //form
  public searchForm;
  //dataSource
  public dataSource;
  //displayedColumns
  public displayedColumns = [
    "nominativo",
    "messaggioLetto"
  ]
  //paginator
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //search input
  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;

  constructor(public router: Router,
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    private alertSrv: AlertService) {
    //setup validators
    this.searchForm = this.formBuilder.group({
      name: [null]
    })
    //setup nav params
    this.modalNavParams =
    {
      list: this.navParams.get("list") ? this.navParams.get("list") : [],
      hideRead: this.navParams.get("hideRead") ? this.navParams.get("hideRead") : false
    }
    if(this.modalNavParams.hideRead){
      this.displayedColumns = [
        "nominativo"
      ]
    }
    this.dataSource = new MatTableDataSource(this.modalNavParams.list);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.dataSource.filter = this.searchForm.get('name').value;
      });
  }

  //cleanFilters
  public cleanFilters() {
    this.searchForm.get('name').setValue(null);
    this.dataSource = new MatTableDataSource(this.modalNavParams.list);
  }

}
