import { NgModule } from '@angular/core';
import { RecipientsListPopoverComponent } from './recipients-list-popover.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
@NgModule({
    declarations: [
        RecipientsListPopoverComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule
    ],
    exports: [
        RecipientsListPopoverComponent
    ]
})
export class RecipientsListPopoverComponentModule { }