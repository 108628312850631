import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'pssfe-dropdown-prompt',
  templateUrl: './pssfe-dropdown-prompt.component.html',
  styleUrls: ['./pssfe-dropdown-prompt.component.scss'],
})
export class PssfeDropdownPromptComponent implements OnInit, AfterViewInit {
  //optionsList: lista delle opzioni per la selezione
  public optionsList: Array<any>;
  public filteredOptionsList: Array<any>;
  //optionValue: proprietà di riferimento per il valore. Occorre quando le opzioni sono oggetti
  public optionValue: string;
  //optionText: proprietà per testo da mostrare per le opzioni
  public optionText: string;
  //multiple: abilita selezione multipla
  public multiple: boolean;
  //searchEndpoint: endpoint per la ricerca lato server
  public searchEndpoint: string;
  //searchPlaceholder: placeholder per casella ricerca
  public searchPlaceholder: string;
  //value: valore per ngModel
  public value: any;
  //canClean: permette la pulizia della selezione
  public canClean: boolean;
  //searchValue: valore della casella di ricerca
  public searchValue: string;
  //noResultText: testo per nessun risultato
  public noResultText: string;
  //search input
  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;
  public searchLoading: boolean;
  public searchDone: boolean;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public httpUtilitySrv: HttpUtilityService,
    public navParams: NavParams,
    public modalCtrl: ModalController
  ) {
    this.optionsList = this.navParams.get("optionsList") ? this.navParams.get("optionsList") : [];
    this.filteredOptionsList = this.navParams.get("optionsList") ? this.navParams.get("optionsList") : [];
    this.optionValue = this.navParams.get("optionValue");
    this.optionText = this.navParams.get("optionText");
    this.multiple = this.navParams.get("multiple");
    this.searchEndpoint = this.navParams.get("searchEndpoint");
    this.searchPlaceholder = this.navParams.get("searchPlaceholder");
    this.value = this.navParams.get("value");
    this.canClean = this.navParams.get("canClean");
    this.searchValue = this.navParams.get("searchValue");
    this.noResultText = this.navParams.get("noResultText");
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((event) => {
        this.search();
      });
  }

  //optionSelected: selezione delle singole opzioni
  public optionSelected(option: any) {
    //valore opzione optionValue ? [{}] | {} : [""] | ""
    let optionValueFilter: any, optionValueTmp;
    //selezione singola
    if (!this.multiple) {
      this.value = option;
      //il parametro updated è utilizzato per differire tra chiusura backdrop da selezione
      this.modalCtrl.dismiss({ value: this.value, updated: true });
    }
    //selezione multipla
    else {
      //se opzione non selezionata controllare se array esistente prima di aggiungerla
      if (!this.isOptionSelected(option)) {
        if (!this.value || !Array.from(this.value)) {
          this.value = [option];
        } else {
          this.value.push(option);
        }
      }
      //già selezionata (rimuovi)
      else {
        this.value = this.value.filter((optionFilter) => {
          optionValueFilter = this.optionValue ? optionFilter[this.optionValue] : optionFilter;
          optionValueTmp = this.optionValue ? option[this.optionValue] : option;
          return optionValueFilter != optionValueTmp;
        });
      }
    }
  }

  //isOptionSelected: controlla se l'opzione è selezionata
  public isOptionSelected(option) {
    if (this.value) {
      let optionValue = this.optionValue ? option[this.optionValue] : option,
        currentValue;
      //selezione singola
      if (!this.multiple) {
        currentValue = this.optionValue ? this.value[this.optionValue] : this.value
        return optionValue == currentValue;
      } else {
        return this.value.filter((optionFilter) => {
          currentValue = this.optionValue ? optionFilter[this.optionValue] : optionFilter;
          return optionValue == currentValue;
        })[0];
      }
    } else
      return false;
  }

  //save muliple: salva la selezione corrente
  public saveMultiple() {
    this.modalCtrl.dismiss({ value: this.value, updated: true });
  }

  //clean: pulisce la selezione
  public clean() {
    this.value = this.multiple ? [] : null;
  }

  //close: chiude il popover
  public close() {
    this.modalCtrl.dismiss();
  }

  //search
  public search() {
    let optionTextString: string;
    //search without endpoint
    if (!this.searchEndpoint) {
      this.filteredOptionsList = JSON.parse(JSON.stringify(this.optionsList));
      if (this.searchValue != undefined && this.searchValue != null && this.searchValue != "") {
        this.filteredOptionsList = this.filteredOptionsList.filter((option) => {
          optionTextString = this.optionText ? option[this.optionText] : option;
          optionTextString = optionTextString.toString();
          return optionTextString.toUpperCase().indexOf(this.searchValue.toString().toUpperCase()) != -1;
        })
      }
      this.searchDone = true;
    }
    //search by endpoint
    else {
      this.searchLoading = true;
      //add search value to endpoint
      let endpointTmp = JSON.parse(JSON.stringify(this.searchEndpoint));
      endpointTmp = endpointTmp.replace(/\[searchValue\]/g, this.searchValue);
      this.httpUtilitySrv.genericGet(endpointTmp).then((res) => {
        let url = new URL(this.searchEndpoint);
        let urlParams = url.searchParams;
        if (res && Array.isArray(res)) {
          this.optionsList = res;
          this.filteredOptionsList = JSON.parse(JSON.stringify(this.optionsList));
        } else if (res) {
          if (res[urlParams.get("searchResultTarget")]) {
            this.optionsList = res[urlParams.get("searchResultTarget")];
            this.filteredOptionsList = JSON.parse(JSON.stringify(this.optionsList));
          } else {
            this.optionsList = [];
            this.filteredOptionsList = [];
          }
        } else {
          this.optionsList = [];
          this.filteredOptionsList = [];
        }
        this.searchLoading = false;
        this.searchDone = true;
      }).catch((err) => {
        this.searchLoading = false;
        this.searchDone = true;
        if ((err && !err.status) || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
          })
        }
      })
    }
  }
}
