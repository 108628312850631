import { Injectable } from '@angular/core';
import { HttpUtilityService } from './http-utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminUserDetailService {

  constructor(public httpUtilitySrv: HttpUtilityService,
    public http: HttpClient) { }

  //get ruoli api request
  public getRuoli() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getRuoli`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get clienti api request
  public getClienti() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getClienti`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get full clienti api request
  public getFullClienti() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}getFullClienti`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //get dettaglio utente api request
  public getDettaglioUtente(userId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}dettaglioUtente/` + userId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //elimina utente api request
  public eliminaUtente(userId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.httpUtilitySrv.links.server}eliminaUtente/` + userId).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //impersona utente api request
  public impersonaUtente(userId: number) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        let payload = {
          "idUtente": userId
        }
        this.http.post(`${this.httpUtilitySrv.links.server}impersonaUtente`, payload, { observe: 'response' }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }

  //stop impersona utente api request
  public stopImpersonaUtente(guid: string) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        let payload = {
          "id": guid
        }
        this.http.post(`${this.httpUtilitySrv.links.server}annullaImpersonaUtente`, payload, { observe: 'response' }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }

  //inserisci utente api request
  public inserisciUtente(username, email, name, surname, list, language, phone, proprietario, enabled, services, tempPass) {
    return new Promise((resolve, reject) => {
      let payload = {
        "userName": username,
        "emails": [{
          "email": email,
          "descEmail": "",
        }],
        "Nome": name,
        "Cognome": surname,
        "clienti": list,
        "language": language,
        "descUtente": '',
        "telefono": phone,
        "idProprietario": proprietario,
        "link": `${this.httpUtilitySrv.getBaseUri()}reset-password`,
        "attivo": enabled,
        "newServizi": services && services.length > 0 ? services : null,
        "passwordTemporanea": tempPass ? tempPass : null
      }
      this.http.post(`${this.httpUtilitySrv.links.server}inserisciUtente`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //modifica utente api request
  public modificaUtente(userId, username, email, name, surname, list, language, phone, proprietario, enabled, services, tempPass) {
    return new Promise((resolve, reject) => {
      let payload = {
        "idUtente": userId,
        "userName": username,
        "emails": [{
          "email": email,
          "descEmail": ""
        }],
        "Nome": name,
        "Cognome": surname,
        "clienti": list,
        "language": language,
        "descUtente": '',
        "telefono": phone,
        "idProprietario": proprietario,
        "attivo": enabled,
        "newServizi": services && services.length > 0 ? services : null,
        "passwordTemporanea": tempPass ? tempPass : null
      }
      this.http.post(`${this.httpUtilitySrv.links.server}modificaUtente`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        
        reject(err);
      })
    });
  }

  //getListaChiaviValoreUtenteServiziSafo api request
  public getListaChiaviValoreUtenteServiziSafo(userId, rolesIdlist) {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        let payload = {
          "idUtente": userId,
          "idRuoliSelezionati": rolesIdlist
        }
        this.http.post(`${this.httpUtilitySrv.links.server}getListaChiaviValoreUtenteServiziSafo`, payload).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }

  //get temp pass
  public getTempPass() {
    return new Promise((resolve, reject) => {
      this.httpUtilitySrv.getHttpHeader().then((headers: any) => {
        this.http.get(`${this.httpUtilitySrv.links.server}generaPasswordTemporanea`).subscribe((res) => {
          resolve(res);
        }, (err) => {
          
          reject(err);
        })
      }).catch((err) => {
        reject(err)
      });
    });
  }
}
