import { NgModule } from '@angular/core';
import { LoadingLogPopoverComponent } from './loading-log-popover.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
;
import { InfiniteTreeComponentModule } from '../../components/infinite-tree/infinite-tree.component.module'
@NgModule({
    declarations: [
        LoadingLogPopoverComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
    
        InfiniteTreeComponentModule
    ],
    exports: [
        LoadingLogPopoverComponent
    ]
})
export class LoadingLogPopoverComponentModule {}