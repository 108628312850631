<ion-content class="pending-request-popover">
  <div class="group-header">
    {{'labels.pendingRequests' | translate}}
  </div>
  <div class="request-list" *ngIf="pendingRequests && pendingRequests.length > 0">
    <div class="request" *ngFor="let request of pendingRequests">
      <ion-spinner name="dots"></ion-spinner>
      <span>{{('requestPendingPath.' + request.extractionId) | translate}}</span>
    </div>
  </div>
  <div class="no-result" *ngIf="!pendingRequests || pendingRequests.length == 0">
    {{'labels.noRequestsPenging' | translate}}
  </div>
</ion-content>