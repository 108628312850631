<ion-content class="selectable-modules-component" >
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.modules' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <div *ngIf="modalNavParams && modalNavParams.tree" class="mt-10px mb-10px">
        <app-modules-tree *ngFor="let item of modalNavParams.tree" [item]="item"
          [checkboxDisabled]="modalNavParams.checkboxDisabled" (handleCheckbox)="handleCheckbox($event)">
        </app-modules-tree>
      </div>
    </ion-card-content>
  </ion-card>
  <div class="button-box">
    <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
      <ion-label>{{'buttons.cancel' | translate}}</ion-label>
    </ion-button>
    <ion-button color="primary" size="default" (click)="select()">
      <ion-label>{{'buttons.select' | translate}}</ion-label>
    </ion-button>
  </div>
</ion-content>