import { throwError as observableThrowError, Observable, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth-service.service';
import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { StorageVariablesService } from '../services/storage-variables.service';
import { AlertService } from '../services/alert.service';
import { LanguageService } from '../services/language.service';
import { AppService } from '../services/app.service';
import { HttpUtilityService } from '../services/http-utility.service';
import { AppDriverStateService } from 'src/projects/mobile/app-driver/services/app-driver-state.service';
import { AppDriverFlags } from 'src/projects/mobile/app-driver/models/app-driver.models';
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  //show messsage
  public showMessage = true;

  constructor(public router: Router, public authSrv: AuthService, public alertCtrl: AlertController, public popoverCtrl: PopoverController,
    public modalCtrl: ModalController, public storaveVariableSrv: StorageVariablesService, public languageSrv: LanguageService, private appSrv: AppService, public http: HttpClient,
    private _alertSrv: AlertService, private _httpUtilitySrv: HttpUtilityService, private _platform: Platform, private _appDriverState: AppDriverStateService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            //check for token
            /*let newToken = event.headers.get('token');
          if (newToken != null && newToken != undefined && newToken != '' && !event.url.endsWith("\/getMenu") && !event.url.endsWith("\/user/configuration")) {
             this.authSrv.getAuthObject().then((authObject: any) => {
               if (authObject && authObject.userData && authObject.userData.token) {
                 authObject.userData.token = newToken;
                 this.authSrv.setAuthObject(authObject);
                 this.authSrv.getAuthObject().then((res: any) => {
                 })
               }
             })
           }*/
            event = event.clone({ body: event.body })
            //token not valid
            if (event.body && event.body.code == 3) {
              this.popoverCtrl.dismiss();
              this.authSrv.setAuthObject(null);
              this.storaveVariableSrv.setAdminImpersGuidObject(null);
              if (this.showMessage) {
                this.languageSrv.transaltePathsPromise(['labels.operation_error']).then((translationList) => {
                  this.errorAlert(translationList['labels.operation_error']);
                })
                this.showMessage = false;
              }
              this.appSrv.navigate('/login');
            }
          }
          return event;
        }),
        catchError(async (err) => {
          if (err.status == 401 && !req.url.endsWith("\/login") && req.url.indexOf("external") == -1 && req.url.indexOf("user/configuration") == -1) {
            let refreshTokenReq: { response, error };
            if (req.url.indexOf("refreshToken") != -1 || req.headers.get('skipRetry') == 'true')
              refreshTokenReq = { response: null, error: err }
            else
              refreshTokenReq = await this._refreshToken({ method: req.method, url: req.url, body: req.body, headers: req.headers, params: req.params }, err);
            if (refreshTokenReq.error) {
              //401 403 logout
              if (refreshTokenReq.error?.status == 401 || refreshTokenReq.error?.status == 403) {
                this.authSrv.getAuthObject().then(async (authObject: any) => {
                  if (authObject && authObject.role)
                    this.authSrv.setLastRoleObject(authObject.role)
                  this.authSrv.setAuthObject(null);
                  this.storaveVariableSrv.setAdminImpersGuidObject(null);
                  if (this._platform.is('capacitor')) {
                    this._appDriverState.setState(null);
                    let flags: AppDriverFlags = await this._appDriverState.getFlags();
                    flags.rememberMe = false;
                    await this._appDriverState.setFlags(flags);
                  }
                  this.dismissAllModalsAndPopovers();
                  if (this.showMessage && window.location.href.indexOf("login") == -1 && window.location.href.indexOf("reset-password") == -1 && req.url.indexOf('userConfiguration') == -1) {
                    this.languageSrv.transaltePathsPromise(['messages.session_expired_message', 'labels.operation_error']).then((translationList) => {
                      this.errorAlert(translationList['messages.session_expired_message'], () => {
                        if (window.location.href.indexOf("reset-password") == -1)
                          this.appSrv.navigate('/login');
                      });
                    })
                    this.showMessage = false;
                  }
                })
              } else {
                throw new HttpErrorResponse(refreshTokenReq.error);
              }
            } else {
              return refreshTokenReq.response;
            }
          }
          if (err.status == 403 && !req.url.endsWith("\/login") && req.url.indexOf("traduzioni/getTranslations") == -1) {
            this.authSrv.setAuthObject(null);
            this.storaveVariableSrv.setAdminImpersGuidObject(null);
            if (this.showMessage && window.location.href.indexOf("login") == -1 && window.location.href.indexOf("reset-password") == -1) {
              this.languageSrv.transaltePathsPromise(['errors.7', 'labels.operation_error']).then((translationList) => {
                this.errorAlert(translationList['errors.7']);
              })
              this.showMessage = false;
            }
            this.dismissAllModalsAndPopovers();
            if (window.location.href.indexOf("reset-password") == -1)
              this.router.navigateByUrl('/login');
          }
          if (err.status == 404 && !this._bypassErrorControlByEndpoint(req.url) && !this._bypassErrorControlByLocation()) {
            if (this.showMessage) {
              this.languageSrv.transaltePathsPromise(['errors.1', 'labels.operation_error']).then((translationList) => {
                this.errorAlert(translationList['errors.1']);
              })

              this.showMessage = false;
            }
          }
          const error = this._bypassErrorControlByLocation() || this._bypassErrorControlByEndpoint(req.url) ? err : err.error?.message || err.error?.detail || err.statusText;
          //console.log(error)
          throw new HttpErrorResponse(error);
          //return observableThrowError(error);
        })
      )
  }

  //error alert
  async errorAlert(message, callback?: any) {
    await this._alertSrv.errorAlert(message, () => {
      this.showMessage = true;
      if (callback)
        callback();
    });
    /*message = '<div class="alert-service-title title-danger">' +
      '<ion-icon name="warning-outline"></ion-icon>' +
      '&nbsp;' + '<span>' + title + '</span>' +
      '</div>' +
      '<div class="alert-service-message">' +
      '<span>' + message + '</span>' +
      '</div>';
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      message: message,
      buttons: [
        {
          text: this.languageSrv.instant('buttons.ok'),
          cssClass: 'secondary'
        }
      ]
    });

    alert.onDidDismiss().then(() => {
      this.showMessage = true;
    })
    await alert.present()*/
  }

  //dismiss all modals and popovers
  private dismissAllModalsAndPopovers() {
    this.modalCtrl.getTop().then((modal) => {
      if (modal) {
        this.modalCtrl.dismiss().then(() => {
          //this.dismissAllModalsAndPopovers();
        });
      }
    })
    this.popoverCtrl.getTop().then((popover) => {
      if (popover) {
        this.popoverCtrl.dismiss().then(() => {
          //this.dismissAllModalsAndPopovers();
        });
      }
    })
  }

  //bypassErrorControl
  private _bypassErrorControlByLocation() {
    let baseList = ["/odeva/", "/view-and-fine/", "/admin-postit-board", "/custom-report-search", "/custom-report", "/custom-ocr", "/admin-dashboard-carousell", "/admin-restgenerator-dashboard",
      "/admin-message-broker", "/admin-scheduler", "/form", "/ald/polling-request", "/route/gemtoll"];
    for (let base of baseList) {
      if (window.location.href.indexOf(base) != -1)
        return true
    }
    return false;
  }

  //bypassErrorControlByEndpoint
  private _bypassErrorControlByEndpoint(endpoint: string) {
    let baseList = ["traduzioni/getTranslations", "/styles/", "/LIM", "/GEM", "/Assistant/"];
    for (let base of baseList) {
      if (endpoint.indexOf(base) != -1)
        return true
    }
    return false;
  }

  //regenerateSessionToken
  private async _refreshToken(originalReqeust: { method: string, url: string, body: any, headers: HttpHeaders, params: HttpParams }, originalError: HttpErrorResponse): Promise<{ response: any, error: HttpErrorResponse }> {
    return new Promise(async (resolve) => {
      let authObject: any = await this.authSrv.getAuthObject();
      if (authObject) {
        //set token as refreshToken
        authObject.userData.token = authObject?.userData?.refreshToken;
        await this.authSrv.setAuthObjectAsync(authObject);
        this.http.get(`${this._httpUtilitySrv.links.server}refreshToken`, { observe: 'response' }).subscribe({
          next: async (refreshTokenResponse: HttpResponse<any>) => {
            if (refreshTokenResponse?.body?.status?.toString().toUpperCase() == 'OK') {
              //tokens
              authObject.userData.token = refreshTokenResponse.headers.get('token');
              authObject.userData['refreshToken'] = refreshTokenResponse.headers.get('tokenrefresh');
              await this.authSrv.setAuthObject(authObject);
              //retry request
              let headers = {};
              for (let key of originalReqeust.headers.keys()) {
                headers[key] = originalReqeust.headers.get(key);
              }
              headers['skipRetry'] = 'true';
              this.http.request(originalReqeust.method, originalReqeust.url, { ...originalReqeust.params, body: originalReqeust.body, headers: headers, observe: 'response' }).subscribe({
                next: (newResponse) => {
                  resolve({ response: newResponse, error: null });
                },
                error: (newError) => {
                  resolve({ response: null, error: newError as HttpErrorResponse });
                }
              })
            } else {
              resolve({ response: null, error: new HttpErrorResponse({}) });
            }
          },
          error: () => {
            resolve({ response: null, error: originalError as HttpErrorResponse });
          }
        })
      } else
        resolve({ response: null, error: originalError as HttpErrorResponse });
    });
  }
}



