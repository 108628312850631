import { NgModule } from '@angular/core';
import { CustomReportsComponent } from './custom-reports.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableComponentModule } from '../../../app/shared-components/table/table.component.module';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { getDateFormat, getDateTimeFormat} from 'src/app/services/app.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFormatPlaceholderPipeModule } from 'src/app/pipes/date-format-placeholder-pipe/date-format-placeholder.pipe.module';
import { NgChartsModule } from 'ng2-charts';
import { IonicSelectableComponent } from 'ionic-selectable';
@NgModule({
    declarations: [
        CustomReportsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        DragDropModule,
        TableComponentModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
        DateFormatPlaceholderPipeModule,
        NgChartsModule,
        IonicSelectableComponent
    ],
    exports: [
        CustomReportsComponent
    ],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat()},
        {
            provide: DateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: getDateFormat() },
    ]
})
export class CustomReportsComponentModule { }