import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AlertService } from 'src/app/services/alert.service';
import { tableColumn, tableSettingsEvent, columnTypes } from '../table/models/table-models';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-table-settings-component',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
})
export class TableSettingsComponent implements OnInit {
  //modal nav params
  public modalNavParams;
  //masterCheckbox
  public masterCheckbox: boolean;
  //columnTypes
  public columnTypes = columnTypes;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    private alertSrv: AlertService,
  ) {
    //setup nav params
    this.modalNavParams =
    {
      tableColumns: this.navParams.get("tableColumns") ? this.navParams.get("tableColumns") : null
    }
    this.isAllSelected();
  }

  ngOnInit() {

  }

  //resetColumns
  public resetColumns() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant("messages.resetColumnMessage") }, () => {
      let dismissPayload: tableSettingsEvent = {
        tableColumns: null
      }
      this.modalCtrl.dismiss(dismissPayload);
    })
  }

  //save
  public save() {
    let dismissPayload: tableSettingsEvent = {
      tableColumns: this.modalNavParams.tableColumns
    }
    this.modalCtrl.dismiss(dismissPayload);
  }

  //masterCheckboxChanged
  public masterCheckboxChanged() {
    if (this.modalNavParams && this.modalNavParams.tableColumns && this.modalNavParams.tableColumns.length > 0) {
      this.modalNavParams.tableColumns.forEach((column: tableColumn) => {
        column.columnHidden = this.masterCheckbox;
      });
    }
  }

  //is all selected
  public isAllSelected() {
    if (this.modalNavParams && this.modalNavParams.tableColumns && this.modalNavParams.tableColumns.length > 0) {
      let totalSelected = this.modalNavParams.tableColumns.filter((x: tableColumn) => {
        return x.columnHidden
      }).length
      if (totalSelected == this.modalNavParams.tableColumns.length) {
        this.masterCheckbox = true;
      }
      else
        this.masterCheckbox = false;
    } else
      this.masterCheckbox = false;
  }

  
  //--- ORDER COLUMNS ---//
  
  //dropListDropped
  public dropListDropped(event: any) {
    if (event) {
      moveItemInArray(this.modalNavParams.tableColumns, event.previousIndex, event.currentIndex);
      this.moveColumns();
    }
  }
  private moveColumns() {
    this.modalNavParams.tableColumns.forEach((colunm, index) => {
      colunm.columnIndex = index;
    });
  }
  public handleColumnsReorder(ev) {
    if (ev) {
      let tmp = this.modalNavParams.tableColumns[ev.detail.from];
      this.modalNavParams.tableColumns.splice(ev.detail.from, 1);
      this.modalNavParams.tableColumns.splice(ev.detail.to, 0, tmp);
      //complete ion reorder
      ev.detail.complete();
      this.moveColumns();
    }
  }
  //--- END ORDER COLUMNS ---//

  //handleCOlumnStickyLeft
  public handleColumnStickyLeft(i) {
    for (let j = 0; j < this.modalNavParams.tableColumns.length; j++) {
      if (!this.modalNavParams.tableColumns[i].columnStickyLeft)
        this.modalNavParams.tableColumns[j].columnStickyLeft = j <= i ? true : false;
      else if (j >= i)
        this.modalNavParams.tableColumns[j].columnStickyLeft = false;
    }
  }
}
