<div class="gauge">
    <div class="gauge-chart">
        <div class="gauge-body">
          <div class="gauge-fill" [style.transform]="'rotate(' + rotationValue + 'deg)'" [style.background]="getSemiCircleBackground()"></div>
          <div class="gauge-cover"></div>
        </div>
      </div>
    <div class="gauge-data">
        <div class="gauge-data-value">
            {{value}}
        </div>
        <div class="gauge-data-range">
            <span class="min">{{min}}</span>
            <span>{{label}}</span>
            <span class="max">{{max}}</span>
        </div>
    </div>
</div>