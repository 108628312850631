import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { tableColumn } from '../../table/models/table-models';

export interface crpFE {
    crpTitle: string, //titolo della crp
    formGroup: FormGroup, //lista di campi per la costruzione del form di ricerca da BE: {property: string, type: string}
    formGroupConfig: any, //lista di campi restituiti da BE
    crpId: string, //fornito da be
    crpRequestToken: string, //fornito da be
    crpSubTitle: null, //sottotitolo
    tipo: string,//fornito da be
    queries: Array<query>,
    loading: boolean,
    crpIcon: any,
    descriptions: any
}

export interface query {
    result?: MatTableDataSource<any>, //lista popolamento tabella
    resultChart?: any,
    tableId: string, //id tabella relativa al crp
    tableConfig: tableConfig, //configurazione tabella vedi interfaccia tableConfig
    expanded: boolean, //indica se il crp è visibile a pieno o solo l'header,
    extractEndpoint: string, //endpoint per estrazione
    filter: filter, //filtro ricerca corrente
    errorsOccured: string,
    idxQry: string
    threadFinished: boolean,
    qryTitle: string,
    pollingStopped: boolean,
    idxFlag: string,
    updateButton: boolean,
    FEReportType?: any,
    loading?: boolean
}

export interface tableConfig {
    tableColumns: Array<tableColumn>,
    updateColumns: Array<updateColumn>,
    updateMessage?: string,
    columnUpdateTarget: string
}
export interface updateColumn {
    columnDef: string,
    idxFlag: string,
    updateMessage?: string
}

export interface filter {
    tableId: string,
    column: tableColumn,
    element: any,
    comparison: string
}

export enum FEReportType {
    TABLE = "TABLE",
    CHART_BAR = "B",
    CHART_LINE = "L",
    CHART_POLARAREA = "PA",
    CHART_DOUGHNUT = "D",
    CHART_PIE = "P"
}
