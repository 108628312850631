<!-- item (3 types available)-->
<ion-menu-toggle auto-hide="false" [hidden]="p.tipoRoot != 'MAIN'" *ngIf="p.idtipoModulo != 1">
    <!-- page -->
    <ion-item lines="none" routerDirection="root" [routerLink]="isMobileMenu ? null : [p.url]" [queryParams]="p.params"
        [lines]="isActive() && !isMobileMenu ? 'inset' : 'none'" detail="false" [class.selected]="isActive()"
        *ngIf="p.url && p.idtipoModulo != 5  && p.idtipoModulo != 7" (click)="itemMenuClicked(p)"
        [ngClass]="{'menuSubItem': p.menuSubItem}">
        <div class="menu-item-inner">
            <img class="menu-icon" [src]="getImageUrl(p.nome, p.url, p.id)" (error)="onImgError($event)" *ngIf="!isMobileMenu">
            <ion-icon class="menu-icon" [src]="getImageUrl(p.nome, p.url, p.id)" (error)="onImgError($event)" *ngIf="isMobileMenu"></ion-icon>
            <span>{{ p.nome | translateMenu: p.noTranslationName : p.id | async}}</span>
            <ion-icon class="icon-right" [src]="'assets/appDriver/chevron-right.svg'" *ngIf="isMobileMenu"></ion-icon>
        </div>
    </ion-item>
    <!-- micro fe -->
    <ion-item lines="none" detail="false" [lines]="isActive() && !isMobileMenu ? 'inset' : 'none'" [class.selected]="isActive()"
        *ngIf="p.url && p.idtipoModulo == 7" (click)="emitMicroFrontendClicked(p)"
        [ngClass]="{'menuSubItem': p.menuSubItem}" class="cursor-pointer">
        <div class="menu-item-inner">
            <img class="menu-icon" [src]="getImageUrl(p.nome, p.url, p.id)" (error)="onImgError($event)">
            <span>{{ p.nome | translateMenu: p.noTranslationName : p.id | async}}</span>
        </div>
    </ion-item>
    <!-- link -->
    <ion-item lines="none" detail="false" class="cursor-pointer" *ngIf="p.idtipoModulo == 5"
        (click)="getLinkServizio(p.id);">
        <div class="menu-item-inner">
            <img class="menu-icon" [src]="getImageUrl(p.nome, p.url, p.id)" (error)="onImgError($event)">
            <span>{{ p.nome | translateMenu: p.noTranslationName : p.id | async}}</span>
        </div>
    </ion-item>
</ion-menu-toggle>
<!-- node (container) -->
<ion-item lines="none" detail="false" *ngIf="[1,6].includes(p.idtipoModulo)" (click)="p['opened'] = !p['opened']"
    [hidden]="p.tipoRoot != 'MAIN'" class="item-node" [ngClass]="{'hidden-container': p.idtipoModulo == 6}">
    <div class="menu-item-inner">
        <img class="menu-icon" [src]="getImageUrl(p.nome, p.url, p.id)" (error)="onImgError($event)" *ngIf="!isMobileMenu">
        <span>{{ p.nome | translateMenu: p.noTranslationName : p.id | async}}
            <ion-icon [ngClass]="{'circle-selected': !p['opened']}" slot="end" name="chevron-up" *ngIf="!isMobileMenu">
            </ion-icon>
        </span>
    </div>
</ion-item>

<!-- sub item (1 type available) -->
<div *ngIf="p.figli && p.tipoRoot == 'MAIN'" class="node-container">
    <div class="sub-item" [ngClass]="{'parent-hidden': p.idtipoModulo == 6, 'item-page': child.url && child.idtipoModulo != 5  && child.idtipoModulo != 7}" *ngFor="let child of p.figli"
        [hidden]="!p['opened'] && p.idtipoModulo != 6 && p.idtipoModulo != 5">
        <app-menu-tree [isMobileMenu]="isMobileMenu" [p]="child"
            (microFrontendClicked)="emitMicroFrontendClicked($event)" (pageClicked)="itemMenuClicked($event)"></app-menu-tree>
    </div>
</div>

<!--  
     <ion-menu-toggle auto-hide="false">

            <ion-item routerDirection="root" [routerLink]="[item.url]" lines="none" detail="false"
                [class.selected]="item.url == activePage" (error)="onImgError($event)">
                <img class="menu-icon" [src]="getImageUrl(item.nome)">
                <ion-label class="menu-label">{{ 'menu.'+item.nome | translate}}</ion-label>
            </ion-item>
        </ion-menu-toggle>
    -->