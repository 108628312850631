import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NumberSeparator'
})
export class NumberSeparatorPipe implements PipeTransform {

  constructor() { }
  transform(number: number | null | undefined) {
    if (number == null || number == undefined || typeof number != 'number')
      return "";
    else {
      return Number(number).toLocaleString('it-IT');
    }
  }
}
