import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { LoaderService } from '../../services/loader.service'
import { AdminUserDetailService } from '../../services/admin-user-detail.service'
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { TablesPromptComponent } from './tables-prompt/tables-prompt.component';
import { Ask, ChatBotConfig, ChatBotService, ChatMessage, MessageOption, MessageOptionProvider } from 'src/app/services/chat-bot.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent implements OnInit, OnDestroy {
  public chatBot: ChatBotConfig = new ChatBotConfig();
  public text: string;
  public textDisabled: boolean = true;
  private _askFromPageSub: Subscription;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public loadingSrv: LoaderService,
    public adminUserDetailSrv: AdminUserDetailService,
    public storaveVariableSrv: StorageVariablesService,
    public httpUtilitySrv: HttpUtilityService,
    public modalCtrl: ModalController,
    private _chatBotSrv: ChatBotService) {

  }
  MatTooltipModule

  ngOnInit() {
    if (!this._askFromPageSub)
      this._askFromPageSub = this._chatBotSrv.askFromPageSubject.subscribe((message) => {
        if (message) {
          this.chatBot = new ChatBotConfig(true);
          this.ask(message);
          this.chatBot.show = true;
        }
      });
  }

  ngOnDestroy(): void {
    if (this._askFromPageSub)
      this._askFromPageSub.unsubscribe()
  }

  //handleFButton
  public handleFButton(){
    this.chatBot.show = !this.chatBot.show;
    if(this.chatBot.show && (!this.chatBot.chat || this.chatBot.chat.length == 0))
      this.ask(null);
  }

  //getListByEndpoint
  public getListByEndpoint(event, message: ChatMessage, provider: MessageOptionProvider, minLength?) {
    let autoSearchList = [];
    if ((autoSearchList.includes(event.component._element.nativeElement.id) || (event.text != null && event.text != undefined && event.text != "")) && (!minLength || event.text.length >= minLength) && !event.component.isSearching) {
      let url = this._resolveEndpointByProviders(provider.endPoint, message.FEOptionSelected.providers, event.text);
      event.component.startSearch();
      event.component.showLoading();
      this._chatBotSrv.genericGet(url).then((res: any) => {
        event.component.items = res.result;
        event.component.hideLoading();
        event.component.endSearch();
      }).catch((err) => {
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
            event.component.hideLoading();
            event.component.endSearch();
          })
        }
      })
    } else {
      event.component.items = null;
    }
  }

  //ask
  public ask(message: ChatMessage, askForJson?: boolean, onlySetCurrentOptions?: boolean) {
    if (!onlySetCurrentOptions)
      this.chatBot.isLoading = true;
    let ask: Ask
    let newText: string = this.text != null && this.text != undefined && this.text != '' ? JSON.parse(JSON.stringify(this.text)) : null;
    if (!onlySetCurrentOptions && (message || newText)) {
      this.chatBot.chat.push({
        testo: askForJson ? this.translate.instant('labels.askForJson') : message == null ? newText : (message.FEOptionSelected?.nome),
        idSessioneAI: this.chatBot.currentSessionId, type: 'human', opzioni: null, datasetJson: null, quotaAssegnata: null, quotaUsata: null, token: null
      })
    }
    this._scrollToLastMessage();
    //check for providers
    let providers = this._getParametriPrecompilati(message);
    if (providers)
      this.chatBot.currentConfig = message;
    //check service id
    let serviceId;
    if (this.chatBot.currentServiceId)
      serviceId = this.chatBot.currentServiceId;
    else if (message)
      serviceId = message.FEOptionSelected?.id
    else if ((this.chatBot.chat?.length && this.chatBot.chat[this.chatBot.chat.length - 2]?.FEOptionSelected?.idTipoRisposta == 5))
      serviceId = this.chatBot.chat[this.chatBot.chat.length - 2]?.FEOptionSelected?.id
    //build question
    ask = {
      testo: askForJson ? this.translate.instant('labels.askForJson') : message ? null : newText,
      sessione: {
        idSessioneAI: this.chatBot.currentSessionId,
        parametriPrecompilati: this._getParametriPrecompilati(this.chatBot.currentConfig)
      },
      idServizio: serviceId
    }
    //clean
    this.text = null;
    if (!this.chatBot.currentSessionId)
      this.textDisabled = true;
    if (!onlySetCurrentOptions) {
      this._chatBotSrv.ask(ask).then((res: ChatMessage) => {
        this.chatBot.chat.push({
          testo: !res.testo && res.opzioni ? this.translate.instant('labels.chatBotStart') : res.testo?.trim(),
          opzioni: res.opzioni,
          idSessioneAI: res.idSessioneAI,
          type: 'ai',
          datasetJson: this._extratJson(res.testo),
          quotaAssegnata: res.quotaAssegnata,
          quotaUsata: res.quotaUsata,
          token: res.token
        })
        //sessionId + idServizio
        if (!this.chatBot.currentSessionId) {
          this.chatBot.currentSessionId = res.idSessioneAI ? res.idSessioneAI : null;
          if (this.chatBot.currentSessionId) {
            this.textDisabled = false;
            this.chatBot.currentServiceId = ask.idServizio;
          }
        }
        //datasetJson
        if (!this.chatBot.datasetJson)
          this.chatBot.datasetJson = res.datasetJson;
        //quota
        this.chatBot.currentQuota.current = res.quotaUsata;
        this.chatBot.currentQuota.max = res.quotaAssegnata;
        this.chatBot.currentQuota.percentage = (this.chatBot.currentQuota.current * 100) / this.chatBot.currentQuota.max;
        this.chatBot.isLoading = false;
        this._scrollToLastMessage();
      }).catch((err) => {
        this.chatBot.isLoading = false;
        if (!err.status || (err && err.status && err.status != 401 && err.status != 403)) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.chatBot.chat.push({ testo: err, idSessioneAI: null, opzioni: null, type: 'error', datasetJson: null, quotaAssegnata: null, quotaUsata: null, token: null })
            this._scrollToLastMessage();
            this.textDisabled = false;
          })
        }
      })
    } else {
      this.chatBot.currentServiceId = ask.idServizio;
      this.textDisabled = false;
    }
  }

  //handleOption
  public handleOption(option: MessageOption, message: ChatMessage, index: number) {
    if (index == (this.chatBot.chat.length - 1)) {
      //Argomento
      if (option.idTipoRisposta == 1) {
        message.FEOptionSelected = option;
        this._scrollToLastMessage()
      }
      //Navigazione
      else if (option.idTipoRisposta == 2) {
        message.FEOptionSelected = option;
        this.ask(message);
      }
      //Standard
      else if (option.idTipoRisposta == 3) {
        message.FEOptionSelected = option;
        this.ask(message);
      }
      //Domanda libera
      else if (option.idTipoRisposta == 5) {
        message.FEOptionSelected = option;
        this.textDisabled = false;
        let textDom = document.getElementById('textDom');
        setTimeout(() => {
          if (textDom)
            textDom.focus();
        }, 200)

      }
    }
  }

  //refreshChatBot
  public refreshChatBot() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('labels.resetChat') }, () => {
      this.chatBot = new ChatBotConfig(true, this.chatBot);
      this.ask(null);
    })
  }

  //scrollToLastMessage
  private _scrollToLastMessage() {
    setTimeout(() => {
      let chatDom = document.getElementById('chatDom');
      if (chatDom) {
        chatDom.scrollTo({
          top: chatDom.scrollHeight,
          behavior: 'smooth'
        });
      }
      /*let messageDom = document.getElementById('message-' + (this.chatBot.chat.length - 1));
      if (messageDom) {
        messageDom.scrollIntoView({ behavior: 'smooth', block: "end" });
      }
      let thinkingDom = document.getElementById('thinkingDom');
      if (thinkingDom) {
        thinkingDom.scrollIntoView({ behavior: 'smooth', block: "end" });
      }*/
    }, 200)
  }

  //searchKeyUp
  public searchKeyUp(event) {
    if (event.keyCode == 13 && this.text != '' && this.text && !this.chatBot.isLoading) {
      this.ask(null);
    }
  }

  //openTablesPrompt
  public async openTablesPrompt(datasetJson?: any) {
    let datasetJsonProps = datasetJson ? datasetJson : JSON.parse(this.chatBot.datasetJson);
    let modal = await this.modalCtrl.create({
      component: TablesPromptComponent,
      cssClass: 'tables-prompt-component',
      componentProps: { datasetJson: datasetJsonProps }
    })
    await modal.present();
  }

  //extractJson
  private _extratJson(text: string): object | Array<any> {
    if (!text || text == '')
      return null;
    const pattern = /```json([\s\S]*?)```/;
    const matches = text.match(pattern);
    if (matches) {
      try {
        let jsonObj = JSON.parse(matches[1].trim());
        if (!Array.isArray(jsonObj))
          jsonObj = [jsonObj];
        return jsonObj;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  //getParametriPrecompilati
  private _getParametriPrecompilati(message: ChatMessage): Array<{ key, value }> {
    let parameters = [];
    if (message?.FEOptionSelected?.providers?.length) {
      message.FEOptionSelected.providers.forEach((provider) => {
        parameters.push({ key: provider.campoDaMappare, value: provider.FEValue ? provider.FEValue[provider.campoEndPointDaPrendere].toString() : null });
      })
    }
    return parameters.length ? parameters : null;
  }

  //resolve endpoint by providers
  private _resolveEndpointByProviders(endpoint, providers: Array<MessageOptionProvider>, text: string) {
    let endpointTmp: string = endpoint, endpointBe: string = endpoint, currentTag;
    while (endpointTmp.indexOf("]") != -1) {
      currentTag = endpointTmp.substring(endpointTmp.indexOf("[") + 1, endpointTmp.indexOf("]"));
      //input
      if (currentTag == 'INPUT') {
        endpointBe = endpointBe.toString().replace("[" + currentTag + "]", text);
      }
      //check for providers
      else {
        for (let innerProvider of providers) {
          //value exists in providers
          if (innerProvider.campoDaMappare == currentTag) {
            endpointBe = endpointBe.toString().replace("[" + currentTag + "]", innerProvider.FEValue != null ? (innerProvider.FEValue[innerProvider.campoEndPointDaPrendere] + "") : "");
          }
        }
      }
      endpointTmp = endpointTmp.substring(endpointTmp.indexOf("]") + 1);
    }
    if (endpointBe)
      endpointBe = encodeURI(endpointBe).replace(/#/g, '%23');
    return endpointBe;
  }

  //confirmProvidersDisabled
  public confirmProvidersDisabled(message: ChatMessage, index: number): boolean {
    return index != (this.chatBot.chat.length - 1) || !!message.FEOptionSelected.providers.filter((p) => { return p.FEValue == null })[0];
  }

  //providerChanged
  public providerChanged(message: ChatMessage, pIndex: number) {
    if (pIndex != (message.FEOptionSelected.providers.length - 1)) {
      for (let i = pIndex + 1; i < message.FEOptionSelected.providers.length; i++) {
        message.FEOptionSelected.providers[i].FEValue = null;
      }
    }
    setTimeout(() => {
      this._scrollToLastMessage();
    }, 500)
  }

}
