<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">

        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content>
  <div class="content" *ngIf="currentTables">
    <ion-card class="page-card" *ngFor="let table of currentTables.tables">
      <ion-card-header>
        <ion-card-title>
          {{table.title}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="table">
          <table>
            <tr>
              <th *ngFor="let header of table.headers">{{header}}</th>
            </tr>
            <tr *ngFor="let row of table.rows">
              <td *ngFor="let header of table.headers">
                {{row[header]}}
              </td>
            </tr>
          </table>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
<ion-footer>
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>{{'buttons.close' | translate}}</ion-label>
        </ion-button>
        <ion-button color="primary" size="default" (click)="extractTables()">
          <ion-label>{{'buttons.extractExcel' | translate}}</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>