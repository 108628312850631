import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service'
import { AdminUserDetailService } from '../../services/admin-user-detail.service'
import { SelectableTreePopoverComponent } from '../selectable-tree-popover/selectable-tree-popover.component';
import { SectionService } from 'src/app/services/section.service';
import { AuthService } from '../../services/auth-service.service';
import { Router } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';

@Component({
  selector: 'app-admin-user-detail-modal',
  templateUrl: './admin-user-detail-modal.component.html',
  styleUrls: ['./admin-user-detail-modal.component.scss'],
})
export class AdminUserDetailModalComponent implements OnInit {
  //data integration form
  public userDetailForm: FormGroup;
  //modal nav params
  public modalNavParams;
  //type options
  public customerOption = [];
  //territories options
  public customerOnlyOption = [];
  //roles list
  public rolesList = [];
  //role id list (used for user detail)
  public roleIdList = [];
  //section id
  public sectionIdList = [];
  //current Territory Id
  public currentTerritoryId = null;
  //current User Id
  public currentUserId = null;
  //impersonification is active
  public impersonificationOn = false;
  //firstIndex
  public firstIndex = 0;
  //user credentials
  public userCredentials = [];
  //is environment imma
  public isEnvironmentImma;
  //showCopy
  public showCopy = false;

  constructor(private httpUtilitySrv: HttpUtilityService,
    public translate: TranslateService,
    private alertSrv: AlertService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loadingSrv: LoaderService,
    public navParams: NavParams,
    public adminUserDetailSrv: AdminUserDetailService,
    public sectionSrv: SectionService,
    private authSrv: AuthService,
    public storaveVariableSrv: StorageVariablesService,
    private router: Router) {
    //setup environment
    this.isEnvironmentImma = this.httpUtilitySrv.isEnvironmentImmaExt();
    //setup validators
    this.userDetailForm = this.formBuilder.group({
      //userName: [null, Validators.required],
      username: [null, Validators.compose([Validators.required, Validators.pattern("^['a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")])],
      //email: [null],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      language: ["it", Validators.required],
      phone: [null],
      enabled: [true, Validators.required],
      currentTerritoryIsOwner: ["0"],
      id: [null],
      tempPass: [null]
    })
    //setup nav params
    this.modalNavParams =
    {
      userId: this.navParams.get("userId") ? this.navParams.get("userId") : null,
      idProprietario: this.navParams.get("idProprietario") ? this.navParams.get("idProprietario") : null, //owner territory id
      denominazioneProprietario: this.navParams.get("denominazioneProprietario") ? this.navParams.get("denominazioneProprietario") : null, //owner territory name
      proprietarioModificabile: this.navParams.get("proprietarioModificabile") ? this.navParams.get("proprietarioModificabile") : null //owner territory can be changed by current profile
    }
    if (this.isEnvironmentImma) {
      this.userDetailForm.get('username').setValidators(Validators.required);
      this.userDetailForm.get('name').setValidators(null);
      this.userDetailForm.get('surname').setValidators(null);
      if (!this.modalNavParams.userId)
        this.userDetailForm.get('tempPass').setValidators(Validators.required);
    }
  }

  ngOnInit() {
    this.getSezioni();
    this.authSrv.getAuthObject().then((authObject: any) => {
      this.currentTerritoryId = authObject.customerId;
      this.currentUserId = authObject.userData.idUtente;
    });
    this.storaveVariableSrv.getAdminImpersGuidObject().then((res: any) => {
      if (res && res.guid && res.guid != "" && res.guid.includes('-')) {
        this.impersonificationOn = true;
      }
    })
    //setup temp pass
    if (this.isEnvironmentImma && !this.modalNavParams.userId) {
      this.getTempPass();
    }
  }

  //get sezioni
  private getSezioni() {
    this.loadingSrv.open();
    this.sectionSrv.getSezioni("amministrazione.gestione_utenti").then((res: any) => {
      if (res.code == 200) {
        this.sectionIdList = res.payload;
        this.getClienti();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
        this.popoverCtrl.dismiss();
      }
    }).catch((err) => {
      this.loadingSrv.dismiss();
    })
  }

  //get clienti
  private getClienti() {
    this.adminUserDetailSrv.getClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOnlyOption = res.payload;
        this.getFullClienti();
      } else {
        this.loadingSrv.dismiss();
        this.customerOnlyOption = [];
        this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
      }
    }).catch((err) => {
      this.loadingSrv.dismiss();
    })
  }

  //get full clienti
  private getFullClienti() {
    this.adminUserDetailSrv.getFullClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOption = res.payload;
        //setup user detail
        if (this.modalNavParams && this.modalNavParams.userId)
          this.getDettaglioUtente(this.modalNavParams.userId);
        else {
          this.loadingSrv.dismiss();
        }
      } else {
        this.loadingSrv.dismiss();
        this.customerOption = [];
        this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
      }
    }).catch((err) => {
      this.loadingSrv.dismiss();
    })
  }



  //hide section
  public hideSection(id) {
    return !this.sectionIdList.includes(id);
  }

  //enable Impersonate button
  public canBeImpersonated() {
    if (!this.hideSection('amministrazione.gestione_utenti.impersonate')) {
      if (this.modalNavParams.proprietarioModificabile != null && !this.modalNavParams.proprietarioModificabile) {
        return false;
      }
      if (this.currentUserId && this.modalNavParams.userId && this.modalNavParams.userId == this.currentUserId) {
        return false;
      }
      if (this.impersonificationOn) {
        return false;
      }
    }
    return true;
  }

  //get dettaglio utente
  private getDettaglioUtente(userId) {
    this.adminUserDetailSrv.getDettaglioUtente(userId).then((res: any) => {
      this.loadingSrv.dismiss();
      if (res.code == 200) {
        let payload = res.payload;
        this.getRoleList(payload.clienti);
        this.getRoleIdList(payload.clienti);
        this.mergeRoleIdList(this.customerOption, payload.idTerritoriDaEsplodere);
        this.getListaChiaviValoreUtenteServiziSafo(this.modalNavParams && this.modalNavParams.userId, this.roleIdList);
        this.updateForm(payload.userName, payload.nome, payload.cognome, payload.language, payload.telefono, payload.idUtente, payload.attivo);
        this.modalNavParams.idProprietario = payload.idProprietario;
        this.modalNavParams.denominazioneProprietario = payload.denominazioneProprietario;
        this.modalNavParams.proprietarioModificabile = payload.proprietarioModificabile;
        this.setStartingOwnerId(this.customerOnlyOption);
      } else {
        this.modalNavParams = {
          userId: null,
          idProprietario: null,
          denominazioneProprietario: null,
          proprietarioModificabile: null
        }
        this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
        this.popoverCtrl.dismiss();
      }
    }).catch((err) => {
      this.loadingSrv.dismiss();
      this.modalNavParams = {
        userId: null,
        idProprietario: null,
        denominazioneProprietario: null,
        proprietarioModificabile: null
      }

    })
  }

  //save user
  public saveUser(valid) {
    if (this.modalNavParams.userId != null && this.modalNavParams.userId != undefined)
      this.modificaUtente(valid)
    else
      this.inserisciUtente(valid)
  }

  //inserisci utente 
  private inserisciUtente(valid) {
    if (valid) {
      this.loadingSrv.open();
      this.setOwnerIdAsCurrentTerritory(this.userDetailForm.get("currentTerritoryIsOwner").value);
      this.adminUserDetailSrv.inserisciUtente(
        this.userDetailForm.get("username").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("username").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("name").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("surname").value,
        this.rolesList,
        this.userDetailForm.get("language").value,
        this.userDetailForm.get("phone").value,
        this.modalNavParams.idProprietario,
        this.userDetailForm.get('enabled').value,
        this.sectionIdList.includes("amministrazione.gestione_utenti.set_services_credentials_and_keysvalues") ? this.userCredentials : null,
        this.isEnvironmentImma ? this.userDetailForm.get("tempPass").value : null,
      ).then((res: any) => {
        if (res.code == 200) {
          this.loadingSrv.dismiss();
          this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
          this.popoverCtrl.dismiss();
        } else {
          this.loadingSrv.dismiss();
          this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
        }
      }).catch((err) => {

        this.loadingSrv.dismiss();
      })
    }
  }

  //modifica utente 
  private modificaUtente(valid) {
    if (valid) {
      this.loadingSrv.open();
      this.setOwnerIdAsCurrentTerritory(this.userDetailForm.get("currentTerritoryIsOwner").value);
      this.adminUserDetailSrv.modificaUtente(
        this.modalNavParams.userId,
        this.userDetailForm.get("username").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("username").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("name").value,
        this.isEnvironmentImma ? "-" : this.userDetailForm.get("surname").value,
        this.rolesList,
        this.userDetailForm.get("language").value,
        this.userDetailForm.get("phone").value,
        this.modalNavParams.idProprietario,
        this.userDetailForm.get('enabled').value,
        this.sectionIdList.includes("amministrazione.gestione_utenti.set_services_credentials_and_keysvalues") ? this.userCredentials : null,
        this.isEnvironmentImma ? this.userDetailForm.get("tempPass").value : null,
      ).then((res: any) => {
        if (res.code == 200) {
          this.loadingSrv.dismiss();
          this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
          this.popoverCtrl.dismiss();
        } else {
          this.loadingSrv.dismiss();
          this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
        }
      }).catch((err) => {

        this.loadingSrv.dismiss();

      })
    }
  }

  //delete user alert
  async deleteUserAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.delete_user'),
      message: this.translate.instant('messages.delete_user_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.eliminaUtente();
          }
        }
      ]
    });

    await alert.present()
  }

  //impersonate user alert
  async impersonateUserAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.impersonate_user'),
      message: this.translate.instant('messages.impersonate_user_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.impersonaUtente();
          }
        }
      ]
    });

    await alert.present()
  }

  //elimina utente 
  private eliminaUtente() {
    this.loadingSrv.open();
    this.adminUserDetailSrv.eliminaUtente(this.modalNavParams.userId).then((res: any) => {
      if (res.code == 200) {
        this.loadingSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.popoverCtrl.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
      }
    }).catch((err) => {

      this.loadingSrv.dismiss();

    })
  }

  //impersona utente 
  private impersonaUtente() {
    this.loadingSrv.open();
    this.popoverCtrl.dismiss({ refresh: false });
    this.adminUserDetailSrv.impersonaUtente(this.modalNavParams.userId).then((res: any) => {
      if (res.body.code == 200) {
        if (res.body.payload && res.body.payload.idUtente) {
          res.body.payload["token"] = res.headers.get('token');
          res.body.payload["refreshToken"] = res.headers.get('tokenrefresh');
          setTimeout(() => {
            this.storaveVariableSrv.setAdminImpersUserObject(res.body.payload);
            this.router.navigateByUrl("login");
          }, 200)
        }
        this.loadingSrv.dismiss();
      } else {
        this.loadingSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.body.code))
      }
    }).catch((err) => {

      this.loadingSrv.dismiss();
    })
  }

  //update form
  public updateForm(username, name, surname, language, phone, id, enabled) {
    //this.userDetailForm.get("userName").setValue(username);
    this.userDetailForm.get("name").setValue(name);
    this.userDetailForm.get("surname").setValue(surname);
    this.userDetailForm.get("username").setValue(username);
    this.userDetailForm.get("language").setValue(language);
    this.userDetailForm.get("phone").setValue(phone);
    this.userDetailForm.get("id").setValue(id);
    this.userDetailForm.get("enabled").setValue(enabled);
  }

  //----- selectable tree ------//

  //selectable tree popover
  async selectableTreePopover(type) {
    const popover = await this.popoverCtrl.create({
      component: SelectableTreePopoverComponent,
      cssClass: 'selectable-tree-popover',
      componentProps: {
        title: this.translate.instant('labels.select'),
        tree: type == 'customer' ? this.customerOnlyOption : this.customerOption,
        titleKey: "denominazione",
        childrenKey: "figli",
        hideCheckboxParent: type == 'customer' ? false : true,
        hideCheckboxRole: type == 'customer' ? true : false,
        single: type == 'customer' ? true : false,
        showRolesIndicator: type == 'customer' ? false : true
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          if (type == 'customer') {
            this.modalNavParams.idProprietario = null;
            this.setOwnerId(res.data.tree);
          } else {
            this.rolesList = [];
            this.getRoleList(res.data.tree)
            this.roleIdList = [];
            this.getRoleIdList(res.data.tree)
            if (this.roleIdList && this.roleIdList.length > 0)
              this.getListaChiaviValoreUtenteServiziSafo(this.modalNavParams.userId, this.roleIdList)
            else {
              this.userCredentials = [];
            }
          }
        }
      });
    return await popover.present();
  }

  //get role list
  public getRoleList(tree) {
    let currentCustomer = {};
    currentCustomer["ruoli"] = [];
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (i == "ruoli" && tree[i].length > 0) {
          currentCustomer["idCliente"] = tree["idCliente"];
          for (let role of tree[i]) {
            if (role.selected)
              currentCustomer["ruoli"].push(role)
          }
          if (currentCustomer["ruoli"].length > 0)
            this.rolesList.push(currentCustomer)
        }
        this.getRoleList(tree[i]);
      }
    }
  }

  //get role id list
  public getRoleIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].id && tree[i].selected)
          this.roleIdList.push(tree[i].id)
        this.getRoleIdList(tree[i]);
      }
    }
  }

  //set setOwnerId (advanced module)
  public setOwnerId(tree) {
    if (this.sectionIdList.includes("amministrazione.gestione_utenti.set_owner")) {
      for (let i in tree) {
        if (!!tree[i] && typeof (tree[i]) == "object") {
          if (tree[i].idCliente && tree[i].selected) {
            this.modalNavParams.idProprietario = tree[i].idCliente;
            break;
          }

          this.setOwnerId(tree[i]);
        }
      }
    }
  }

  //set setOwnerId (simplified module)
  private setOwnerIdAsCurrentTerritory(value) {
    if (this.sectionIdList.includes("amministrazione.gestione_utenti.set_owner_simplified")) {
      if (value == "1") {
        this.modalNavParams.idProprietario = this.currentTerritoryId;
      } else if (value == "2") {
        this.modalNavParams.idProprietario = null;
      }
    }
  }

  //merge roleIdList
  public setStartingOwnerId(tree) {
    if (this.modalNavParams.idProprietario != null) {
      for (let i in tree) {
        if (!!tree[i] && typeof (tree[i]) == "object") {
          if (tree[i].idCliente && this.modalNavParams.idProprietario == tree[i].idCliente) {
            tree[i].selected = true;
            break;
          }
          this.setStartingOwnerId(tree[i]);
        }
      }
    }
  }

  public mergeRoleIdList(tree, idTerritoriDaEsplodere) {
    for (let item of tree) {
      //check if role is selected
      if (item.ruoli)
        for (let role of item.ruoli) {
          if (role.id && this.roleIdList.includes(role.id)) {
            role['selected'] = true;
            item['rolesOpened'] = true;
          }
        }
      //check for opened
      if (idTerritoriDaEsplodere.includes(item.idCliente)) {
        item['opened'] = true;
      }
      if (item.figli)
        this.mergeRoleIdList(item.figli, idTerritoriDaEsplodere);
    }
  }

  //----- end selectable tree ------//

  public getListaChiaviValoreUtenteServiziSafo(userId, rolesIdList) {
    if (this.sectionIdList.includes("amministrazione.gestione_utenti.set_services_credentials_and_keysvalues")) {
      this.loadingSrv.open();
      this.adminUserDetailSrv.getListaChiaviValoreUtenteServiziSafo(userId, rolesIdList).then((res: any) => {
        if (res.code == 200) {
          for (let item of res.payload) {
            for (let obj of this.userCredentials) {
              if (obj.idModulo == item.idModulo) {
                item['serviceUserName'] = obj['serviceUserName'];
              }
            }
          }
          this.userCredentials = res.payload;
          this.loadingSrv.dismiss();
        } else {
          this.loadingSrv.dismiss();
          this.alertSrv.errorAlert(res.code ? this.translate.instant("errors." + res.code) : this.translate.instant("errors.1"));
        }
      }).catch((err) => {

        this.loadingSrv.dismiss();
      })
    }
  }

  //get temp pass
  public getTempPass() {
    this.adminUserDetailSrv.getTempPass().then((res: any) => {
      if (res.code == 200) {
        this.userDetailForm.get("tempPass").setValue(res.payload);
      }
    }).catch((err) => {
      ;
    })
  }

  //refresh temp pass alert
  public refreshTempPassAlert() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.refresh_user_tmp_pass_message') }, () => {
      this.getTempPass();
    });
  }

  //copyTmpPass
  public copyTmpPass() {
    this.showCopy = true;
    setTimeout(() => {
      this.showCopy = false;
    }, 1000)
  }
}
