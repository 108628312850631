import { Component, OnInit, AfterViewInit, ViewChild, AfterContentChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Platform, ModalController, AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from '../../services/loader.service'
import { AdminRoleDetailService } from '../../services/admin-role-detail.service'
import { SelectableTreePopoverComponent } from '../selectable-tree-popover/selectable-tree-popover.component';
import { AdminTerritoryDetailService } from 'src/app/services/admin-territory-detail.service';
import { Router } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { AlertService } from 'src/app/services/alert.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-admin-territory-detail',
  templateUrl: './admin-territory-detail.component.html',
  styleUrls: ['./admin-territory-detail.component.scss'],
})
export class AdminTerritoryDetailComponent implements OnInit, AfterViewInit {
  //modal nav params
  public modalNavParams;
  //territory detail form
  public territoryDetailForm: FormGroup;
  //company detail form
  public companyDetailForm: FormGroup;
  //existing company form
  public existingCompanyForm: FormGroup;
  //company options
  public companyOptions = [];
  //modules options
  public modulesOptions = [];
  //public idList (module id list)
  public idList: string[] = [];
  //sectionIdList
  public sectionIdList = [];

  constructor(public translate: TranslateService,
    private alertSrv: AlertService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loaderSrv: LoaderService,
    public navParams: NavParams,
    public adminRoleDetailSrv: AdminRoleDetailService,
    public adminTerritoryDetailSrv: AdminTerritoryDetailService,
    public storageVariableSrv: StorageVariablesService,
    public router: Router,
    private sectionSrv: SectionService) { }



  ngOnInit() {
    //setup validators
    this.territoryDetailForm = this.formBuilder.group({
      description: [null, Validators.required],
      active: [true, Validators.required],
      isClosed: [null],
      type: [null, Validators.required],
      id: [null]
    })
    this.companyDetailForm = this.formBuilder.group({
      description: [null, Validators.required],
      acronym: [null, Validators.required],
      address: [null, Validators.required],
      nation: [null, Validators.required],
      vatNumber: [null, Validators.required],
      phone: [null, Validators.required],
      fax: [null, Validators.required],
      isClienteSafo: [false, Validators.required],
      idManagerMulte: [null]
    })
    this.existingCompanyForm = this.formBuilder.group({
      companyId: [null, Validators.required]
    })
    //setup nav params
    this.modalNavParams =
    {
      territory: this.navParams.get("territory") ? this.navParams.get("territory") : null, //for detail
      idTerritorioPadre: this.navParams.get("idTerritorioPadre") ? this.navParams.get("idTerritorioPadre") : null //for new territory
    }
    //setup clienti
    this.getClienti();
  }

  ngAfterViewInit() {
    this.getSezioni();
    if (this.modalNavParams && this.modalNavParams.territory)
      setTimeout(() => {
        this.dettaglioTerritorio(this.modalNavParams.territory.idCliente)
      }, 200)
    else {
      this.getModuli();
    }
  }

  //get sezioni
  private getSezioni() {
    this.sectionSrv.getSezioni("amministrazione.gestione_territori").then((res: any) => {
      if (res.code == 200) {
        this.sectionIdList = res.payload;
      }
    }).catch((err) => {
      
    })
  }

  //dettaglio territorio
  private dettaglioTerritorio(idTerritorio) {
    this.loaderSrv.open();
    this.adminTerritoryDetailSrv.dettaglioTerritorio(idTerritorio).then((res: any) => {
      if (res.code == 200) {
        this.modalNavParams.territory = res.payload;
        this.updateForm();
        this.modulesOptions = res.payload.modulo.figli;
        this.checkFullMenuForcedModule(this.modulesOptions);
        this.getModuleIdList(this.modulesOptions);
        this.loaderSrv.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {

      this.loaderSrv.dismiss();
      
    })
  }

  //update form
  public updateForm() {
    //territory form
    this.territoryDetailForm.get('description').setValue(this.modalNavParams.territory.denominazione);
    this.territoryDetailForm.get('active').setValue(this.modalNavParams.territory.attivo);
    this.territoryDetailForm.get('isClosed').setValue(this.modalNavParams.territory.isClosed);
    this.territoryDetailForm.get('id').setValue(this.modalNavParams.territory.idCliente);
    if (!this.modalNavParams.territory.idAzienda)
      this.territoryDetailForm.get('type').setValue("0");
    else if (this.modalNavParams.territory.idAzienda) {
      this.territoryDetailForm.get('type').setValue("1");
      //company form
      this.companyDetailForm.get('description').setValue(this.modalNavParams.territory.denominazioneAzienda);
      this.companyDetailForm.get('acronym').setValue(this.modalNavParams.territory.acronimo);
      this.companyDetailForm.get('address').setValue(this.modalNavParams.territory.indirizzo);
      this.companyDetailForm.get('nation').setValue(this.modalNavParams.territory.nazione);
      this.companyDetailForm.get('vatNumber').setValue(this.modalNavParams.territory.partitaIva);
      this.companyDetailForm.get('phone').setValue(this.modalNavParams.territory.telefono);
      this.companyDetailForm.get('fax').setValue(this.modalNavParams.territory.fax);
      this.companyDetailForm.get('isClienteSafo').setValue(this.modalNavParams.territory.isClienteSafo);
      this.companyDetailForm.get('idManagerMulte').setValue(this.modalNavParams.territory.idManagerMulte);
    }
  }

  //get clienti
  private getClienti() {
    this.adminTerritoryDetailSrv.listaAziende().then((res: any) => {
      if (res.code == 200) {
        this.companyOptions = res.payload;
      } else {
        this.companyOptions = [];
      }
    }).catch((err) => {
      
    })
  }

  /*----- Modules management -----*/
  //get full menu
  private getModuli() {
    this.adminRoleDetailSrv.getModuli(this.modalNavParams.idTerritorioPadre).then((res: any) => {
      if (res.code == 200) {
        this.modulesOptions = res.payload.figli;
        this.checkFullMenuForcedModule(this.modulesOptions);
        this.getModuleIdList(this.modulesOptions);
      } else {
        this.modulesOptions = [];
      }
    }).catch((err) => {
      
    })
  }

  //check full menu forced module
  public checkFullMenuForcedModule(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].attivazioneForzata && tree[i].attivazioneForzata == true) {
          tree[i]["abilitato"] = true;
          this.handleCheckbox({ value: true, id: tree[i].id, skipCheckChildren: true })
        }
        this.checkFullMenuForcedModule(tree[i]);
      }
    }
  }

  //handle checkbox
  public handleCheckbox(event) {
    if (event.value) {
      this.checkParents(this.modulesOptions, event);
      if (!event.skipCheckChildren)
        this.searchForModule(this.modulesOptions, event, "checkChildren");
      if (!this.idList.includes(event.id)) {
        this.idList.push(event.id)
      }
    } else {
      this.searchForModule(this.modulesOptions, event, "uncheckChildren");
      this.searchForModule(this.modulesOptions, event, "uncheckParent");
    }
  }

  //check parents
  public checkParents(tree, event) {
    if (tree && event.parentId) {
      let checked = false;
      for (let module of tree) {
        if (module.id == event.parentId) {
          module["abilitato"] = true;
          checked = true;
          if (!this.idList.includes(module.id)) {
            this.idList.push(module.id)
          }
        }
        if (!checked && module.figli)
          this.checkParents(module.figli, event)
        else if (checked && module.idPadre) {
          event.parentId = module.idPadre;
          this.checkParents(this.modulesOptions, event)
        }
      }
    }
  }



  //search for module(){}
  public searchForModule(tree, event, type) {
    if (tree) {
      for (let module of tree) {
        if (type == 'uncheckChildren' && event.id && module.id == event.id) {
          if (this.idList.includes(module.id))
            this.idList = this.idList.filter(e => e !== module.id);
          this.uncheckChildren(module.figli)
          break;
        } else if (type == 'uncheckParent' && event.parentId && module.id == event.parentId) {
          this.uncheckParent(module, event.id)
          break;
        } else if (type == 'checkChildren' && event.id && module.id == event.id) {
          this.checkChildren(module.figli)
        }
        else
          this.searchForModule(module.figli, event, type)
      }
    }
  }

  //uncheck parent
  public uncheckParent(parent, childId) {
    if (parent && parent.figli && (parent.idtipoModulo == 1 || parent.idtipoModulo == 6)) {
      let atLeastOneSelected = false;
      for (let module of parent.figli) {
        if (module.id == childId) {
          if (!module["abilitato"]) {
            atLeastOneSelected = true;
            break;
          }
        } else if (module["abilitato"]) {
          atLeastOneSelected = true;
          break;
        }
      }
      if (!atLeastOneSelected) {
        parent["abilitato"] = false
        if (this.idList.includes(parent.id))
          this.idList = this.idList.filter(e => e !== parent.id);
        if (parent.idPadre)
          this.searchForModule(this.modulesOptions, { parentId: parent.idPadre }, 'uncheckParent')
      }
    }
  }

  //uncheck children
  public uncheckChildren(tree) {
    if (tree) {
      for (let module of tree) {
        module["abilitato"] = false;
        if (this.idList.includes(module.id))
          this.idList = this.idList.filter(e => e !== module.id);
        this.uncheckChildren(module.figli);
      }
    }
  }

  //check children
  public checkChildren(tree) {
    if (tree) {
      for (let module of tree) {
        module["abilitato"] = true;
        if (!this.idList.includes(module.id))
          this.idList.push(module.id)
        this.checkChildren(module.figli);
      }
    }
  }


  //get idList
  public getModuleIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].abilitato && tree[i].abilitato == true) {
          this.idList.push(tree[i].id);
        }
        this.getModuleIdList(tree[i]);
      }
    }
  }

  /*----- End modules management -----*/

  //save territorio
  public saveTerritorio() {
    if (this.modalNavParams && this.modalNavParams.idTerritorioPadre) {
      this.inserisciTerritorio();
    }
    else if (this.modalNavParams && this.modalNavParams.territory) {
      this.modificaTerritorio();
    }
  }
  //inserisci territorio
  public inserisciTerritorio() {
    this.loaderSrv.open();
    let territory = {
      "denominazione": this.territoryDetailForm.get('description').value,
      "attivo": this.territoryDetailForm.get('active').value,
      "isClosed": this.territoryDetailForm.get('isClosed').value,
      "idTerritorioPadre": this.modalNavParams.idTerritorioPadre ? this.modalNavParams.idTerritorioPadre : this.modalNavParams.territory.idTerritorioPadre,
      "idAzienda": this.modalNavParams.territory ? this.modalNavParams.territory.idAzienda : this.existingCompanyForm.get('companyId').value ? this.existingCompanyForm.get('companyId').value.idAzienda : null,
      "denominazioneAzienda": this.companyDetailForm.get('description').value,
      "acronimo": this.companyDetailForm.get('acronym').value,
      "indirizzo": this.companyDetailForm.get('address').value,
      "nazione": this.companyDetailForm.get('nation').value,
      "partitaIva": this.companyDetailForm.get('vatNumber').value,
      "telefono": this.companyDetailForm.get('phone').value,
      "fax": this.companyDetailForm.get('fax').value,
      "isClienteSafo": this.companyDetailForm.get('isClienteSafo').value,
      "newIdModuli": this.idList,
      "idManagerMulte": this.companyDetailForm.get('idManagerMulte').value
    }
    this.adminTerritoryDetailSrv.inserisciTerritorio(
      territory,
      this.territoryDetailForm.get('type').value,
    ).then((res: any) => {
      if (res.code == 200) {
        this.loaderSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.modalCtrl.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loaderSrv.dismiss();

    })
  }

  //modifica azienda
  public modificaTerritorio() {
    this.loaderSrv.open();
    let territory = {
      "denominazione": this.territoryDetailForm.get('description').value,
      "attivo": this.territoryDetailForm.get('active').value,
      "isClosed": this.territoryDetailForm.get('isClosed').value,
      "idTerritorioPadre": this.modalNavParams.territory.idTerritorioPadre,
      "idAzienda": this.modalNavParams.territory.idAzienda,
      "denominazioneAzienda": this.companyDetailForm.get('description').value,
      "acronimo": this.companyDetailForm.get('acronym').value,
      "indirizzo": this.companyDetailForm.get('address').value,
      "nazione": this.companyDetailForm.get('nation').value,
      "partitaIva": this.companyDetailForm.get('vatNumber').value,
      "telefono": this.companyDetailForm.get('phone').value,
      "fax": this.companyDetailForm.get('fax').value,
      "isClienteSafo": this.companyDetailForm.get('isClienteSafo').value,
      "idManagerMulte": this.companyDetailForm.get('idManagerMulte').value,
      "idCliente": this.modalNavParams.territory.idCliente,
      "newIdModuli": this.idList
    }
    this.adminTerritoryDetailSrv.modificaTerritorio(
      territory
    ).then((res: any) => {
      if (res.code == 15 || res.code == 16) {
        this.alertSrv.warningAlert({ message: this.translate.instant('errors.' + res.code) })
        this.loaderSrv.dismiss();
      }
      else if (res.code == 200) {
        this.loaderSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.modalCtrl.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loaderSrv.dismiss();

    })
  }

  //elimina territorio alert
  async eliminaTerritorioAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.delete_territory'),
      message: this.translate.instant('messages.delete_territory_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.eliminaTerritorio(this.modalNavParams.territory.idCliente);
          }
        }
      ]
    });

    await alert.present()
  }

  //eliminaTerritorio
  public eliminaTerritorio(territoryId) {
    this.loaderSrv.open();
    this.adminTerritoryDetailSrv.eliminaTerritorio(
      territoryId
    ).then((res: any) => {
      if (res.code == 200) {
        this.loaderSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.modalCtrl.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {
      
      this.loaderSrv.dismiss();

    })
  }


  //save disabled
  public saveDisabled() {
    let disabled = false;
    if (!this.territoryDetailForm.valid || this.idList.length == 0)
      disabled = true;
    else if (this.territoryDetailForm.get('type').value == '1' && !this.companyDetailForm.valid) {
      disabled = true;
    } else if (this.territoryDetailForm.get('type').value == '2' && !this.existingCompanyForm.valid) {
      disabled = true;
    }
    return disabled;
  }

  //go role search
  public goAdminRoleSearch() {
    this.storageVariableSrv.setAdminRoleSearchObject(this.modalNavParams.territory.idCliente);
    this.router.navigateByUrl('admin-role-search');
    this.modalCtrl.dismiss();
  }

  //go user search
  public goAdminUserSearch() {
    this.storageVariableSrv.setAdminUserSearchObject(this.modalNavParams.territory.idCliente, null);
    this.router.navigateByUrl('admin-user-search');
    this.modalCtrl.dismiss();
  }
}
