<ion-header>
  <div class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{modalParams?.apiResponse?.header?.title ? (modalParams.apiResponse.header.title | translate) : ''}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="modalCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
  </div>
</ion-header>
<ion-content class="news-detail-popover">
  <app-dcrud-form *ngIf="modalParams" [modalParams]="modalParams"></app-dcrud-form>
</ion-content>
<ion-footer>
  <ion-row>
    <ion-col size-md="12" size-sm="12" size-xs="12" class="col-button-box">
      <div class="button-box">
        <ion-button color="tertiary" size="default" (click)="modalCtrl.dismiss()">
          <ion-label>{{'buttons.close' | translate}}</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-footer>