import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ViewAndFineRegistrationFormComponent } from './view-and-fine-registration-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion'
import { IonicSelectableComponent } from 'ionic-selectable';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { getDateFormat, getDateTimeFormat} from 'src/app/services/app.service';

@NgModule({
  declarations: [ViewAndFineRegistrationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    MatExpansionModule,
    IonicSelectableComponent,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatDatepickerModule
  ],
  exports: [
    ViewAndFineRegistrationFormComponent
  ],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: getDateTimeFormat() }
  ]
})
export class ViewAndFineRegistrationFormComponentModule { }
